import React, { useEffect } from "react";
import gtmParts from "react-google-tag-manager";
import { useUser } from "auth";

const DATA_LAYER_CONTAINER = "dataLayer";

interface GoogleTagManagerProps {
	gtmId: string;
	dataLayerName?: string;
	scriptId?: string;
	previewVariables?: string;
	scheme?: string;
	additionalEvents?: { [index: string]: any };
}

// note: this functionality could probably be moved into `GoogleTagManagerTracker`
export class GoogleTagManager extends React.Component<GoogleTagManagerProps> {
	componentDidMount() {
		const dataLayerName = this.props.dataLayerName || "dataLayer";
		const scriptId = this.props.scriptId || "react-google-tag-manager-gtm";

		// disable for development
		if (process.env.NODE_ENV !== "development" && !window[dataLayerName]) {
			const script = document.createElement("script");
			const gtmScriptNode = document.getElementById(scriptId);
			const scriptText = document.createTextNode(gtmScriptNode!.textContent!);

			script.appendChild(scriptText);
			document!.head!.appendChild(script);
		}
	}

	render() {
		const gtm = gtmParts({
			id: this.props.gtmId,
			dataLayerName: this.props.dataLayerName || "dataLayer",
			additionalEvents: this.props.additionalEvents || {},
			previewVariables: this.props.previewVariables || false,
			scheme: this.props.scheme || "https:",
		});

		// disable GTM when in development
		if (process.env.NODE_ENV === "development") {
			return null;
		}

		return (
			<div>
				<div>{gtm.noScriptAsReact()}</div>
				<div id={this.props.scriptId || "react-google-tag-manager-gtm"}>{gtm.scriptAsReact()}</div>
			</div>
		);
	}
}

export const GoogleTagManagerTracker: React.FC = () => {
	const u = useUser();
	const userId = u?.profile?.sub;

	useEffect(() => {
		if (userId !== undefined) {
			const dataLayer = (window as any)[DATA_LAYER_CONTAINER] || [];
			dataLayer.push({ "User ID": userId });
		}
	}, [userId]);

	return null;
};
