// enum of Feature Flag constants in the system
export enum FlagId {
	DevInfoLink = "DEV_INFO_LINK",
	CopyTemplateVariation = "COPY_TEMPLATE_VARIATION",
	ObjectTutorial = "OBJECT_TUTORIAL",
	CampaignTest = "CAMPAIGN_TEST_MODE",
	AdvancedCampaignsEnabled = "ADVANCED_CAMPAIGNS_ENABLED",
	STATS_DASHBOARD_ENABLED = "STATS_DASHBOARD_ENABLED",
	AdvancedDistributionsEnabled = "ADVANCED_DISTRIBUTIONS_ENABLED",
	DeveloperMode = "DEVELOPER_MODE_ENABLED",
	ConnectTabIconEnabled = "CONNECT_TAB_ICON_ENABLED",
	FoldersAndCommunities = "FOLDERS_AND_COMMUNITIES",
	TestWizardCampaigns = "TEST_WIZARD_CAMPAIGNS_ENABLED",
	TokenGating = "TOKEN_GATING",
	Logs = "LOGS",
}

// structure of the Flags object
export interface FlagDef {
	default: any;
	title?: string; // optional display name to be used on Dev-Info page
}

// Flags object
export const flags: { [key in FlagId]: FlagDef } = {
	[FlagId.DevInfoLink]: {
		default: false,
		title: "Show Dev Info Link",
	},
	[FlagId.CopyTemplateVariation]: {
		default: false,
		title: "Show Copy Template Variation Button",
	},
	[FlagId.ObjectTutorial]: {
		default: false,
		title: "Enable Object Tutorial",
	},
	[FlagId.CampaignTest]: {
		default: false,
		title: "Enable Campaign Test Mode",
	},
	[FlagId.AdvancedCampaignsEnabled]: {
		default: false,
		title: "Advanced Campaigns Enabled",
	},
	[FlagId.STATS_DASHBOARD_ENABLED]: {
		default: false,
		title: "Stats Dashboard Enabled",
	},
	[FlagId.AdvancedDistributionsEnabled]: {
		default: false,
		title: "Advanced Distributions Enabled",
	},
	[FlagId.DeveloperMode]: {
		default: false,
		title: "Developer Mode Enabled",
	},
	[FlagId.ConnectTabIconEnabled]: {
		default: false,
		title: "Show Tab Bar Icon Show Connect",
	},
	[FlagId.FoldersAndCommunities]: {
		default: false,
		title: "Show Folders & Communities",
	},
	[FlagId.TestWizardCampaigns]: {
		default: false,
		title: "Show Test Wizard Campaign",
	},
	[FlagId.TokenGating]: {
		default: false,
		title: "Show Token Gating Under Developer Settings",
	},
	[FlagId.Logs]: {
		default: false,
		title: "Show Logs Under User Settings",
	},
};
