import history from "./history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import createSentryMiddleware from "redux-sentry-middleware";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "reducers";
import Sentry, { isEnabled as isSentryEnabled } from "./sentry";

const middlewares = [routerMiddleware(history), thunk];

// sentry middleware
if (isSentryEnabled) {
	const stateTransformer = ({ user: { variusUser } }) => ({ variusUser });
	const actionTransformer = ({ type }) => ({ type });
	middlewares.push(createSentryMiddleware(Sentry, { stateTransformer, actionTransformer }));
}

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
