import entities, { DigitalObject, Provider, Wizard } from "reducers/entitiesReducers";
import { SelectTargetsActions, SelectTargetsActionKeys } from "actions/selectTargetsActions";
import { AuthActions } from "actions/authActions";
import { UserActions, UserActionsKeys } from "actions/userActions";

export interface Facet {
	name: string;
	selectedValue: {
		value?: string;
	};
}

export interface DigitalObjectsFilters {
	query?: string;
	page?: number;
	hitsPerPage?: number;
	tags?: any;
	facets?: Facet[];
}

// Interfaces
export interface SelectTargetsState {
	totalViewers: number;
	viewersOrder: string[];
	digitalObjectsOrder: string[];
	wizardsOrder: string[];
	providersOrder: string[];
	totalDigitalObjects: number;
	isFiltering: boolean;
	filters: DigitalObjectsFilters;
}

// Initial State
const initialState: SelectTargetsState = {
	totalViewers: 0,
	viewersOrder: [],
	wizardsOrder: [],
	providersOrder: [],
	totalDigitalObjects: 0,
	digitalObjectsOrder: [],
	isFiltering: false,
	filters: {
		query: "",
		page: 0,
		hitsPerPage: 12,
		tags: [],
		facets: [],
	},
};

export default (
	state: SelectTargetsState = initialState,
	action: SelectTargetsActions | UserActions | AuthActions
): SelectTargetsState => {
	switch (action.type) {
		// Reset to initialState on logout
		case UserActionsKeys.STORE_SELECTED_BUSINESS: {
			return {
				...initialState,
			};
		}

		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case SelectTargetsActionKeys.RESET_FILTERS: {
			return {
				...state,
				filters: {
					...initialState.filters,
				},
			};
		}

		case entities.providers.actionNames.list.success: {
			const providersOrder = (action as any).data.items.map((provider: Provider) => provider.id);

			return {
				...state,
				providersOrder,
			};
		}

		case entities.wizards.actionNames.list.success: {
			const wizardsOrder = (action as any).data.items.map((wizard: Wizard) => wizard.id);

			return {
				...state,
				wizardsOrder,
			};
		}

		case entities.digitalObjects.actionNames.listSearch.success:
		case entities.digitalObjects.actionNames.list.success: {
			const { filters, isFiltering } = state;

			// this reducer should only respond to entities actions when its isFiltering flag is raised
			if (!isFiltering) {
				return {
					...state,
				};
			}

			const { page, hitsPerPage } = filters;
			const { total } = (action as any).data;

			// let currentFacets = state.filterData.currentFacets;
			const pageStartIndex = page! * hitsPerPage!;
			const newDigitalObjectsOrder = (action as any).data.items.map(
				(digitalObject: DigitalObject) => digitalObject.id
			);
			let digitalObjectsOrder = [...state.digitalObjectsOrder];

			// splice new object ids into order array at the appropriate location based on page, and make unqiue
			digitalObjectsOrder.splice(pageStartIndex, hitsPerPage!, ...newDigitalObjectsOrder);
			digitalObjectsOrder = [...Array.from(new Set(digitalObjectsOrder))];

			return {
				...state,
				totalDigitalObjects: total,
				digitalObjectsOrder,
			};
		}

		case SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_START: {
			const filters = (action as any).data.filters;
			const page = filters.page || state.filters.page;
			const totalDigitalObjects = !page ? 0 : state.totalDigitalObjects;
			const digitalObjectsOrder = !page ? [] : state.digitalObjectsOrder;

			return {
				...state,
				totalDigitalObjects,
				digitalObjectsOrder,
				isFiltering: true,
				filters: {
					...state.filters,
					...filters,
				},
			};
		}

		case SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS:
		case SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR: {
			return {
				...state,
				isFiltering: false,
			};
		}

		default: {
			return state;
		}
	}
};

const selectTargetsState = state => state.selectTargets as SelectTargetsState;

// Selectors
export const selectors = {
	totalViewers: state => selectTargetsState(state).totalViewers,
	viewersOrder: state => selectTargetsState(state).viewersOrder,
	wizardsOrder: state => selectTargetsState(state).wizardsOrder,
	totalDigitalObjects: state => selectTargetsState(state).totalDigitalObjects,
	digitalObjectsOrder: state => selectTargetsState(state).digitalObjectsOrder,
	providersOrder: state => selectTargetsState(state).providersOrder,
	filters: state => selectTargetsState(state).filters,
	isFiltering: state => selectTargetsState(state).isFiltering,
};
