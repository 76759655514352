import React, { useEffect, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "reducers/userReducers";
import userActions from "actions/userActions";
import businessActions from "actions/businessActions";
import authActions from "actions/authActions";

import { useRouteMatch } from "react-router";
import { useUser } from "auth";
import PageLoader from "components/App/components/PageLoader";

const NotFound = lazy(() => import("components/App/NotFound"));

interface RouteParamProps {
	businessName: string;
}

export interface InjectedBusinessProps {
	businesses: string[];
	selectedBusiness: string;
	storeSelectedBusiness(businessName: string): any;
	getFonts(businessId: string): any;
	match: any;
	children?: any;
}

class BusinessRequiredContainer extends React.Component<InjectedBusinessProps> {
	constructor(props) {
		super(props);

		const businessName = this.props.match!.params!.businessName;
		const { selectedBusiness } = this.props;

		// when the user has switched to a new business URL, update the selectedBusiness in redux
		if (businessName !== selectedBusiness) {
			this.props.storeSelectedBusiness(businessName);
			this.props.getFonts(businessName);
		}
	}

	componentDidUpdate() {
		const businessName = this.props.match!.params.businessName;
		const { selectedBusiness } = this.props;

		// when the user has switched to a new business URL, update the selectedBusiness in reduxA
		if (selectedBusiness && businessName !== selectedBusiness) {
			this.props.storeSelectedBusiness(businessName);
			this.props.getFonts(businessName);
		}
	}

	render() {
		const businessName = this.props.match!.params!.businessName;
		const { businesses, selectedBusiness, children } = this.props;
		const validBusiness: boolean = businesses
			? businesses.includes(businessName) || businesses.includes("*")
			: false;
		// must request a valid business
		if (!validBusiness) {
			return <NotFound />;
		}

		if (selectedBusiness === undefined) {
			return <PageLoader />;
		}

		return <>{children}</>;
	}
}

const BusinessRequired = ({ children }) => {
	const businesses = useSelector(userSelectors.businesses);
	const selectedBusiness = useSelector(userSelectors.selectedBusiness);
	const dispatch = useDispatch();
	const match = useRouteMatch<RouteParamProps>();

	const storeSelectedBusiness = (businessName: string) =>
		dispatch(userActions.storeSelectedBusiness(businessName));
	const getFonts = () => dispatch(businessActions.getFonts());

	const user = useUser();
	const userId = user?.profile?.sub;

	useEffect(() => {
		if (userId !== undefined) {
			dispatch(authActions.getUserRoles(userId));
		}
	}, [userId, dispatch]);

	if (businesses === undefined) {
		return null;
	}

	return (
		<BusinessRequiredContainer
			businesses={businesses}
			selectedBusiness={selectedBusiness as any}
			storeSelectedBusiness={storeSelectedBusiness}
			getFonts={getFonts}
			match={match}
		>
			{children}
		</BusinessRequiredContainer>
	);
};

export default BusinessRequired;
