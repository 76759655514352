import { Space } from "reducers/entitiesReducers";

export type SpacesActions =
  | SpacesListSuccessAction
  | SpacesListFailureAction;

export enum SpacesActionsKeys {
  SPACES_LIST_SUCCESS = "SPACES/SPACES_LIST_SUCCESS",
  SPACES_LIST_FAILURE = "SPACES/SPACES_LIST_FAILURE",
}

interface Action<Type> {
  type: Type;
  data?: any;
  error?: string;
}

export interface SpacesListSuccessAction extends Action<SpacesActionsKeys.SPACES_LIST_SUCCESS> {
  data: Space[];
}

export interface SpacesListFailureAction extends Action<SpacesActionsKeys.SPACES_LIST_FAILURE> {
  error: string;
}

const actions = {
  spacesListSuccess: (data: Space[]) => ({
    type: SpacesActionsKeys.SPACES_LIST_SUCCESS,
    data,
  }),

  spacesListFailure: (error: string) => ({
    type: SpacesActionsKeys.SPACES_LIST_FAILURE,
    error,
  }),
};

export default actions;
