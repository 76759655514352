import VatomInc from "./index";
import { Design } from "reducers/entitiesReducers";

export default class VatomIncDesigns {
	constructor(private varius: typeof VatomInc) {}

	public async listDesigns(typeId: string): Promise<Design[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/types/${typeId}/designs`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body.items;
	}

	public async getDesign(designId: string): Promise<Design> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/designs/${designId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Design could not be loaded.");
		}

		// todo: check `status` in body
		return res.body;
	}
}
