import { BusinessActionKeys, BusinessActions } from "actions/businessActions";
import entities, { Provider, Service } from "./entitiesReducers";
import { UserActionsKeys, UserActions } from "actions/userActions";
import { Invite } from "utils/VatomIncApi/account";

export interface ServiceOrder {
	email: string[];
	sms: string[];
	ecommerce: string[];
	coupon: string[];
	other: string[];
	social: string[];
	system: string[];
	push: string[];
}

export interface ProviderOrder {
	email: string[];
	sms: string[];	
	ecommerce: string[];
	coupon: string[];
	other: string[];
	social: string[];
	system: string[];
	push: string[]
}

export interface TxtInfo {
	recordName: string;
	txtValue: string;
}

// Interfaces
export interface BusinessState {
	isSendingInvites: boolean;
	inviteBusinessError?: any;
	inviteEmails: Invite[];
	maxSeats: number;
	providersOrder: ProviderOrder;
	servicesOrder: ServiceOrder;
	deletingProviderId?: string;
	domain: string;
	domainLoadStatus: string;
	domainTxtInfo: TxtInfo | undefined;
	isGettingFonts?: boolean;
	getFontsError?: any;
	fonts?: any[];
}

const INITIAL_PROVIDERS_ORDER = {
	email: [],
	sms: [],
	ecommerce: [],
	coupon: [],
	other: [],
	social: [],
	system: [],
	push: []
};

// Initial State
const initialState: BusinessState = {
	isSendingInvites: false,
	maxSeats: 5,
	inviteEmails: [{ email: "", id: "1" }, { email: "", id: "2" }, { email: "", id: "3" }],
	providersOrder: INITIAL_PROVIDERS_ORDER,
	servicesOrder: {
		email: [],
		sms: [],
		ecommerce: [],
		coupon: [],
		other: [],
		social: [],
		system: [],
		push: []
	},
	domain: "",
	domainLoadStatus: "",
	domainTxtInfo: undefined,
};

export default (state: BusinessState = initialState, action: UserActions | BusinessActions): BusinessState => {
	switch (action.type) {
		// case BusinessActionKeys.SETUP_INVITES: {
		// 	return {
		// 		...state,
		// 		inviteEmails: action.data.invites,
		// 		maxSeats: action.data.maxSeats,
		// 	};
		// }

		case BusinessActionKeys.GET_BUSINESS_FONTS_START: {
			return {
				...state,
				isGettingFonts: true,
			};
		}

		case BusinessActionKeys.GET_BUSINESS_FONTS_ERROR: {
			return {
				...state,
				isGettingFonts: false,
				getFontsError: action.data.error,
			};
		}

		case BusinessActionKeys.GET_BUSINESS_FONTS_SUCCESS: {
			return {
				...state,
				isGettingFonts: false,
				fonts: action.data.fonts,
			};
		}

		// case BusinessActionKeys.UPDATE_BUSINESS_INVITE: {
		// 	const { email, index } = action.data;
		// 	const inviteEmails = [...state.inviteEmails];

		// 	inviteEmails[index].email = email;
		// 	inviteEmails[index].errored = false;
		// 	inviteEmails[index].sending = false;
		// 	inviteEmails[index].sent = false;

		// 	// when editing the last email in the list, push an empty one into the list (limit maxSeats)
		// 	if (inviteEmails.length < state.maxSeats && index === inviteEmails.length - 1) {
		// 		inviteEmails.push({ email: "", id: index.toString() });
		// 	}

		// 	return {
		// 		...state,
		// 		inviteEmails,
		// 	};
		// }

		// case BusinessActionKeys.SEND_INVITES_START: {
		// 	const { recipientIndices } = action.data;
		// 	const inviteEmails = [...state.inviteEmails];

		// 	// mark the emails as sending for UI purposes
		// 	recipientIndices.map((index: number) => (inviteEmails[index].sending = true));

		// 	return {
		// 		...state,
		// 		isSendingInvites: true,
		// 		inviteEmails,
		// 	};
		// }

		// case BusinessActionKeys.SEND_INVITES_SUCCESS: {
		// 	const { recipientIndices } = action.data;
		// 	const inviteEmails = [...state.inviteEmails];
			
		// 	// mark the emails as sent for UI purposes
		// 	for (const index of recipientIndices) {
		// 		inviteEmails[index].sent = true;
		// 		inviteEmails[index].sending = false;
		// 	}

		// 	return {
		// 		...state,
		// 		isSendingInvites: false,
		// 		inviteBusinessError: null,
		// 	};
		// }

		// case BusinessActionKeys.SEND_INVITES_ERROR: {
		// 	const { recipientIndices } = action.data;
		// 	const inviteEmails = [...state.inviteEmails];
			
		// 	// mark the emails as sent for UI purposes
		// 	for (const index of recipientIndices) {
		// 		inviteEmails[index].errored = true;
		// 		inviteEmails[index].sending = false;
		// 	}

		// 	return {
		// 		...state,
		// 		isSendingInvites: false,
		// 		inviteBusinessError: action.data.error,
		// 		inviteEmails,
		// 	};
		// }
		// case BusinessActionKeys.RESET_INVITES: {
		// 	return {
		// 		...state,
		// 		inviteEmails: [{ email: "", id: "1" }, { email: "", id: "2" }, { email: "", id: "3" }],
		// 	};
		// }

		case entities.providers.actionNames.archive.start: {
			const deletingProviderId = (action as any).data.id;

			return {
				...state,
				deletingProviderId,
			};
		}

		case entities.providers.actionNames.archive.error:
		case entities.providers.actionNames.archive.success: {
			return {
				...state,
				deletingProviderId: undefined,
			};
		}

		case entities.services.actionNames.list.success: {
			const services = (action as any).data.items;

			const servicesOrder: ServiceOrder = {
				email: [],
				sms: [],
				ecommerce: [],
				coupon: [],
				other: [],
				social: [],
				system: [],
				push: []
			};

			services.map((service: Service) => servicesOrder[service.type].push(service.id));
			
			return {
				...state,
				servicesOrder,
			};
		}

		case entities.providers.actionNames.list.success: {
			const providers = (action as any).data.items;
			const providersOrder: ProviderOrder = {
				email: [],
				ecommerce: [],
				coupon: [],
				other: [],
				social: [],
				system: [],
				sms: [],
				push: []
			};

			// sort providers to put default ones first
			providers.sort((a: Provider, b: Provider) =>
				a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1
			);
			
			providers.map((provider: Provider) => providersOrder[provider.typeId]?.push(provider.id));
			
			return {
				...state,
				providersOrder,
			};
		}

		case "business.get.domain.pending" as any: {
			return {
				...state,
				domainLoadStatus: "pending",
				domain: "",
				domainTxtInfo: undefined,
			};
		}

		case "business.get.domain.success" as any: {
			return {
				...state,
				domainLoadStatus: "success",
				domain: (action as any).data.value,
				domainTxtInfo: (action as any).data.txtInfo,
			};
		}

		case "business.get.domain.failure" as any: {
			return {
				...state,
				domainLoadStatus: "failure",
				domain: "",
			};
		}
		
		case UserActionsKeys.STORE_SELECTED_BUSINESS: {
			return {
				...state,
				providersOrder: INITIAL_PROVIDERS_ORDER,
			};
		}

		default: {
			return state;
		}
	}
};

const businessState = state => state.business as BusinessState;

// Selectors
export const selectors = {
	isSendingInvites: state => businessState(state).isSendingInvites,
	inviteBusinessError: state => businessState(state).inviteBusinessError,
	inviteEmails: state => businessState(state).inviteEmails,
	providersOrder: state => businessState(state).providersOrder,
	deletingProviderId: state => businessState(state).deletingProviderId,
	servicesOrder: state => businessState(state).servicesOrder,
	isGettingFonts: state => businessState(state).isGettingFonts,
	getFontsError: state => businessState(state).isGettingFonts,
	fonts: state => businessState(state).fonts,
};
