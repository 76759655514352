import VatomInc from "./index";
import { View } from "reducers/entitiesReducers";

export default class VatomIncViews {
	constructor(private varius: typeof VatomInc) {}

	public async getView(viewId: string): Promise<View> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/views/${viewId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("View could not be loaded.");
		}

		// todo: check `status` in body
		return res.body;
	}
}
