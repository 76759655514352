import React from "react";
import { combineReducers } from "redux";
import entity from "utils/makeEntity";
import VatomInc from "utils/VatomIncApi";
import { DigitalObjectsFilters } from "./digitalObjectsReducers";

export interface SearchResults<Entity> {
	total: number;
	totalPages: number;
	items: Entity;
	newlyAvailableFacets?: any[];
}

export interface BusinessPageConfig {
	theme: {
		header: {
			logo: string;
		};
		iconTitle: React.CSSProperties;
		icon: React.CSSProperties;
		main: React.CSSProperties;
		emptyState: React.CSSProperties;
        pointsHeader?: React.CSSProperties;
		mode: "light" | "dark" | "system"
    };
	text: {
		emptyState: string;
	};
    features: {
		vatom: {
            autoOpen?: string[],
			pinned?: string[],
			disableNewTokenToast: boolean,
			ignoreNewTokenToast: string[],
        },
        pointsHeader?: {
            enabled?: boolean,
			position?: string
        },
        icon: {
            badges?: boolean,
            editions?: boolean,
            title?: boolean
        }
    },
}

export interface BusinessRewardsSettings {
	enabled: boolean;
	logo?: string;
	name?: string | undefined;
	plural_name?: string | undefined;
	symbol?: string;
	type?: string;
}

export interface Business {
	id: string;
	name: string;
	displayName: string;
	isAgency: boolean;
	parentBusinessId: string;
	ownerId: string; //
	url?: string;
	location?: string;
	logoSrc?: string;
	logoSrcTemporary?: string;
	ethAccount?: string;
	ownerOperated?: boolean;
	defaultCampaignId?: string;
	paymentMethod?: string; // This can only be updated by system admins
	pageConfig?: BusinessPageConfig;
	coin?: BusinessRewardsSettings;
	viewerId?:string;
	defaultHostname?:string
}

export interface IsBusinessAdmin {
	isBusinessAdmin: boolean;
}



export interface BusinessStats {
	emitted: number;
}

export interface BlueprintBehavior {
	[index: string]: any;
}

export interface Blueprint {
	behaviors: BlueprintBehavior;
	id: string;
	uri: string;
	displayName: string;
	description: string;
	draft: boolean;
	updatedAt: string;
	version: string;
	cover: {
		type: string;
		url: string;
	};
	configSchema?: any;
	full?: boolean;
}

export interface BlueprintMap {
	[id: string]: Blueprint;
}

const blueprints = entity<Blueprint>("blueprints", "id", {
	list: async (): Promise<Blueprint[]> => {
		return VatomInc.blueprints.listBlueprints();
	},
	get: async (id: string): Promise<Blueprint> => {
		return VatomInc.blueprints.getBlueprint(id);
	},
});

export interface Type {
	id: string;
	uri: string;
	displayName: string;
	description: string;
	updatedAt: string;
	createdAt: string;
	cover: {
		type: string;
		url: string;
	};
	example: string;
	hero: string;
	tags: string[]
}

export interface TypeMap {
	[id: string]: Type;
}

const types = entity<Type>("types", "id", {
	list: async (): Promise<Type[]> => {
		return VatomInc.types.listTypes();
	},
});

// TODO: update this
export interface View {
	[id: string]: any;
}

export interface ViewMap {
	[id: string]: View;
}

const views = entity<View>("views", "id", {
	get: async (id: string): Promise<View> => {
		return VatomInc.views.getView(id);
	},
});

export interface Design {
	id: string;
	displayName: string;
	businessName: string;
	name: string;
	description: string;
	cover: {
		type: string;
		url: string;
	};
	full?: boolean;
	views?: any[];
	entrypoint: string;
	typeId: string;
	blueprintId: string;
}

export interface DesignMap {
	[designId: string]: Design;
}

const designs = entity<Design>("designs", "id", {
	list: async (params: any): Promise<Design[]> => {
		return VatomInc.designs.listDesigns(params.typeId);
	},
	get: async (id: string): Promise<Design> => {
		return VatomInc.designs.getDesign(id);
	},
});

export enum ObjectDefinitionStage {
	Published = "published",
	Draft = "draft",
	Test = "test",
}

export interface DigitalObject {
	blueprintId: string;
	blueprintName: string;
	businessId: string;
	campaignId?: string;
	category?: string;
	blueprintValues?: {};
	designValues?: {};
	designValues2?: [];
	cover: {
		type: string;
		url: string;
	};
	createdAt: string;
	description?: string;
	designId: string;
	designName: string;
	displayName: string;
	stage: ObjectDefinitionStage;
	isExplicit?: boolean;
	id: string;
	objectID?: string;
	testProviderInfo?: { [index: string]: any };
	providerInfo?: { [index: string]: any };
	updatedAt: string;
	isFullyHydrated: boolean;
	_highlightResut?: any;
}

export interface DigitalObjectMap {
	[digitalObjectName: string]: DigitalObject;
}

const digitalObjects = entity<DigitalObject>("digitalObjects", "id", {
	list: async (filters?: DigitalObjectsFilters): Promise<DigitalObject[]> => {
		return VatomInc.digitalObjects.listDigitalObjects({
			facets: [],
			tags: [],
			...filters,
			// force some filters to keep this working like API
			page: 0,
			hitsPerPage: 1000,
		});
	},
	listSearch: async (filters: DigitalObjectsFilters): Promise<SearchResults<DigitalObject[]>> => {
		if (process.env.REACT_APP_NO_ALGOLIA === "true") {
			return VatomInc.digitalObjects.hackedListSearchDigitalObjects();
		} else {
			return VatomInc.digitalObjects.listSearchDigitalObjects(filters);
		}
	},
	get: async (id: string): Promise<DigitalObject> => {
		return VatomInc.digitalObjects.getDigitalObject(id);
	},
	update: async (id: string, data: Partial<DigitalObject>): Promise<DigitalObject> => {
		return VatomInc.digitalObjects.updateDigitalObject(id, data);
	},
	create: async (data: Partial<DigitalObject>): Promise<DigitalObject> => {
		return VatomInc.digitalObjects.createDigitalObject(data);
	},
	clone: async (id: string): Promise<DigitalObject> => {
		return VatomInc.digitalObjects.cloneDigitalObject(id);
	},
	archive: async (id: string): Promise<DigitalObject> => {
		return VatomInc.digitalObjects.archiveDigitalObject(id);
	},
});

export interface CampaignMap {
	[campaignId: string]: Campaign;
}

export enum CampaignStage {
	None = "",
	Draft = "draft",
	Test = "test",
	Published = "published",
}

export interface Campaign {
	id: string;
	groupCampaignId: string;
	revision: number;
	numRevisions?: number;
	displayName: string;
	startDate: string;
	startDateTimezone: string;
	endDate: string;
	endDateTimezone: string;
	unitsBudgeted?: number;
	unitType: string;
	createdAt: string;
	viewerId?: string;
	updatedAt: string;
	businessId: string;
	type: any;
	cover: {
		type: string;
		url: string;
	};
	uri: string;
	budget: number;
	wsUrl: string;
	stage: CampaignStage;
	testCampaignId: string | undefined;
	isRevision?: boolean;
	futureStage: CampaignStage | undefined | null;
	isActive: boolean;
	isUpcoming: boolean;
	isExpired: boolean;
	dashboardUrl: string;
	dashboardSummaryUrl: string;
	latestRevision: {
		stage: CampaignStage;
		revision: number;
	};
	userWizardId?: string;
	attemptedFutureStage: CampaignStage;
	errorLog?:string
}

export interface CampaignStats {
    available: { [key: string]: number; },
	inventory: { [key: string]: number; },
    emitted: number,
}

export interface ObjectDistributionStats {
		[key: string]: number
}

export interface CampaignDistributionStats {
	[key: string] : ObjectDistributionStats
}

const campaigns = entity<Campaign>("campaigns", "id", {
	list: async (): Promise<Campaign[]> => {
		return VatomInc.campaignGroups.listCampaignGroups();
	},
	get: async (id: string, revision?: number): Promise<Campaign> => {
		if (revision) {
			return VatomInc.campaignGroups.getCampaignGroupRevision(id, revision);
		}

		return VatomInc.campaignGroups.getCampaignGroup(id);
	},
	create: async (data: Partial<Campaign>): Promise<Campaign> => {
		return VatomInc.campaigns.createCampaign(data);
	},
	update: async (id: string, data: Partial<Campaign>): Promise<Campaign> => {
		return VatomInc.campaignGroups.updateCampaignGroup(id, data);
	},
	clone: async (id: string): Promise<Campaign> => {
		return VatomInc.campaigns.cloneCampaign(id);
	},
	archive: async (groupId: string, force: boolean): Promise<boolean> => {
		return VatomInc.campaignGroups.archiveCampaignGroup(groupId, force);
	},
	version: async (id: string): Promise<Campaign> => {
		return VatomInc.campaignGroups.versionCampaignGroup(id);
	},
});

const digitalObjectCampaigns = entity<Campaign>("digitalObjectCampaigns", "groupCampaignId", {
	list: async (id: string): Promise<Campaign[]> => {
		return VatomInc.digitalObjects.getDigitalObjectCampaigns(id);
	},
});

export interface Space {
    id: string;
    url: string;
    displayName: string;
    subTitle: string;
    description: string;
    createdAt: string;
    createdById: string;
    updatedAt: string;
    size: string;
    status: string;
    businessId: string;
    communityId: string | null;
    addressable: boolean;
    alias: string;
    cover: string;
    accessLevel: string;
    dashboardAnalyticsUrl: string;
    detailedDashboardAnalyticsUrl: string;
    startedAt: string;
    stoppedAt: string;
    provisionId: string;
    additionalAuth: Record<string, any>;
    entryCoordinates: Record<string, any>;
    browserCompatibility: Record<string, any>;
    attendeeCount: number;
    authorization: string | null;
    path: string | null;
    compatibility: Record<string, any>;
    locked: boolean;
    config: any;
    guestList: any[];
    matrixRoomId: string;
    maxAttendees: number;
    members: number;
    createdByDisplayName: string;
}

const spaces = entity<Space>("spaces", "id", {
	list: async (): Promise<Space[]> => {
		const response = await VatomInc.events.getAllBusinessSpaces()
		return response.items;
	},
});

export interface Behavior {
	id: string;
	uri: string;
	version: string;
	displayName: string;
	description: string;
	draft: boolean;
	createdAt: string;
	updatedAt: string;
	configSchema: {};
	stateSchema: {};
	actions: {};
}

const behaviors = entity<Behavior>("behaviors", "id", {
	get: async (id: string): Promise<Behavior> => {
		return VatomInc.behaviors.getBehavior(id);
	},
	list: async (type): Promise<Behavior[]> => {
		return VatomInc.behaviors.listBehaviors(type);
	},
});

export interface Viewer {
	id: string;
	displayName: string;
	businessId: string;
	distributionUrl?: string;
	entrypoint?: string;
	isExplicit?: boolean;
	cover: {
		type: string;
		url: string;
	};
	designValues?: any;
	createdAt: string;
	updatedAt: string;
	uri: string;
	appId: string;
	parentUrl?: string;
	config?: any;
}

export interface ViewerMap {
	[viewerId: string]: Viewer;
}

export interface CampaignEntity {
	id: string;
	type: "object-definition" | "viewer" | "provider" | "service" | "user" | "space" | "plugin";
}

export interface Rule {
	id: string;
	uri?: string;
	createdBy?: string;
	createdAt?: string;
	trigger: Trigger;
	effects: Effect[];
	isTriggerValid?: boolean;
	isEffectValid?: boolean;
	isValid?: boolean;
}

export interface RuleMap {
	[ruleId: string]: Rule;
}

const rules = entity<Rule>("rules", "id", {
	list: async (campaignId: string): Promise<Rule[]> => {
		return VatomInc.rules.listRules(campaignId);
	},
	update: async (id: string, data: Partial<Rule>, [campaignId]: string[]): Promise<Rule> => {
		return VatomInc.rules.updateRule(campaignId, id, data);
	},
	create: async (data: Partial<Rule>, [campaignId]: string[]): Promise<Rule> => {
		return VatomInc.rules.createRule(campaignId, data);
	},
	archive: async (id: string, [campaignId]: string[]): Promise<Rule> => {
		return VatomInc.rules.deleteRule(campaignId, id);
	},
});

export interface ObjectDefinitionRuleEntity {
	id: string;
	displayName: string;
	cover: {
		type: string;
		url: string;
	};
	type: "object-definition";
	isExplicit: boolean;
	providerInfo?: any;
	behaviors: { [behaviorUri: string]: Behavior };
}

export interface ViewerRuleEntity {
	id: string;
	displayName: string;
	cover: {
		type: string;
		url: string;
	};
	type: "viewer";
	providerInfo?: any;
	distributionUrl: string;
}
export interface SpaceRuleEntity {
	id: string;
	displayName: string;
	cover: string;
	type: "space";
	isExplicit:boolean;
}

export interface ProviderRuleEntity {
	id: string;
	displayName: string;
	type: "provider";
	isExplicit: boolean;
}

export interface UserRuleEntity {
	id: string;
	displayName: "User";
	type: "user";
	isExplicit: boolean;
}

export type RuleEntity = ObjectDefinitionRuleEntity | ViewerRuleEntity;

const rulesEntities = entity<RuleEntity[]>("rulesEntities", "id", {
	get: async (campaignId: string): Promise<RuleEntity[]> => {
		return VatomInc.rulesEntities.getRulesEntities(campaignId);
	},
	update: async (campaignId: string, data: any): Promise<any> => {
		return VatomInc.rulesEntities.updateRulesEntities(campaignId, data);
	},
});

export interface ConfigSchema {
	$schema: string;
	required?: string[];
	properties: { [propertyName: string]: any };
}

export interface Trigger {
	id?: string;
	uri?: string;
	source: CampaignEntity;
	displayName?: string;
	configSchema?: ConfigSchema;
	callSchema?: ConfigSchema;
	config: {};
	sourceIdConfigPointer?: string;
	hasNonce?: boolean;
	isArchived: boolean;
}

export interface TriggerMap {
	[triggerId: string]: Trigger;
}

const triggers = entity<Trigger>("triggers", "id", {
	list: async ({
		source,
		campaignId,
	}: {
		source: CampaignEntity;
		campaignId: string;
	}): Promise<Trigger[]> => {
		return VatomInc.campaigns.listTriggers(source, campaignId);
	},
});

export interface Effect {
	id: string;
	uri: string;
	displayName: string;
	config: {};
	configSchema?: ConfigSchema;
	isNonceEnabled?: boolean;
	isArchived: boolean;
}

export interface EffectMap {
	[effectId: string]: Effect;
}

const effects = entity<Effect>("effects", "id", {
	get: async (effectId: string): Promise<Effect> => {
		return VatomInc.effects.getEffect(effectId);
	},
	list: async (businessId?:string): Promise<Effect[]> => {
		return VatomInc.effects.listEffects(businessId);
	},
});

export interface Prize {
	id: number;
	maxAvailable: number;
	limitPerUser: number;
	probability?: number;
	sequence?: number;
	objectDefinitionId: string;
}

export enum PrizeSetType {
	Probability = "probability",
	Sequence = "sequential",
}

export interface PrizeSet {
	displayName: string;
	id: string;
	type: PrizeSetType;
	prizes: Prize[];
}

export interface PrizeSetMap {
	[prizeSetId: string]: PrizeSet;
}


export interface ContactList {
	id: string;
	displayName: string;
	createdAt: string;
	count: number;
}

const contactLists = entity<ContactList>("contactLists", "id", {
	list: async (): Promise<ContactList[]> => {
		return VatomInc.contactLists.listContactLists();
	},
	create: async (item: Partial<ContactList>): Promise<ContactList> => {
		return VatomInc.contactLists.create(item);
	},
});

export enum DistributionStage {
	Pending = "pending",
	Active = "active",
	Failed = "failed",
	// todo: there may be more
}

export interface Distribution {
	id: string;
	type: string;
	displayName: string;
	createdAt: string;
	stage: DistributionStage;

	sentCount: number;
	openedCount: number;
	clickedCount: number;
	claimedCount: number;

	images?: any[];
	map?: any;
	// pvid?: string;
	stats?: any;

	objectDefinitionId?: string;
	scheduledDate?: string;
	campaignId?: string;
	groupCampaignId?: string;
	
	qr?: {
		pvid?: string; // deprecated
		emitUrl?: string;
	};
}

const distributions = entity<Distribution>("distributions", "id", {
	list: async (groupCampaignId: string): Promise<Distribution[]> => {
		return VatomInc.distributions.list(groupCampaignId);
	},
	create: async (item: any): Promise<Distribution> => {
		return VatomInc.distributions.createDistribution(item as any);
	},
	get: async (campaignGroupId: string, id: string): Promise<Distribution> => {
		return VatomInc.distributions.get(campaignGroupId, id);
	},
	update: async (id: string, data: Partial<Distribution>, [type]): Promise<Distribution> => {
		return VatomInc.distributions.updateDistribution(id, data, type);
	},
});

export interface Provider {
	id: string;
	displayName: string;
	createdAt: string;
	businessId: string;
	typeId: string;
	serviceId: string;
	config: any;
	isDefault: boolean;
	hasTriggers?: boolean;
	iconSrc?: string;
}

export interface ProviderMap {
	[providerId: string]: Provider;
}

const providers = entity<Provider>("providers", "id", {
	list: async (): Promise<Provider[]> => {
		return VatomInc.providers.listProviders();
	},
	archive: async (providerId: string): Promise<Provider> => {
		return VatomInc.providers.deleteProvider(providerId);
	},
	create: async (data: Partial<Provider>, [typeId, serviceId]: string[]): Promise<Provider> => {
		return VatomInc.providers.createProvider(typeId, serviceId, data);
	},
	update: async (
		providerId: string,
		data: Partial<Provider>,
		[typeId, serviceId]: string[]
	): Promise<Provider> => {
		return VatomInc.providers.updateProvider(typeId, serviceId, providerId, data);
	},
});

export interface Service {
	id: string; // This is a weird composite of serviceId:type - not sure why
	serviceId: string;
	displayName: string;
	type: string;
	iconSrc: string;
	configSchema: any;
}

export interface ServiceMap {
	[serviceId: string]: Service;
}

const services = entity<Service>("services", "id", {
	list: async (): Promise<Service[]> => {
		return VatomInc.providers.listServices();
	},
});

export enum PrereqKey {
	Pay = "pay",
	Domain = "domain",
	Shopify = "shopify",
}

export enum PrereqPriority {
	Required = "required",
	Warn = "warn",
}

export interface Wizard {
	id: string;
	displayName: string;
	description: string;
	createdAt: string;
	updatedAt: string;
	cover: {
		type: string;
		url: string;
	};
	configSchema: any;
	businessId: string;
	hero: string;
	prereqs: { [key in PrereqKey]?: PrereqPriority };
	isDraft: boolean;
}

export interface WizardMap {
	[wizardId: string]: Wizard;
}

const wizards = entity<Wizard>("wizards", "id", {
	list: async (): Promise<Wizard[]> => {
		return VatomInc.wizards.listWizards();
	},
	get: async (wizardId: string, businessId?: string): Promise<Wizard> => {
		return VatomInc.wizards.getWizard(wizardId, businessId);
	},
});

export enum UserWizardStatus {
	LIVE = "live",
	PUBLISHED = "published",
	ARCHIVED = "archived",
}

export interface UserWizard {
	id: string;
	userId: string;
	businessId: string;
	campaignId: string;
	campaignGroupId: string;
	wizardId: string;
	config: any;
	createdAt: string;
	updatedAt: string;
	step?: number;
	status: UserWizardStatus;
	distributions: string[];
	isTesting?: boolean;
}

export interface UserWizardMap {
	[businessId: string]: UserWizard;
}

const userWizards = entity<UserWizard>(
	"userWizards",
	"id",
	{
		list: async (userId: string): Promise<UserWizard[]> => {
			return VatomInc.userWizards.listUserWizards(userId);
		},
		get: async (businessId: string, campaignGroupId: string): Promise<UserWizard> => {
			return VatomInc.userWizards.getCampaignWizard(businessId, campaignGroupId);
		},
		create: async (body: any): Promise<UserWizard> => {
			return VatomInc.userWizards.createUserWizard(body);
		},
		update: async (userWizardId: string, body: Partial<UserWizard>) => {
			return VatomInc.userWizards.updateCampaignWizard(userWizardId, body);
		},
		archive: async (userWizardId: string) => {
			return VatomInc.userWizards.deleteUserWizard(userWizardId);
		},
		publish: async (campaignGroupId: string, data: any): Promise<UserWizard> => {
			const body = {
				stage:  data?.isTesting ? "test" : "published"
			}

			return VatomInc.userWizards.publishCampaignWizard(campaignGroupId, body);
		},
	},
	{
		clearOnBusinessSwitch: false,
	}
);

export default {
	providers,
	blueprints,
	designs,
	views,
	digitalObjects,
	digitalObjectCampaigns,
	campaigns,
	behaviors,
	rules,
	rulesEntities,
	triggers,
	effects,
	contactLists,
	distributions,
	types,
	services,
	spaces,
	wizards,
	userWizards,
	reducer: combineReducers({
		blueprints: blueprints.reducer as any,
		designs: designs.reducer as any,
		views: views.reducer as any,
		digitalObjects: digitalObjects.reducer as any,
		digitalObjectCampaigns: digitalObjectCampaigns.reducer as any,
		campaigns: campaigns.reducer as any,
		behaviors: behaviors.reducer as any,
		rules: rules.reducer as any,
		rulesEntities: rulesEntities.reducer as any,
		triggers: triggers.reducer as any,
		effects: effects.reducer as any,
		contactLists: contactLists.reducer as any,
		distributions: distributions.reducer as any,
		types: types.reducer as any,
		providers: providers.reducer as any,
		services: services.reducer as any,
		spaces: spaces.reducer as any,
		wizards: wizards.reducer as any,
		userWizards: userWizards.reducer as any,
	}),
};