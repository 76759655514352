import VatomInc from "./index";
import { RuleEntity } from "reducers/entitiesReducers";

export default class VatomIncRulesEntities {
	constructor(private varius: typeof VatomInc) {}

	public async getRulesEntities(campaignId: string): Promise<any> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/entities`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign Entities could not be loaded.");
		}

		// todo: check `status` in res
		return res.body.items;
	}

	public async updateRulesEntities(campaignId: string, data: any): Promise<RuleEntity[]> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/entities`,
			isAuthed: true,
			body: {
				...data,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign Entities could not be loaded.");
		}

		// todo: check `status` in res
		return { ...res.body, id: campaignId };
	}
}
