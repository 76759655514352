import React from "react";
import { Container, Row, Column, Loader } from "@varius.io/wombo";
import { withTheme, ThemeResult } from "@varius.io/wombo";

export interface LoaderProps {
	theme: ThemeResult;
}

const PageLoader = ({ theme }: LoaderProps) => (
	<Container narrow>
		<Row>
			<Column
				xs="12"
				style={{
					marginTop: theme.rhythm(1.25),
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<Loader isLoading center />
			</Column>
		</Row>
	</Container>
);

export default withTheme(PageLoader);
