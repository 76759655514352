import React from "react";
import Sentry from "utils/sentry";
import { HttpError } from "utils/VatomIncApi";
import { Column, Container, Modal, Row, Text, ThemeResult, withTheme } from "@varius.io/wombo";
import styled from "@emotion/styled";

const StyledDetails = styled<any>("details")`
	text-align: left;
	margin: 0 auto;
	max-width: 650px;
	width: 100%;
	white-space: pre-wrap;
`;

interface HttpErrorBoundaryProps {
	theme: ThemeResult;
	children?:any
}

class HttpErrorBoundary extends React.Component<HttpErrorBoundaryProps> {
	state = {
		hasError: false,
		error: undefined as HttpError | undefined,
	};

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error) {
		if (error instanceof HttpError === false) {
			throw error;
		}

		this.setState({ error });

		Sentry.withScope(scope => {
			const { headers, body } = error;
			scope.setExtra("headers", JSON.stringify(headers));
			scope.setExtra("body", JSON.stringify(body));
			Sentry.captureException(error);
		});
	}

	render() {
		const { children, theme } = this.props;
		const { error, hasError } = this.state;

		if (hasError) {
			return (
				<Modal
					showCloseIcon={false}
					isOpen={error !== undefined}
					onClose={() => {
						this.setState({ error: undefined });
					}}
				>
					<Container narrow>
						<Row
							style={{
								margin: theme.rhythm(3),
								textAlign: "center",
							}}
						>
							<Column>
								<Text renderAs="h2">We're sorry, but an HTTP error occurred.</Text>
								<Text
									renderAs="h5"
									style={{
										margin: `${theme.rhythm(1)} 0 ${theme.rhythm(1)}`,
									}}
								>
									There was an error making a request to our server.
								</Text>
								{process.env.NODE_ENV !== "production" && (
									<StyledDetails>
										<Text
											style={{
												margin: `${theme.rhythm(1)} 0 0`,
											}}
										>
											URL: {this.state.error?.url}
											<br />
											Method: {this.state.error?.method}
											<br />
											Status Code: {this.state.error?.statusCode}
											<br />
											Response Body: {JSON.stringify(this.state.error?.body)}
										</Text>
									</StyledDetails>
								)}

								<div
									style={{
										marginTop: theme.rhythm(4),
									}}
								>
									<button onClick={() => window.location.reload()}>Return to Studio </button>
								</div>
							</Column>
						</Row>
					</Container>
				</Modal>
			);
		}
		return children;
	}
}

export default withTheme(HttpErrorBoundary);
