import VatomInc, { RequestService } from "./index";
import { ContactList } from "reducers/entitiesReducers";

// todo: compression support
// ref: https://stackoverflow.com/a/47190348
// function compressBody(body) {
// 	return new Promise((resolve, reject) => {
// 		zlib.deflate(body, (err, buffer) => {
// 			if (err:any) {
// 				console.log("Error zipping");
// 				reject(err);
// 				return;
// 			}

// 			console.log("Zipped");
// 			resolve(buffer);
// 		});
// 	});
// }

export default class VatomIncContactLists {
	constructor(private varius: typeof VatomInc) {}

	public async listContactLists(): Promise<ContactList[]> {
		const res = await this.varius.get({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/contact-lists`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body.items;
	}

	public async create(item: Partial<ContactList>): Promise<ContactList> {
		const res = await this.varius.post({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/contact-lists`,
			isAuthed: true,
			body: item,
		});

		return res.body;
	}

	public async addContacts(listId: string, contacts: any[]): Promise<void> {
		// const compressedBody = await compressBody(JSON.stringify({
		// 	contacts,
		// }));

		await this.varius.post({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/contact-lists/${listId}/contacts`,
			isAuthed: true,
			body: {
				contacts,
			},
		});
	}
}
