import VatomInc from "./index";

type MimeTypeHTML = {
	"text/html": string;
}

type MimeTypePlain = {
	"text/plain": string;
}
export interface SubTemplate {
	displayName? : string;
	isActive: boolean;
	createdByUserId: string
	businessId?: string;
	from: any;
	groupedBy: string;
	createdAt?: string;
	id: string;
	body: string | MimeTypeHTML | MimeTypePlain;
	type: string;
	subject: string;
}
export interface Template {
	businessId?: string;
	id?: string;
	title?: string;
	type?: string;
	template?: string;
	subject?: string;
	displayName?: string;
	createdByUserId?: string;
	updatedByUserId?: string;
	from?: any;	
	isActive?: boolean;
	updatedAt?: string | object;
	createdAt?: string | object;
	subtemplateId?: string;
	groupedBy?: string;
	subtemplateType?: string;
	body?: any;
	subtemplate? : SubTemplate
}

export interface SendTemplate {
	email?: string;
	phoneNumber?: string;
	bodyType?: string; 
	isActive?: boolean;
} 

export interface EmailIdentity {
	email: string;
}

export interface IsVerified {
	isVerified: boolean;
}

export enum TemplatesError {
	CLONE_LIMIT = "clone_system_template_limit"
}
export default class VatomIncTemplates {
	constructor(private varius: typeof VatomInc) {}

	public async listSystemNotificationTemplates(): Promise<Array<Template>> {
		const res = await this.varius.get({
			path: `/b/system/notification-templates`,
			isAuthed: true
		});
	
		return res.body;
	}

	public async listCustomNotificationTemplates(businessId: string): Promise<Array<Template>> {
		const res = await this.varius.get({
			path: `/b/${businessId}/notification-templates`,
			isAuthed: true
		});
	
		return res.body;
	}

	public async cloneTemplate(businessId: string, data: Object): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/notification-templates/clone/`,
			isAuthed: true,
			body: data,
			needErrorMessage: true
		});
	
		return res.body;
	}

	public async createPrimaryTemplate(businessId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/primary-templates/`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async updatePrimaryTemplate(businessId: string, templateId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.patch({
			path: `/b/${businessId}/primary-templates/${templateId}`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async sendTest(businessId: string, templateId: string, data: SendTemplate, type: string): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/notification-templates/send/${templateId}?subtype=${type}`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async createEmailTemplate(businessId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/email-templates/`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async createSMSTemplate(businessId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/sms-templates/`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async createPushNotificationTemplate(businessId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.post({
			path: `/b/${businessId}/push-notification-templates/`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async retrieveTemplate(businessId: string, id: string, templateType: string): Promise<Template> {
		const res = await this.varius.get({
			path: `/b/${businessId}/notification-templates/detail?templateId=${id}&subtype=${templateType}`,
			isAuthed: true
		});
	
		return res.body;
	}

	public async updateEmailTemplate(businessId: string, templateId:string, data: Template): Promise<Template> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/email-templates/${templateId}`,
			isAuthed: true,
			body: data
		});
	
		return res.body;
	}

	public async updatePushNotificationTemplate(businessId: string, templateId:string, data: Template): Promise<Template> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/push-notification-templates/${templateId}`,
			isAuthed: true,
			body: data
		});
	
		return res.body;
	}


	public async updateSMSTemplate(businessId: string, templateId: string, data: Template): Promise<Template> {
		const res: any = await this.varius.patch({
			path: `/b/${businessId}/sms-templates/${templateId}`,
			isAuthed: true,
			body: data,
		});
	
		return res.body;
	}

	public async deleteTemplate(businessId: string, templateId: string): Promise<Template> {
		const res: any = await this.varius.delete({
			path: `/b/${businessId}/notification-templates/delete?templateId=${templateId}`,
			isAuthed: true,
		});
	
		return res.body;
	}

	public async isEmailIdentityVerified(businessId: string, data: EmailIdentity): Promise<IsVerified> {
		const res = await this.varius.post({
			path: `/b/${businessId}/email-identities/isVerified`,
			isAuthed: true,
			body: data
		});
	
		return res.body;
	}
}


