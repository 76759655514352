import VatomInc, { RequestService } from "utils/VatomIncApi/index";

export default class VatomIncBudget {
	constructor(private varius: typeof VatomInc) {}

	public async calculateBudget(budgetAmount: number, campaignId: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			service: RequestService.Billing,
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/budget`,
			body: {
				budgetAmount,
			},
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("Could not calculate budget");
		}

		return res.body;
	}
}
