import { AuthActions } from "actions/authActions";
import { AppActions, AppActionsKeys } from "actions/appActions";
import entities from "reducers/entitiesReducers";

export interface Notification {
	[index: string]: any;
}

export interface LocalFeatureFlagMap {
	[flagId: string]: boolean;
}

type Environments = "BLUE" | "GREEN";

export interface SystemInfo {
	environments: {
		API_ENV: Environments;
		STUDIO_ENV: Environments;
	};
}

// Interfaces
export interface AppState {
	notifications: Notification[];
	localDeletedObjectIds: string[];
	systemInfo?: SystemInfo;
	systemInfoStatus: string;
}

// Initial State
const initialState: AppState = {
	notifications: [],
	localDeletedObjectIds: [],
	systemInfoStatus: "",
};

export default (state: AppState = initialState, action: AuthActions | AppActions): AppState => {
	switch (action.type) {
		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case entities.digitalObjects.actionNames.archive.success: {
			const { id } = (action as any).data;
			let localDeletedObjectIds = [...state.localDeletedObjectIds];

			// add the recently deleted id to the array and make it unique (just in case)
			localDeletedObjectIds.push(id);
			localDeletedObjectIds = [...Array.from(new Set(localDeletedObjectIds))];

			return {
				...state,
				localDeletedObjectIds,
			};
		}

		case AppActionsKeys.GET_SYSTEM_INFO_START: {
			return {
				...state,
				systemInfoStatus: "loading",
			};
		}

		case AppActionsKeys.GET_SYSTEM_INFO_SUCCESS: {
			const systemInfo = action.data.systemInfo;

			return {
				...state,
				systemInfo,
				systemInfoStatus: "ready",
			};
		}

		case AppActionsKeys.GET_SYSTEM_INFO_ERROR: {
			return {
				...state,
				systemInfoStatus: "error",
			};
		}

		default: {
			return state;
		}
	}
};

const appState = state => state.app as AppState;

// Selectors
export const selectors = {
	localDeletedObjectIds: state => appState(state).localDeletedObjectIds,
	systemInfo: state => appState(state).systemInfo,
	isLoadingSystemInfo: state => appState(state).systemInfoStatus === "loading",
};
