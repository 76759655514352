export function throwIfDefined(error: Error): void {
	if (error !== undefined) {
		throw error;
	}
}

class ExhaustivenessError extends Error {
	constructor() {
		super("Failed exhaustiveness check at runtime.");
		this.name = this.constructor.name;
	}
}

/**
 * Checks for enum exhaustiveness at compile-time and run-time.
 * https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking
 */
export function assertExhaustive(_: never): never {
	throw new ExhaustivenessError();
}
