import { useEffect, useRef } from "react";

// ref: https://stackoverflow.com/a/57706747
const usePrevious = <T extends {}>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export default usePrevious;
