import React from "react";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

export default function wrapWithStripeElements<ComponentProps>(Component: any) {
	return class WithStripeElements extends React.Component<any> {
		render() {
			return (
				<Elements stripe={stripePromise}>
					<Component {...this.props} />
				</Elements>
			);
		}
	};
}
