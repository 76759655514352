import { PlanActions, PlanActionTypes } from "actions/planActions";
import { RequestStatus } from "utils/makeEntity";
import { License } from "utils/VatomIncApi/account";

export interface PlansList {
	activeSubscriptionId: string;
	activePlanId: string;
	items: License[];
}

export interface User {
	email: string;
	roles: any[];
}

export interface PlanState {
	plans: PlansList;
	updatePlanId: string;
	isLoadingCurrentPlan: boolean;
	plansListLoadStatus: RequestStatus;
	isUpdatingSubscription: boolean;
	isSubscriptionUpdated: boolean;
	error: string | null;
	updatedPlan: string;
}

const initialState: PlanState = {
	plans: {
		items: [],
		activePlanId: "",
		activeSubscriptionId: "",
	},
	updatePlanId: "",
	plansListLoadStatus: "",
	isLoadingCurrentPlan: false,
	isUpdatingSubscription: false,
	isSubscriptionUpdated: false,
	error: null,
	updatedPlan: "",
};

export default function planReducer(state = initialState, action: PlanActions) {
	const { type, data } = action;
	switch (type) {
		// fetch plans
		case PlanActionTypes.START_FETCH_PLANS:
			return {
				...state,
				plansListLoadStatus: "pending",
			};
		case PlanActionTypes.SUCCESS_FETCH_PLANS:
			return {
				...state,
				plansListLoadStatus: "success",
				isSubscriptionUpdated: false,
				plans: data,
			};
		case PlanActionTypes.ERROR_FETCH_PLANS:
			return {
				...state,
				plansListLoadStatus: "failure",
				error: data,
			};
		// update plans
		case PlanActionTypes.START_UPDATE_SUBSCRIPTION:
			return {
				...state,
				isUpdatingSubscription: true,
				isSubscriptionUpdated: false,
			};
		case PlanActionTypes.SUCCESS_UPDATE_SUBSCRIPTION:
			console.log('SUCCESS_UPDATE_SUBSCRIPTION', data);
			return {
				...state,
				isUpdatingSubscription: false,
				isSubscriptionUpdated: true,
				plans: data,
			};
		case PlanActionTypes.ERROR_UPDATE_SUBSCRIPTION:
			return {
				...state,
				isUpdatingSubscription: false,
				error: data,
			};

		default:
			return state;
	}
}

const plansState = state => state.plans as PlanState;

export const planSelectors = {
	error: state => plansState(state).error,
	plansListLoadStatus: state => plansState(state).plansListLoadStatus,
	isLoadingPlansList: state => plansState(state).plansListLoadStatus === "pending",
	isLoadingCurrentPlan: state => plansState(state).isLoadingCurrentPlan,
	activePlanId: state => plansState(state).plans.activePlanId,
	activeSubscriptionId: state => plansState(state).plans.activeSubscriptionId,
	plansList: state => plansState(state).plans,
	isUpdatingSubscription: state => plansState(state).isUpdatingSubscription,
	isSubscriptionUpdated: state => plansState(state).isSubscriptionUpdated,
};
