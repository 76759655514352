import { useEffect } from "react";
import { useSocket } from "components/App/SocketContext";
import { SocketEvent } from "components/App/SocketEvents";

export default function useSocketEvent(name: string, callback: (payload: any) => void): void {
	const socket = useSocket();
	
	useEffect(() => {
		function handler(event: SocketEvent) {
			const { eventType, payload } = event;
			
			if (eventType === name) {
				callback(payload);
			}
		}
		
		if (socket === undefined) {
			return;
		}
		
		socket.addEventListener("event", handler);
		return () => socket.removeEventListener("event", handler);
	}, [name, callback, socket]);
}
