import VatomInc from "./index";
import { Campaign, DigitalObject } from "reducers/entitiesReducers";
import { DigitalObjectsFilters } from "reducers/digitalObjectsReducers";
import { SearchResults } from "../../reducers/entitiesReducers";
import algoliasearch from "algoliasearch";

export default class VatomIncDigitalObjects {
	constructor(private varius: typeof VatomInc) {}

	public async createDigitalObject(data): Promise<DigitalObject> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/object-definitions`,
			isAuthed: true,
			body: {
				...data,
			},
		});

		// todo: check `status` in res
		return res.body;
	}

	public async updateDigitalObject(objectDefinitionId: string, body: any): Promise<DigitalObject> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/object-definitions/${objectDefinitionId}`,
			isAuthed: true,
			body,
		});

		// todo: check `status` in res
		return res.body;
	}

	public async archiveDigitalObject(objectDefinitionId: string): Promise<DigitalObject> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/object-definitions/${objectDefinitionId}`,
			isAuthed: true,
		});

		// todo: check `status` in res
		return res.body;
	}

	public async cloneDigitalObject(objectDefinitionId: string): Promise<DigitalObject> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/object-definitions/${objectDefinitionId}/clone`,
			isAuthed: true,
		});

		// todo: check `status` in res
		return res.body;
	}

	public async listDigitalObjects(
		filters: Partial<DigitalObjectsFilters>
	): Promise<DigitalObject[]> {
		
		const newFilters: any = {
			...filters,
			facets: "*",
		};

		newFilters.facetFilters = (filters.facets || [])
			.filter(f => f.selectedValue !== undefined)
			.map(f => `${f.name}:${f.selectedValue!.value}`);

		// for us its easier to think of this as an emtpy array, so convert to wildcard string here
		if (newFilters.facetFilters.length === 0) {
			newFilters.facetFilters = [`businessId:${this.varius.businessId}`]
		} else {
			newFilters.facetFilters.push(`businessId:${this.varius.businessId}`)
		}


		try {
			const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || "", this.varius.algoliaKey || "");
			const algoliaIndex = client.initIndex('objectDefinitions');
			// TODO: find a more efficeint way to pass newFilters
			let {query,...nfs} = newFilters 
			const res = await algoliaIndex.search(query,nfs);

			// convert Algolia objects to Studio objects format
			const items = res.hits.map(
				(obj: any): DigitalObject => ({
					id: obj.objectID,
					displayName: obj.displayName,
					stage: obj.stage || (obj.isDraft ? "draft" : "published"),
					updatedAt: obj.updatedAt,
					createdAt: obj.createdAt,
					designId: obj.designId,
					designName: obj.designName,
					blueprintId: obj.blueprintId,
					blueprintName: obj.blueprintName,
					businessId: obj.businessId,
					isFullyHydrated: false,
					cover: {
						url: obj.coverImageUrl,
						type: "image",
					},
				})
			);

			return items;
		} catch (err:any) {
			console.error(err) // This is temporary
			return [];
		}

	}

	public async hackedListSearchDigitalObjects(): Promise<SearchResults<DigitalObject[]>> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/object-definitions/`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Digital Objects could not be loaded.");
		}

		const results = {
			items: res.body.items,
			total: res.body.items.length,
			totalPages: 1,
			newlyAvailableFacets: [],
		};

		return results;
	}

	public async listSearchDigitalObjects(
		filters: DigitalObjectsFilters
	): Promise<SearchResults<DigitalObject[]>> {
		
		const newFilters: any = {
			...filters,
			facets: "*",
		};

		newFilters.facetFilters = (filters.facets || [])
			.filter(f => f.selectedValue !== undefined)
			.map(f => `${f.name}:${f.selectedValue!.value}`);

		// for us its easier to think of this as an emtpy array, so convert to wildcard string here
		if (newFilters.facetFilters.length === 0) {
			newFilters.facetFilters = [`businessId:${this.varius.businessId}`]
		} else {
			newFilters.facetFilters.push(`businessId:${this.varius.businessId}`)
		}


		try {
			const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || "", this.varius.algoliaKey || "");
			const algoliaIndex = client.initIndex('objectDefinitions');
			let {query,...nfs} = newFilters 
			const res = await algoliaIndex.search(query,nfs);
			// convert Algolia objects to Studio objects format
			const items = res.hits.map(
				(obj: any): DigitalObject => ({
					id: obj.objectID,
					displayName: obj.displayName,
					stage: obj.stage || (obj.isDraft ? "draft" : "published"),
					updatedAt: obj.updatedAt,
					createdAt: obj.createdAt,
					designId: obj.designId,
					designName: obj.designName,
					blueprintId: obj.blueprintId,
					blueprintName: obj.blueprintName,
					businessId: obj.businessId,
					isFullyHydrated: false,
					cover: {
						url: obj.coverImageUrl,
						type: "image",
					},
				})
			);

			const results = {
				items,
				total: res.nbHits,
				totalPages: res.nbPages,
				newlyAvailableFacets: res.facets as any || [],
			};
	
			return results;

		} catch (err:any) {
			return {
				items: [],
				total: 0,
				totalPages: 0,
				newlyAvailableFacets: [],
			}
		}

		
	}

	public async getDigitalObject(digitalObjectName: string): Promise<DigitalObject> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/object-definitions/${digitalObjectName}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Digital Object could not be loaded.");
		}
		// inform redux that this is a full object
		res.body.isFullyHydrated = true;

		// todo: check `status` in res
		return res.body;
	}

	public async testDigitalObject(digitalObjectName: string, body: any): Promise<{ viewerEntrypoint: string }> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/object-definitions/${digitalObjectName}/send-test`,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 200) {
			throw new Error("Digital Object could not be loaded.");
		}
		return res.body;
	}

	public async getDigitalObjectCampaigns(digitalObjectName: string): Promise<Campaign[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/object-definitions/${digitalObjectName}/campaigns`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Digital Object Campaigns could not be loaded.");
		}

		// todo: check `status` in res
		return res.body.items;
	}
}
