import VatomInc from "utils/VatomIncApi/index";
import { SystemInfo } from "reducers/appReducers";

export default class VatomIncAdmin {
	constructor(private vatomInc: typeof VatomInc) {}

	public async getSystemInfo(): Promise<SystemInfo> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.vatomInc.get({
			path: `/a/system-info`,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("System info could not be loaded.");
		}

		return res.body;
	}

	public async updateCampaignUserPoints(userId, campaignId, points, channel): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.vatomInc.post({
			path: `/a/campaigns/${campaignId}/users/${userId}/points`,
			body: {
				points: parseInt(points, 10),
				channel,
			}
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Campaign user points could not be reset could not be loaded.");
		}

		return res.body;
	}

	public async resetCampaignState(userId, campaignId): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.vatomInc.delete({
			path: `/a/campaigns/${campaignId}/users/${userId}`,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("User campaign state could not be reset")
		}

		return res.body;
	}
}
