import { UserRole, UserState } from "reducers/userReducers";
import VatomInc from "utils/VatomIncApi";

export type UserActions =
	| StoreSelectedBusinessAction
	| SendVerificationEmailSuccessAction
	| SendVerificationEmailStartAction
	| SendVerificationEmailErrorAction
	| ChangeBusinessName
	| ToggleChangingBusinessName
	| UpdateDefaultViewer;

// Define Action Keys
export enum UserActionsKeys {
	STORE_SELECTED_BUSINESS = "USER/STORE_SELECTED_BUSINESS",
	SEND_VERIFICATION_EMAIL_START = "USER/SEND_VERIFICATION_EMAIL_START",
	SEND_VERIFICATION_EMAIL_SUCCESS = "USER/SEND_VERIFICATION_EMAIL_SUCCESS",
	SEND_VERIFICATION_EMAIL_ERROR = "USER/SEND_VERIFICATION_EMAIL_ERROR",
	CHANGE_BUSINESS_NAME = "USER/CHANGE_BUSINESS_NAME",
	IS_CHANGING_BUSINESS_NAME = "USER/IS_CHANGING_BUSINESS_NAME",
	UPDATE_DEFAULT_VIEWER = "USER/UPDATE_DEFAULT_VIEWER",
}

interface Action<Type> {
	type: Type;
	data?: any;
}

export interface SendVerificationEmailStartAction
	extends Action<UserActionsKeys.SEND_VERIFICATION_EMAIL_START> {}

export interface SendVerificationEmailSuccessAction
	extends Action<UserActionsKeys.SEND_VERIFICATION_EMAIL_SUCCESS> {}

export interface SendVerificationEmailErrorAction
	extends Action<UserActionsKeys.SEND_VERIFICATION_EMAIL_ERROR> {
	data: {
		error: any;
	};
}

export interface ToggleChangingBusinessName
	extends Action<UserActionsKeys.IS_CHANGING_BUSINESS_NAME> {
	data: boolean;
}

export interface StoreSelectedBusinessAction
	extends Action<UserActionsKeys.STORE_SELECTED_BUSINESS> {
	data: {
		businessName: string;
	};
}

export interface UpdateDefaultViewer
	extends Action<UserActionsKeys.UPDATE_DEFAULT_VIEWER> {
	data: {
		viewerId: string;
		businessId: string;
	};
}

export interface ChangeBusinessName extends Action<UserActionsKeys.CHANGE_BUSINESS_NAME> {
	data: {
		userRoles: any;
		algoliaKey: any;
	};
}

// Action Creators
const actions = {
	storeSelectedBusiness: (businessName: string) => (dispatch, getState) => {
		dispatch({
			type: UserActionsKeys.STORE_SELECTED_BUSINESS,
			data: { businessName },
		});
	},
	changeBusinessName: (newBusinessName: string, businessId: string) => async (
		dispatch,
		getState
	) => {
		try {
			dispatch({
				type: UserActionsKeys.IS_CHANGING_BUSINESS_NAME,
				data: true,
			});

			await VatomInc.users.changeBusinessName(newBusinessName);

			const { algoliaKey, userRolesMap }: UserState = getState().user;
			const currentBusiness = userRolesMap![businessId];

			// create array from map (to pass to reducer) and update businessName by business id
			const userRolesArray: UserRole[] = Object.keys(userRolesMap!).map(role => {
				if (userRolesMap![role].businessId === businessId) {
					return {
						...userRolesMap![role],
						eruedisplayName: newBusinessName,
					};
				}
				return userRolesMap![role];
			});

			dispatch({
				type: UserActionsKeys.CHANGE_BUSINESS_NAME,
				data: {
					userRoles: userRolesArray,
					algoliaKey,
				},
			});

			dispatch({
				type: UserActionsKeys.STORE_SELECTED_BUSINESS,
				data: {
					businessName: currentBusiness.businessName,
				},
			});
		} finally {
			dispatch({
				type: UserActionsKeys.IS_CHANGING_BUSINESS_NAME,
				data: false,
			});
		}
	},
	resendVerification: (userId: string) => async (dispatch, getState) => {
		dispatch({
			type: UserActionsKeys.SEND_VERIFICATION_EMAIL_START,
		});

		try {
			await VatomInc.users.sendVerificationEmail(userId);
			
			dispatch({
				type: UserActionsKeys.SEND_VERIFICATION_EMAIL_SUCCESS,
			});
		}
		catch (e:any) {
			dispatch({
				type: UserActionsKeys.SEND_VERIFICATION_EMAIL_ERROR,
				data: { error: e },
			});
		}
	},
	updateDefaultViewer: (viewerId?: string, businessId?: string) => async (dispatch, getState) => {
		dispatch({
			type: UserActionsKeys.UPDATE_DEFAULT_VIEWER,
			data: {
				viewerId,
				businessId,
			}
		});
	}
};

export default actions;
