import VatomInc, { RequestService } from "./index";

export default class VatomIncCommunities {
	constructor(private varius: typeof VatomInc) {}

	public async create(name: string, icon: string, description: string): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			service: RequestService.Events,
			path: `/b/${this.varius.businessId}/communities`,
			isAuthed: true,
			body: {
				name,
				icon,
				description
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		// todo: check 'status' in body
		return res.body;
	}

	public async list(): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Events,
			path: `/b/${this.varius.businessId}/communities`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		return res.body.filter( $ => !$.isDefault);
	}
}
