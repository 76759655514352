import React, { lazy, Suspense } from "react";
import { planSelectors } from "reducers/planReducers";
import { connect } from "react-redux";
import { planActions } from "actions/planActions";
import { toast } from "react-toastify";

import { RequestStatus } from "utils/makeEntity";

const PageLoader = lazy(() => import("components/App/components/PageLoader"));

export interface InjectedAuthProps {
	plansListLoadStatus: RequestStatus;
	activeSubscriptionId: string;
	match: any;

	fetchPlansList(): void;
	updateSubscription(planId: string): void;
}

// const StyledLink = styled<any>("span")`
// 	cursor: pointer;
// 	text-decoration: underline;
// `;

export default function<ComponentProps>(CommerceComponent: any) {
	class SubscriptionRequiredContainer extends React.Component<InjectedAuthProps> {
		componentDidMount() {
			if (this.props.plansListLoadStatus === "") {
				this.props.fetchPlansList();
			}
		}

		componentDidUpdate() {
			const { plansListLoadStatus, activeSubscriptionId } = this.props;
			// const businessName = match!.params.businessName;

			const isSubscribed: boolean = localStorage.getItem("isSubscribed") === "true" || false;
			if (plansListLoadStatus === "success" && !activeSubscriptionId && !isSubscribed) {
				localStorage.setItem("isSubscribed", "true");
				this.props.updateSubscription(process.env.REACT_APP_BUSINESS_DEFAULT_LICENSE_PLAN_ID!);
				toast.success(
					<>
						You have been assigned a free Basic License for the platform.{" "}
						{/* <StyledLink onClick={() => history.push(`/${businessName}/account/plans`)}>Click here</StyledLink> to
									select a different license. */}
					</>
				);
				window.location.reload();
			}
		}

		render() {
			const { plansListLoadStatus } = this.props;

			return (
				<>
					{plansListLoadStatus !== "success" ? (
						<Suspense fallback={<></>}>
							<PageLoader />
						</Suspense>
					) : (
						<CommerceComponent {...this.props} />
					)}
				</>
			);
		}
	}

	const mapStateToProps = state => {
		return {
			plansListLoadStatus: planSelectors.plansListLoadStatus(state),
			activeSubscriptionId: planSelectors.activeSubscriptionId(state),
		};
	};

	const mapDispatchToProps = {
		fetchPlansList: planActions.fetchPlans,
		updateSubscription: planActions.updateSubscription,
	};

	return connect(mapStateToProps, mapDispatchToProps)(SubscriptionRequiredContainer);
}
