import { BillingActions, CommerceActionTypes } from "actions/billingActions";
import moment from "moment";
import { AccountInfo, AutoPayInvoices, Source } from "utils/VatomIncApi/account";

export interface SingleInvoice {
	invoice: DetailedInvoice;
	source: Source;
}

export interface AddressForm {
	name: string;
	zip: string;
	country: string;
}

export interface CheckoutFormState {
	form: AddressForm;
	errors: FieldError[];
	isLoading: boolean;
}

export enum InvoiceStatuses {
	OPEN = "open",
	PAID = "paid",
	DUE = "due",
	DRAFT = "draft",
}

export interface Invoice {
	id: string;
	amount: number;
	date: string;
	description: string | null;
	status: {
		date: string;
		type: InvoiceStatuses;
	};
}

export interface FieldError {
	field: keyof AddressForm | "card";
	errorText: string;
}


export enum INVOICE_STATUSES {
	UNPAID = "unpaid",
	PAYMENT_PROCESSING = "payment_processing",
	PAYMENT_COMPLETE = "payment_complete",
	BILLING_SOURCE_ERROR = "billing_source_error",
	PAID = "paid",
	DRAFT = "draft",
}

export interface DetailedInvoice {
	id: string;
	object: string;
	amount_due: number;
	amount_paid: number;
	amount_remaining: number;
	application_fee: number | null;
	attempt_count: number;
	attempted: boolean;
	period_end: number;
	period_start: number;
	auto_advance: boolean;
	billing: AutoPayInvoices;
	billing_reason: string;
	charge: number | null;
	status: INVOICE_STATUSES;
	paid: boolean;
	currency: string;
	custom_fields: any;
	customer: string;
	date: number;
	default_source: string | null;
	description: string | null;
	discount: number | null;
	due_date: number;
	ending_balance: number;
	status_transitions: {
		finalized_at: number;
	}
	footer: null;
	hosted_invoice_url: string;
	invoice_pdf: string;
	lines: { data: DetailedInvoiceLine[] };
}

export interface DetailedInvoiceLine {
	id: string;
	object: string;
	amount: number;
	currency: string;
	description: string;
	discountable: boolean;
	livemode: boolean;
	metadata: any;
	quantity: number;
	subscription: string;
	subscription_item: string;
	type: string;
}






export interface MetaData {
	max_rules_per_campaign: number;
	max_seats: number;
	included_seats: number;
	max_object_types_per_campaign: number;
	campaigns?: boolean;
}


export interface BillingReducer {
	checkoutForm: CheckoutFormState;
	businessId: string;
	allInvoices: InvoicesList;
	invoicesLoading: boolean;
	singleInvoiceLoading: boolean;
	isAccountInfoLoading: boolean;
	invoice: SingleInvoice | null;
	accountInfo: AccountInfo | null;
	error?: any;
	nextPayment: Invoice | null;
	isChangingAutoPayInvoices: boolean;
	paymentToken: string | null;
	isPayingInvoice: boolean;
	detailsCollapsed: boolean;
	isDeletingSource: boolean;
}

export interface InvoicesList {
	hasMore: boolean;
	lastInvoiceId: string | null;
	items: {
		currentInvoices: Invoice[];
		pastInvoices: Invoice[];
		overdueInvoices: Invoice[];
		draftInvoices: Invoice[];
	};
}

const initialState: BillingReducer = {
	checkoutForm: {
		form: {
			name: "",
			zip: "",
			country: "",
		},
		errors: [],
		isLoading: false,
	},
	singleInvoiceLoading: true,
	businessId: "",
	invoicesLoading: true,
	allInvoices: {
		hasMore: false,
		lastInvoiceId: null,
		items: {
			pastInvoices: [],
			currentInvoices: [],
			overdueInvoices: [],
			draftInvoices: []
		},
	},
	nextPayment: null,
	invoice: null,
	accountInfo: null,
	isAccountInfoLoading: false,
	isChangingAutoPayInvoices: false,
	isPayingInvoice: false,
	paymentToken: null,
	detailsCollapsed: true,
	isDeletingSource: false,
};

const findClosestPayment = (data: InvoicesList) => {
	let invoiceList = data.items.currentInvoices.concat(data.items.overdueInvoices);
	return invoiceList.reduce((foundInvoice: Invoice | null, nextInvoice: Invoice) => {
		// for first bypass - just return next invoice
		if (!foundInvoice) {
			return nextInvoice;
		}
		// compare invoice1.date with invoice2.date
		return moment(foundInvoice.date).diff(moment(nextInvoice.date)) > 0
			? nextInvoice
			: foundInvoice;
	}, null);
};

export default (state: BillingReducer = initialState, action: BillingActions) => {
	const { type, data } = action;
	switch (type) {
		// change payment details
		case CommerceActionTypes.CHANGE_PAYMENT_DETAILS:
			return {
				...state,
				checkoutForm: {
					...state.checkoutForm,
					form: data,
				},
			};
		case CommerceActionTypes.TOGGLE_LOADING:
			return {
				...state,
				checkoutForm: {
					...state.checkoutForm,
					isLoading: data.isLoading,
				},
			};
		case CommerceActionTypes.CHANGE_ERRORS_LIST:
			return {
				...state,
				checkoutForm: {
					...state.checkoutForm,
					errors: (data as any).errors,
				},
			};
		// LIST INVOICE ACTIONS
		case CommerceActionTypes.START_FETCH_INVOICES_LIST:
			return {
				...state,
				invoicesLoading: true,
			};
		case CommerceActionTypes.SUCCESS_FETCH_INVOICES_LIST:
			return {
				...state,
				allInvoices: data,
				nextPayment: findClosestPayment(data),
				invoicesLoading: false,
			};
		case CommerceActionTypes.ERROR_FETCH_INVOICES_LIST:
			return {
				...state,
				invoicesLoading: false,
			};
		// SINGLE INVOICE ACTIONS
		case CommerceActionTypes.START_FETCH_INVOICE:
			return {
				...state,
				singleInvoiceLoading: true,
			};
		case CommerceActionTypes.SUCCESS_FETCH_INVOICE:
			return {
				...state,
				invoice: data,
				singleInvoiceLoading: false,
			};
		case CommerceActionTypes.ERROR_FETCH_INVOICE:
			return {
				...state,
				singleInvoiceLoading: false,
			};
		// fetch billing source
		case CommerceActionTypes.START_FETCH_BILLING_SOURCE:
			return {
				...state,
				isAccountInfoLoading: true,
			};
		case CommerceActionTypes.SUCCESS_FETCH_BILLING_SOURCE:
			return {
				...state,
				accountInfo: data,
				isAccountInfoLoading: false,
			};
		case CommerceActionTypes.ERROR_FETCH_BILLING_SOURCE:
			return {
				...state,
				error: data,
				isAccountInfoLoading: false,
			};
		// set auto invoice
		case CommerceActionTypes.START_SET_AUTOMATIC_INVOICE:
			return {
				...state,
				isChangingAutoPayInvoices: true,
			};
		case CommerceActionTypes.ERROR_SET_AUTOMATIC_INVOICE:
			return {
				...state,
				isChangingAutoPayInvoices: false,
			};
		case CommerceActionTypes.SUCCESS_SET_AUTOMATIC_INVOICE:
			return {
				...state,
				isChangingAutoPayInvoices: false,
				accountInfo: {
					...state.accountInfo,
					billing:
						state.accountInfo!.billing === AutoPayInvoices.TRUE
							? AutoPayInvoices.FALSE
							: AutoPayInvoices.TRUE,
				},
			};
		// pay invoice
		case CommerceActionTypes.ERROR_PAYING_INVOICE:
			return {
				...state,
				isPayingInvoice: false,
				error: data,
			};
		case CommerceActionTypes.START_PAYING_INVOICE:
			return {
				...state,
				isPayingInvoice: true,
			};
		case CommerceActionTypes.SUCCESS_PAYING_INVOICE:
			return {
				...state,
				isPayingInvoice: false,
				invoice: {
					...state.invoice!,
					invoice: {
						...state.invoice!.invoice,
						status: InvoiceStatuses.PAID,
						paid: true,
					},
				},
			};
		// toggle collapsed
		case CommerceActionTypes.TOGGLE_BILLING_DETAILS_COLLAPSED:
			return {
				...state,
				detailsCollapsed: data,
			};
		// delete source
		case CommerceActionTypes.ERROR_DELETING_SOURCE:
			return {
				...state,
				isDeletingSource: false,
				error: data,
			};
		case CommerceActionTypes.START_DELETING_SOURCE:
			return {
				...state,
				isDeletingSource: true,
			};
		case CommerceActionTypes.SUCCESS_DELETING_SOURCE:
			return {
				...state,
				isDeletingSource: false,
			};
		default:
			return state;
	}
};

const billingState = state => state.billing as BillingReducer;

export const selectors = {
	getAddressForm: state => billingState(state).checkoutForm.form,
	getCheckoutForm: state => billingState(state).checkoutForm,
	listInvoices: state => billingState(state),
	loadMore: state => billingState(state),
	autoPayInvoices: state =>
		billingState(state).accountInfo &&
		billingState(state).accountInfo!.billing === AutoPayInvoices.TRUE,
	allInvoices: state => billingState(state).allInvoices,
	invoicesLoadingState: state => billingState(state).invoicesLoading,
	singleInvoiceLoading: state => billingState(state).singleInvoiceLoading,
	getInvoice: state => billingState(state).invoice,
	invoice: state => billingState(state).invoice,
	getNextPayment: state => billingState(state).nextPayment,
	// isAccountInfoLoading: state => billingState(state).isAccountInfoLoading,
	isPayingInvoice: state => billingState(state).isPayingInvoice,
	// accountInfo: state => billingState(state).accountInfo,
	error: state => billingState(state).error,
	isChangingAutoPayInvoices: state => billingState(state).isChangingAutoPayInvoices,
	billingDetailsCollapsed: state => billingState(state).detailsCollapsed,
	isDeletingSource: state => billingState(state).isDeletingSource,
};
