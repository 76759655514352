import VatomInc from "./index";
import { Rule } from "reducers/entitiesReducers";

export default class VatomIncRules {
	constructor(private varius: typeof VatomInc) {}

	public async listRules(campaignId: string): Promise<Rule[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/rules`,
			isAuthed: true,
		});
		
		return res.body.items;
	}

	public async createRule(campaignId: string, data: any): Promise<Rule> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/rules`,
			isAuthed: true,
			body: {
				...data,
			},
		});

		return res.body;
	}

	public async updateRule(campaignId: string, ruleId: string, data: any): Promise<Rule> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/rules/${ruleId}`,
			isAuthed: true,
			body: {
				...data,
			},
		});

		return res.body;
	}

	public async deleteRule(campaignId: string, ruleId: string): Promise<Rule> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/rules/${ruleId}`,
			isAuthed: true,
		});

		return res.body;
	}
}
