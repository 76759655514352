import { AuthActions } from "actions/authActions";
import { FileUploadActionKeys, FileUploadActions } from "actions/fileUploadActions";
import arrayMove from "array-move";

// Interfaces
export interface ImagePolicyState {
	rule: object;
	name: string;
	image: {
		ref: string;
		type: string;
	};
}

export interface ImagePolicyStateMeta {
	x?: number;
	y?: number;
	height?: number;
	width?: number;
	zoom: number;
}

export interface FileUploadState {
	states: ImagePolicyState[];
	stateMetas: ImagePolicyStateMeta[];
	selectedStateIndex: number;
	isUploading: boolean;
	isCropping: boolean;
	uploadError?: any;
}

// Initial State
const initialState: FileUploadState = {
	states: [],
	stateMetas: [],
	isUploading: false,
	isCropping: false,
	selectedStateIndex: 0,
};

export default (
	state: FileUploadState = initialState,
	action: AuthActions | FileUploadActions
): FileUploadState => {
	switch (action.type) {
		// Reset to initialState on logout
		case FileUploadActionKeys.RESET_IMAGE_POLICY_STATES: {
			const states = action?.data?.initialStates ?? initialState.states;

			return {
				...initialState,
				states,
			};
		}

		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case FileUploadActionKeys.CROP_IMAGES_START: {
			return {
				...state,
				isCropping: true,
			};
		}

		case FileUploadActionKeys.CROP_IMAGES_SUCCESS: {
			const states = action.data.states;

			return {
				...state,
				isCropping: false,
				states,
			};
		}

		case FileUploadActionKeys.REORDER_IMAGE_POLICY_STATES: {
			const { oldIndex, newIndex } = action.data;
			const states = arrayMove(state.states, oldIndex, newIndex);
			const stateMetas = arrayMove(state.stateMetas, oldIndex, newIndex);

			return {
				...state,
				states,
				stateMetas,
				selectedStateIndex: newIndex,
			};
		}

		case FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_START: {
			return {
				...state,
				isUploading: true,
				uploadError: undefined,
			};
		}

		case FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_ERROR: {
			return {
				...state,
				isUploading: false,
				uploadError: action.data.error,
			};
		}

		case FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_SUCCESS: {
			const states = JSON.parse(JSON.stringify(state.states));
			const stateMetas = JSON.parse(JSON.stringify(state.stateMetas));
			const { type, selectedStateIndex, ref } = action.data;

			const newState = selectedStateIndex !== state.selectedStateIndex;

			const selectedState = states[selectedStateIndex] || {};

			const name =
				selectedState.name || (selectedStateIndex ? `State ${selectedStateIndex + 1}` : "Default");
			const rule = selectedState.rule || {};

			const imagePolicyState = {
				image: {
					type,
					ref,
				},
				name,
				rule,
			};

			if (newState) {
				states.splice(1, 0, imagePolicyState);
				stateMetas.splice(1, 0, {});
			} else {
				states[selectedStateIndex] = imagePolicyState;
				stateMetas[selectedStateIndex] = {};
			}

			return {
				...state,
				states,
				stateMetas,
				selectedStateIndex: newState ? 1 : selectedStateIndex,
				isUploading: false,
				uploadError: undefined,
			};
		}

		case FileUploadActionKeys.UPDATE_IMAGE_POLICY_STATE: {
			const { selectedStateIndex } = state;
			const { field, value } = action.data;
			const states: ImagePolicyState[] = JSON.parse(JSON.stringify(state.states));

			states[selectedStateIndex][field] = value;

			return {
				...state,
				states,
			};
		}

		case FileUploadActionKeys.DELETE_IMAGE_POLICY_STATE: {
			const states: ImagePolicyState[] = JSON.parse(JSON.stringify(state.states));
			const stateMetas: ImagePolicyStateMeta[] = JSON.parse(JSON.stringify(state.stateMetas));
			const deletingIndex = action.data.selectedStateIndex;
			const { selectedStateIndex } = state;

			states.splice(deletingIndex, 1);
			stateMetas.splice(deletingIndex, 1);

			const newSelectedStateIndex =
				!selectedStateIndex || selectedStateIndex === deletingIndex ? 0 : selectedStateIndex - 1;

			return {
				...state,
				states,
				stateMetas,
				selectedStateIndex: newSelectedStateIndex,
			};
		}

		case FileUploadActionKeys.SELECT_IMAGE_POLICY_STATE: {
			const { selectedStateIndex, editorData, zoom } = action.data;
			const oldSelectedStateIndex = state.selectedStateIndex;
			const stateMetas: ImagePolicyStateMeta[] = JSON.parse(JSON.stringify(state.stateMetas));

			stateMetas[oldSelectedStateIndex] = {
				...editorData,
				zoom,
			};

			return {
				...state,
				stateMetas,
				selectedStateIndex,
			};
		}

		default: {
			return state;
		}
	}
};

const fileUploadState = state => state.fileUpload as FileUploadState;

// Selectors
export const selectors = {
	states: state => fileUploadState(state).states,
	stateMetas: state => fileUploadState(state).stateMetas,
	selectedStateIndex: state => fileUploadState(state).selectedStateIndex,
	isUploading: state => fileUploadState(state).isUploading,
	isCropping: state => fileUploadState(state).isCropping,
};
