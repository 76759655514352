import VatomInc from "utils/VatomIncApi";

export type AuthActions =
	| GetUserRolesSuccessAction
	| GetUserRolesStartAction
	| GetUserRolesErrorAction
	| UpdateUserRolesSuperUserSuccessAction
	| UpdateUserRolesSuperUserStartAction
	| UpdateUserRolesSuperUserErrorAction;

// Define Action Keys
export enum AuthActionsKeys {
	GET_USER_ROLES_START = "AUTH/GET_USER_ROLES_START",
	GET_USER_ROLES_ERROR = "AUTH/GET_USER_ROLES_ERROR",
	GET_USER_ROLES_SUCCESS = "AUTH/GET_USER_ROLES_SUCCESS",
	UPDATE_ROLES_SUPERUSER_START = "AUTH/UPDATE_ROLES_SUPERUSER_START",
	UPDATE_ROLES_SUPERUSER_ERROR = "AUTH/UPDATE_ROLES_SUPERUSER_ERROR",
	UPDATE_ROLES_SUPERUSER_SUCCESS = "AUTH/UPDATE_ROLES_SUPERUSER_SUCCESS",
}

interface Action<Type> {
	type: Type;
	data?: any;
}

export interface GetUserRolesStartAction extends Action<AuthActionsKeys.GET_USER_ROLES_START> {}

export interface GetUserRolesSuccessAction extends Action<AuthActionsKeys.GET_USER_ROLES_SUCCESS> {
	data: {
		userRoles: string[];
		algoliaKey: string;
	};
}

export interface GetUserRolesErrorAction extends Action<AuthActionsKeys.GET_USER_ROLES_ERROR> {
	data: {
		error: any;
	};
}

export interface UpdateUserRolesSuperUserStartAction
	extends Action<AuthActionsKeys.UPDATE_ROLES_SUPERUSER_START> {}

export interface UpdateUserRolesSuperUserSuccessAction
	extends Action<AuthActionsKeys.UPDATE_ROLES_SUPERUSER_SUCCESS> {
	data: {
		role: any;
	};
}
export interface UpdateUserRolesSuperUserErrorAction
	extends Action<AuthActionsKeys.UPDATE_ROLES_SUPERUSER_ERROR> {
	data: {
		error: any;
	};
}

// Action Creators
const actions = {
	getUserRoles: (userId: string) => async dispatch => {
		dispatch({
			type: AuthActionsKeys.GET_USER_ROLES_START,
		});

		try {
			const userData: any = await VatomInc.users.listUserRoles(userId);
			dispatch({
				type: AuthActionsKeys.GET_USER_ROLES_SUCCESS,
				data: {
					userRoles: userData.roles,
					algoliaKey: userData.algoliaKey,
				},
			});
		} catch (error) {
			dispatch({
				type: AuthActionsKeys.GET_USER_ROLES_ERROR,
				data: { error },
			});
		}
	},

	updateUserRoles: (businessId: string) => async dispatch => {
		dispatch({
			type: AuthActionsKeys.UPDATE_ROLES_SUPERUSER_START,
		});

		try {
			const role = await VatomInc.users.getRole(businessId);
			dispatch({
				type: AuthActionsKeys.UPDATE_ROLES_SUPERUSER_SUCCESS,
				data: {
					role,
				},
			});
		} catch (error) {
			dispatch({
				type: AuthActionsKeys.UPDATE_ROLES_SUPERUSER_ERROR,
				data: { error },
			});
		}
	},
};

export default actions;
