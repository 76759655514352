import entities, {
	Behavior,
	Campaign,
	Effect,
	ObjectDefinitionRuleEntity,
	Rule,
	RuleMap,
	CampaignEntity,
	Trigger,
	ProviderRuleEntity,
	ViewerRuleEntity,
	CampaignStage,
	UserRuleEntity,
	SpaceRuleEntity,
} from "reducers/entitiesReducers";
import { AuthActions } from "actions/authActions";
import { UserActions, UserActionsKeys } from "actions/userActions";
import { CampaignsActionKeys, CampaignsActions } from "actions/campaignsActions";
import { behaviorUris } from "utils/behaviors";
import { convertSource } from "utils/campaignsource";
import { RulesActionKey, RulesActions } from "actions/rules";
import { LoadStatus } from "hooks/useReduxLoadable";
import { useSelector } from "react-redux";

export const hardcodedBehaviors = Object.values(behaviorUris);

export interface CampaignReviewData {
	id: string;
	uri: string;
	displayName: string;
	type: string;
	startDate: string | null;
	endDate: string | null;
	createdAt: string;
	createdBy: string;
	updatedAt: string | null;
	businessId: string;
	cover: {
		type: string;
		url: string;
	};
	wsUrl: string;
	stage: CampaignStage;
	status: string;
	subscriptionId: string | null;
	budget: number | null;
	// emailMessageTemplateId: string;
	// smsMessageTemplateId: string;
	// sharePageTemplateId: string;
	wizardId?: string;
}

export interface PrizeObjectOptions {
	maxAvailable: number;
	limitPerUser: number;
	probability?: number;
	sequence?: number;
}

export interface PrizeObjectOptionsMap {
	[digitalObjectId: string]: PrizeObjectOptions;
}

export interface RuleSourceMap {
	[sourceType: string]: {
		[sourceId: string]: string[];
	};
}

export interface RuleEntityMap {
	"object-definition": { [id: string]: ObjectDefinitionRuleEntity };
	viewer: { [id: string]: ViewerRuleEntity };
	provider: { [id: string]: ProviderRuleEntity };
	user: { [id: string]: UserRuleEntity };
	space:{[id: string]: SpaceRuleEntity}
}

export interface SourceTriggerMap {
	"object-definition": {
		[objectDefinitionId: string]: string[];
	};
	viewer: {
		[viewerId: string]: string[];
	};
	service: {
		[serviceId: string]: string[];
	};
	user: {
		[userId: string]: string[];
	};
	space: {
		[spaceId: string]: string[];
	};
}

export interface CampaignBehavior {
	config: {
		[index: string]: any;
	};
	isRemovable: boolean;
	referencedInRules: any[];
	isUsedInBlueprint: boolean;
	configSchema: {
		[index: string]: any;
	};
	userConfigurable?: boolean;
}

export interface BusinessBehavior {
	displayName: string;
	description?: string;
	isRemovable: boolean;
	businessId: string;
	id: string;
	configSchema: any;
	hasBrain: boolean;
	hasActions: boolean;
	uri: string;
	version: string;
	userConfigurable: boolean;
	isUsedInBlueprint?: boolean;
	config?: {
		[index: string]: any;
	};
}

export interface BehaviorMap {
	[behaviorId: string]: BusinessBehavior;
}

export interface BehaviorsMap {
	[entityId: string]: BehaviorMap;
}

export interface BehaviorsOrder {
	system: string[];
	business: string[];
}

export interface CampaignsState {
	rulesSourceMap: RuleSourceMap;
	rulesError: any;
	ruleEntitiesMap: RuleEntityMap;
	editableRulesCopy: RuleMap;
	editableBehaviorsCopy: BehaviorsMap;
	prizeSetsOrder: string[];
	sourceOrder: CampaignEntity[];
	campaignId: string;
	groupCampaignId: string;
	campaignsOrder: string[];
	distributionsOrder: string[];
	viewersOrder: string[];
	campaignGroupLoadStatus: LoadStatus;
	sourceTriggersMap: SourceTriggerMap;
	effectsOrder: string[];
	behaviorsOrder: BehaviorsOrder;
	selectedSource?: CampaignEntity;
	creatingRuleId: string;
	isUpdatingCampaignEntities: boolean;
	isUpdatingBehavior: boolean;
	explicitObjectsError: any;
	ruleError: any;
	ruleStatus?: "adding" | "error" | "success";
	sendStatus?: "sending" | "error" | "success";
	campaignReviewData: CampaignReviewData | null;
	isUpdatingVersion: boolean;
	lastDistributionId: string;
	isUploadingImage: boolean;
	isUpdatingImage: boolean;
}

// Initial State
const initialState: CampaignsState = {
	campaignId: "",
	groupCampaignId: "",
	creatingRuleId: "",
	ruleEntitiesMap: {
		"object-definition": {},
		viewer: {},
		provider: {},
		user: {},
		space:{}
	},
	editableRulesCopy: {},
	editableBehaviorsCopy: {},
	sourceOrder: [],
	rulesSourceMap: {},
	campaignsOrder: [],
	behaviorsOrder: {
		system: [],
		business: [],
	},
	distributionsOrder: [],
	viewersOrder: [],
	prizeSetsOrder: [],
	effectsOrder: [],
	sourceTriggersMap: {
		"object-definition": {},
		viewer: {},
		service: {},
		user: {},
		space:{}
	},
	campaignGroupLoadStatus: "",
	isUpdatingBehavior: false,
	isUpdatingVersion: false,
	rulesError: false,
	ruleError: false,
	isUpdatingCampaignEntities: false,
	explicitObjectsError: false,
	campaignReviewData: null,
	lastDistributionId: "",
	isUploadingImage: false,
	isUpdatingImage: false,
};

export default (
	state: CampaignsState = initialState,
	action: UserActions | AuthActions | CampaignsActions | RulesActions
): CampaignsState => {
	switch (action.type) {
		// Reset to initialState on logout
		case UserActionsKeys.STORE_SELECTED_BUSINESS: {
			return {
				...initialState,
			};
		}

		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_START: {
			return {
				...state,
				isUpdatingBehavior: true,
			};
		}

		case CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_SUCCESS:
		case CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_ERROR: {
			return {
				...state,
				isUpdatingBehavior: false,
			};
		}

		case CampaignsActionKeys.LOAD_CAMPAIGN_DATA_START: {
			return {
				...state,
				campaignGroupLoadStatus: "pending",
				selectedSource: initialState.selectedSource,
				sourceTriggersMap: initialState.sourceTriggersMap,
				sourceOrder: initialState.sourceOrder,
			};
		}

		case CampaignsActionKeys.LOAD_CAMPAIGN_DATA_SUCCESS: {
			const { groupId, campaignId } = action.data;

			return {
				...state,
				campaignGroupLoadStatus: "success",
				campaignId,
				groupCampaignId: groupId,
			};
		}

		case CampaignsActionKeys.LOAD_CAMPAIGN_DATA_ERROR: {
			return {
				...state,
				campaignGroupLoadStatus: "failure",
			};
		}

		case CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_START: {
			return {
				...state,
				sendStatus: "sending",
			};
		}

		case CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_SUCCESS: {
			return {
				...state,
				sendStatus: "success",
			};
		}

		case CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_ERROR: {
			return {
				...state,
				sendStatus: "error",
			};
		}

		case entities.behaviors.actionNames.list.success: {
			const type = (action as any).data.params === "system" ? "system" : "business";
			const behaviorsOrder = (action as any).data.items
				// filter out behaviors that we hard code into the display
				.filter((behavior: Behavior) => !hardcodedBehaviors.includes(behavior.uri))
				.map((behavior: Behavior) => behavior.id);

			return {
				...state,
				behaviorsOrder: {
					...state.behaviorsOrder,
					[type]: behaviorsOrder,
				},
			};
		}

		case entities.distributions.actionNames.create.success: {
			return {
				...state,
				lastDistributionId: action.data.item.id,
				distributionsOrder: [(action as any).data.item.id, ...state.distributionsOrder],
			};
		}

		case entities.distributions.actionNames.update.success: {
			return {
				...state,
				// distributionDetails: action.data.item.id,
			};
		}

		// case entities.prizeSets.actionNames.list.success: {
		// 	const prizeSetsOrder = (action as any).data.items.map((prizeSet: PrizeSet) => prizeSet.id);
		// 	return {
		// 		...state,
		// 		prizeSetsOrder,
		// 	};
		// }

		case entities.campaigns.actionNames.list.success: {
			const campaignsOrder = (action as any).data.items.map((campaign: Campaign) => campaign.id);

			return {
				...state,
				campaignsOrder,
			};
		}

		case entities.distributions.actionNames.list.success: {
			const distributionsOrder = (action as any).data.items.map((item: Campaign) => item.id);

			return {
				...state,
				distributionsOrder,
			};
		}

		// case entities.viewers.actionNames.list.success: {
		// 	const viewersOrder = action.data.items.map((viewer: Viewer) => viewer.id);

		// 	return {
		// 		...state,
		// 		viewersOrder,
		// 	};
		// }

		case entities.effects.actionNames.list.success: {
			const effectsOrder = (action as any).data.items.map((effect: Effect) => effect.id);

			return {
				...state,
				effectsOrder,
			};
		}

		case entities.rules.actionNames.create.start: {
			return {
				...state,
				creatingRuleId: (action as any).data.addArgs[1],
			};
		}

		case entities.rules.actionNames.create.success: {
			const { creatingRuleId } = state;
			const rulesSourceMap = JSON.parse(JSON.stringify(state.rulesSourceMap));
			const editableRulesCopy = JSON.parse(JSON.stringify(state.editableRulesCopy));
			const rule = (action as any).data.item;
			const {
				id,
				trigger: { source },
			} = rule;
			const convertedType = source.type === "service" ? "provider" : source.type;
			const ruleOrder = rulesSourceMap[convertedType][source.id];
			const ruleIndex = ruleOrder.indexOf(creatingRuleId);

			// search for temporary rule in this source's rule list, and replace it with new rule id from API
			if (ruleIndex !== -1) {
				ruleOrder[ruleIndex] = id;
				rulesSourceMap[convertedType][source.id] = ruleOrder;
			}

			// set new rule as valid
			rule.isValid = true;
			rule.isTriggerValid = true;
			rule.isEffectValid = true;

			// remove the temporary rule from the local rule copy and add the new permanent one
			delete editableRulesCopy[creatingRuleId];
			editableRulesCopy[id] = rule;

			return {
				...state,
				creatingRuleId: "",
				rulesSourceMap,
				editableRulesCopy,
			};
		}

		case entities.triggers.actionNames.list.success: {
			const {
				items,
				params: { source },
			} = (action as any).data;
			const triggersOrder = items.map((trigger: Trigger) => trigger.id);
			const sourceTriggersMap = JSON.parse(JSON.stringify(state.sourceTriggersMap));

			const { type, id } = convertSource(source);

			sourceTriggersMap[type][id] = triggersOrder;

			return {
				...state,
				sourceTriggersMap,
			};
		}

		case entities.rules.actionNames.create.error: {
			return {
				...state,
				creatingRuleId: "",
			};
		}

		case entities.rules.actionNames.list.error: {
			return {
				...state,
				rulesSourceMap: {},
				sourceOrder: [],
				editableRulesCopy: {},
				rulesError: true,
			};
		}

		case entities.rules.actionNames.list.success: {
			const rulesSourceMap = {};

			// build a copy of the rules for this campaign, to be locally editable
			const editableRulesCopy = (action as any).data.items.reduce(
				(map: RuleMap, rule: Rule) => ({
					...map,
					// merge current state's entity with incoming entity
					[rule.id]: rule,
				}),
				{}
			);

			// build a map of rules by source (object-definition or viewer)
			for (const rule of (action as any).data.items) {
				const {
					trigger: {
						source: { id, type },
					},
				} = rule;
	
				// @todo unhack
				const convertedType = type === "service" ? "provider" : type;
	
				const types = rulesSourceMap[convertedType] || {};
				const source = types[id!] || [];
	
				source.push(rule.id);
				types[id!] = source;
				rulesSourceMap[convertedType] = types;
			}

			return {
				...state,
				rulesSourceMap,
				editableRulesCopy,
				rulesError: false,
			};
		}

		case entities.rulesEntities.actionNames.get.success: {
			const ruleEntities = (action as any).data.item;
			const ruleEntitiesMap = {
				"object-definition": {},
				viewer: {},
				provider: {},
				user: {},
				space:{}
			};
			const digitalObjects: ObjectDefinitionRuleEntity[] = [];
			const editableBehaviorsCopy = {};
			let sourceOrder: CampaignEntity[] = [];

			// build sourceOrder array
			for (const ruleEntity of ruleEntities) {
				const { type, id } = ruleEntity;
	
				sourceOrder.push({ type, id });
				ruleEntitiesMap[type][id] = ruleEntity;
	
				if (type === "object-definition") {
					digitalObjects.push(ruleEntity);
				}
			}

			// de dupe sourceOrder array
			sourceOrder = sourceOrder.filter((obj, pos, arr) => {
				return arr.map(source => source.id + source.type).indexOf(obj.id + obj.type) === pos;
			});
			
			for (const digitalObject of digitalObjects) {
				if (digitalObject.behaviors) {
					editableBehaviorsCopy[digitalObject.id] = digitalObject.behaviors;
				}
			}

			return {
				...state,
				sourceOrder,
				editableBehaviorsCopy,
				ruleEntitiesMap,
			};
		}

		case RulesActionKey.DELETE_TEMP_RULE:
		case entities.rules.actionNames.archive.success: {
			const { id } = (action as any).data;
			const sourceOrder: CampaignEntity[] = [...state.sourceOrder];
			const rulesSourceMap = JSON.parse(JSON.stringify(state.rulesSourceMap));
			const editableRulesCopy = JSON.parse(JSON.stringify(state.editableRulesCopy));
			const rule = editableRulesCopy[id];
			const {
				trigger: { source },
			} = rule;
			const convertedType = source.type === "service" ? "provider" : source.type;
			const ruleOrder = rulesSourceMap[convertedType][source.id];
			const ruleIndex = ruleOrder.indexOf(id);

			// search for deleted rule in this source's rule list, and remove it
			if (ruleIndex !== -1) {
				ruleOrder.splice(ruleIndex, 1);
				rulesSourceMap[convertedType][source.id] = ruleOrder;
			}

			// remove the temporary rule from the local rule copy
			delete editableRulesCopy[id];

			return {
				...state,
				rulesSourceMap,
				sourceOrder,
				editableRulesCopy,
			};
		}
		
		case entities.campaigns.actionNames.archive.success: {
			const { id, item } = (action as any).data;

			// todo: for campaign archival... hacky...
			if (item === false) {
				return state;
			}
			
			return {
				...state,
				campaignsOrder: state.campaignsOrder.filter(i => i !== id),
			};
		}

		case CampaignsActionKeys.SELECT_SOURCE: {
			return {
				...state,
				selectedSource: action.data.source,
			};
		}

		case CampaignsActionKeys.UPDATE_RULE: {
			const { rule } = action.data;
			const editableRulesCopy = { ...state.editableRulesCopy };

			editableRulesCopy[rule.id] = rule;

			return {
				...state,
				editableRulesCopy,
			};
		}

		case RulesActionKey.ADD_NEW_RULE_START: {
			return {
				...state,
				ruleError: false,
				ruleStatus: "adding",
			};
		}

		case CampaignsActionKeys.UPDATE_IMAGE_START: {
			return {
				...state,
				isUpdatingImage: true,
			};
		}

		case CampaignsActionKeys.UPDATE_IMAGE_ERROR:
		case CampaignsActionKeys.UPDATE_IMAGE_SUCCESS: {
			return {
				...state,
				isUpdatingImage: false,
			};
		}

		case CampaignsActionKeys.UPLOAD_IMAGE_START: {
			return {
				...state,
				isUploadingImage: true,
			};
		}

		case CampaignsActionKeys.UPLOAD_IMAGE_SUCCESS:
		case CampaignsActionKeys.UPLOAD_IMAGE_ERROR: {
			return {
				...state,
				isUploadingImage: false,
			};
		}

		case RulesActionKey.ADD_NEW_RULE_ERROR: {
			return {
				...state,
				ruleError: action.data.error,
				ruleStatus: "error",
			};
		}

		case RulesActionKey.ADD_NEW_RULE_SUCCESS: {
			const { rule } = action.data;
			const { type, id } = rule.trigger.source;
			const rulesSourceMap = JSON.parse(JSON.stringify(state.rulesSourceMap));
			const sources = rulesSourceMap[type] || {};
			const rules = sources[id!] || [];
			const editableRulesCopy = JSON.parse(JSON.stringify(state.editableRulesCopy));
			let sourceOrder: CampaignEntity[] = JSON.parse(JSON.stringify(state.sourceOrder));

			// add the new (temp) rule to the rulesSourceMap
			rules.push(rule.id);
			sources[id!] = rules;
			rulesSourceMap[type] = sources;
			sourceOrder.push({ type, id });

			// add this new (temp) rule to the local rulesMap
			editableRulesCopy[rule.id] = rule;

			// de dupe sourceOrder array
			sourceOrder = sourceOrder.filter((obj, pos, arr) => {
				return arr.map(source => source.id + source.type).indexOf(obj.id + obj.type) === pos;
			});

			return {
				...state,
				rulesSourceMap,
				sourceOrder,
				editableRulesCopy,
				ruleStatus: "success",
				ruleError: false,
			};
		}

		case CampaignsActionKeys.MODIFY_REVISION_START: {
			return {
				...state,
				isUpdatingVersion: true,
			};
		}

		case CampaignsActionKeys.MODIFY_REVISION_SUCCESS:
		case CampaignsActionKeys.MODIFY_REVISION_ERROR: {
			return {
				...state,
				isUpdatingVersion: false,
			};
		}

		case CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_START: {
			return {
				...state,
				isUpdatingCampaignEntities: true,
				explicitObjectsError: false,
			};
		}

		case CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_ERROR: {
			return {
				...state,
				isUpdatingCampaignEntities: false,
				explicitObjectsError: true,
			};
		}

		case CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_SUCCESS: {
			return {
				...state,
				isUpdatingCampaignEntities: false,
				explicitObjectsError: false,
			};
		}
		
		case entities.distributions.actionNames.get.success: {
			const { id } = action.data.item;
			
			if (state.distributionsOrder.includes(id)) {
				return state;
			}
			
			return {
				...state,
				distributionsOrder: [...state.distributionsOrder, id],
			};
		}

		default: {
			return state;
		}
	}
};

const campaignsState = state => state.campaigns as CampaignsState;

// Selectors
export const selectors = {
	rulesSourceMap: state => campaignsState(state).rulesSourceMap,
	sourceOrder: state => campaignsState(state).sourceOrder,
	campaignId: state => campaignsState(state).campaignId,
	groupCampaignId: state => campaignsState(state).groupCampaignId,
	campaignsOrder: state => campaignsState(state).campaignsOrder,
	distributionsOrder: state => campaignsState(state).distributionsOrder,
	viewersOrder: state => campaignsState(state).viewersOrder,
	sourceTriggersMap: state => campaignsState(state).sourceTriggersMap,
	isLoadingCampaignData: (state) => campaignsState(state).campaignGroupLoadStatus === "pending",
	campaignGroupLoadStatus: (state) => campaignsState(state).campaignGroupLoadStatus,
	effectsOrder: state => campaignsState(state).effectsOrder,
	selectedSource: state => campaignsState(state).selectedSource,
	editableRulesCopy: state => campaignsState(state).editableRulesCopy,
	creatingRuleId: state => campaignsState(state).creatingRuleId,
	rulesError: state => campaignsState(state).rulesError,
	prizeSetsOrder: state => campaignsState(state).prizeSetsOrder,
	isUpdatingCampaignEntities: state => campaignsState(state).isUpdatingCampaignEntities,
	explicitObjectsError: state => campaignsState(state).explicitObjectsError,
	isSendingObject: state => campaignsState(state).sendStatus === "sending",
	sendObjectError: state => campaignsState(state).sendStatus === "error",
	editableBehaviorsCopy: state => campaignsState(state).editableBehaviorsCopy,
	isUpdatingBehavior: state => campaignsState(state).isUpdatingBehavior,
	isAddingRule: state => campaignsState(state).ruleStatus === "adding",
	ruleError: state => campaignsState(state).ruleError,
	ruleEntitiesMap: state => campaignsState(state).ruleEntitiesMap,
	campaignData: state => campaignsState(state).campaignReviewData,
	isUpdatingVersion: state => campaignsState(state).isUpdatingVersion,
	behaviorsOrder: state => campaignsState(state).behaviorsOrder,
	lastDistributionId: state => campaignsState(state).lastDistributionId,
	isUploadingImage: state => campaignsState(state).isUploadingImage,
	isUpdatingImage: state => campaignsState(state).isUpdatingImage,
};

export function useCurrentCampaignId(): string | undefined {
	return useSelector(selectors.campaignId);
}
