import VatomInc from "utils/VatomIncApi";
import { PlansList, planSelectors } from "reducers/planReducers";

export type PlanActions =
	| StartFetchPlans
	| SuccessFetchPlans
	| ErrorFetchPlans
	| StartUpdateSubscription
	| SuccessUpdateSubscription
	| ErrorUpdateSubscription;

export enum PlanActionTypes {
	START_FETCH_PLANS = "PLANS/FETCH_PLANS",
	SUCCESS_FETCH_PLANS = "PLANS/SUCCESS_PLANS",
	ERROR_FETCH_PLANS = "PLANS/ERROR_FETCH_PLANS",

	START_UPDATE_SUBSCRIPTION = "PLANS/START_UPDATE_SUBSCRIPTION",
	SUCCESS_UPDATE_SUBSCRIPTION = "PLANS/SUCCESS_UPDATE_SUBSCRIPTION",
	ERROR_UPDATE_SUBSCRIPTION = "PLANS/ERROR_UPDATE_SUBSCRIPTION",
}

export const planActions = {
	fetchPlans: (businessId?: string | undefined) => async dispatch => {
		try {
			await dispatch({
				type: PlanActionTypes.START_FETCH_PLANS,
			});

			const res = await VatomInc.plans.fetchPlans(
				process.env.REACT_APP_AUDIENCE || "",
				false,
				businessId,
				dispatch
			);
			await dispatch({
				type: PlanActionTypes.SUCCESS_FETCH_PLANS,
				data: res,
			});
		} catch (err) {
			if (err instanceof Error)
				dispatch({
					type: PlanActionTypes.ERROR_FETCH_PLANS,
					data: err.message || "Plans could not be loaded",
				});
		}
	},
	updateSubscription: (updatePlanId: string | null) => async (dispatch, getState) => {
		const subscriptionId = planSelectors.activeSubscriptionId(getState());
		const plans = planSelectors.plansList(getState());

		if (updatePlanId === null) {
			dispatch({
				type: PlanActionTypes.ERROR_UPDATE_SUBSCRIPTION,
				data: "Subscription could not be updated",
			});
			return;
		}
		try {
			dispatch({
				type: PlanActionTypes.START_UPDATE_SUBSCRIPTION,
			});
			const res = await VatomInc.plans.updateSubscription(updatePlanId, subscriptionId);
			const updatedPlans = {
				...plans,
				activePlanId: res.planId,
				activeSubscriptionId: res.subscriptionId,
			};

			dispatch({
				type: PlanActionTypes.SUCCESS_UPDATE_SUBSCRIPTION,
				data: updatedPlans,
			});
		} catch (err) {
			if (err instanceof Error)
				dispatch({
					type: PlanActionTypes.ERROR_UPDATE_SUBSCRIPTION,
					// @ts-ignore
					data: err.body.error.message || err.message || "Subscription could not be updated",
				});
		}
	},
};
interface Action<Type> {
	type: Type;
}

// fetch plans
export interface StartFetchPlans extends Action<PlanActionTypes.START_FETCH_PLANS> {
	data: any;
}

export interface SuccessFetchPlans extends Action<PlanActionTypes.SUCCESS_FETCH_PLANS> {
	data: any;
}

export interface ErrorFetchPlans extends Action<PlanActionTypes.ERROR_FETCH_PLANS> {
	data: string;
}

// update subscription
export interface StartUpdateSubscription extends Action<PlanActionTypes.START_UPDATE_SUBSCRIPTION> {
	data: any;
}

export interface SuccessUpdateSubscription
	extends Action<PlanActionTypes.SUCCESS_UPDATE_SUBSCRIPTION> {
	data: any;
}

export interface ErrorUpdateSubscription extends Action<PlanActionTypes.ERROR_UPDATE_SUBSCRIPTION> {
	data: string;
}

export function findActivePlan(plans: PlansList) {
	const { activePlanId, items } = plans;
	const selectedPlanName = activePlanId.split("_")[0];

	// loop through the plans list looking for a name that matches the current plan ID slug
	for (const plan of items) {
		const name = plan.name.toLowerCase();

		if (selectedPlanName === name) {
			return plan;
		}
	}

	// shouldn't happen
	return undefined;
}
