import * as uuid from "uuid";
import path from "path-browserify";
import VatomInc from "./index";
// import { getFileExtension } from "utils/functions"

export interface Operation {
	name: string;
	width?: number;
	height?: number;
	x?: number;
	y?: number;
}

const RESOURCES_API = process.env.REACT_APP_RESOURCE_SERVER || "https://resources.api.vatominc.com";
export default class VatomIncResources {
	readonly businessName: string = "";

	constructor(private varius: typeof VatomInc) {}

	public async uploadImage(image: File, needErrorMessage: boolean = false, keepOriginalFileName: boolean = false): Promise<any> {
		try {
			const formData = new FormData();
			formData.append("Image", image);

			// const fileExt = getFileExtension(image.name);
			
			const origFileExt = path.extname(image.name)
			const origFileBase = path.basename(image.name)
			formData.append("fileName", keepOriginalFileName ? origFileBase : uuid.v1() + origFileExt)
			
			const res: any = await this.varius.post({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/resources`,
				isAuthed: true,
				isAbsoluteUrl: true,
				body: formData,
				needErrorMessage
			});
			return await res.body;
		} catch (e:any) {
			console.error(e);
			return e;
		}
	}

	public async sendSnapshot(imageURL: string, objectId: string, exampleObject: any): Promise<any> {
		// get the real base64 content of the file
		// const realData = imageURL.split(";")[1].split(",")[1];
		// const blobData = new Blob([realData], { type: "image/png" });

		try {
			const res: any = await this.varius.post({
				path: `/b/${this.varius.businessId}/object-definitions/${objectId}/cover`,
				isAuthed: true,
				body: {
					imageURL,
					exampleObject,
				},
			});

			return res.body;
		} catch (e:any) {
			return e;
		}
	}

	public async updateImage(resourceUrl: string, operations: Operation[] = [], isFileSystemFile?: boolean): Promise<any> {
		try {
			const body = {
				operations,
			};

			let fileName : string = resourceUrl;
			if (!isFileSystemFile) {
				fileName = resourceUrl.split(`/${this.varius.businessId}/`)[1];
			}

			const res: any = await this.varius.put({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/resources/${fileName}`,
				isAuthed: true,
				isAbsoluteUrl: true,
				body,
			});

			return res.body;
		} catch (e:any) {
			return e;
		}
	}

	public async deleteImage(oldFileName: string): Promise<any> {
		try {

			const res: any = await this.varius.delete({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/resources/${oldFileName}`,
				isAuthed: true,
				isAbsoluteUrl: true
			});

			return res.body;
		} catch (e:any) {
			return e;
		}
	}

	public async uploadFile(file: File): Promise<any> {
		try {
			const origFileExt = path.extname(file.name)
			
			const formData = new FormData();
			formData.append("file", file);
			formData.append("fileName", uuid.v1() + origFileExt)

			const res: any = await this.varius.post({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/resources`,
				isAuthed: true,
				isAbsoluteUrl: true,
				body: formData,
			});
			const { fileName } = res.body;
			const res2: any = await this.varius.put({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/resources/${fileName}`,
				isAuthed: true,
				isAbsoluteUrl: true,
				body: {
					operations: [],
					save: {
						businessId: this.varius.businessId,
					},
				},
			});
			
			return {...res2.body, resourceInfo: res.body.resourceInfo };
		} catch (e:any) {
			return e;
		}
	}

	public async createFolder(folderPath: string): Promise<any> {
		try {
			const res: any = await this.varius.post({
				path: `${RESOURCES_API}/b/${this.varius.businessId}/folder/${folderPath}`,
				isAuthed: true,
				isAbsoluteUrl: true,
				needErrorMessage: true
			});
			
			return res.body;
		} catch (e:any) {
			return e;
		}
	}
}