import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import {
	DropdownTrigger,
	DropdownDivider,
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuHeader,
	NavBar,
	NavLink,
	Text,
	useTheme,
	AppSwitcher,
	Dropdown,
	Logo,
	Avatar,
	NavDropdownItem,
	AsyncSearchableDropdown,
} from "@varius.io/wombo";
import { Link } from "react-router-dom";
import Flag from "components/common/Flag";
import { FlagId } from "utils/flags";
import { useSelector, useDispatch } from "react-redux";
import { selectors as appSelectors } from "reducers/appReducers";
import {
	selectors as userSelectors,
	useCurrentBusinessName,
	useCurrentBusinessId,
} from "reducers/userReducers";
import { useUser, useAuthStatus, AuthStatus, useLogout } from "auth";
import { CSSProperties } from "react";
import { useIsMobile } from "hooks/queries";
import { useLicenseLimits } from "hooks/licenses";
import { NavMenu } from "@varius.io/wombo/components/NavBar/NavBar";
import {
	StyledAccountDropdown,
	StyledSwatch,
	StyledStatsContainer,
	StyledStatsText,
	DropdownTriggerContainer,
	StyledNavLink,
} from "./styles";
import VatomIncApi from "utils/VatomIncApi";
import { debounce } from "ts-debounce";
import useSWR from "swr";
import authActions from "actions/authActions";
import store from "utils/store";

interface NavComponentsProps {
	currentBusinessName?: string;
	isBusinessAdmin?: boolean;
	isBillingAdmin?: boolean;
	isSpaceAdmin?: boolean;
	isSpaceAdminLocal?: boolean;

	showSpaces?: boolean;
	showHome?: boolean;
	showNFT?: boolean;
	showCustomers?: boolean;
	showAppSwitcher?: boolean;
	showBussinessSearch?: boolean;
}

var info = require("../../../package.json");

const ProgressBar = props => {
	const { completed } = props;

	const containerStyles = {
		height: 5,
		width: "90%",
		margin: "auto",
		backgroundColor: "rgba(0, 41, 83, 0.2)",
	};

	const fillerStyles: CSSProperties = {
		height: "100%",
		width: `${completed}%`,
		backgroundColor: "#ff9f5c",
		borderRadius: "inherit",
		textAlign: "right",
	};

	return (
		<div style={containerStyles}>
			<div style={fillerStyles} />
		</div>
	);
};

const LogoutMenuItem: React.FC = () => {
	const logout = useLogout();
	const { t } = useTranslation("nav");
	localStorage.removeItem("isSubscribed");
	return (
		<DropdownMenuItem id="logout-link" onClick={logout}>
			<Text>{t("account.logout")}</Text>
		</DropdownMenuItem>
	);
};

const VatomsAvailable: React.FC = () => {
	const { t } = useTranslation("nav");
	const theme = useTheme();
	const licenseLimits = useLicenseLimits();
	const isMobile = useIsMobile();
	const color = theme.components?.nav?.color || "#343a40";

	if (licenseLimits.isLoading) return null;

	const total = licenseLimits.freeVatoms;

	if (!total) return null;

	const remaining = licenseLimits.qtyFreeRemaining;

	return (
		<StyledStatsContainer theme={theme} isMobile={isMobile}>
			<div style={{ height: "60%", textAlign: "center", marginTop: 4, color }}>
				<Text renderAs="small" style={{ color }}>
					{remaining}
				</Text>
				<StyledStatsText style={{ color }} renderAs="small">
					&nbsp;{t("nft.free")}
				</StyledStatsText>
			</div>
			<ProgressBar completed={((total - remaining) / total) * 100} />
		</StyledStatsContainer>
	);
};

interface BusinessDropdownProps {
	isMobile: boolean;
	currentBusiness: any;
	userBusinesses: any;
	showBussinessSearch?: boolean;
}

interface BannedWord {
	value: string;
	source: string;
	continue: boolean;
}

interface Option {
	label: string;
	value: string;
	routeToSpaces?: boolean;
}

const BusinessDropdown: React.FC<BusinessDropdownProps> = ({
	isMobile,
	currentBusiness,
	userBusinesses,
	showBussinessSearch,
}) => {
	// i18n
	const { t } = useTranslation("common");
	// Local state
	const [businessSearchValue, setBusinessSearchValue] = useState<Option>({
		value: currentBusiness.businessId,
		label: currentBusiness.displayName,
	});

	useEffect(() => {
		setBusinessSearchValue({
			value: currentBusiness.businessId,
			label: currentBusiness.displayName,
		});
	}, [currentBusiness]);

	const currentUser = useUser();
	const currentUserId = currentUser?.profile?.sub || "";
	const currentBusinessName = currentBusiness ? currentBusiness.businessName : "";
	const currentUrl = window.location.pathname.substr(1);

	// Redux
	const dispatch = useDispatch();

	// const isOnBusinessPage = currentBusinessName
	// 	? currentUrl.indexOf(currentBusinessName) !== -1
	// 	: false;

	if (!currentBusinessName) {
		return null;
	}

	let displayName = currentBusiness!.displayName;
	if (isMobile) {
		displayName = displayName.slice(0, 12);
	}

	let options: Option[] = [];
	if (userBusinesses) {
		Object.keys(userBusinesses).map((businessId: string, key: number) => {
			const business = userBusinesses[businessId];
			const isBusinessAdmin = !!business?.roles?.["Business Admin"];
			const routeToSpaces =
				!isBusinessAdmin ??
				(!!business?.roles?.["Space Admin Local"] || !!business?.roles?.["Space Admin"]);

			if (business.businessName !== "*")
				options.push({
					value: business.businessName,
					label: business.displayName,
					routeToSpaces,
				});
		});
	}

	const { data: amISuperAdminResponse } = useSWR(
		currentUserId ? `/u/${currentUserId}/roles/amISuperAdmin` : null,
		() => VatomIncApi.users.validateSuperAdminRole(currentUserId),
		{ revalidateOnFocus: false }
	);

	const redirectToBusiness = async (businessId, businessName, routeToSpaces) => {
		const state = store.getState();
		if (state.user.businesses?.length && !state.user.businesses.includes(businessName)) {
			await dispatch(authActions.updateUserRoles(businessId));
		}

		if (routeToSpaces) {
			history.push(`/${businessName}/spaces/list`);
		} else {
			const url = buildNavLink(businessName);
			history.push(url);
		}
	};

	// Superusers
	const _loadOptions = async (inputValue: string, callback: (options: any[]) => void) => {
		const { items: businesses } = await VatomIncApi.me.listBusiness(inputValue);
		const loadedOptions = businesses.map(({ id, displayName, businessName }) => {
			return { value: businessName, label: displayName, id };
		});

		callback(loadedOptions);
	};
	const loadOptions = debounce(_loadOptions, 300);

	// Non-superusers
	const _loadStaticOptions = (inputValue: string, callback: (options: any[]) => void) => {
		const loadedOptions = options.filter(
			i =>
				i.label.toLowerCase().includes(inputValue.toLowerCase()) ||
				i.value.toLowerCase().includes(inputValue.toLowerCase())
		);
		callback(loadedOptions);
	};
	const loadStaticOptions = debounce(_loadStaticOptions, 300);

	const dropdownRef = useRef<Dropdown>(null);

	if (!showBussinessSearch)
		return (
			<div
				style={{
					width: isMobile ? "120px" : "200px",
					display: "flex",
					justifyContent: "flex-end",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<span
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						fontFamily: "Inter",
						fontWeight: 600,
						fontSize: 15,
					}}
				>
					{businessSearchValue.label}
				</span>
			</div>
		);
	return (
		<>
			{currentBusiness && userBusinesses && amISuperAdminResponse ? (
				<div
					style={{
						width: isMobile ? "120px" : "200px",
					}}
				>
					<Dropdown ref={dropdownRef} style={{ width: "100%" }}>
						<DropdownTrigger
							style={{
								display: "flex",
								justifyContent: "flex-end",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<span
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									fontFamily: "Inter",
									fontWeight: 600,
									fontSize: 15,
								}}
							>
								{businessSearchValue.label}
							</span>
							<i style={{ marginLeft: 10 }} className="fa-thin fa-angle-down" />
						</DropdownTrigger>
						<DropdownMenu style={{ width: "100%", paddingBottom: 0 }}>
							<AsyncSearchableDropdown
								id="async-business-dropdown"
								key="async-business-dropdown"
								menuIsOpen={true}
								value={null}
								setValue={value => {
									dropdownRef?.current?.closeMenu();
									setBusinessSearchValue(value);
								}}
								onChangeCallback={data =>
									redirectToBusiness(data.id, data.value, data.routeToSpaces)
								}
								options={options}
								className="sd"
								placeholder={t("search")}
								loadOptions={amISuperAdminResponse.isSuperAdmin ? loadOptions : loadStaticOptions}
								isSearchable
								styles={{
									indicatorsContainer: (base: any, state: any) => ({
										display: "none",
									}),
									menu: (base: any, state: any) => ({
										position: "relative",
										boxShadow: "none",
										marginTop: 5,
										overflowY: "hidden",
										width: "calc(100% + 0.665rem)",
										fontSize: 15,
									}),
								}}
							/>
						</DropdownMenu>
					</Dropdown>
				</div>
			) : null}
		</>
	);
};

function buildNavLink(businessName: string) {
	const currentUrl = window.location.pathname.substr(1);
	const urlSlugs = currentUrl.split("/");
	let url: string = "";
	const bannedWords: Array<BannedWord> = [
		{
			value: "profile",
			source: "customers", // For example account/profile shouldn't be banned but customers/profile should be.
			continue: false, // For urls that don't end with the word value we want to ban. Example. customers/profile/userId/recent-activity. We want profile/etc banned, continue set to false so indicate that from /profile onwards is banned.
		},
		{
			value: "edit",
			source: "", // If empty means the value will be banned from every url. customers/edit, spaces/edit, campaigns/edit, etcccc.
			continue: true, // if the value is the last slug of the url maintain in true. Else false.
		},
		{
			value: "review",
			source: "",
			continue: true,
		},
	];

	let continueAddingSlugsToUrl: boolean = true;
	urlSlugs.forEach((slug: string, index: number) => {
		if (index > 0) {
			const regex = /^[A-Za-z0-9]{10}$/; // Regex for strings with alphanumeric words with 10 chars
			const isNotANumber = Number.isNaN(Number(slug)); // Check if the slug is not a number
			const isAlphanumeric = regex.test(slug);
			const containsBannedWords = bannedWords.some(word => {
				const hasBannedWordIncluded: boolean = slug.toLowerCase().includes(word.value);
				const hasSource: boolean = !!word.source;
				const urlHasSource: boolean = !!(word.source && urlSlugs.includes(word.source));
				if (hasBannedWordIncluded && !hasSource) return true;
				else if (hasBannedWordIncluded && !urlHasSource) return false;
				else if (hasBannedWordIncluded && hasSource && urlHasSource) {
					url = `/${word.source}`;
					continueAddingSlugsToUrl = word.continue;
				}
				return hasBannedWordIncluded;
			});
			if (!isAlphanumeric && !containsBannedWords && isNotANumber && continueAddingSlugsToUrl)
				url += `/${slug}`;
		}
	});
	return `/${businessName}${url}`;
}

const AccountDropdown = ({
	currentBusinessName,
	isBusinessAdmin,
	isBillingAdmin,
}: NavComponentsProps) => {
	const { t } = useTranslation("nav");
	const theme = useTheme();
	const isMobile = useIsMobile();
	const user = useUser();

	const authStatus = useAuthStatus();
	const systemInfo = useSelector(appSelectors.systemInfo);

	return (
		<StyledAccountDropdown theme={theme} id="user-dropdown" isMobile={isMobile}>
			<DropdownTrigger id="locatorUserDropdownMenu">
				<Avatar size={44} borderColor={theme.colors.primary} src={user?.profile?.picture} />
			</DropdownTrigger>

			<DropdownMenu style={{ minWidth: 200 }} alignment="right">
				<DropdownMenuHeader>
					<Text>{user?.profile?.email}</Text>
				</DropdownMenuHeader>

				<DropdownDivider />

				{currentBusinessName && (
					<>
						{(isBillingAdmin || isBusinessAdmin) && (
							<DropdownMenuItem
								id="locatorUserAccount"
								onClick={() => history.push(`/${currentBusinessName}/account/profile`)}
							>
								<Text>{t("account.title")}</Text>
							</DropdownMenuItem>
						)}

						<DropdownMenuItem
							id="locatorUserConnections"
							onClick={() => history.push(`/${currentBusinessName}/connections`)}
						>
							<Text>{t("account.connections")}</Text>
						</DropdownMenuItem>
					</>
				)}

				<DropdownMenuItem
					id="locatorUserFirehose"
					onClick={() => history.push(`/${currentBusinessName}/firehose`)}
				>
					<Text>{t("account.firehose")}</Text>
				</DropdownMenuItem>

				{isBusinessAdmin && (
					<DropdownMenuItem
						id="locatorUserSettings"
						onClick={() => history.push(`/${currentBusinessName}/settings`)}
					>
						<Text>{t("account.settings")}</Text>
					</DropdownMenuItem>
				)}

				<DropdownDivider />

				<Flag id={FlagId.DevInfoLink}>
					<DropdownMenuItem id="dev-info-link" onClick={() => history.push("/dev-info")}>
						<Text
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							{t("account.dev")}
							{systemInfo && (
								<>
									<StyledSwatch theme={theme} backgroundColor={systemInfo.environments.STUDIO_ENV}>
										S
									</StyledSwatch>
									<StyledSwatch theme={theme} backgroundColor={systemInfo.environments.API_ENV}>
										A
									</StyledSwatch>
								</>
							)}
						</Text>
					</DropdownMenuItem>

					<DropdownMenuItem onClick={() => history.push(`/${currentBusinessName}/flags`)}>
						<Text
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							{t("account.flags")}
						</Text>
					</DropdownMenuItem>
				</Flag>

				{authStatus === AuthStatus.Authed && <LogoutMenuItem />}

				<DropdownDivider />
				<Text
					style={{
						textAlign: "center",
						fontSize: theme.ms(-0.3),
					}}
				>
					{info.version}
				</Text>
			</DropdownMenu>
		</StyledAccountDropdown>
	);
};

const LeftContent = ({ showHome, showNFT, showSpaces, showCustomers }: NavComponentsProps) => {
	const { t } = useTranslation("nav");
	const theme = useTheme();
	const authStatus = useAuthStatus();
	const businessName = useCurrentBusinessName();
	const licenseLimits = useLicenseLimits();
	const isMobile = useIsMobile();
	const isLoggedIn = authStatus === AuthStatus.Authed;

	const colorNav = theme.components?.nav?.color || "#343a40";
	const activeColor = theme.components?.nav?.activeColor || theme.colors.primary;

	// Flags

	// hide left content when user is unauthed
	if (authStatus === AuthStatus.Unauthed) {
		return null;
	}

	// hide left content while business is loading (currently assumes that a
	// business WILL be loaded separately by `businessRequired` in order to
	// show left content)
	if (authStatus === AuthStatus.Loading || !businessName || licenseLimits.isLoading) {
		return null;
	}

	function buildSpacesDropdown() {
		const menuItems = [
			{ path: `/${businessName}/spaces/list`, title: t("spaces.spaces"), id: "spaces-link" },
			{
				path: `/${businessName}/spaces/templates`,
				title: t("spaces.templates"),
				id: "templates-link",
			},
			{ path: `/${businessName}/spaces/events`, title: t("spaces.events"), id: "events-link" },
		];
		return (
			<Dropdown>
				<DropdownTrigger>
					<DropdownTriggerContainer>
						<NavLink
							id="spaces-link"
							to={`/${businessName}/spaces`}
							title={t("spaces.title")}
							color={colorNav}
							activeColor={activeColor}
						/>
						<i className="fa-thin fa-angle-down"></i>
					</DropdownTriggerContainer>
				</DropdownTrigger>
				<DropdownMenu>
					{menuItems.map((item, index) => (
						<DropdownMenuItem key={index}>
							<NavDropdownItem id={item.id} to={item.path} title={item.title} />
						</DropdownMenuItem>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	}

	function buildNFTDropdown() {
		return (
			<Dropdown>
				<DropdownTrigger>
					<DropdownTriggerContainer>
						<NavLink
							id="nftLink"
							to={`/${businessName}/campaigns`}
							title={t("nft.title")}
							color={colorNav}
							activeColor={activeColor}
						/>
						<i className="fa-thin fa-angle-down"></i>
					</DropdownTriggerContainer>
				</DropdownTrigger>
				<DropdownMenu>
					<DropdownMenuItem>
						<NavDropdownItem
							id="locatorCampaignsLink"
							to={`/${businessName}/campaigns`}
							title={t("nft.campaigns")}
						/>
					</DropdownMenuItem>
					<DropdownMenuItem>
						<NavDropdownItem
							id="objects-link"
							to={`/${businessName}/objects`}
							title={t("nft.designs")}
						/>
					</DropdownMenuItem>
				</DropdownMenu>
			</Dropdown>
		);
	}

	function customerOptions() {
		return (
			<Dropdown>
				<DropdownTrigger>
					<DropdownTriggerContainer>
						<NavLink
							id="customers-dropdown"
							to={`/${businessName}/customers`}
							title={t("customers.title")}
							color={colorNav}
							activeColor={activeColor}
						/>
						<i className="fa-thin fa-angle-down"></i>
					</DropdownTriggerContainer>
				</DropdownTrigger>
				<DropdownMenu>
					<DropdownMenuItem onClick={() => history.push(`/${businessName}/customers/search`)}>
						<NavDropdownItem
							id="customers-search"
							to={`/${businessName}/customers/search`}
							title={t("customers.search")}
						/>
					</DropdownMenuItem>
					<>
						<DropdownMenuItem onClick={() => history.push(`/${businessName}/customers/explore`)}>
							<NavDropdownItem
								id="customers-search"
								to={`/${businessName}/customers/explore`}
								title={t("customers.explore")}
							/>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => history.push(`/${businessName}/customers/segments`)}>
							<NavDropdownItem
								id="customers-search"
								to={`/${businessName}/customers/segments`}
								title={t("customers.segments")}
							/>
						</DropdownMenuItem>
					</>
					<DropdownMenuItem onClick={() => history.push(`/${businessName}/customers/lists`)}>
						<NavDropdownItem
							id="customer-lists"
							to={`/${businessName}/customers/lists`}
							title={t("customers.lists")}
						/>
					</DropdownMenuItem>
				</DropdownMenu>
			</Dropdown>
		);
	}

	const vatomLogoLink = isLoggedIn && businessName ? `/${businessName}/analytics` : "/";
	const color = theme.components?.nav?.color || "#343a40";
	const logoSrc = theme?.appLogo || "";
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{!isMobile && (
				<Link id="logo-link" to={vatomLogoLink}>
					<Logo name="Studio" h3Styles={{ color }} src={logoSrc} />
				</Link>
			)}
			{licenseLimits.campaignsEnabled ? (
				<NavMenu>
					{showHome && (
						<StyledNavLink
							id="home-link"
							to={`/${businessName}/analytics`}
							title={t("home")}
							color={colorNav}
							activeColor={activeColor}
						/>
					)}
					{showNFT && buildNFTDropdown()}
					{showSpaces && buildSpacesDropdown()}
					{showCustomers && customerOptions()}
				</NavMenu>
			) : (
				<NavMenu>
					<StyledNavLink
						id="locatorCampaignsLink"
						to={`/${businessName}/campaigns`}
						title="NFTs"
						color={colorNav}
						activeColor={activeColor}
					/>
				</NavMenu>
			)}
			{isMobile && (
				<Link id="logo-link" to={isLoggedIn && businessName ? `/${businessName}/campaigns` : "/"}>
					<Logo name="" h3Styles={{ color }} />
				</Link>
			)}
		</div>
	);
};

const RightContent = ({
	currentBusinessName,
	isBusinessAdmin,
	isBillingAdmin,
	showAppSwitcher,
	showBussinessSearch
}: NavComponentsProps) => {
	const authStatus = useAuthStatus();
	const isMobile = useIsMobile();

	const currentBusiness = useSelector(userSelectors.currentBusiness);
	const userBusinesses = useSelector(userSelectors.userRolesMap);

	if (authStatus !== AuthStatus.Authed) {
		return null;
	}

	return (
		<>
			<VatomsAvailable />
			{currentBusiness ? (
				<BusinessDropdown
					// theme={theme}
					isMobile={isMobile}
					currentBusiness={currentBusiness}
					userBusinesses={userBusinesses}
					showBussinessSearch={showBussinessSearch}
				/>
			) : null}
			{showAppSwitcher && <AppSwitcher active="studio" style={{ float: "right" }} isMobile={isMobile} />}
			<AccountDropdown
				currentBusinessName={currentBusinessName}
				isBusinessAdmin={isBusinessAdmin}
				isBillingAdmin={isBillingAdmin}
			/>
		</>
	);
};

const Nav: React.FC = () => {
	const businessId = useCurrentBusinessId();
	const theme = useTheme();

	const currentBusiness = useSelector(userSelectors.currentBusiness);
	const currentBusinessName = currentBusiness ? currentBusiness.businessName : "";

	// Roles conntrol
	const isBusinessAdmin = !!currentBusiness && !!currentBusiness.roles?.["Business Admin"];
	const isBillingAdmin = !!currentBusiness && !!currentBusiness.roles?.["Billing Admin"];
	const isDeveloper = !!currentBusiness && !!currentBusiness.roles?.["Developer"];
	const isMarketer = !!currentBusiness && !!currentBusiness.roles?.["Marketer"];
	const isSpaceAdmin = !!currentBusiness && !!currentBusiness.roles?.["Space Admin"];
	const isSpaceAdminLocal = !!currentBusiness && !!currentBusiness.roles?.["Space Admin Local"];
	const isCustomerSupport = !!currentBusiness && !!currentBusiness.roles?.["Customer Support "];
	const isAdmin = isBusinessAdmin || isBillingAdmin
	// Show Sections
	const showHome = (isAdmin) && !theme?.hideSections?.home;
	const showNFT = (isAdmin || isDeveloper || isMarketer) && !theme?.hideSections?.nft;
	const showSpaces = (isSpaceAdmin || isSpaceAdminLocal || isBusinessAdmin) && !theme?.hideSections?.spaces ;
	const showCustomers = (isAdmin || isCustomerSupport) && !theme?.hideSections?.customer;
	const showAppSwitcher = !theme?.hideSections?.appSwitcher;
	const showBussinessSearch = !theme?.hideSections?.businessSearch;
	

	// Nav styles
	const backgroundNav = theme.components?.nav?.background || "white";
	const colorNav = theme.components?.nav?.color || "#343a40";

	// note: the business name is loaded before the business id, and the
	// business id is needed by the `useBillingSource` hook in `LeftContent`

	return (
		<NavBar
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				zIndex: 100,
				background: backgroundNav,
				color: colorNav,
			}}
			id="nav-bar"
			leftContent={
				businessId && (
					<LeftContent
						showHome={showHome}
						showNFT={showNFT}
						showSpaces={showSpaces}
						showCustomers={showCustomers}
					/>
				)
			}
			rightContent={
				<RightContent
					currentBusinessName={currentBusinessName}
					isBusinessAdmin={isBusinessAdmin}
					isBillingAdmin={isBillingAdmin}
					showAppSwitcher={showAppSwitcher}
					showBussinessSearch={showBussinessSearch}
				/>
			}
		/>
	);
};

export default Nav;
