import store from "utils/store";
import { CampaignEntity } from "reducers/entitiesReducers";

export function convertSource(source: CampaignEntity) {
	if (source.type !== "provider") {
		return source;
	}

	const provider = (store.getState().entities.providers as any).all[source.id];

	return {
		type: "service",
		id: `${provider.typeId}/${provider.serviceId}`,
	};
}
