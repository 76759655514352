import React from "react";
import actionName from "./utils/name-gen";
import VatomInc from "utils/VatomIncApi";
import { selectCurrentBusinessId } from "reducers/userReducers";
import {
	selectDisplayName,
	selectUrl,
	selectLocation,
	selectLogoSrc,
	selectEthAccount,
	selectOwnerOperated,
	selectDefaultCampaignId,
} from "reducers/businessProfileReducer";
import { Operation } from "utils/VatomIncApi/resources";
import { BusinessUpdate } from "utils/VatomIncApi/businesses";

// V2
import { Types as BusinessReducerTypes } from 'reducers/v2/business'
import { mutate } from "swr";
import { Business, BusinessPageConfig, BusinessRewardsSettings } from "reducers/entitiesReducers";

// TODO: Deprecated (aleady in use)
export function getBusiness() {
	return async (dispatch, getState) => {
		const state = getState();
		const businessId = selectCurrentBusinessId(state);

		const actName = actionName("business-profile", "get", "profile");

		dispatch({
			type: actName("pending"),
		});

		const api = VatomInc;
		const business = await api.businesses.getBusiness(businessId!);

		dispatch({
			type: actName("success"),
			data: business,
		});
	};
}

const AUTOSAVE_DEBOUNCE_TIME: number = parseInt(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_TIME!, 10) || 1000; // debounce time for autosave feature in ms


let timeout;
export function updateBusiness(update: BusinessUpdate) {
	return async dispatch => {
		dispatch({
			type: actionName("business-profile", "update", "profile")(),
			data: update,
		});

		// debounced auto-save
		if (timeout !== undefined) {
			clearTimeout(timeout);
		}

		
		timeout = setTimeout(() => {
			dispatch(saveBusiness());
		}, AUTOSAVE_DEBOUNCE_TIME);
	};
}

function  saveBusiness() {
	return async (dispatch, getState) => {
		const state = getState();
		const businessId = selectCurrentBusinessId(state);
		const displayName = selectDisplayName(state);
		const defaultCampaignId = selectDefaultCampaignId(state);
		const url = selectUrl(state);
		const location = selectLocation(state);
		const logoSrc = selectLogoSrc(state);
		const ethAccount = selectEthAccount(state);
		const ownerOperated = selectOwnerOperated(state);

		if (displayName.length === 0) {
			return;
		}

		const actName = actionName("business-profile", "save", "profile");

		dispatch({
			type: actName("pending"),
		});

		const api = VatomInc;
		const business = await api.businesses.updateBusiness(businessId!, {
			displayName,
			url,
			location,
			logoSrc,
			ethAccount,
			ownerOperated,
			defaultCampaignId,
		});

		dispatch({
			type: actName("success"),
			data: business,
		});
	};
}

export async function partialUpdate(business, field: string, value: any, dispatchBusiness: React.Dispatch<any>, setIsSaving: Function) {
	const newBusiness: Partial<Business> =  {
		displayName: business.displayName,
		url: business.url || "",
		location: business.location || "",
		logoSrc: business.logoSrc || "",
		ethAccount: business.ethAccount || "",
		ownerOperated: business.ownerOperated,
		defaultCampaignId: business.defaultCampaignId || "",
	}
	newBusiness[field] = value
	if (!business.hasOwnProperty(field) || !business.displayName) return	
	// debounced auto-save
	if (timeout !== undefined) {
		clearTimeout(timeout);
	}

	timeout = setTimeout(async () => {
		const api = VatomInc;
		await api.businesses.updateBusiness(business.id, newBusiness);
		dispatchBusiness({
			type: BusinessReducerTypes.UpdateField,
			payload: {
				field,
				value,
			},
		})
		// Revalidate SWR
		mutate(`/b/${business.id}`)
		setIsSaving(false)
		business[field] = value
 		return business
	}, AUTOSAVE_DEBOUNCE_TIME);
	business[field] = value
}

export async function uploadLogo(file: File, setTemporaryLogoSrc: Function, setIsUploadingLogo: Function) {
	setIsUploadingLogo(true)
	const { url } = await VatomInc.resources.uploadFile(file);	
	setTemporaryLogoSrc(url)
	setIsUploadingLogo(false)
}


export async function saveLogo(operations: Operation[], businessId: string, resourceURL: string, dispatchBusiness: React.Dispatch<any>, setIsSaving: Function) {	
	setIsSaving(true)
	let url = resourceURL;
	if (operations && operations.length) {
		const timestamp = new Date().getTime();
		const res = await VatomInc.resources.updateImage(resourceURL, operations);
		url = `${res.url}?${timestamp}`; // append timestamp for cache-busting purposes
	}
	const api = VatomInc;
	await api.businesses.updateBusiness(businessId!, {
		logoSrc: url,
	});
	dispatchBusiness({
		type: BusinessReducerTypes.UpdateField,
		payload: {
			field: "logoSrc",
			value: url,
		},
	})
	mutate(`/b/${businessId}`)
	setIsSaving(false)
}


export async function patchPageConfig(businessId: string, pageConfig: BusinessPageConfig|null, dispatchBusiness: React.Dispatch<any>, callback: Function) {
	const api = VatomInc;
	const newPageConfig = await api.businesses.patchBusinessPageConfig(businessId, pageConfig);
	
	dispatchBusiness({
		type: BusinessReducerTypes.UpdateField,
		payload: {
			field: 'pageConfig',
			value: newPageConfig,
		},
	})
	
	callback()
}

export async function patchRewardsSettings(businessId: string, rewardSettings: BusinessRewardsSettings,  dispatchBusiness: React.Dispatch<any>, callback: Function) {
	const api = VatomInc;
	const coin = rewardSettings
	await api.businesses.updateBusiness(businessId!, { coin });

	dispatchBusiness({
		type: BusinessReducerTypes.UpdateField,
		payload: {
			field: 'coin',
			value: coin,
		},
	})

	// Toast message
	callback();
}
