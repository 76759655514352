import entitiesReducers from "reducers/entitiesReducers";
import VatomInc from "utils/VatomIncApi";
import JsonPointer from "json-pointer";

/**
 * Defines a reference to a specific property in a user wizard.
 */
class UserWizardPropertyRef {
	constructor(public businessId: string, public campaignGroupId: string, public userWizardId: string, public stepIndex: number, public propertyPointer: string, public isNew: boolean, public isEditing) {}
	
	// json serialization / deserialization
	
	public static fromJSON(str: string): UserWizardPropertyRef {
		const obj = JSON.parse(str);
		
		const {
			businessId,
			campaignGroupId,
			userWizardId,
			stepIndex,
			propertyPointer,
			isNew,
			isEditing,
		} = obj;

		return new UserWizardPropertyRef(businessId, campaignGroupId, userWizardId, stepIndex, propertyPointer, isNew, isEditing);
	}
	
	public toJSON(): string {
		const {
			businessId,
			campaignGroupId,
			userWizardId,
			stepIndex,
			propertyPointer,
			isNew,
			isEditing,
		} = this;
		
		return JSON.stringify({
			businessId,
			campaignGroupId,
			userWizardId,
			stepIndex,
			propertyPointer,
			isNew,
			isEditing,
		});
	}
	
	/**
	 * Set the property ref value in the user wizard via http calls and redux.
	 * @param value The value to set the property to.
	 * @param dispatch A reference to the redux dispatch function.
	 */
	public async set(value: any, dispatch: any): Promise<void> {
		const {
			businessId,
			campaignGroupId,
			userWizardId,
			stepIndex,
			propertyPointer,
		} = this;
		
		// get step configs
		const { config: stepConfigs } = await VatomInc.userWizards.getCampaignWizard(businessId, campaignGroupId);
		
		// get existing step config
		const config = stepConfigs[stepIndex];
		
		// set new config value
		if (value === undefined) {
			JsonPointer.remove(config, propertyPointer, value);
		}
		else {
			JsonPointer.set(config, propertyPointer, value);
		}
		
		// update user wizard
		dispatch(entitiesReducers.userWizards.actions.update!(userWizardId, {
			step: stepIndex,
			config,
		}));
	}
	
	public static fromPageQueryParams(): UserWizardPropertyRef | undefined {
		const params = new URLSearchParams(window.location.search);
		
		const businessId = params.get("businessId");
		if (businessId === null) { return undefined; }
		
		const campaignGroupId = params.get("campaignGroupId")!;
		if (campaignGroupId === null) { return undefined; }
		
		const userWizardId = params.get("userWizardId")!;
		if (userWizardId === null) { return undefined; }
		
		const stepIndex = Number.parseInt(params.get("stepIndex")!, 10);
		if (Number.isNaN(stepIndex)) { return undefined; }
		
		const propertyPointer = params.get("propertyPointer")!;
		if (propertyPointer === null) { return undefined; }
		
		const isNew = params.get("isNew") === "true";
		const isEditing = params.get("isEditing") === "true";
		
		return new UserWizardPropertyRef(businessId, campaignGroupId, userWizardId, stepIndex, propertyPointer, isNew, isEditing);
	}
	
	public toQueryParams(): string {
		const params = new URLSearchParams({
			businessId: this.businessId,
			campaignGroupId: this.campaignGroupId,
			userWizardId: this.userWizardId,
			stepIndex: this.stepIndex.toString(),
			propertyPointer: this.propertyPointer,
			isNew: this.isNew + "",
			isEditing: this.isEditing + "",
		});
		
		return params.toString();
	}
}

export default UserWizardPropertyRef;
