import { UserWizard } from "reducers/entitiesReducers";
import { StepValidations } from "reducers/wizardsReducers";
import { selectors as userSelectors } from "reducers/userReducers";
import entities from "reducers/entitiesReducers";
// import history from "utils/history";
import { validateSchema } from "../utils/validator";
import { DigitalObject } from "../reducers/entitiesReducers";
import { DigitalObjectsActionKeys } from "./digitalObjectsActions";

interface Action<ActionType extends WizardsActionKeys, ActionData> {
	type: ActionType;
	data: ActionData;
}

export enum WizardsActionKeys {
	VALIDATE_USER_WIZARD = "WIZARDS/VALIDATE_USER_WIZARD",
	REGISTER_NEW_OBJECT_CALLBACK = "WIZARDS/REGISTER_NEW_OBJECT_CALLBACK",
}

export type WizardActions =
	| Action<WizardsActionKeys.VALIDATE_USER_WIZARD, { stepValidations: StepValidations }>
	| Action<WizardsActionKeys.REGISTER_NEW_OBJECT_CALLBACK, { callback: (objectId: string) => any }>;

const actions = {
	createUserWizard: (wizardId: string, campaignId: string) => async (dispatch, getState) => {
		const state = getState();
		const { businessId } = userSelectors.currentBusiness(state)!;

		const userWizard: Partial<UserWizard> = {
			wizardId,
			businessId,
			campaignId,
		};
		const res = await dispatch(entities.userWizards.actions.create!(userWizard));
		return res
	},

	registerNewObjectCallback: (callback: (objectId: string) => any) => dispatch => {
		dispatch({
			type: WizardsActionKeys.REGISTER_NEW_OBJECT_CALLBACK,
			data: {
				callback,
			},
		});
	},

	loadWizardData: (userWizardId: string) => async (dispatch, getState) => {
		// load necessary data in parallel
		await Promise.all([
			(async () => {
				// load wizards for this business
				await dispatch(entities.wizards.actions.list!());
			})(),
			(async () => {
				// load types for this business
				await dispatch(entities.types.actions.list!());
			})(),
		]);

		const state = getState();
		const wizardMap = entities.wizards.selectors.all(state);
		// const currentBusiness = userSelectors.currentBusiness(state);
		const userWizardsMap = entities.userWizards.selectors.all(state);
		const activeUserWizard = userWizardsMap[userWizardId];
		// if (!activeUserWizard) {
		// 	history.replace(`/${currentBusiness!.businessName}/campaigns`);
		// 	return;
		// }

		const activeWizard = wizardMap[activeUserWizard.wizardId];

		await dispatch(actions.validateUserWizard(activeWizard.configSchema, activeUserWizard.config));
	},

	validateUserWizard: (wizardConfigSchema: any, wizardConfig: any) => async (dispatch, getState) => {
		const stepValidations = {};
		const steps = wizardConfigSchema.map((step, index) =>
			(async () => {
				// validate the step
				const { isValid, errors } = await validateSchema(step, wizardConfig[index]);
				stepValidations[index] = isValid;
				
				if (isValid === false) {
					console.log(`wizard step with index ${index} failed validation`, errors);
				}
			})()
		);

		// valid the trigger and effect schemas for this rule in parallel
		await Promise.all(steps);

		await dispatch({
			type: WizardsActionKeys.VALIDATE_USER_WIZARD,
			data: {
				stepValidations,
			},
		});
	},

	/**
	 * Create a Digital Object using a specified designId from a Wizard
	 * @param designId
	 */
	createDigitalObject: (designId: string) => async (dispatch, getState) => {
		// load the design into memory
		const design = await dispatch(entities.designs.actions.get!(designId));
		
		// const typesMap: TypeMap = entities.types.selectors.all(getState());
		// const type: Type = typesMap[design.typeId];
		
		const displayName = `My ${design.displayName} NFT`;
		const cover = design.cover;

		dispatch({
			type: DigitalObjectsActionKeys.SELECT_DESIGN,
			data: {
				designId,
			},
		});

		const digitalObject: DigitalObject = await dispatch(
			entities.digitalObjects.actions.create!({
				designId,
				displayName,
				cover,
			})
		);

		return digitalObject;
	},
};

export default actions;
