import Ajv from "ajv";

export interface ValidationResult {
	isValid: boolean;
	errors: Ajv.ErrorObject[];
}

const cachedSchemas = {};

async function loadSchema(url: string) {
	// try cached

	const cachedSchema = cachedSchemas[url];

	if (cachedSchema !== undefined) {
		return cachedSchema;
	}

	// load from network

	const res = await fetch(url, { method: "GET", mode: "cors" });

	if (res.status >= 400) {
		throw new Error(`validator: error resolving schema with url "${url}": ` + res.status);
	}

	const schema = res.json();

	// add to cache

	cachedSchemas[url] = schema;

	return schema;
}

const ajv = new Ajv({
	// note: needed to pull out default values from schemas
	useDefaults: true,

	// async schema url loader function
	loadSchema,
});

export async function validateSchema(schema: any, data: any): Promise<ValidationResult> {
	const validate = await ajv.compileAsync(schema);
	const isValid = await validate(data);

	return {
		isValid,
		errors: validate.errors || [],
	};
}
