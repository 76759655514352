import VatomInc from "./index";
import { Type } from "reducers/entitiesReducers";

export default class VatomIncTypes {
	constructor(public varius: typeof VatomInc) {}

	public async listTypes(): Promise<Type[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/types`,
			isAuthed: true,
		});

		// todo: check `status` in res
		return res.body.items;
	}
}
