import React, { useEffect } from "react";
import { AuthStatus, useAuthStatus } from "auth";
import PageLoader from "components/App/components/PageLoader";
import { useHistory } from "react-router-dom";

const AuthRequired = ({ children }) => {
	const authStatus = useAuthStatus();
	const history = useHistory();

	// redirect to login route if unauthed
	useEffect(() => {
		if (authStatus === AuthStatus.Unauthed) {
			history.push("/login");
		}
	}, [authStatus, history]);
	
	if (authStatus !== AuthStatus.Authed) {
		return <PageLoader />;
	}
	else {
		return <>{children}</>;
	}
};

export default AuthRequired;
