import { Space, Event } from "components/Spaces/interfaces";
import VatomInc, { RequestService } from "./index";

const OIDC_AUTHORITY = process.env.REACT_APP_OIDC_AUTHORITY;
export interface ITheme {
	id: string;
	version: string;
	businessId: string;
	spaceId: string;
	displayName: string;
	description: string;
	cover: string;
	status: string;
	url: string;
	createdById: string;
	createdByDisplayName: string;
	templateInfo: {
		id: string;
		status: string;
		version: {
			major?: number;
			minor?: number;
			patch?: number;
		};
	};
	createdAt: Date;
	updatedAt: Date;
}
export default class VatomIncEvents {

  constructor(private varius: typeof VatomInc) { }

  public async getBusinessSpaces(filter?: string, page?: number, size?: number, checkLicense?: boolean, hasSpaceAdminLocal?: boolean): Promise<any> {
    try {
			let path = `/b/${this.varius.businessId}/spaces/?`
			const searchParams = new URLSearchParams();

      // Disabled the cache for this call
      searchParams.append("noCache", String(true));

      if (size && typeof page === "number") {
				searchParams.append("page", String(page))
				searchParams.append("size", String(size))
      }
      if (filter) {
				searchParams.append("filter", filter)
      }
			if (checkLicense) {
				searchParams.append("checkLicense", String(checkLicense))
			}

      if (hasSpaceAdminLocal) searchParams.append("spaceAdminLocal", String(hasSpaceAdminLocal))

			path = `${path}${searchParams.toString()}`
			
      const res: any = await this.varius.get({
        path,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }
  public async getBusinessEvents(filter?: string, page?: number, size?: number): Promise<any> {
    try {

      let path = `/b/${this.varius.businessId}/events/`
      if (size && typeof page === "number") {
        path = `${path}?page=${page}&size=${size}`
      }
      if (filter) {
        path = `${path}&filter=${filter}`
      }
      
      const res: any = await this.varius.get({
        path: path,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }
  public async getBusinessEventsBySpaceId(spaceId: string): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `/b/${this.varius.businessId}/events/`,
        isAuthed: true,
        service: RequestService.Events,
      });
      return res.body.filter((i: any) => i.spaceId === spaceId);
    } catch (e: any) {
      return e;
    }
  }
  public async getBusinessSpace(spaceId: string): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `/b/${this.varius.businessId}/spaces/${spaceId}`,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }

  public async getAllBusinessSpaces(): Promise<any> {
    try {
			let path = `/b/${this.varius.businessId}/all/spaces/`
      const res: any = await this.varius.get({
        path,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }
  public async getBusinessEvent(eventId: string): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `/b/${this.varius.businessId}/event/${eventId}`,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }

  public async getBusinessTemplates(
		onlyBusiness?: boolean,
		filter?: string,
		page?: number,
		size?: number,
    id?: string,
	): Promise<{ items: ITheme[], totalPages?: number }> {
		try {
			let path = `/b/${this.varius.businessId}/templates/?`;

			const searchParams = new URLSearchParams();
			if (onlyBusiness) searchParams.append("onlyBusiness", String(true));
      if (id) searchParams.append("id", id);

			if (size && typeof page === "number") {
				searchParams.append("page", String(page));
				searchParams.append("size", String(size));
			}
			if (filter) {
				searchParams.append("filter", filter);
			}

			path = `${path}${searchParams.toString()}`;

			const res: any = await this.varius.get({
				path,
				isAuthed: true,
				service: RequestService.Events,
			});

			return res.body;
		} catch (e: any) {
			return e;
		}
	}

  public async createSpace(body: object): Promise<any> {
    const EXPECTED_STATUS_CODE = 201;

    const res: any = await this.varius.post({
      path: `/b/${this.varius.businessId}/spaces`,
      isAuthed: true,
      body: body,
      service: RequestService.Events,
    });

    if (res.statusCode !== EXPECTED_STATUS_CODE) {
      throw new Error("Space creation error.");
    }

    return res.body;
  }


  public async deleteSpace(spaceId: string): Promise<any> {
    const EXPECTED_STATUS_CODE = 204;

    const res: any = await this.varius.delete({
      path: `/b/${this.varius.businessId}/spaces/${spaceId}`,
      isAuthed: true,
      service: RequestService.Events,
    });

    if (res.statusCode !== EXPECTED_STATUS_CODE) {
      throw new Error("Space delete error.");
    }

    return res.body;
  }



  public async checkSpaceAlias(alias: string): Promise<any> {
    try {
      const res: any = await this.varius.post({
        path: `/b/${this.varius.businessId}/spaces/alias`,
        isAuthed: true,
        body: { alias },
        service: RequestService.Events,
      });

      return res.body;
    } catch (e: any) {
      return e;
    }
  }

  public async updateSpace(spaceID: string, body: Partial<Space>): Promise<any> {
    try {
      const res: any = await this.varius.patch({
        path: `/b/${this.varius.businessId}/spaces/${spaceID}`,
        isAuthed: true,
        body: body,
        service: RequestService.Events,
      });
      return res.body;
    } catch (e) {
      throw new Error("Space update error. ");
    }
  }


  public async createSpaceAlias(alias: string, spaceID: string): Promise<any> {
    try {
      const res: any = await this.varius.patch({
        path: `/b/${this.varius.businessId}/spaces/${spaceID}`,
        isAuthed: true,
        body: { alias },
        service: RequestService.Events,
      });
      return res.body;
    } catch (e: any) {
      throw new Error("Space Alias creation error. ");
    }
  }

  public async createEvent(body: object): Promise<any> {
    const EXPECTED_STATUS_CODE = 201;

    const res: any = await this.varius.post({
      path: `/b/${this.varius.businessId}/events`,
      isAuthed: true,
      body: body,
      service: RequestService.Events,
    });

    if (res.statusCode !== EXPECTED_STATUS_CODE) {
      throw new Error("Event creation error.");
    }

    return res.body;
  }

  public async updateEvent(eventID: string, body: Partial<Event>): Promise<any> {
    try {
      const res: any = await this.varius.patch({
        path: `/b/${this.varius.businessId}/events/${eventID}`,
        isAuthed: true,
        body: body,
        service: RequestService.Events,
      });
      return res.body;
    } catch (e) {
      throw new Error("Event update error. ");
    }
  }

  public async deleteEvent(eventId: string): Promise<any> {
		const EXPECTED_STATUS_CODE = 204;
		try {
			const res: any = await this.varius.delete({
				path: `/b/${this.varius.businessId}/events/${eventId}`,
				isAuthed: true,
				service: RequestService.Events,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Event delete error.");
			}

			return res.body;
		} catch (error) {
			return error;
		}
	}

  public async checkLicenseLimits(licenseId: string, billingPeriod): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `/b/${this.varius.businessId}/spaces/check-limits?licenseId=${licenseId}&billingPeriod=${billingPeriod}`,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e) {
      return e;
    }
  }

	public async listSpaceCapacities(licenseId: string, billingPeriod): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `/b/${this.varius.businessId}/spaces/list-capacities?licenseId=${licenseId}&billingPeriod=${billingPeriod}`,
        isAuthed: true,
        service: RequestService.Events,
      });

      return res.body;
    } catch (e) {
      return [];
    }
  }

  public async getSSOProviders(): Promise<any> {
    const EXPECTED_STATUS_CODE = 200;

    const res: any = await this.varius.get({
      path: `/api/sso-providers?business-id=${this.varius.businessId}&only-custom=true`,
      isAuthed: true,
      service: RequestService.Identity,
    });

    if (res.statusCode !== EXPECTED_STATUS_CODE) {
      throw new Error("Event delete error.");
    }

    return res.body;
  }

  public async getSpaceAdminUsers(spaceId: string): Promise<any[] | null> {
		const EXPECTED_STATUS_CODE = 200;
		try {
			const res: any = await this.varius.get({
				path: `${OIDC_AUTHORITY}/api/b/${this.varius.businessId}/spaces/${spaceId}/users`,
				isAuthed: true,
				isAbsoluteUrl: true,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Get Space admin users error.");
			}

			return res.body;
		} catch (e) {
			return null;
		}
	}

	public async updateSpaceAdminUser(
		spaces: Space[],
		userId: string,
		roles: { id: string }[]
	): Promise<any[] | null> {
		const EXPECTED_STATUS_CODE = 200;
		const spacesList = spaces.map(space => ({ id: space.id, displayName: space.displayName }));
		try {
			const res: any = await this.varius.patch({
				service: RequestService.Api,
				path: `/b/${this.varius.businessId}/spaceAdmin/update`,
				isAuthed: true,
				body: {
					roles,
					userId,
					spaces: spacesList,
				},
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Space admin user patch error.");
			}

			return res.body;
		} catch (e) {
			return null;
		}
	}

	public async removeSpaceAdminUser(spaceIds: string[], userId: string): Promise<any[] | null> {
		const EXPECTED_STATUS_CODE = 200;
		try {
			const res: any = await this.varius.delete({
				service: RequestService.Api,
				path: `/b/${this.varius.businessId}/spaceAdmin/delete`,
				isAuthed: true,
				body: {
					userId,
					spaces: spaceIds,
				},
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Space admin user patch error.");
			}

			return res.body;
		} catch (e) {
			return null;
		}
	}

	public async getSpaceAdminsByBusiness(): Promise<any[] | null> {
		const EXPECTED_STATUS_CODE = 200;
		try {
			const res: any = await this.varius.get({
				path: `${OIDC_AUTHORITY}/api/b/${this.varius.businessId}/users`,
				isAuthed: true,
				isAbsoluteUrl: true,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Get Space admin users error.");
			}

			return res.body;
		} catch (e) {
			return null;
		}
	}
  
  public async deleteTemplate(templateId: string): Promise<any> {
		const EXPECTED_STATUS_CODE = 204;
		try {
			const res: any = await this.varius.delete({
				path: `/b/${this.varius.businessId}/templates/${templateId}`,
				isAuthed: true,
				service: RequestService.Events,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Template delete error.");
			}
			return res.body;
		} catch (error) {
			return error;
		}
	}
}

