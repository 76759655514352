import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

const isEnabled = process.env.NODE_ENV !== "development";

if (isEnabled) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN!,
		environment: process.env.NODE_ENV,
		release: process.env.REACT_APP_VERSION,
		integrations: [
			new TracingIntegrations.BrowserTracing(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

export default Sentry;

export {
	isEnabled,
}
