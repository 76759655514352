import "./utils/sentry";

import { createRoot } from "react-dom/client";
import App from "components/App/App";
import registerServiceWorker from "registerServiceWorker";
import * as buffer from "buffer";
import * as process from "process";
import "./i18n";

(window as any).process = process;
(window as any).Buffer = (window as any).Buffer || buffer.Buffer;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

registerServiceWorker();
