import VatomInc, { RequestService } from "./index";
import { UserRole } from "reducers/userReducers";
import { Business } from "reducers/entitiesReducers";

export enum IdentityType {
	Eth = "eth",
	Phone = "phoneNumber",
	Email = "email",
	BlockV = "blockv",
	// Id = "id",
	Solana = "sol",
}

export interface PublicProfile {
	id?: string;
	sub: string;
	name: string;
	picture: string;
	default_business_id: string | undefined;
	birthday: string;
	identities: { type: IdentityType; value: string; isCustodial?: boolean }[];
}

export interface UserProfile {
	sub: string;
	name?: string;
	email?: string;
	picture?: string;
	linkedin?: string;
	email_verified?: boolean;
	default_business_id?: string | null;
	default_space_id?: string | null;
	updated_at?: number;
	skip_object_tutorial?: boolean;
}

export interface UserIdentity {
	count: number;
	identities: [];
}

export interface UserCoins {
	coins: [];
}

export interface UserSuperAdminValidation {
	isSuperAdmin: boolean;
}

export default class Users {
	constructor(private varius: typeof VatomInc) {}

	// ONLY SUPER ADMINS
	public async getRole(businessId: string): Promise<UserRole[]> {
		const res = await this.varius.get({
			path: `/b/${businessId}/role`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("User roles could not be found.");
		}
		return res.body;
	}

	public async listUserRoles(userId: string): Promise<UserRole[]> {
		const res = await this.varius.get({
			path: `/u/${userId}/roles`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("User roles could not be found.");
		}
		return res.body;
	}

	public async joinBusiness(userId: string, code: string): Promise<Business> {
		const res = await this.varius.post({
			path: `/u/${userId}/business/join`,
			isAuthed: true,
			body: {
				code,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Business could not be joined.");
		}

		return res.body;
	}

	public async sendVerificationEmail(userId: string): Promise<any> {
		const res = await this.varius.post({
			path: `/u/${userId}/email/send-verification`,
			isAuthed: true,
			body: {},
		});

		if (res.statusCode !== 200) {
			throw new Error("Verification email could not be sent.");
		}

		return res.body;
	}

	public async changeBusinessName(newBusinessName: string) {
		await this.varius.patch({
			path: `/b/${this.varius.businessId}`,
			isAuthed: true,
			body: {
				displayName: newBusinessName,
			},
		});
	}

	public async getIdentities(): Promise<UserIdentity[]> {
		const res = await this.varius.get({
			path: `/me/identities`,
			service: RequestService.Identity,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getProfile(): Promise<UserProfile> {
		const res = await this.varius.get({
			path: `/me`,
			service: RequestService.Identity,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`User profile could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	async getPublicProfile(userId: string): Promise<PublicProfile | undefined> {
		try {
			const response = await this.varius.get({
				path: `${process.env.REACT_APP_USERS_BUCKET}/${userId}`,
				isAuthed: true,
				isAbsoluteUrl: true,
			});
			if (response.statusCode !== 200) {
				return undefined;
			}

			const resp = response.body;

			// If the user is undefined, the response will be {}
			if (!resp.sub && !resp.id) return undefined;

			return {
				...resp,
				id: resp.sub || resp.id,
			};
		} catch (err) {
			return undefined;
		}
	}

	public async updateProfile(body: Partial<UserProfile>): Promise<UserProfile> {
		const res = await this.varius.patch({
			path: `/me`,
			service: RequestService.Identity,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 200) {
			throw new Error(
				`User profile could not be updated. Req: ${JSON.stringify(body)} Res: ${JSON.stringify(
					res.body
				)}`
			);
		}

		return res.body;
	}

	public async validateSuperAdminRole(userId: string): Promise<UserSuperAdminValidation> {
		const res = await this.varius.get({
			path: `/u/${userId}/roles/amISuperAdmin`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Something went wrong.");
		}
		return res.body;
	}
}
