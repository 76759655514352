import { ChonkyIconProps } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import React from "react";

// Static constants

export const includedFileTypes = [
	"pdf",
	"excel",
	"csv",
	"xls",
	"jpg",
	"jpeg",
	"gif",
	"png",
	"svg",
	"mp4",
	"webp",
	"folder",
]

const customIconsStyle = { fontSize: "17px", marginLeft: "2px", color: "black" };
const customIcons = {
	update: <i style={customIconsStyle} className="fas fa-pen"></i>,
	delete: <i style={customIconsStyle} className="fas fa-trash-alt"></i>,
	copy: <i style={customIconsStyle} className="fas fa-copy"></i>,
	open: <i style={customIconsStyle} className="fas fa-external-link-alt"></i>,
	info: <i style={customIconsStyle} className="fas fa-info-circle"></i>,
	create_folder: <i style={customIconsStyle} className="fa-solid fa-folder"></i>,
	pdf: <i style={customIconsStyle} className="fa-solid fa-file-pdf"></i>,
	excel: <i style={customIconsStyle} className="fa-solid fa-excel"></i>,
	csv: <i style={customIconsStyle} className="fa-solid fa-file-csv"></i>,
	xls: <i style={customIconsStyle} className="fa-solid fa-file-xls"></i>,
	jpg: <i style={customIconsStyle} className="fa-solid fa-file-jpg"></i>,
	jpeg: <i style={customIconsStyle} className="fa-solid fa-file-jpg"></i>,
	gif: <i style={customIconsStyle} className="fa-solid fa-file-gif"></i>,
	png: <i style={customIconsStyle} className="fa-solid fa-file-png"></i>,	
	svg: <i style={customIconsStyle} className="fa-solid fa-file-svg"></i>,
	mp4: <i style={customIconsStyle} className="fa-solid fa-file-mp4"></i>,
	webp: <i style={customIconsStyle} className="fa-solid fa-file-image"></i>,
	folder : <i style={customIconsStyle} className="fa-solid fa-folder"></i>,
	default : <i style={customIconsStyle} className="fa-solid fa-file"></i>,
};

/**
 * CustomIconSet for all chonky
 * CustomIconSet also works for actions(like Update file, Create Folder, etc) and other icons from chonky that are not the files(headers).
 * Mantain logic simple or can ruin something else.
 * Files Icon logic should be at a higher level.
 */
const CustomIconSet: React.FC<ChonkyIconProps> = React.memo((props) => {
	const requestedIcon = customIcons[props.icon];
	if (requestedIcon) {
		return <span>{requestedIcon}</span>;
	} 
	return <ChonkyIconFA {...props} />;
});

export default CustomIconSet;
