import { SystemInfo } from "reducers/appReducers";
import VatomInc from "utils/VatomIncApi";

export type AppActions =
	| GetSystemInfoErrorAction
	| GetSystemInfoStartAction
	| GetSystemInfoSuccessAction;

// Define Action Keys
export enum AppActionsKeys {
	GET_SYSTEM_INFO_START = "APP/GET_SYSTEM_INFO_START",
	GET_SYSTEM_INFO_SUCCESS = "APP/GET_SYSTEM_INFO_SUCCESS",
	GET_SYSTEM_INFO_ERROR = "APP/GET_SYSTEM_INFO_ERROR",
}

interface Action<Type> {
	type: Type;
}

export interface GetSystemInfoStartAction extends Action<AppActionsKeys.GET_SYSTEM_INFO_START> {}

export interface GetSystemInfoSuccessAction extends Action<AppActionsKeys.GET_SYSTEM_INFO_SUCCESS> {
	data: {
		systemInfo: SystemInfo;
	};
}

export interface GetSystemInfoErrorAction extends Action<AppActionsKeys.GET_SYSTEM_INFO_ERROR> {
	data: {
		error: any;
	};
}

// Action Creators
const actions = {
	
	/**
	 * Fetch information related to the system from the API
	 */
	getSystemInfo: () => async dispatch => {
		dispatch({
			type: AppActionsKeys.GET_SYSTEM_INFO_START,
		});

		try {
			const systemInfo: SystemInfo = await VatomInc.admin.getSystemInfo();

			dispatch({
				type: AppActionsKeys.GET_SYSTEM_INFO_SUCCESS,
				data: {
					systemInfo,
				},
			});
		} catch (error) {
			dispatch({
				type: AppActionsKeys.GET_SYSTEM_INFO_ERROR,
				data: {
					error,
				},
			});
		}
	},
};

export default actions;
