import { useContext, Dispatch } from "react";
import useSWR, { mutate } from "swr";
import { AccountRole } from "utils/VatomIncApi/account";
import VatomInc from "utils/VatomIncApi";
import { useSelector } from "react-redux";
import { useCurrentBusinessId, useCurrentBusinessName, selectors as userSelectors, UserRole } from "reducers/userReducers";
import { BusinessStats } from "reducers/entitiesReducers";
import { BusinessContext } from "contexts/business";
import { partialUpdate } from "actions/businessProfileActions";
import { Business } from "reducers/entitiesReducers";
import { useUser } from "auth";

export function useBusiness(): [Business, Dispatch<any>, Function] {
	const context = useContext(BusinessContext);
	if (context === undefined) {
	  throw new Error("useUserContext was used outside of its Provider");
	}
	
	return [context.state, context.dispatch, context.revalidate];
  };


export function useUpdateBusiness() {
	return async ( business: Business, field: string, value: any, dispatchBusiness: React.Dispatch<any>, setIsSaving: Function) => {
		await mutate(`/b/${business.id}`, async () => await partialUpdate(business, field, value, dispatchBusiness, setIsSaving), false);
		if (field === "ownerId") await mutate(`/b/${business.id}/users`); // Added this
	};
}

export function useCurrentBusiness(): UserRole | undefined {
	const currentBusiness = useSelector(userSelectors.currentBusiness);
	return currentBusiness;
}

export function useBusinessDisplayName(): string | undefined {
	const currentBusiness = useCurrentBusiness();
	return currentBusiness?.displayName;
}

export function useBusinessLicenseId(): String | undefined {
	const currentBusiness = useCurrentBusiness();
	return currentBusiness?.licenseId;
}


export function useBusinessRoles(): AccountRole[] | undefined {
    const { data: roles, error } = useSWR(`/roles` , () => VatomInc.account.fetchRoles());
    if (error)
        throw error;

	return roles?.sort((a,b)=>a.name.localeCompare(b.name));
}

export function useCampaignStats(): BusinessStats | undefined {

    const businessName = useCurrentBusinessName();
    const businessId = useCurrentBusinessId();
	const u = useUser();

	// Only make the call if there is a current business and the token is not expired
	const isValid = !!businessName && !u?.expired
	const { data: stats, error } = useSWR(isValid ? `/b/${businessName}/stats` : null, () => VatomInc.businesses.getStats(businessId!), {
		revalidateOnFocus: false,
  		revalidateOnReconnect: false
	});

    if (error)
        throw error

    return stats;

}
