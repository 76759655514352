import VatomInc from "utils/VatomIncApi";
import history from "utils/history";
import { selectors as userSelectors } from "reducers/userReducers";
import { selectors as fileUploadSelectors } from "reducers/fileUploadReducers";
import {
	AccessoriesMap,
	DigitalObjectsFilters,
	selectors as digitalObjectsSelectors,
} from "reducers/digitalObjectsReducers";
import { selectors as wizardSelectors } from "reducers/wizardsReducers";
import { Operation } from "utils/VatomIncApi/resources";
import entities, {
	DesignMap,
	Design,
	BlueprintMap,
	DigitalObject,
	ObjectDefinitionStage,
} from "reducers/entitiesReducers";
import pointer from "json-pointer";
import { parseEntityUriString } from "utils/uri";
import jsonPointer from "json-pointer";
import UserWizardPropertyRef from "components/Wizards/utils/UserWizardPropertyRef";

const AUTOSAVE_DEBOUNCE_TIME: number = parseInt(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_TIME!, 10); // debounce time for autosave feature in ms
const FILTER_DEBOUNCE_TIME: number = parseInt(process.env.REACT_APP_FILTER_DEBOUNCE_TIME!, 10); // debounce time for autosave feature in ms

let autosaveDebounce;
let sendSnapshotDebounce;
let filterDebounce;

export type DigitalObjectsActions =
	| LoadDigitalObjectDataStartAction
	| LoadDigitalObjectDataErrorAction
	| LoadDigitalObjectDataSuccessAction
	| CreateFormDataAction
	| SelectDesignAction
	| SelectTypeAction
	| UpdateFormDataObjectAction
	| PublishDigitalObjectStartAction
	| PublishDigitalObjectSuccessAction
	| PublishDigitalObjectErrorAction
	| TestDigitalObjectStartAction
	| TestDigitalObjectSuccessAction
	| TestDigitalObjectErrorAction
	| UploadImageStartAction
	| UploadImageSuccessAction
	| UploadImageErrorAction
	| UploadFileStartAction
	| UploadFileSuccessAction
	| UploadFileErrorAction
	| StoreBlueprintDesignsAction
	| UpdateImageSuccessAction
	| UpdateImageErrorAction
	| UpdateImageStartAction
	| StoreAccessoriesMapAction
	| FilterDigitalObjectsStartAction
	| FilterDigitalObjectsSuccessAction
	| FilterDigitalObjectsErrorAction;

// Define Action Keys
export enum DigitalObjectsActionKeys {
	LOAD_DIGITAL_OBJECT_DATA_START = "DIGITAL_OBJECTS/LOAD_DIGITAL_OBJECT_DATA_START",
	LOAD_DIGITAL_OBJECT_DATA_SUCCESS = "DIGITAL_OBJECTS/LOAD_DIGITAL_OBJECT_DATA_SUCCESS",
	LOAD_DIGITAL_OBJECT_DATA_ERROR = "DIGITAL_OBJECTS/LOAD_DIGITAL_OBJECT_DATA_ERROR",
	CREATE_FORM_DATA = "DIGITAL_OBJECTS/CREATE_FORM_DATA",
	UPDATE_FORM_DATA = "DIGITAL_OBJECTS/UPDATE_FORM_DATA",
	SELECT_TYPE = "DIGITAL_OBJECTS/SELECT_TYPE",
	SELECT_DESIGN = "DIGITAL_OBJECTS/SELECT_DESIGN",
	STORE_BLUEPRINT_DESIGNS = "DIGITAL_OBJECTS/STORE_BLUEPRINT_DESIGNS",
	PUBLISH_DIGITAL_OBJECT_START = "DIGITAL_OBJECTS/PUBLISH_DIGITAL_OBJECT_START",
	PUBLISH_DIGITAL_OBJECT_SUCCESS = "DIGITAL_OBJECTS/PUBLISH_DIGITAL_OBJECT_SUCCESS",
	PUBLISH_DIGITAL_OBJECT_ERROR = "DIGITAL_OBJECTS/PUBLISH_DIGITAL_OBJECT_ERROR",
	TEST_DIGITAL_OBJECT_START = "DIGITAL_OBJECTS/TEST_DIGITAL_OBJECT_START",
	TEST_DIGITAL_OBJECT_SUCCESS = "DIGITAL_OBJECTS/TEST_DIGITAL_OBJECT_SUCCESS",
	TEST_DIGITAL_OBJECT_ERROR = "DIGITAL_OBJECTS/TEST_DIGITAL_OBJECT_ERROR",
	UPLOAD_IMAGE_START = "DIGITAL_OBJECTS/UPLOAD_IMAGE_START",
	UPLOAD_IMAGE_SUCCESS = "DIGITAL_OBJECTS/UPLOAD_IMAGE_SUCCESS",
	UPLOAD_IMAGE_ERROR = "DIGITAL_OBJECTS/UPLOAD_IMAGE_ERROR",
	UPLOAD_FILE_START = "DIGITAL_OBJECTS/UPLOAD_FILE_START",
	UPLOAD_FILE_SUCCESS = "DIGITAL_OBJECTS/UPLOAD_FILE_SUCCESS",
	UPLOAD_FILE_ERROR = "DIGITAL_OBJECTS/UPLOAD_FILE_ERROR",
	UPDATE_IMAGE_START = "DIGITAL_OBJECTS/UPDATE_IMAGE_START",
	UPDATE_IMAGE_SUCCESS = "DIGITAL_OBJECTS/UPDATE_IMAGE_SUCCESS",
	UPDATE_IMAGE_ERROR = "DIGITAL_OBJECTS/UPDATE_IMAGE_ERROR",
	UPLOAD_SNAPSHOT_START = "DIGITAL_OBJECTS/UPLOAD_SNAPSHOT_START",
	UPLOAD_SNAPSHOT_SUCCESS = "DIGITAL_OBJECTS/UPLOAD_SNAPSHOT_SUCCESS",
	UPLOAD_SNAPSHOT_ERROR = "DIGITAL_OBJECTS/UPLOAD_SNAPSHOT_ERROR",
	STORE_ACCESSORIES_MAP = "DIGITAL_OBJECTS/STORE_ACCESSORIES_MAP",
	FILTER_DIGITAL_OBJECTS_START = "DIGITAL_OBJECTS/FILTER_DIGITAL_OBJECTS_START",
	FILTER_DIGITAL_OBJECTS_SUCCESS = "DIGITAL_OBJECTS/FILTER_DIGITAL_OBJECTS_SUCCESS",
	FILTER_DIGITAL_OBJECTS_ERROR = "DIGITAL_OBJECTS/FILTER_DIGITAL_OBJECTS_ERROR",
}

interface Action<ActionType> {
	type: ActionType;
}

export interface StoreAccessoriesMapAction
	extends Action<DigitalObjectsActionKeys.STORE_ACCESSORIES_MAP> {
	data: {
		accessoriesMap: AccessoriesMap;
	};
}

export interface FilterDigitalObjectsStartAction
	extends Action<DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_START> {
	data: {
		filters: DigitalObjectsFilters;
	};
}

export interface FilterDigitalObjectsSuccessAction
	extends Action<DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS> { }

export interface FilterDigitalObjectsErrorAction
	extends Action<DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR> { }

export interface StoreBlueprintDesignsAction
	extends Action<DigitalObjectsActionKeys.STORE_BLUEPRINT_DESIGNS> {
	data: {
		designIds: string[];
	};
}

export interface UpdateFormDataObjectAction
	extends Action<DigitalObjectsActionKeys.UPDATE_FORM_DATA> {
	data: {
		formData: any;
	};
}

export interface SelectDesignAction extends Action<DigitalObjectsActionKeys.SELECT_DESIGN> {
	data: {
		designId: string;
	};
}

export interface SelectTypeAction extends Action<DigitalObjectsActionKeys.SELECT_TYPE> {
	data: {
		typeId: string;
	};
}

export interface CreateFormDataAction extends Action<DigitalObjectsActionKeys.CREATE_FORM_DATA> {
	data: {
		formData: any;
	};
}

export interface UploadImageStartAction
	extends Action<DigitalObjectsActionKeys.UPLOAD_IMAGE_START> {
	data: {
		image: File;
	};
}

export interface UploadImageSuccessAction
	extends Action<DigitalObjectsActionKeys.UPLOAD_IMAGE_SUCCESS> {
	data: {
		temporaryResourceUrl: string;
	};
}

export interface UploadImageErrorAction
	extends Action<DigitalObjectsActionKeys.UPLOAD_IMAGE_ERROR> {
	data: {
		error: any;
	};
}

export interface UpdateImageStartAction
	extends Action<DigitalObjectsActionKeys.UPDATE_IMAGE_START> { }

export interface UpdateImageSuccessAction
	extends Action<DigitalObjectsActionKeys.UPDATE_IMAGE_SUCCESS> { }

export interface UpdateImageErrorAction
	extends Action<DigitalObjectsActionKeys.UPDATE_IMAGE_ERROR> {
	data: {
		error: any;
	};
}

export interface UploadFileStartAction extends Action<DigitalObjectsActionKeys.UPLOAD_FILE_START> {
	data: {
		file: File;
	};
}

export interface UploadFileSuccessAction
	extends Action<DigitalObjectsActionKeys.UPLOAD_FILE_SUCCESS> {
	data: {
		temporaryResourceUrl: string;
	};
}

export interface UploadFileErrorAction extends Action<DigitalObjectsActionKeys.UPLOAD_FILE_ERROR> {
	data: {
		error: any;
	};
}

export interface LoadDigitalObjectDataStartAction
	extends Action<DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_START> {
	data: {
		digitalObjectId: string;
	};
}

export interface LoadDigitalObjectDataSuccessAction
	extends Action<DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_SUCCESS> { }

export interface LoadDigitalObjectDataErrorAction
	extends Action<DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_ERROR> {
	data: {
		error: any;
	};
}

export interface TestDigitalObjectStartAction
	extends Action<DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_START> { }

export interface TestDigitalObjectSuccessAction
	extends Action<DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_SUCCESS> { }

export interface TestDigitalObjectErrorAction
	extends Action<DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_ERROR> {
	data: {
		error: any;
	};
}

export interface PublishDigitalObjectStartAction
	extends Action<DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_START> { }

export interface PublishDigitalObjectSuccessAction
	extends Action<DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_SUCCESS> { }

export interface PublishDigitalObjectErrorAction
	extends Action<DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_ERROR> {
	data: {
		error: any;
	};
}

// Action Creators
const actions = {
	selectType: (typeId?: string) => async (dispatch, getState) => {
		await dispatch({
			type: DigitalObjectsActionKeys.SELECT_TYPE,
			data: {
				typeId,
			},
		});

		// when typeId is not set, we are closing the design drawer, so do nothing
		if (!typeId) {
			return;
		}

		const designIds = digitalObjectsSelectors.typeDesignsMap(getState())[typeId];

		// if we've already loaded designs for this blueprint, set them in the digitalObjectReducers designsOrder
		if (designIds) {
			await dispatch({
				type: DigitalObjectsActionKeys.STORE_BLUEPRINT_DESIGNS,
				data: {
					designIds,
				},
			});

			return;
		}

		try {
			await dispatch(entities.designs.actions.list!({ typeId }));
		} catch (error) {
			// console.log(error);
		}
	},

	/**
	 * Archive a specified Digital Object
	 * @param digitalObjectId
	 */
	archiveDigitalObject: (digitalObjectId: string) => async dispatch => {
		await dispatch(entities.digitalObjects.actions.archive!(digitalObjectId));
	},

	/**
	 * Clone a specified Digital Object
	 * @param digitalObjectId
	 */
	cloneDigitalObject: (digitalObjectId: string) => async (dispatch, getState) => {
		const digitalObject = await dispatch(entities.digitalObjects.actions.clone!(digitalObjectId));
		const businessName = userSelectors.selectedBusiness(getState());

		if (digitalObject) {
			history.push(`/${businessName}/objects/${digitalObject.id}`);
		}
	},

	/**
	 * Create a Digital Object for a specified design and blueprint
	 * @param typeId
	 * @param designId
	 */
	createDigitalObject: (typeId: string, designId: string, name?: string) => async (dispatch, getState) => {
		const designsMap: DesignMap = entities.designs.selectors.all(getState());
		
		// const typesMap: TypeMap = entities.types.selectors.all(getState());
		// const type: Type = typesMap[typeId];
		
		const design: Design = designsMap[designId];

		const businessName = userSelectors.selectedBusiness(getState());
		const displayName = name || `My ${design.displayName} NFT`;
		const cover = design.cover;

		dispatch({
			type: DigitalObjectsActionKeys.SELECT_DESIGN,
			data: {
				designId,
			},
		});

		const newObjectCallback = wizardSelectors.newObjectCallback(getState());

		const digitalObject: DigitalObject = await dispatch(
			entities.digitalObjects.actions.create!({
				designId,
				displayName,
				cover,
			})
		);

		if (newObjectCallback) {
			await newObjectCallback(digitalObject.id);
		}

		if (digitalObject) {
			const propRef = UserWizardPropertyRef.fromPageQueryParams();
			history.replace(`/${businessName}/objects/${digitalObject.id}?${propRef?.toQueryParams()}`);
		}
	},

	/**
	 * Create a Digital Object for a specified design and blueprint
	 * @param typeId
	 * @param designId
	 */
	createDigitalObjectSimple: (typeId: string, designId: string, name?: string) => async (dispatch, getState) => {
		const designsMap: DesignMap = entities.designs.selectors.all(getState());
		
		// const typesMap: TypeMap = entities.types.selectors.all(getState());
		// const type: Type = typesMap[typeId];
		
		const design: Design = designsMap[designId];
		const displayName = name || `My ${design.displayName} NFT`;
		const cover = design.cover;

		dispatch({
			type: DigitalObjectsActionKeys.SELECT_DESIGN,
			data: {
				designId,
			},
		});

		const newObjectCallback = wizardSelectors.newObjectCallback(getState());

		const digitalObject: DigitalObject = await dispatch(
			entities.digitalObjects.actions.create!({
				designId,
				displayName,
				cover,
			})
		);

		if (newObjectCallback) {
			await newObjectCallback(digitalObject.id);
		}

		return digitalObject;
	},

	/**
	 * Load all the data necessary to edit a Digital Object, including blueprints and designs
	 * @param digitalObjectId
	 */
	loadDigitalObjectData: (digitalObjectId: string) => async (dispatch, getState) => {
		const isLoading = digitalObjectsSelectors.isBuildingFormData(getState());

		// this action can be pre-fired on the objects list page when selecting an object, so don't fire this twice
		if (isLoading) {
			return;
		}

		await dispatch({
			type: DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_START,
			data: {
				digitalObjectId,
			},
		});

		try {
			const state = getState();
			const blueprintMap: BlueprintMap = entities.blueprints.selectors.all(state);
			const designMap: DesignMap = entities.designs.selectors.all(state);
			const digitalObject = await dispatch(entities.digitalObjects.actions.get!(digitalObjectId));

			// first, load the full blueprint, design, and campaigns in parallel
			await Promise.all([
				(async () => {
					if (
						!blueprintMap[digitalObject.blueprintId] ||
						!blueprintMap[digitalObject.blueprintId].full
					) {
						await dispatch(entities.blueprints.actions.get!(digitalObject.blueprintId));
					}
				})(),
				(async () => {
					if (!designMap[digitalObject.designId] || !designMap[digitalObject.designId].full) {
						await dispatch(entities.designs.actions.get!(digitalObject.designId));
					}
				})(),
				(async () => {
					await dispatch(entities.digitalObjectCampaigns.actions.list!(digitalObject.id));
				})(),
			]);
			await dispatch(actions.buildDigitalObjectForm(digitalObject));
			await dispatch(actions.buildAccessoriesMap(digitalObject));

			dispatch({
				type: DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_SUCCESS,
			});
		} catch (error) {

			dispatch({
				type: DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Build a map of all Accessory components (zoom, color, nudge, etc.) to their parent component
	 * @param digitalObject
	 */
	buildAccessoriesMap: (digitalObject: DigitalObject) => async (dispatch, getState) => {
		const state = getState();
		const blueprintMap: BlueprintMap = entities.blueprints.selectors.all(state);
		const designMap: DesignMap = entities.designs.selectors.all(state);

		const design = designMap[digitalObject.designId];
		const blueprint = blueprintMap[digitalObject.blueprintId];

		const accessoriesMap: AccessoriesMap = {};

		// loop through design views looking for accessory components
		for (const view of design.views!) {
			if (view.configSchema) {
				for (const propertyName of Object.keys(view.configSchema.properties)) {
					const property = view.configSchema.properties[propertyName];
					findAccessories(
						property,
						propertyName,
						accessoriesMap,
						["designValues", view.placeholder.uri, "config"],
						view.uri
					);
				}
			}
		}

		// loop through blueprint looking for accessory components
		for (const propertyName of Object.keys(blueprint.configSchema.properties)) {
			const property = blueprint.configSchema.properties[propertyName];
			findAccessories(property, propertyName, accessoriesMap, ["blueprintValues"]);
		}

		await dispatch({
			type: DigitalObjectsActionKeys.STORE_ACCESSORIES_MAP,
			data: {
				accessoriesMap,
			},
		});
	},

	/**
	 * Build the initial formData for a digital object
	 * @param digitalObject
	 */
	buildDigitalObjectForm: (digitalObject: DigitalObject) => async dispatch => {
		const objectCopy = JSON.parse(JSON.stringify(digitalObject));

		const formData = {
			displayName: objectCopy.displayName,
			description: objectCopy.description || "",
			cover: objectCopy.cover,
			category: objectCopy.category,
			blueprintValues: objectCopy.blueprintValues || {},
			designValues: objectCopy.designValues || {},
		};

		dispatch({
			type: DigitalObjectsActionKeys.CREATE_FORM_DATA,
			data: {
				formData,
			},
		});
	},

	/**
	 * Upload a user-selected image to Varius
	 * @param image
	 */
	uploadImage: (image: File, state?: any) => async dispatch => {
		await dispatch({
			type: DigitalObjectsActionKeys.UPLOAD_IMAGE_START,
			data: {
				image,
			},
		});
		try {
			const { url } = await VatomInc.resources.uploadImage(image);

			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_IMAGE_SUCCESS,
				data: {
					temporaryResourceUrl: url,
				},
			})
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_IMAGE_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Upload a user-selected file to Varius
	 * @param file
	 * @param keys
	 * @param viewUri
	 * @param propName
	 */
	uploadFile: (isInEditMode: boolean, file: File, keys: string[], viewUri: string, propName?: string) => async (
		dispatch,
		getState
	) => {
		await dispatch({
			type: DigitalObjectsActionKeys.UPLOAD_FILE_START,
			data: {
				file,
			},
		});

		try {
			const formData: any = digitalObjectsSelectors.formData(getState());
			const key = pointer.compile(keys);
			const hasValue = pointer.has(formData, key);
			const curValue = hasValue ? pointer.get(formData, key) : undefined;
			const timestamp = new Date().getTime();
			const viewId = viewUri ? parseEntityUriString(viewUri).identifier : undefined;
			// HERE
			const { url, resourceInfo } = await VatomInc.resources.uploadFile(file);
			
			const ref = `${url}?${timestamp}`; // append timestamp for cache-busting purposes;

			let value: any = { ref, type: resourceInfo.mimeType };

			// if a prop name has been set, add an additional level to the value (this clears other set values for * support)
			if (propName) {
				value = { [propName]: value };

				// additionally, if we are editing a video placeholder, persist cover photo upload through this change
				if ((viewId === "video-v1" || viewId === "crate-video-v1") && curValue && curValue.coverImage) {
					value.coverImage = curValue.coverImage;
				}
			}

			if (!isInEditMode) {
				// update the file in the formData
				await dispatch(actions.updateFormData(keys, value, viewUri, false, true));
			}

			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_FILE_SUCCESS,
				data: {
					temporaryResourceUrl: url,
				},
			});
			return value;
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_FILE_ERROR,
				data: {
					error,
				},
			});
		}
	},
	sendSnapshot: (imageUrl: string, objectId: string, exampleObject: any) => async dispatch => {
		await dispatch({
			type: DigitalObjectsActionKeys.UPLOAD_SNAPSHOT_START,
			data: {
				imageUrl,
				objectId,
				exampleObject,
			},
		});

		// cancel the previous autosave timeout
		if (sendSnapshotDebounce) {
			clearTimeout(sendSnapshotDebounce);
		}

		try {
			// set a debounce timeout for autosaving
			sendSnapshotDebounce = setTimeout(async () => {
				await VatomInc.resources.sendSnapshot(imageUrl, objectId, exampleObject);
			}, AUTOSAVE_DEBOUNCE_TIME);

			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_SNAPSHOT_SUCCESS,
				data: {},
			});
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.UPLOAD_SNAPSHOT_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Apply a list of operations to an image, and then persist the returned image to the digital object
	 * @param changes
	 * @param keys
	 * @param operations
	 * @param viewUri
	 * @param propName
	 */
	updateImage: (
		changes: any,
		keys: string[],
		operations?: Operation[],
		viewUri?: string,
		propName?: string,
		autoSave: boolean = true,
	) => async (dispatch, getState) => {
		await dispatch({
			type: DigitalObjectsActionKeys.UPDATE_IMAGE_START,
		});

		try {
			const key = pointer.compile(keys);
			const resourceUrl = changes.image || digitalObjectsSelectors.temporaryResourceUrl(getState());
			const formData: any = digitalObjectsSelectors.formData(getState());
			const hasValue = pointer.has(formData, key);
			const value = hasValue ? pointer.get(formData, key) : undefined;
			const timestamp = new Date().getTime();
			let ref: string;
			let type: string;

			// const editModeFileTypeStatus = value && changes.file && value.type !== changes.file.type;

			// if (editModeFileTypeStatus) {
			// 	throw Error(`Current image type of ${changes.file.type} does not match previous image ${value.type}`);
			// }

			// if this function is called with a list of operations, send them to the API
			if (operations && operations.length) {
				const res = await VatomInc.resources.updateImage(resourceUrl, operations);

				ref = `${res.url}?${timestamp}`; // append timestamp for cache-busting purposes
			} else {
				// otherwise, we already have the url for this image, use that
				ref = resourceUrl;
			}

			// if the user has uploaded a new file, set the file type here
			if (changes.file) {
				type = changes.file.type;
			} else {
				type = propName ? value[propName].type : value.type;
			}

			let updatedValue: any = { ref, type };

			if (propName) {
				updatedValue = { [propName]: updatedValue };
			}

			// update the image in the formData
			dispatch(actions.updateFormData(keys, updatedValue, viewUri, false, autoSave));

			dispatch({
				type: DigitalObjectsActionKeys.UPDATE_IMAGE_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.UPDATE_IMAGE_ERROR,
				data: {
					error,
				},
			});
		}
	},

	updatePlaceholderUri: (keys: string[], uri: string, initial?: boolean) => async (
		dispatch,
		getState
	) => {
		const formData = digitalObjectsSelectors.formData(getState());
		const newFormData: any = JSON.parse(JSON.stringify(formData));
		const viewKey = pointer.compile([...keys.slice(0, 2), "uri"]);
		const configKey = pointer.compile([...keys.slice(0, 2), "config"]);
		const states = fileUploadSelectors.states(getState());
		const currentUri = pointer.get(newFormData, viewKey);
		
		if (initial || currentUri !== uri) {
			pointer.set(newFormData, viewKey, uri);
			pointer.set(newFormData, configKey, initial ? { states } : {});

			await dispatch({
				type: DigitalObjectsActionKeys.UPDATE_FORM_DATA,
				data: {
					formData: newFormData,
				},
			});

			// cancel the previous autosave timeout
			if (autosaveDebounce) {
				clearTimeout(autosaveDebounce);
			}

			// set a debounce timeout for autosaving
			autosaveDebounce = setTimeout(() => {
				dispatch(actions.saveFormData());
			}, AUTOSAVE_DEBOUNCE_TIME);
		}
	},

	/**
	 * Update the form data for the specified keys with the user-selected value
	 * @param keys
	 * @param newValue
	 * @param uri
	 * @param arrayDelete
	 */
	updateFormData: (
		keys: string[],
		newValue: any,
		uri: string | undefined,
		arrayDelete: boolean,
		autoSave: boolean,
	) => async (dispatch, getState) => {
		const formData = digitalObjectsSelectors.formData(getState());
		
		let newFormData: any = JSON.parse(JSON.stringify(formData));
		const key = pointer.compile(keys);
		const isEmptyValue = newValue === undefined || newValue === null;
		const hasValue = pointer.has(newFormData, key);

		if ((arrayDelete || isEmptyValue) && hasValue) {
			pointer.remove(newFormData, key);
		} else {
			pointer.set(newFormData, pointer.compile(keys), newValue);
		}

		// when a URI is set, we are updating a view, so take the first 2 keys and set the view in the config
		if (uri !== undefined) {
			const viewKeys = [...keys.slice(0, 2), "uri"];
			const viewKey = pointer.compile(viewKeys);

			pointer.set(newFormData, viewKey, uri);
		}

		// when deleting an item from an array, also remove its associated accessory values
		if (arrayDelete) {
			newFormData = await dispatch(actions.deleteAssociatedAccessories(newFormData, keys));
		}

		// prune empty objects from blueprint_values and design_values
		pruneFormData(newFormData.blueprintValues);
		pruneFormData(newFormData.designValues);

		await dispatch({
			type: DigitalObjectsActionKeys.UPDATE_FORM_DATA,
			data: {
				formData: newFormData,
			},
		});
		
		if (autoSave) {
			// cancel the previous autosave timeout
			if (autosaveDebounce) {
				clearTimeout(autosaveDebounce);
			}
	
			// set a debounce timeout for autosaving
			autosaveDebounce = setTimeout(() => {
				dispatch(actions.saveFormData());
			}, AUTOSAVE_DEBOUNCE_TIME);
		}
	},

	/**
	 * When removing a value from an array,
	 * @param formData
	 * @param keys
	 */
	deleteAssociatedAccessories: (formData: FormData, keys: string[]) => async (
		dispatch,
		getState
	) => {
		const accessoriesMap = digitalObjectsSelectors.accessoriesMap(getState());
		const arrayIndex = keys.pop(); // pop the last value off the keys array
		const key = pointer.compile(keys); // compile the new key to find if any associated accessories

		// if there are accessories associated with this component, delete them as well
		if (accessoriesMap[key]) {
			const accessories = accessoriesMap[key];

			// loop through each registered accessory
			for (const accessory of accessories) {
				// append array index to key for this accessory to find its value
				const accessoryKeys = [...accessory.keys, arrayIndex];
				const hasValue = pointer.has(formData, pointer.compile(accessoryKeys));

				// remove the corresponding accessory value, if set
				if (hasValue) {
					pointer.remove(formData, accessoryKeys);
				}
			}
		}

		return formData;
	},

	/**
	 * Publish a digital object
	 * @param digitalObjectId
	 */
	publishDigitalObject: (digitalObjectId: string) => async (dispatch, getState) => {
		const body = {
			stage: ObjectDefinitionStage.Published,
		};

		dispatch({
			type: DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_START,
		});

		try {
			// todo: don't cast "formData" to defined
			await dispatch(entities.digitalObjects.actions.update!(digitalObjectId, body, { merge: true }));

			dispatch({
				type: DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Apply a set of filters and update digital objects in redux
	 * @param partialFilters
	 * @param debounceTime
	 */
	listDigitalObjects: (
		partialFilters: Partial<DigitalObjectsFilters>,
		debounceTime: number = FILTER_DEBOUNCE_TIME
	) => async (dispatch, getState) => {
		const isFiltering = digitalObjectsSelectors.isFiltering(getState());
		
		// do nothing if filtering has already started, except when debounced
		// when debounced, it's a search filter and we need to udpate the search term immediately
		if (isFiltering && !debounceTime) {
			return;
		}

		dispatch({
			type: DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_START,
			data: { filters: partialFilters },
		});

		const filters = digitalObjectsSelectors.filters(getState());

		if (filterDebounce) {
			clearTimeout(filterDebounce);
		}

		try {
			// set a debounce timeout for autosaving
			filterDebounce = setTimeout(async () => {
				await dispatch(entities.digitalObjects.actions.listSearch!(filters));

				dispatch({
					type: DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS,
				});
			}, debounceTime);
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR,
			});
		}
	},

	/**
	 * Persist changes from the editor to the object definition
	 */
	saveFormData: () => (dispatch, getState) => {
		const formData = digitalObjectsSelectors.formData(getState());
		const digitalObjectId: string = digitalObjectsSelectors.digitalObjectId(getState());

		// todo: don't cast "formData" to defined
		return dispatch(entities.digitalObjects.actions.update!(digitalObjectId, formData!, { merge: true }));
	},

	savePendingDesignChanges: (changes: any) => async (dispatch, getState) => {
		const formData = digitalObjectsSelectors.formData(getState());
		const digitalObjectId: string = digitalObjectsSelectors.digitalObjectId(getState());
		const newFormData = JSON.parse(JSON.stringify(formData || null)) || undefined;

		for (const [pointer, newValue] of Object.entries(changes)) {
			jsonPointer.set(newFormData, pointer, newValue);
		}

		// todo: don't cast "formData" to defined
		await dispatch(entities.digitalObjects.actions.update!(digitalObjectId, newFormData!, { merge: true }));

		await dispatch({
			type: DigitalObjectsActionKeys.UPDATE_FORM_DATA,
			data: {
				formData: newFormData,
			},
		});
	},

	/**
	 * Send a test digital object to a phone number, email address, or QR code
	 * In some cases, the digital object must be published first
	 * @param digitalObjectId
	 * @param userIdentity
	 */
	testDigitalObject: (
		digitalObjectId: string,
		userIdentity: { type: string; value: string }
	) => async dispatch => {
		dispatch({
			type: DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_START,
		});

		try {
			const response = await VatomInc.digitalObjects.testDigitalObject(digitalObjectId, { userIdentity });

			dispatch({
				type: DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_SUCCESS,
			});
			
			return response;
		} catch (error) {
			dispatch({
				type: DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_ERROR,
				data: {
					error,
				},
			});
		}
		
		return undefined;
	},
};

/**
 * Loop through the form data object and clean up empty objects for design and blueprint values
 * @param formData
 */
const pruneFormData = formData => {
	for (const key in formData) {
		if (formData.hasOwnProperty(key)) {
			if (!formData[key] || typeof formData[key] !== "object") {
				continue;
			}

			pruneFormData(formData[key]);

			let empty = true;

			for (const key2 in formData[key]) {
				if (formData[key].hasOwnProperty(key2)) {
					if (formData[key][key2] !== undefined && formData[key][key2] !== null) {
						empty = false;
					}
				}
			}

			if (empty && key !== "config" && key !== "rule") {
				delete formData[key];
			}
		}
	}
};

/**
 * Recursive function to look for accessories on a property
 * @param property
 * @param propertyName
 * @param accessoriesMap
 * @param prevKeys
 * @param uri
 */
const findAccessories = (
	property: any,
	propertyName: string,
	accessoriesMap: any,
	prevKeys: string[],
	uri?: string
) => {
	const keys = [...prevKeys, propertyName];

	const accessoryTargetPointer = property.accessoryTargetPointer;

	let currentProperty = property;

	// recurse through this object's properties
	if (currentProperty.type === "object") {
		if (currentProperty.properties) {
			for (const childName of Object.keys(currentProperty.properties)) {
				const childProp = currentProperty.properties[childName];

				findAccessories(childProp, childName, accessoriesMap, keys, uri);
			}
		}
		return;
	}

	// for array types, analyze the "items" property
	if (currentProperty.type === "array") {
		currentProperty = currentProperty.items;
	}

	// all accessories will have $refs, so cut out now if there is not one
	if (!currentProperty.$ref) {
		return;
	}

	if (accessoryTargetPointer) {
		const currentAccessories = accessoriesMap[accessoryTargetPointer] || [];

		accessoriesMap[accessoryTargetPointer] = [
			...currentAccessories,
			{
				...currentProperty,
				keys,
				accessoryTargetPointer,
				uri,
			},
		];
	}

	return;
};

export default actions;
