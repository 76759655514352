import React from 'react'

const CustomToastIcon: React.FC<any> = props => {
	const { type } = props;
	const sizeStyles = { width: "18px", height: "18px" }
	if (type === "error")
		return (
			<i
				className="fa-solid fa-triangle-exclamation"
				style={{ color: "#c84d6b", ...sizeStyles }}
			></i>
		);
	if (type === "success")
		return (
			<i
				className="fa-solid fa-circle-check"
				style={{ color: "#389d50", ...sizeStyles }}
			></i>
		);
	if (type === "info")
		return <i className="fa-solid fa-circle-info" style={{ color: "#d9d9db", ...sizeStyles }}></i>;

	return <></>;
};

export default CustomToastIcon;