import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi";

export enum ClientType {
	SDK = "identity-sdk",
	REST = "rest",
	WALLET = "wallet",
	STUDIO = "studio",
}

export interface TypedClients {
	"identity-sdk": Array<Client>;
	wallet: Array<Client>;
	rest: Array<Client>;
	legacy: Array<Client>;
	studio: Array<Client>;
}

export interface Client {
	id: string;
	name: string;
	type: ClientType;
	secret: string;
	businessId: string;
	createdAt: string;
	updatedAt: string;
	redirect_uris: Array<string>;
	post_logout_redirect_uris: Array<string>;
	shimClientParentUris: Array<string>;
	isAvailable?: boolean;
}

export interface NewClient {
	name: string;
	type: ClientType;
	redirect_uris: Array<string>;
	post_logout_redirect_uris: Array<string>;
	shimClientParentUris: Array<string>;
	walletBaseUrl?: string;
}
export interface PatchClient {
	name: string;
	redirect_uris?: Array<string>;
	post_logout_redirect_uris?: Array<string>;
	shimClientParentUris?: Array<string>;
}

const clientInitialValue: TypedClients = {
	wallet: [],
	"identity-sdk": [],
	rest: [],
	legacy: [],
	studio: [],
};
export default class VatomIncClient {
	constructor(private varius: typeof VatomInc) {}

	public async listClients(): Promise<Array<Client>> {
		const res = await this.varius.get({
			path: `/api/b/${this.varius.businessId}/clients`,
			isAuthed: true,
			service: RequestService.Identity,
		});
		return await res.body;
	}

	public async listAllClientsByType(): Promise<TypedClients> {
		const res = await this.listClients();
		// If we don't put the correct types the reduce will fail!
		const typedClients = res.reduce((acc, client) => {
			return {
				...acc,
				[client.type]: [...acc[client.type], client],
			};
		}, clientInitialValue);
		return typedClients;
	}

	/**
	 * @function listClientsBySpecificType
	 * @description
	 * @param type we just return the clients with that type
	 * @returns clients separated by their type
	 */

	public async listClientsBySpecificType(type: ClientType): Promise<Array<Client>> {
		return (await this.listAllClientsByType())[type];
	}

	public async newClient(payload: NewClient): Promise<Array<NewClient>> {
		if (payload.type === ClientType.WALLET) {
			payload.walletBaseUrl = `${this.varius.businessId?.toLowerCase()}.wallet.vatominc.com`;
		}
		const res = await this.varius.post({
			path: `/api/b/${this.varius.businessId}/clients`,
			isAuthed: true,
			service: RequestService.Identity,
			body: payload,
		});
		return res.body;
	}
	public async deleteClient(clientId: string): Promise<Array<Client>> {
		const res = await this.varius.delete({
			path: `/api/b/${this.varius.businessId}/clients/${clientId}`,
			isAuthed: true,
			service: RequestService.Identity,
		});
		return res.body;
	}
	public async patchClient(payload: PatchClient, clientId: string): Promise<Array<Client>> {
		const res = await this.varius.patch({
			path: `/api/b/${this.varius.businessId}/clients/${clientId}`,
			isAuthed: true,
			service: RequestService.Identity,
			body: payload,
		});
		return res.body;
	}
}
