import {
	DigitalObjectsFilters,
	selectors as selectTargetsSelectors,
} from "reducers/selectTargetsReducers";
import entities from "reducers/entitiesReducers";

const FILTER_DEBOUNCE_TIME: number = parseInt(process.env.REACT_APP_FILTER_DEBOUNCE_TIME!, 10); // debounce time for autosave feature in ms

let filterDebounce;

// Define Action Keys
export enum SelectTargetsActionKeys {
	FILTER_DIGITAL_OBJECTS_START = "SELECT_OBJECTS/FILTER_DIGITAL_OBJECTS_START",
	FILTER_DIGITAL_OBJECTS_SUCCESS = "SELECT_OBJECTS/FILTER_DIGITAL_OBJECTS_SUCCESS",
	FILTER_DIGITAL_OBJECTS_ERROR = "SELECT_OBJECTS/FILTER_DIGITAL_OBJECTS_ERROR",
	RESET_FILTERS = "SELECT_OBJECTS/RESET_FILTERS",
}

interface Action<Type> {
	type: Type;
}

export interface FilterDigitalObjectsStartAction
	extends Action<SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_START> {
	data: {
		filters: DigitalObjectsFilters;
	};
}

export interface FilterDigitalObjectsSuccessAction
	extends Action<SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS> {}

export interface FilterDigitalObjectsErrorAction
	extends Action<SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR> {}

export interface ResetFiltersAction extends Action<SelectTargetsActionKeys.RESET_FILTERS> {}

export type SelectTargetsActions =
	| FilterDigitalObjectsStartAction
	| FilterDigitalObjectsSuccessAction
	| FilterDigitalObjectsErrorAction
	| ResetFiltersAction;

// Action Creators
const actions = {
	/**
	 * Apply a set of filters and update digital objects in redux
	 * @param partialFilters
	 * @param debounceTime
	 */
	listDigitalObjects: (
		partialFilters: Partial<DigitalObjectsFilters>,
		debounceTime: number = FILTER_DEBOUNCE_TIME
	) => async (dispatch, getState) => {
		const isFiltering = selectTargetsSelectors.isFiltering(getState());

		// do nothing if filtering has already started, except when debounced
		// when debounced, it's a search filter and we need to udpate the search term immediately
		if (isFiltering && !debounceTime) {
			return;
		}

		await dispatch({
			type: SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_START,
			data: { filters: partialFilters },
		});

		const filters = selectTargetsSelectors.filters(getState());

		// @todo hacky, if someone has over 1000 objects, this may not work
		filters.hitsPerPage = 1000;

		if (filterDebounce) {
			clearTimeout(filterDebounce);
		}

		try {
			// set a debounce timeout for autosaving
			filterDebounce = setTimeout(async () => {
				await dispatch(entities.digitalObjects.actions.listSearch!(filters));

				dispatch({
					type: SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS,
				});
			}, debounceTime);
		} catch (error) {
			dispatch({
				type: SelectTargetsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR,
			});
		}
	},
	resetFilters: () => async dispatch => {
		await dispatch({
			type: SelectTargetsActionKeys.RESET_FILTERS,
		});
	},
};

export default actions;
