import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi";

export interface Events {
	identity: Array<Event>;
	spaces: Array<Event>;
	wallet: Array<Event>;
	market: Array<Event>;
	developer: Array<Event>;
	cash_balance: Array<Event>;
}

export interface WebhookEvent {
	id: string;
	product: string;
	description: string;
}

export interface EventSubject {
	product?: string;
	subject: string;
	isOpened: boolean;
}

export interface SubjectsCounter {
	identity: number;
	spaces: number;
	wallet: number;
	market: number;
	developer: number;
	cash_balance: number;
}

export interface Event {
	id: string;
	product: string;
	displayName: string;
	subject: string;
	action: string;
	archived?: boolean;
	description?: string;
	isSelected?: boolean;
}

export interface WebhookEvent {
	id: string;
}
export interface Webhook {
	id?: string;
	endpoint: string;
	lastSevenDays?: string;
	errorRate?: number;
	description: string;
	isDisabled: boolean;
	signingSecret: string;
	events?: Array<Event>;
}

export interface WebhookWithEvent {
	id?: string;
	endpoint?: string;
	description?: string;
	isDisabled: boolean;
	events?: Array<WebhookEvent> | Array<Event>;
}

export default class VatomIncWebhooks {
	constructor(private varius: typeof VatomInc) {}

	public async listWebhooks(businessId: string): Promise<Array<Webhook>> {
		const res = await this.varius.get({
			path: `/b/${businessId}/webhooks`,
			isAuthed: true,
			service: RequestService.Businesses,
		});

		return res.body;
	}

	public async getWebhook(businessId: string, webhookId: string): Promise<Webhook> {
		const res = await this.varius.get({
			path: `/b/${businessId}/webhooks/${webhookId}`,
			isAuthed: true,
			service: RequestService.Businesses,
		});

		return res.body;
	}

	public async listAllEvents(businessId: string): Promise<Events> {
		const res = await this.varius.get({
			path: `/b/${businessId}/webhooks-events`,
			isAuthed: true,
			service: RequestService.Businesses,
		});

		return res.body;
	}

	public async addWebhook(businessId: string, body: WebhookWithEvent): Promise<Events> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			path: `/b/${businessId}/webhooks/`,
			isAuthed: true,
			service: RequestService.Businesses,
			body,
		});
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not add webhook");
		}
		return res.body;
	}

	public async updateWebhook(
		businessId: string,
		webhookId: string,
		body: WebhookWithEvent
	): Promise<Events> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.patch({
			path: `/b/${businessId}/webhooks/${webhookId}`,
			isAuthed: true,
			service: RequestService.Businesses,
			body,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not update webhook");
		}
		return res.body;
	}

	public async deleteWebhook(businessId: string, webhookId: string): Promise<{ id: string }> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.delete({
			path: `/b/${businessId}/webhooks/${webhookId}`,
			isAuthed: true,
			service: RequestService.Businesses,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not delete webhook");
		}
		return res.body;
	}
}
