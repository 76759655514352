import { Text, colors, Dropdown, Container, NavLink } from "@varius.io/wombo";
import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

export const StyledContainer = styled(ToastContainer)`
	// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity

	&&&.Toastify__toast-container {
		z-index: 2147483647;
		box-sizing: border-box;
		padding: 6px 16px 0 16px;
		width: 100vw;

		@media (max-width: 600px) {
			height: 100vh;
		}

		@media (min-width: 600px) {
			padding: 0 32px;
		}

		@media (min-width: 768px) {
			max-width: 800px;
			padding: 0;
		}
	}
`;

export const StyledAccountDropdown = styled<any>(Dropdown)`
	margin-left: ${(props: any) => props.theme.rhythm(1)};
`;

export const StyledSwatch = styled<any>("span")`
	width: 18px;
	height: 18px;
	color: white;
	font-size: ${(props: any) => props.theme.ms(-0.5)};
	background-color: ${(props: any) => props.backgroundColor};
	margin-left: ${(props: any) => props.theme.rhythm(1 / 3)};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledStatsContainer = styled<any>("div")`
	width: 150px;
	height: 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(0, 41, 83, 0.25);
	border-radius: 3px;
	margin-left: ${(props: any) => (props.isMobile ? props.theme.rhythm(0.5) : "100px")};
	display: ${(props: any) => (props.isMobile ? "none" : "block")};
`;

export const StyledStatsText = styled<any>(Text)`
	color: #7f94a9;
`;

export const DropdownTriggerContainer = styled<any>(Container)`
	display: flex;
	align-items: center;
	gap: 7px;
`;

export const StyledNavLink = styled<any>(NavLink)`
	padding: 0 15px;
`;

export const StyledBusinessNameText = styled<any>(Text)`
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #11141e;
`;
