import useSWR from "swr";
import VatomInc from "utils/VatomIncApi";
import { useCurrentBusinessId, useCurrentBusinessName } from "reducers/userReducers";
import { AccountInfo } from "utils/VatomIncApi/account";
import { useUser } from "auth";

export default function useAccountInfo(businessId?: string): AccountInfo | undefined {

    if (!businessId)
        businessId = useCurrentBusinessId();

    const businessName = useCurrentBusinessName();
    const u = useUser();

	// Only make the call if there is a current business and the token is not expired
	const isValid = !!businessName && !u?.expired

    const { data: accountInfo, error } = useSWR(businessName && isValid ? `/b/${businessId}/account` : null, () => VatomInc.account.fetchAccountInfo(), {
		revalidateOnFocus: false,
  		revalidateOnReconnect: false
	});

    if (error)
        throw error

    return accountInfo;

}