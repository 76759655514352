import * as React from "react";
import useFlag from "hooks/useFlag";

interface FlagProps {
	id: string;
	eq?: any; // the value to compare against. default is `true`
	children?:any
}

const Flag: React.FC<FlagProps> = (props) => {
	const { id, eq = true, children } = props;
	const flagValue = useFlag(id);
	
	if (flagValue === eq) {
		return <>{children}</>;
	}
	
	return null;
};

export default Flag;
