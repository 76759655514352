import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "../utils/history";

import business from "reducers/businessReducers";
import user from "reducers/userReducers";
import digitalObjects from "reducers/digitalObjectsReducers";
import contactLists from "reducers/contactListsReducers";
import entities from "reducers/entitiesReducers";
import app from "reducers/appReducers";
import campaigns from "reducers/campaignsReducers";
import billing from "reducers/billingReducers";
import plans from "reducers/planReducers";
import budget from "reducers/budgetReducers";
import selectTargets from "reducers/selectTargetsReducers";
import businessDomains from "reducers/businessDomainsReducer";
import businessProfile from "reducers/businessProfileReducer";
import wizards from "reducers/wizardsReducers";
import fileUpload from "reducers/fileUploadReducers";
import spaces  from './spacesReducers';

const rootReducer = combineReducers({
	business,
	businessDomains,
	businessProfile,
	app,
	campaigns,
	digitalObjects,
	contactLists,
	user,
	billing,
	selectTargets,
	plans,
	budget,
	wizards,
	fileUpload,
	spaces,
	entities: entities.reducer,
	router: connectRouter(history),
});

export default rootReducer;
