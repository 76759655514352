export enum EntityType {
	Action = "action",
	Behavior = "behavior",
	Blueprint = "blueprint",
	ObjectDefinition = "object-definition",
	Campaign = "campaign",
	Design = "design",
	View = "view",
	User = "user",
	Space = "space",
	Leaderboard = "leaderboard",
	Viewer = "viewer",
	Effect = "effect",
	Trigger = "trigger",
	Rule = "rule",
	Business = "business",
}

export function entityUri(type: EntityType, uuid: string): string {
	return `varius.${type}:varius.io:${uuid}`;
}

export interface EntityUriInfo {
	type: EntityType;
	namespace: string;
	identifier: string;
}

export function parseEntityUriString(uri: string): EntityUriInfo {
	if (typeof uri !== "string") {
		throw new Error(`provided uri was not a string: ${uri}`);
	}

	const components = uri.split(":");

	if (components.length !== 3) {
		throw new Error(`provided uri did not have exactly 3 components`);
	}

	return {
		type: components[0].split(".")[1] as EntityType,
		namespace: components[1],
		identifier: components[2],
	};
}
