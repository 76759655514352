import React, { createContext, useEffect, useReducer, Dispatch } from "react";
import useSWR from "swr";
import VatomInc from "utils/VatomIncApi";
import { Business } from "reducers/entitiesReducers";
import { useCurrentBusinessId } from "reducers/userReducers";
import { businessReducer, Types as BusinessReducerTypes } from "reducers/v2/business";
import { Loader } from "@varius.io/wombo";

interface IBusinessContext {
	state: Business;
	dispatch: Dispatch<any>;
	revalidate: Function;
}

const initialState: IBusinessContext = {
	state: {
		id: "",
		name: "",
		displayName: "",
		isAgency: false,
		parentBusinessId: "",
		ownerId: "",
	},
	dispatch: () => null,
	revalidate: () => null,
};

// Context
export const BusinessContext = createContext<IBusinessContext>(initialState);

// Provider
export const BusinessProvider = ({ children }) => {
	// TODO: Once we remove redux from studio it will be changed
	const businessId = useCurrentBusinessId();

	const [state, dispatch] = useReducer(businessReducer, initialState.state);

	useSWR(
		businessId ? `/b/${businessId}/licenses/check` : null,
		() => VatomInc.billing.checkLicences(businessId!),
		{
			revalidateOnFocus: false,
		}
	);

	const { data, error, revalidate } = useSWR<Business | null | undefined>(
		businessId ? `/b/${businessId}` : null,
		() => VatomInc.businesses.getBusiness(businessId!),
		{
			revalidateOnFocus: false,
		}
	);

	useEffect(() => {
		if (!data) return;
		dispatch({
			type: BusinessReducerTypes.Put,
			payload: { business: data },
		});
	}, [data]);

	if (error) return <div>failed to load</div>;
	if (!state)
		return (
			<Loader
				isLoading={!state}
				style={{ display: "flex", justifyContent: "center", width: "100%" }}
			/>
		);

	return (
		<BusinessContext.Provider value={{ state, dispatch, revalidate }}>
			{children}
		</BusinessContext.Provider>
	);
};
