import { UserActions, UserActionsKeys } from "actions/userActions";
import { AuthActions, AuthActionsKeys } from "actions/authActions";
import { useSelector } from "react-redux";
import VatomInc from "utils/VatomIncApi";

export interface VariusUser {
	id: string;
	displayName: string;
	email: string;
	profile: {
		defaultBusinessId?: string;
		skipWelcomePage?: boolean;
		firstCampaignId?: string;
		firstObjectId?: string;
		// todo: oidc - no longer returned... was generated in api user update func
		// hasMadeDistribution?: boolean;
		// todo: oidc - no longer returned... was generated in api user update func
		// completedWelcomePage?: boolean;
		skip_object_tutorial?: boolean;
	};
}

// TODO! Typings do not match real data structure. Is it expected behavior?
export interface UserRole {
	businessName: string;
	businessId: string;
	displayName: string;
	roles: UserRoleNamesMap;
	licenseId?: string;
	viewerId?: string;
	flags: { [id: string]: any };
	ownerId: string;
	defaultCampaignId: string;
	isAgency:boolean
}

export interface UserRoleNamesMap {
	[roleName: string]: {
		id: string;
		description: string;
		name: string;
	};
}

export interface UserRoleMap {
	[businessName: string]: UserRole;
}

export interface BusinessMap {
	[key: string]: string;
}

export interface UserState {
	userRolesMap?: UserRoleMap;
	businessIdMap: BusinessMap;
	businessNameMap: BusinessMap;
	businesses?: string[];
	selectedBusiness?: string;
	currentBusiness?: UserRole;
	hasResentVerification: boolean;
	isResendingVerification: boolean;
	algoliaKey?: string;
	isChangingBusinessName: boolean;
	isUpdatingUser: boolean;
	isRequestingUser: boolean;
}

// Initial State
const initialState: UserState = {
	hasResentVerification: false,
	isResendingVerification: false,
	isUpdatingUser: false,
	isChangingBusinessName: false,
	isRequestingUser: false,
	businessNameMap: {},
	businessIdMap: {},
};

export default (state: UserState = initialState, action: UserActions | AuthActions): UserState => {
	switch (action.type) {
		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case UserActionsKeys.SEND_VERIFICATION_EMAIL_START: {
			return {
				...state,
				isResendingVerification: true,
			};
		}

		case UserActionsKeys.SEND_VERIFICATION_EMAIL_SUCCESS: {
			return {
				...state,
				isResendingVerification: false,
				hasResentVerification: true,
			};
		}

		case UserActionsKeys.SEND_VERIFICATION_EMAIL_ERROR: {
			return {
				...state,
				isResendingVerification: false,
				hasResentVerification: false,
			};
		}

		case UserActionsKeys.IS_CHANGING_BUSINESS_NAME:
			return {
				...state,
				isChangingBusinessName: action.data,
			};

		case UserActionsKeys.CHANGE_BUSINESS_NAME:
		case AuthActionsKeys.GET_USER_ROLES_SUCCESS: {
			const { userRoles, algoliaKey } = action.data;
			// build a map of businessNames to businessIds
			const businessNameMap = userRoles.reduce(
				(map: BusinessMap, userRole: any) => ({
					...map,
					[userRole.businessName]: userRole.businessId,
				}),
				{}
			);

			// build a map of businessIds to businessNames
			const businessIdMap = userRoles.reduce(
				(map: BusinessMap, userRole: any) => ({
					...map,
					[userRole.businessId]: userRole.businessName,
				}),
				{}
			);

			// build an array of this user's available businesses
			const businesses = userRoles.map((userRole: any) => userRole.businessName);

			// build a map of businessName's to the userRole for that business
			const userRolesMap: UserRoleMap = userRoles.reduce(
				(map: UserRoleMap, userRole: any) => ({
					...map,
					[userRole.businessName]: userRole,
				}),
				{}
			);

			return {
				...state,
				userRolesMap,
				businessNameMap,
				businessIdMap,
				businesses,
				algoliaKey,
			};
		}
		case AuthActionsKeys.UPDATE_ROLES_SUPERUSER_SUCCESS: {
			const { role } = action.data;

			const newRole = {
				[role.businessName]: role,
			};

			const newBusinessNameMap: BusinessMap = {
				...state.businessNameMap,
				[role.businessName]: role.businessId,
			};

			const newBusinessIdMap: BusinessMap = {
				...state.businessIdMap,
				[role.businessId]: role.businessName,
			};
			const newUserRolesMap: UserRoleMap = { ...state.userRolesMap, ...newRole };
			let newBusinesses: string[] = [];
			if (state.businesses?.length) {
				newBusinesses = [...state.businesses, role.businessName];
			}

			VatomInc.businessId = role.businessId;
			return {
				...state,
				businessNameMap: newBusinessNameMap,
				businessIdMap: newBusinessIdMap,
				businesses: newBusinesses,
				userRolesMap: newUserRolesMap,
				currentBusiness: role,
				selectedBusiness: role.businessName,
			};
		}

		case UserActionsKeys.UPDATE_DEFAULT_VIEWER: {
			const { businessId, viewerId } = action.data;
			const newUserRolesMap = {
				...state.userRolesMap,
			};
			newUserRolesMap[businessId].viewerId = viewerId;
			return {
				...state,
				userRolesMap: newUserRolesMap,
			};
		}

		case UserActionsKeys.STORE_SELECTED_BUSINESS: {
			const { userRolesMap } = state;

			const currentBusiness = userRolesMap ? userRolesMap[action.data.businessName] : undefined;

			// this needs to be done here, as there's a slight delay between
			// when this runs and when the hooks update (ie. `ApiAuther`)
			VatomInc.businessId = currentBusiness?.businessId;

			return {
				...state,
				currentBusiness,
				selectedBusiness: action.data.businessName,
			};
		}

		default: {
			return state;
		}
	}
};

export const userState = state => state.user as UserState;

// Selectors
export const selectors = {
	algoliaKey: state => userState(state).algoliaKey,
	userRolesMap: state => userState(state).userRolesMap,
	businessIdMap: state => userState(state).businessIdMap,
	businessNameMap: state => userState(state).businessNameMap,
	businesses: state => userState(state).businesses,
	currentBusiness: state => userState(state).currentBusiness,
	selectedBusiness: state => userState(state).selectedBusiness,
	isResendingVerification: state => userState(state).isResendingVerification,
	hasResentVerification: state => userState(state).hasResentVerification,
	isChangingBusinessName: state => userState(state).isChangingBusinessName,
	isUpdatingUser: state => userState(state).isUpdatingUser,
	isRequestingUser: state => userState(state).isRequestingUser,
};

export function selectCurrentBusinessId(state: any): string | undefined {
	const userRole = selectors.currentBusiness(state);

	if (userRole === undefined) {
		return undefined;
	}

	return userRole.businessId;
}

export function useCurrentBusinessId(): string | undefined {
	return useSelector(selectCurrentBusinessId);
}

export function useCurrentBusinessName(): string | undefined {
	return useSelector(selectors.currentBusiness)?.businessName;
}

export function useCurrentBusinessViewerId(): any {
	return useSelector(selectors.currentBusiness)?.viewerId;
}

export function useSelectedBusiness(): string | undefined {
	return useSelector(selectors.selectedBusiness);
}
