import VatomInc from "./index";
import { Effect } from "reducers/entitiesReducers";

export default class VatomIncEffects {
	constructor(private varius: typeof VatomInc) {}

	public async listEffects(businessId?: string): Promise<Effect[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId || businessId}/effects`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body.items;
	}

	public async getEffect(effectId: string): Promise<Effect> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/effects/${effectId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Trigger could not be loaded.");
		}

		// todo: check `status` in body
		return res.body;
	}
}
