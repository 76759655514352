import VatomInc from "utils/VatomIncApi/index";

export default class VatomIncWizards {
	constructor(private varius: typeof VatomInc) {}

	public async listWizards() {				
		
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/wizards/`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("Wizards could not be loaded.");
		}

		return res.body.items;
	}

	public async getWizard(wizardId: string, businessId?: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId || businessId}/wizards/${wizardId}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("Wizard could not be loaded.");
		}

		return res.body.item;
	}
}
