import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi";
import { BusinessBrandConfig } from "components/Business/Settings/interfaces";

export default class VatomIncBrandConfig {
	constructor(private varius: typeof VatomInc) {}

	public async updateBrandConfig(businessId: string, brandConfig): Promise<BusinessBrandConfig> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/brand-config`,
			isAuthed: true,
			service: RequestService.Api,
			body: brandConfig,
		});
		return res.body;
	}

	public async getBrandConfig(businessId: string): Promise<BusinessBrandConfig> {
		const res = await this.varius.get({
			path: `/b/${businessId}/brand-config`,
			isAuthed: true,
			service: RequestService.Api,
		});
		return res.body;
	}
}
