import { Object } from "aws-sdk/clients/s3";

/**
 * If another behavior copies the behavior of another just add it in the constant dependantBehaviors.
 * For example, when the 'share-v1' behavior is activated or desactivated also the 'lifecycle-v1' behavior
 * lifecycle-v1 is not user-configurable(database property) so it will not show up in the behaviors modal
 * For now it is here but probably should be a column in the database that stores this.
 */
export const dependantBehaviors = {
	"share-v1": ["lifecycle-v1"],
};

export const getBehaviorsData = (
	behaviorId: string,
	digitalObjectId: string,
	payload: {} | null
) => {
	let data: {} = {
		"object-definition": {
			[digitalObjectId]: {
				behaviors: {
					[behaviorId]: payload,
				},
			},
		},
	};

  const behaviorHasDependencies: boolean = dependantBehaviors[behaviorId];
	if (behaviorHasDependencies) {
		data = duplicateBehaviorsData(data, behaviorId, digitalObjectId, payload);
	}

	return data;
};

export const duplicateBehaviorsData = (
	data: {},
	behaviorId: string,
	digitalObjectId: string,
	payload: {} | null
) => {
	dependantBehaviors[behaviorId].forEach((dependantBehaviorId: string) => {
		data["object-definition"][digitalObjectId].behaviors[dependantBehaviorId] = payload;
	});

	return data;
};
