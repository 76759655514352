import entities from "reducers/entitiesReducers";
import { AuthActions } from "actions/authActions";
import { WizardActions, WizardsActionKeys } from "actions/wizardActions";

export interface StepValidations {
	[step: number]: boolean;
}

// Interfaces
interface WizardsState {
	stepValidations: StepValidations;
	newObjectCallback?: (objectId: string) => any;
}

// Initial State
const initialState: WizardsState = {
	stepValidations: {},
	newObjectCallback: undefined,
};

export default (
	state: WizardsState = initialState,
	action: AuthActions | WizardActions
): WizardsState => {
	switch (action.type) {
		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case WizardsActionKeys.VALIDATE_USER_WIZARD: {
			const stepValidations = action.data.stepValidations;

			return {
				...state,
				stepValidations,
			};
		}

		case entities.userWizards.actionNames.publish.success: {
			return {
				...state,
				newObjectCallback: undefined,
			};
		}
		
		case entities.userWizards.actionNames.archive.success: {
			return {
				...state,
				newObjectCallback: undefined,
			};
		}

		case entities.digitalObjects.actionNames.create.success: {
			return {
				...state,
				newObjectCallback: undefined,
			};
		}

		case WizardsActionKeys.REGISTER_NEW_OBJECT_CALLBACK: {
			const newObjectCallback = action.data.callback;

			return {
				...state,
				newObjectCallback,
			};
		}

		default: {
			return state;
		}
	}
};

const userWizardsState = state => state.wizards as WizardsState;

// Selectors
export const selectors = {
	stepValidations: state => userWizardsState(state).stepValidations,
	newObjectCallback: state => userWizardsState(state).newObjectCallback,
};
