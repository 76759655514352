import VatomInc from "utils/VatomIncApi";
import entitiesReducers from "reducers/entitiesReducers";

export enum ContactListActionTypes {
	ADD_CONTACTS_PENDING = "CONTACT_LISTS/ADD_CONTACTS_PENDING",
	ADD_CONTACTS_SUCCESS = "CONTACT_LISTS/ADD_CONTACTS_SUCCESS",
	ADD_CONTACTS_FAILURE = "CONTACT_LISTS/ADD_CONTACTS_FAILURE",
}

export const contactListActions = {
	addContacts: (listId: string, contacts: any[]) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ContactListActionTypes.ADD_CONTACTS_PENDING,
			});
			const res = await VatomInc.contactLists.addContacts(listId, contacts);
			dispatch({
				type: ContactListActionTypes.ADD_CONTACTS_SUCCESS,
				data: res,
			});

			const allMap = entitiesReducers.contactLists.selectors.all(getState());
			const list = allMap[listId];

			dispatch({
				type: entitiesReducers.contactLists.actionNames.setByPrimaryKey,
				data: {
					item: {
						...list,
						count: list.count + contacts.length,
					},
				},
			});
		} catch (err:any) {
			console.error(err);
			dispatch({
				type: ContactListActionTypes.ADD_CONTACTS_FAILURE,
				data: undefined, // todo
			});
		}
	},
};

interface Action<Type, Data> {
	type: Type;
	data: Data;
}

export type ContactListAction =
	| Action<ContactListActionTypes.ADD_CONTACTS_PENDING, any>
	| Action<ContactListActionTypes.ADD_CONTACTS_SUCCESS, any>
	| Action<ContactListActionTypes.ADD_CONTACTS_FAILURE, any>;

export default contactListActions;
