import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi";

export default class VatomIncShortWalletConfig {
	constructor(private varius: typeof VatomInc) {}

	// TODO: types of this promises
	public async updateWalletConfig(businessId: string, walletConfig): Promise<any> {
		const pageConfig = { pageConfig: walletConfig };
		const res = await this.varius.patch({
			path: `/b/${businessId}/page-config`,
			isAuthed: true,
			service: RequestService.Api,
			body: pageConfig,
		});
		return res.body;
	}

	// TODO: types of this promises
	public async getWalletConfig(businessId: string): Promise<any> {
		const res = await this.varius.get({
			path: `/b/${businessId}/page-config`,
			isAuthed: true,
			service: RequestService.Api,
		});
		return res.body;
	}
}
