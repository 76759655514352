import { Business } from "reducers/entitiesReducers";
import VatomInc, { RequestService } from "utils/VatomIncApi/index";

interface AutoPayInvoicesRequest {
	chargeAutomatically: boolean;
}

export default class VatomIncBilling {
	constructor(private varius: typeof VatomInc) {}

	public async setAutoPayInvoices(request: AutoPayInvoicesRequest, businessId?: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			service: RequestService.Billing,
			path: `/b/${businessId || this.varius.businessId}/subscriptions`,
			body: request,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not change invoices auto payment");
		}

		return res.body;
	}

	// public async getDefaultAccountInfo() {
	// 	const EXPECTED_STATUS_CODE = 200;
	// 	const res = await this.varius.get({
	// 		service: RequestService.Billing,
	// 		path: `/b/${this.varius.businessId}/account`,
	// 		isAuthed: true,
	// 	});

	// 	if (res.statusCode !== EXPECTED_STATUS_CODE) {
	// 		throw new Error("Could not load billing source");
	// 	}

	// 	return res.body;
	// }

	public async deleteDefaultBillingSource() {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.delete({
			service: RequestService.Billing,
			path: `/b/${this.varius.businessId}/sources`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not delete card");
		}

		return res.body;
	}

	public async sendToken(token) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			service: RequestService.Billing,
			path: `/b/${this.varius.businessId}/sources`,
			isAuthed: true,
			body: { token },
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not get payment token");
		}

		return res.body;
	}

	public async checkout(invoiceId: string) {
		const res = await this.varius.post({
			service: RequestService.Billing,
			path: `/b/${this.varius.businessId}/invoices`,
			isAuthed: true,
			body: {
				invoiceId,
			},
		});
		return res;
	}

	public async checkLicences(businessName: string): Promise<any> {
		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${businessName}/licenses/check`,
			isAuthed: true,
		});
		
		return res.body;
	}

	public async changeToCreditCard(businessName: string): Promise<any> {
		const res = await this.varius.patch({
			service: RequestService.Billing,
			path: `/b/${businessName}/updateToCC`,
			isAuthed: true,
		});
		
		return res.body;
	}

	public async changeInvoicingEmail(businessName: string, customerId: string, email: string): Promise<any> {
		const res = await this.varius.patch({
			service: RequestService.Billing,
			path: `/b/${businessName}/customers/${customerId}/edit-email`,
			isAuthed: true,
			body: {email}
		});
		
		return res.body;
	}

}

