import VatomInc, { HttpError } from "./index";
import { Campaign, CampaignStats } from "reducers/entitiesReducers";

export default class VatomIncCampaignGroups {
	constructor(private varius: typeof VatomInc) {}

	public async listCampaignGroups(): Promise<any> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaign-groups`,
			isAuthed: true,
		});
		
		if (res.statusCode !== 200) {
			throw new Error("Campaign groups could not be retrieved.");
		}

		// todo: check `status` in res
		return res.body.items;
	}

	public async updateCampaignGroup(groupCampaignId: string, body: any): Promise<Campaign> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}`,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 200 && res.statusCode !== 202) {
			throw new Error("Campaign group could not be updated.");
		}

		return res.body;
	}

	public async getCampaignGroup(groupCampaignId: string): Promise<Campaign> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}`,
			isAuthed: true,
		});
		
		return res.body;
	}

	public async archiveCampaignGroup(groupCampaignId: string, args: any): Promise<boolean> {
		// note: this is a bit weird, but to fix this would mean changing a lot about entitiesReducer
		let [ force ] = args;
		if (force === undefined) {
			force = false;
		}
		
		try {
			await this.varius.delete({
				path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}?force=${force}`,
				isAuthed: true,
			});
		}
		catch (e:any) {
			// 403 needs confirmation
			if (e instanceof HttpError) {
				if (e.statusCode === 403) {
					return false;
				}
				else if (e.statusCode === 404) {
					// note: archiving a campaign group that does not exist shouldn't occur,
					// but just in case, this should be treated as a success
					return true;
				}
			}
			
			throw e;
		}
		
		return true;
	}

	public async versionCampaignGroup(groupCampaignId: string): Promise<Campaign> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/revisions`,
		});

		return res.body;
	}

	public async deleteCampaignGroupRevision(groupCampaignId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/revisions`,
		});

		return res.body;
	}

	public async toggleCampaignGroupRevision(groupCampaignId: string): Promise<any> {
		const res = await this.varius.put({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/revisions/toggle`,
		});

		return res.body;
	}

	public async getCampaignGroupRevision(
		groupCampaignId: string,
		revision: number
	): Promise<Campaign> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/revisions/${revision}`,
		});

		return res.body;
	}

	public async getCampaignGroupStats(
		groupCampaignId: string
	): Promise<CampaignStats> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/stats`,
		});

		return res.body;
	}
}
