import VatomInc, { RequestService } from "./index";
import { InvoicesList } from "reducers/billingReducers";

export default class VatomIncInvoices {
	constructor(private varius: typeof VatomInc) {}

	/**
	 *
	 */
	public async getInvoicesByBusiness(
		businessId: string,
		lastInvoice?: string | null
	): Promise<InvoicesList> {
		const EXPECTED_STATUS_CODE = 200;

		const path = lastInvoice ? `?endingBefore=${lastInvoice}` : "";
		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${businessId}/invoices` + path,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Invoices list could not be loaded");
		}
		return res.body;
	}

	public async getUpcomingInvoicesByBusiness(
		businessId: string
	): Promise<any[]> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${businessId}/upcomingInvoices`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Upcoming Invoices list could not be loaded");
		}
		return res.body;
	}

	/**
	 *
	 */
	public async getSingleInvoice(businessId: string, invoiceId: string): Promise<InvoicesList> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${businessId}/invoices/${invoiceId}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Invoice could not be loaded");
		}
		return res.body;
	}
}
