import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi";

export interface ITokenGating {
	id?: string;
	businessId?: string;
	name: string;
	ruleConfig?: Object;
}

export default class VatomIncTokenGating {
	constructor(private varius: typeof VatomInc) {}

	public async createTokenGating(tokenGating: ITokenGating): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/token-gating`,
			isAuthed: true,
			service: RequestService.Businesses,
			body: tokenGating,
		});
		return res.body;
	}

	public async getTokenGatingRules(): Promise<any> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/token-gating`,
			isAuthed: true,
			service: RequestService.Businesses,
		});
		return res.body;
	}

	public async updateTokenGating(tokenGatingId: string, tokenGating: ITokenGating): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/token-gating/${tokenGatingId}`,
			isAuthed: true,
			service: RequestService.Businesses,
			body: tokenGating,
		});
		return res.body;
	}

	public async deleteTokenGating(ruleId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/token-gating/${ruleId}`,
			isAuthed: true,
			service: RequestService.Businesses,
		});
		return res.body;
	}
}
