import { useState, useEffect } from "react";

export function useAppUpdateAvailable(): boolean {
	const [value, setValue] = useState(window.waitingServiceWorker !== null);
	
	useEffect(() => {
		function handler() {
			setValue(true);
		}
		
		window.addEventListener("SWUpdateAvailable", handler);
		return () => window.removeEventListener("SWUpdateAvailable", handler);
	});
	
	return value;
}
