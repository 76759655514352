import VatomInc, { RequestService } from "./index";


export default class Me {
	constructor(private varius: typeof VatomInc) {}

	public async listTerms(): Promise<any[]> {
		const res = await this.varius.get({
			path: `/api/me/terms?business-id=${this.varius.businessId}`,
			service: RequestService.Identity,
			isAuthed: true,
	
		});

		if (res.statusCode !== 200) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async listBusiness(q?: string): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/me/businesses?q=${q}`,
			service: RequestService.Api,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not list businesses");
		}

		return res.body;
	}


}
