import VatomInc from "utils/VatomIncApi";
import { selectors as campaignSelectors } from "reducers/campaignsReducers";

export type BudgetActions =
	| StartCalculatingBudget
	| SuccessCalculatingBudget
	| ErrorCalculatingBudget
	| ChangeBudgetAmount;

export enum BudgetActionTypes {
	START_CALCULATE_BUDGET = "BUDGET/START_CALCULATE_BUDGET",
	SUCCESS_CALCULATE_BUDGET = "BUDGET/SUCCESS_CALCULATE_BUDGET",
	ERROR_CALCULATE_BUDGET = "BUDGET/ERROR_CALCULATE_BUDGET",

	CHANGE_BUDGET_AMOUNT = "BUDGET/CHANGE_BUDGET_AMOUNT",
}

export interface BudgetAmount {
	budgetAmount: string;
}

export const budgetActions = {
	calculateBudget: (budget: BudgetAmount) => async (dispatch, getState) => {
		const campaignId = campaignSelectors.campaignId(getState());

		try {
			dispatch({
				type: BudgetActionTypes.START_CALCULATE_BUDGET,
			});
			const budgetValidNumber = budget.budgetAmount
				.split("")
				.filter(c => c !== ",")
				.join("");
			const intBudget = parseInt(budgetValidNumber, 10);
			const data = await VatomInc.budget.calculateBudget(intBudget, campaignId);
			dispatch({
				type: BudgetActionTypes.SUCCESS_CALCULATE_BUDGET,
				data,
			});
		} catch (err:any) {
			dispatch({
				type: BudgetActionTypes.ERROR_CALCULATE_BUDGET,
				data: err.message || err,
			});
		}
	},
	changeBudgetAmount: (amount: string) => dispatch => {
		let newAmount = amount;

		// remove existing commas and create valid number
		newAmount = newAmount.replace(",", "");
		let intPart: string;
		let fractionPart: string;

		[intPart, fractionPart] = newAmount.split(".");
		const charsArr = intPart.split("").filter(c => c !== ",");
		let result = charsArr
			.reverse()
			.map((char, i) => {
				if (i !== 0 && i % 3 === 0) {
					return `${char},`;
				}
				return char;
			})
			.reverse()
			.join("");

		if (fractionPart) {
			result += `.${fractionPart}`;
		}

		dispatch({
			type: BudgetActionTypes.CHANGE_BUDGET_AMOUNT,
			data: result,
		});
	},
};

interface Action<Type> {
	type: Type;
}

export interface StartCalculatingBudget extends Action<BudgetActionTypes.START_CALCULATE_BUDGET> {
	data: any;
}

export interface SuccessCalculatingBudget
	extends Action<BudgetActionTypes.SUCCESS_CALCULATE_BUDGET> {
	data: any;
}

export interface ErrorCalculatingBudget extends Action<BudgetActionTypes.ERROR_CALCULATE_BUDGET> {
	data: any;
}

export interface ChangeBudgetAmount extends Action<BudgetActionTypes.CHANGE_BUDGET_AMOUNT> {
	data: string;
}
