import VatomInc from "./index";
import { Behavior } from "reducers/entitiesReducers";

export default class VatomIncBehaviors {
	constructor(private varius: typeof VatomInc) {}

	public async getBehavior(behaviorId: string): Promise<Behavior> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/behaviors/${behaviorId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Behavior could not be loaded.");
		}

		// todo: check `status` in body
		return res.body;
	}

	public async listBehaviors(type?: string): Promise<Behavior[]> {
		const businessId = type || this.varius.businessId;
		const res = await this.varius.get({
			path: `/b/${businessId}/behaviors`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Behaviors could not be loaded for ${businessId}.`);
		}

		return res.body.items;
	}
}
