import VatomInc from "./index";

const POINTS_API = process.env.REACT_APP_POINTS_SERVER || "https://points.api.vatominc.com";

export default class VatomIncPoints {
	constructor(private varius: typeof VatomInc) {}

	public async patchPoints(
		points: number,
		userId: string,
		campaignId: string,
		channel: string
	): Promise<any> {
		try {
			const res: any = await this.varius.patch({
				path: `${POINTS_API}/u/${userId}/campaign/${campaignId}/points`,
				isAuthed: true,
				isAbsoluteUrl: true,
				body: { points: points, channel: channel } || { points },
			});

			return res.body;
		} catch (e:any) {
			return e;
		}
	}

    public async getPoints(
		userId: string,
		campaignId: string,
	): Promise<any> {
		try {
			const res: any = await this.varius.get({
				path: `${POINTS_API}/u/${userId}/campaign/${campaignId}/points`,
				isAuthed: true,
				isAbsoluteUrl: true,
			});

			return res.body;
		} catch (e:any) {
			return e;
		}
	}
}
