import VatomInc, { RequestService } from "utils/VatomIncApi/index";
import { UserCoins, UserIdentity } from "./users";

export interface AccountUser {
	id: string;
	uri: string;
	display_name: string;
	email: string;
	profile: {
		default_business_id: string;
	};
	roles: AccountRole[];
}

export interface AccountRole {
	id: string;
	name: string;
	description: string;
}

export interface RecentActivity {
    id: string;
    timestamp: string;
    type: string;
    action: string;
    details: string;
}

export interface PIIData {
	data: {},
	pii_data: boolean
}

interface DashboardData {
	dashboardUrl: string;
}


export default class VatomIncAnalytics {
	constructor(private varius: typeof VatomInc) {}

      public async RecentActivity(userId:string, startDate:string="", endDate:string=""): Promise<RecentActivity> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/user/${userId}/recent-activity?startDate=${startDate}&endDate=${endDate}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Account could not fetched");
		}

		return res.body;
	}
	// TODO: change return type
      public async userWallet(userId:string): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/user/${userId}/wallet`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Account could not fetched");
		}

		return res.body;
	}

	public async getUserIdentities(userId:string): Promise<UserIdentity[] | any > {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/user/${userId}/identities`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getUsersIdentities(payload: any): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/users/identities`,
			headers:  {"Content-Type": "application/json"},
			isAuthed: true,
			body: payload,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getUserBalance(userId:string): Promise<UserCoins[]|any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Loyalty,
			path: `/b/${this.varius.businessId}/users/${userId}/coins`,
			isAuthed: true,
		});

		if( res.statusCode === 204){
			return {points:0}
		}
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getBusinessBalance(userId:string): Promise<UserCoins[]> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/business-balance/${userId}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getCampaignPoints(userId:string, campaignId:string): Promise<UserCoins[]>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/points/${userId}/${campaignId}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async createNewSegment(userId: string, payload: any): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			service: RequestService.Analytics,
			headers:  {"Content-Type": "application/json"},
			path: `/b/${this.varius.businessId}/user/${userId}/segments/create-segment`,
			body: payload,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot create a new Segment. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getSegments(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/segments`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Segments. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async updateSegments(userId:string, payload:any): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			service: RequestService.Analytics,
			headers:  {"Content-Type": "application/json"},
			path: `/b/${this.varius.businessId}/user/${userId}/segments/update-segment`,
			isAuthed: true,
			body: payload
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Segments. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getSegmentRules(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/segments/list-all-rules`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Segments. Res: ${JSON.stringify(res.body)}`);
		}
		return res.body;
	}

	public async getCampaignId(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/business-campaigns/`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Segments. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async postViewFilters(campaignId: string, userId: string, payload: any): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			service: RequestService.Analytics,
			headers:  {"Content-Type": "application/json"},
			path: `/b/${this.varius.businessId}/segments/${campaignId}/${userId}/explore-filters`,
			body: payload,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot create a new Segment. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getObjectDefinitionOwners(payload:any): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			service: RequestService.Analytics,
			headers:  {"Content-Type": "application/json"},
			path: `/b/${this.varius.businessId}/segments/list-owners`,
			isAuthed: true,
			body: payload,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot retrieve Object Definition Object. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async executeSegment(payload:any): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;
		const segmentId = payload.segmentId

		const res = await this.varius.post({
			service: RequestService.Analytics,
			headers:  {"Content-Type": "application/json"},
			path: `/b/${this.varius.businessId}/segments/${segmentId}`,
			isAuthed: true,
			body: {...payload},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`Cannot Execute Segment. Res: ${JSON.stringify(res.body)}`)
		};
		return res.body;
	}

	public async getUserPIIData(userId:string): Promise<PIIData> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/u/${userId}/pii-data`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async getBusinessAnalyticsDashboard(): Promise<DashboardData> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Api,
			path: `/b/${this.varius.businessId}/analytics/url`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User identities could not be retrieved. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async listObjectDefinition(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/object-definition/list`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Object Definition. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async listSpacesName(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/s/list-spaces`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Spaces Names. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async listSpaceZones(spaceId:string): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/s/${spaceId}/list-zones`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Spaces Zones. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async listEventsName(): Promise<any>{
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Analytics,
			path: `/b/${this.varius.businessId}/s/list-events`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(`User cannot fetch Event Names. Res: ${JSON.stringify(res.body)}`);
		}

		return res.body;
	}

	public async exportSegmentUsers(segmentId: string): Promise<any> {

		const res = await this.varius.post({
			service: RequestService.Api,
			path: `/b/${this.varius.businessId}/segmentId/${segmentId}/export-users`,
			isAuthed: true,
			body: {
				segmentId: segmentId,
				businessId: this.varius.businessId,
				action: "export-csv",
			}
		});

		if (res.statusCode !== 204) {
			throw new Error("Segment Users could not be exported.");
		}

		return res.statusCode;
	}
}
