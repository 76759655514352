import VatomInc, { RequestService } from "./index";

interface Config {
  appTitle: string;
  studioViewerId: string;
  oidcClientId: string;
}

interface StudioViewer {
  id: string;
  displayName: string;
  businessId: string;
  config: Config;
  cover?: string | null;
  hostname: string | null;
  updatedAt?: string;
  updatedById?: string;
}

export interface CreateStudioViewerBody {
  displayName?: string;
  clientId?: string;
  hostname?: string;
	config?: any
}

export default class VatomIncStudioViewers {
	constructor(private varius: typeof VatomInc) { }

	public async list(): Promise<StudioViewer[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/studio-viewers`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Studio Viewers could not be loaded.");
		}

		return res.body.items;
	}


	public async update(studioViewerId: string, body: CreateStudioViewerBody): Promise<StudioViewer> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/studio-viewers/${studioViewerId}`,
			service: RequestService.Businesses,
			isAuthed: true,
			body: body,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Studio Viewer with ID: ${studioViewerId} could not be updated.`);
		}

		return res.body;
	}

	public async delete(studioViewerId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/studio-viewers/${studioViewerId}`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Studio Viewer with ID: ${studioViewerId} could not be deleted.`);
		}

		// todo: check `status` in res
		return res.body;
	}

	public async create(body: CreateStudioViewerBody): Promise<StudioViewer> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/studio-viewers`,
			service: RequestService.Businesses,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 201) {
			throw new Error(`Could not create studio viewer`);
		}
		return res.body;
	}
}
