import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi"

export interface Option {
	value: string;
	label: string;
}
export interface FormLink {
	destination: string;
	title: string;
	slug: string;
	startDate: string;
	endDate: string;
	timezone: Option;
	isAlwaysOn: boolean;
	domain?: Option;
}
export interface ShortLink {
	createdAt: string;
	createdByUserId: string;
	createdByUserName: string;
	editedAt?: string;
	editedByUserName?: string;
	editedByUserId?: string;
	isArchived: boolean;
	destination: string;
	id: string;
	isDisabled: boolean;
	title?: string;
	isActive: boolean;
	slug: string;
	startDate?: string;
	endDate?: string;
	status?: string;
	timezone?: Option;
	archivedAt?: string;
	archivedBy?: string;
	dashboardUrl?: string;
	domain?: string;
}

export interface ShortLinkWithRule {
	slug: string;
	rule: {
		destination: string;
		title?: string;
		isAlwaysOn: boolean;
		startDate?: string;
		endDate?: string;
		archivedAt?: string;
		timezone?: Option;
		archivedBy?: string;
		domain: string;
	};
}

export interface ShortLinksByStatus {
	isActive: Array<ShortLink>;
	isScheduled: Array<ShortLink>;
	isArchived: Array<ShortLink>;
	isExpired: Array<ShortLink>;
}

export interface EditedLinkBody {
	destination: string;
	title: string;
	isAlwaysOn: boolean | null;
	startDate?: string;
	endDate?: string;
	timezone?: Option;
}

export default class VatomIncShortLinks {
	constructor(private varius: typeof VatomInc) {}

	public async listShortLinksByStatus(businessId: string): Promise<ShortLinksByStatus> {
		const res = await this.varius.get({
			path: `/b/${businessId}/links`,
			isAuthed: true,
			service: RequestService.Distribution
		});

		return res.body;
	}

	public async createShortLink(
		businessId: string,
		body: ShortLinkWithRule
	): Promise<{ id: string }> {
		const res = await this.varius.post({
			path: `/b/${businessId}/links`,
			isAuthed: true,
			body,
			service: RequestService.Distribution
		});
		return res.body;
	}

	public async disableShortLink(businessId: string, ruleId: string, slug: string): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/links/${ruleId}/disable?slug=${slug}`,
			isAuthed: true,
			service: RequestService.Distribution
		});

		return res.body;
	}

	public async deleteShortLink(businessId: string, ruleId: string, slug: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${businessId}/links/${ruleId}?slug=${slug}`,
			isAuthed: true,
			service: RequestService.Distribution
		});

		return res.body;
	}

	public async editShortLink(
		businessId: string,
		ruleId: string,
		slug: string,
		body: EditedLinkBody
	): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/links/${ruleId}?slug=${slug}`,
			isAuthed: true,
			body,
			service: RequestService.Distribution
		});

		return res.body;
	}
}
