import VatomInc from "utils/VatomIncApi";
import { ImagePolicyState, ImagePolicyStateMeta } from "reducers/fileUploadReducers";
import { selectors as fileUploadSelectors } from "../reducers/fileUploadReducers";

interface Action<ActionType extends FileUploadActionKeys, ActionData> {
	type: ActionType;
	data: ActionData;
}

export enum FileUploadActionKeys {
	UPLOAD_DYNAMIC_IMAGE_START = "FILE_UPLOAD/UPLOAD_DYNAMIC_IMAGE_START",
	UPLOAD_DYNAMIC_IMAGE_SUCCESS = "FILE_UPLOAD/UPLOAD_DYNAMIC_IMAGE_SUCCESS",
	UPLOAD_DYNAMIC_IMAGE_ERROR = "FILE_UPLOAD/UPLOAD_DYNAMIC_IMAGE_ERROR",
	SELECT_IMAGE_POLICY_STATE = "FILE_UPLOAD/SELECT_IMAGE_POLICY_STATE",
	DELETE_IMAGE_POLICY_STATE = "FILE_UPLOAD/DELETE_IMAGE_POLICY_STATE",
	UPDATE_IMAGE_POLICY_STATE = "FILE_UPLOAD/UPDATE_IMAGE_POLICY_STATE",
	RESET_IMAGE_POLICY_STATES = "FILE_UPLOAD/RESET_IMAGE_POLICY_STATES",
	REORDER_IMAGE_POLICY_STATES = "FILE_UPLOAD/REORDER_IMAGE_POLICY_STATES",
	CROP_IMAGES_START = "FILE_UPLOAD/CROP_IMAGES_START",
	CROP_IMAGES_SUCCESS = "FILE_UPLOAD/CROP_IMAGES_SUCCESS",
}

export type FileUploadActions =
	| Action<FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_START, {}>
	| Action<
			FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_SUCCESS,
			{ ref: string; selectedStateIndex: number; type: string }
	  >
	| Action<FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_ERROR, { error: any }>
	| Action<
			FileUploadActionKeys.SELECT_IMAGE_POLICY_STATE,
			{ selectedStateIndex: number; editorData: ImagePolicyStateMeta; zoom: number }
	  >
	| Action<FileUploadActionKeys.DELETE_IMAGE_POLICY_STATE, { selectedStateIndex: number }>
	| Action<FileUploadActionKeys.UPDATE_IMAGE_POLICY_STATE, { field: string; value: string }>
	| Action<FileUploadActionKeys.RESET_IMAGE_POLICY_STATES, { initialStates?: ImagePolicyState[] }>
	| Action<FileUploadActionKeys.CROP_IMAGES_START, {}>
	| Action<FileUploadActionKeys.CROP_IMAGES_SUCCESS, { states: ImagePolicyState[] }>
	| Action<
			FileUploadActionKeys.REORDER_IMAGE_POLICY_STATES,
			{ oldIndex: number; newIndex: number }
	  >;

const actions = {
	/**
	 * Upload a user-selected image to Varius as part of a dynamic image policy
	 * @param image
	 * @param selectedStateIndex
	 */
	uploadDynamicImage: (image: File, selectedStateIndex: number = 0) => async dispatch => {
		await dispatch({
			type: FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_START,
		});

		try {
			const { url } = await VatomInc.resources.uploadImage(image);

			dispatch({
				type: FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_SUCCESS,
				data: {
					ref: url,
					type: image.type,
					selectedStateIndex,
				},
			});
		} catch (error) {
			dispatch({
				type: FileUploadActionKeys.UPLOAD_DYNAMIC_IMAGE_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Reset the image policy states
	 */
	resetImagePolicyStates: (initialStates: ImagePolicyState[] = []) => dispatch => {
		dispatch({
			type: FileUploadActionKeys.RESET_IMAGE_POLICY_STATES,
			data: {
				initialStates,
			},
		});
	},

	/**
	 * Change a value in the selected image policy state
	 * @param field
	 * @param value
	 */
	updateImagePolicyState: (field: string, value: string) => dispatch => {
		dispatch({
			type: FileUploadActionKeys.UPDATE_IMAGE_POLICY_STATE,
			data: {
				field,
				value,
			},
		});
	},

	/**
	 * Delete a selected image policy state
	 * @param selectedStateIndex
	 */
	deleteImagePolicyState: (selectedStateIndex: number) => dispatch => {
		dispatch({
			type: FileUploadActionKeys.DELETE_IMAGE_POLICY_STATE,
			data: {
				selectedStateIndex,
			},
		});
	},

	/**
	 * Select an image policy from the list of states
	 * @param selectedStateIndex
	 * @param editorData
	 * @param zoom
	 */
	selectImagePolicyState: (
		selectedStateIndex: number,
		editorData: any,
		zoom?: number
	) => async dispatch => {
		await dispatch({
			type: FileUploadActionKeys.SELECT_IMAGE_POLICY_STATE,
			data: {
				selectedStateIndex,
				editorData,
				zoom,
			},
		});
	},

	cropImagePolicyStateImages: () => async (dispatch, getState) => {
		const state = getState();
		const imageCrops: any = [];
		const stateMetas = fileUploadSelectors.stateMetas(state);
		const states = JSON.parse(JSON.stringify(fileUploadSelectors.states(state)));

		await dispatch({
			type: FileUploadActionKeys.CROP_IMAGES_START,
		});
		
		// crop each of the images in the states
		for (let i = 0; i < states.length; i++) {
			const imagePolicyState = states[i];
			const index = i;
			
			const timestamp = new Date().getTime();
			const stateMeta = stateMetas[index];
			const imageUrl = imagePolicyState.image.ref;

			if (stateMeta) {
				const operations = [
					{
						name: "crop",
						width: stateMeta.width,
						height: stateMeta.height,
						x: stateMeta.x,
						y: stateMeta.y,
					},
					{
						name: "resize",
						width: 800,
						height: 800,
					},
				];

				imageCrops.push(
					(async () => {
						const res = await VatomInc.resources.updateImage(imageUrl, operations);

						imagePolicyState.image.ref = `${res.url}?${timestamp}`; // append timestamp for cache-busting purposes
					})()
				);
			}
		}

		// crop them all in parallel
		if (imageCrops.length) {
			await Promise.all(imageCrops);
		}

		await dispatch({
			type: FileUploadActionKeys.CROP_IMAGES_SUCCESS,
			data: {
				states,
			},
		});

		return states;
	},

	/**
	 * Reorder the list of image policy states
	 * @param oldIndex
	 * @param newIndex
	 */
	reorderImagePolicyStates: (oldIndex: number, newIndex: number) => dispatch => {
		dispatch({
			type: FileUploadActionKeys.REORDER_IMAGE_POLICY_STATES,
			data: {
				oldIndex,
				newIndex,
			},
		});
	},
};

export default actions;
