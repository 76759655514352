import VatomInc, { RequestService } from "./index";
import { Viewer } from "reducers/entitiesReducers";

export default class VatomIncViewers {
	constructor(private varius: typeof VatomInc) { }

	public async listViewers(excludeSystem = false): Promise<Viewer[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/viewers?excludeSystem=${excludeSystem}`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Wallets could not be loaded.");
		}

		// todo: check `status` in res
		return res.body.items;
	}

	public async listViewersDomainsByBusiness(): Promise<Array<string>> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/viewers/domains?excludeSystem=false`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Viewers domains could not be loaded.");
		}

		return res.body;
	}

	public async update({
		viewerId,
		body,
	}): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/viewers/${viewerId}`,
			service: RequestService.Businesses,
			isAuthed: true,
			body: body,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Wallet with ID: ${viewerId} could not be updated.`);
		}

		this.updateCover(viewerId, body.entrypoint);

		// todo: check `status` in res
		return res;
	}

	public async setDefaultViewer({
		viewerId,
	}): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/viewers/${viewerId}`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Wallet with ID: ${viewerId} could not be updated.`);
		}

		// todo: check `status` in res
		return res;
	}

	public async delete(viewerId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/viewers/${viewerId}`,
			service: RequestService.Businesses,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Wallet with ID: ${viewerId} could not be deleted.`);
		}

		// todo: check `status` in res
		return res.body;
	}



	public async getViewer(viewerId: string): Promise<Viewer> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/viewers/${viewerId}`,
			isAuthed: true,
			service: RequestService.Businesses,
		});

		if (res.statusCode !== 200) {
			throw new Error("Wallet could not be loaded.");
		}

		// todo: check `status` in res
		return res.body;
	}

	public async createViewer(body: any): Promise<any> {

		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/viewers`,
			service: RequestService.Businesses,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 200) {
			throw new Error(`Could not create viewer`);
		}
		// todo: check `status` in res
		return res;
	}

	public async getCaptcha(): Promise<Viewer> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/captcha`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Captcha could not be loaded.");
		}

		// todo: check `status` in res
		return res.body;
	}

	public async updateCover(viewerId: string, entrypoint: string): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/viewers/${viewerId}/cover`,
			isAuthed: true,
			body: {
				entrypoint
			}
		});

		if (res.statusCode !== 200) {
			console.log("Captcha could not be loaded.");
		}

		// todo: check `status` in res
		return res;
	}
}
