import VatomInc, { RequestService } from "utils/VatomIncApi/index";

export interface Invite {
	id?: string | number;
	email: string;
	expires_at?: string;
	businessId?: string;
	created_by_id?: string;
	roles?: string[];
	role_id?: string;
	sending?: boolean;
	// sent?: boolean;
	// errored?: boolean;
}

export interface AccountUser {
	id: string;
	uri: string;
	display_name: string;
	email: string;
	profile: {
		default_business_id: string;
		preferred_username?: string;
		picture?: string;
		guest?: boolean;
	};
	roles: AccountRole[];
	roleAssignments:  AccountRole[];
}

export interface AccountRole {
	id: string;
	name: string;
	description: string;
}

export interface PatchUserRoleResponseDto {
	id: string;
	name: string;
	description: string;
}

export interface AccountInfo {
	source?: Source;
	billingInfo?: {
		creditCard: Source;
		type: "invoice" | "credit_card";
		email?: string;
		status?: string;
	};
	sellerAccount?: SellerAccount;
	billing: AutoPayInvoices;
	license: License;
	seats: number;
	invites: {
		items: Invite[];
	};

	// note: added for displaying renewal date
	subscription: {
		trialPeriodEnd: string;
		currentPeriodEnd: string;
		plan: {
			tiers: { flat_amount: number }[];
		};
	};
	ethAccount: string | undefined;
	stripeId: string | undefined;
}

export interface SellerAccount {
	loginLink?: string;
	status: string;
}

export interface Source {
	expiration: Expiration;
	cardNumberText: string;
	type: CardTypes;
}

export enum CardTypes {
	VISA = "Visa",
	MASTERCARD = "MasterCard",
	AMERICAN_EXPRESS = "American Express",
	DISCOVER = "Discover",
	DINERS = "Diners Club",
	JCB = "JCB",
	UNKNOWN = "UNKNOWN",
	INVALID = "INVALID",
	INVOICE = "invoice",
}

export interface Expiration {
	month: number;
	year: number;
}

export interface Tier {
	max: number;
	unit_amount: number;
}

export interface BusinessLicenses {
	items: License[];
	activePlanId: string;
	activeSubscriptionId: string;
}

export interface License {
	id: string;
	name: string;
	displayName: string;
	level: number;
	limits: {
		max_object_types_per_campaign: number;
		max_rules_per_campaign: number;
		max_seats: number;
		included_seats: number;
		campaigns: boolean;
		customDomains: boolean;
		free_vatoms: number;
		businessPage?: boolean;
		rewards?: boolean;
		advanced_campaigns?: boolean;
		customer_support?: boolean;
		firehose?: boolean;
	};
	audience: {
		id: string;
		displayName: string;
	};
	description?: string;
	features: string[];
	additionalFeatures: string[];
	popular?: boolean;
	isActive: boolean;
	isInternal: boolean;
	pricingTiers: Tier[];
	relatedPlans: {
		monthly: {
			id: string;
			amount: number;
		};
		yearly: {
			id: string;
			amount: number;
		};
	};
	planId: string;
	unitPlanId: string;
	unitType: string;
	shortDescription?: string;
	price?: string;
	billingPeriod?: string;
}

export enum AutoPayInvoices {
	TRUE = "charge_automatically",
	FALSE = "send_invoice",
}

export default class VatomIncAccount {
	constructor(private varius: typeof VatomInc) {}

	public async fetchBusinessTeam(): Promise<AccountUser[]> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId || "system"}/users`,
			isAuthed: true,
		});
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Business Team could not be fetched");
		}
		return res.body;
	}

	public async fetchRoles() {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: "/u/roles",
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Roles could not be loaded");
		}

		return res.body.items;
	}

	public async editUser(user: AccountUser): Promise<PatchUserRoleResponseDto[]> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/users/${user.id}`,
			body: { roles: user.roles.map(role => role.id) },
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("User could not be edited");
		}

		return res.body;
	}

	public async fetchAccountInfo(businessName?: string): Promise<AccountInfo> {
		const EXPECTED_STATUS_CODE = 200;

		const path = businessName || this.varius.businessId;

		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${path}/account`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Account could not fetched");
		}

		return res.body;
	}

	public async setupSeller() {
		const EXPECTED_STATUS_CODE = 200;

		const path = this.varius.businessId;

		const returnUrl = `https://${window.location.host}${window.location.pathname}`; // Force to https for development
		const res = await this.varius.post({
			service: RequestService.Billing,
			path: `/b/${path}/account`,
			isAuthed: true,
			body: { refreshUrl: returnUrl, returnUrl },
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Account could not fetched");
		}

		return res.body;
	}

	public async deleteInvite(inviteId: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/invites/${inviteId} `,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not delete invite");
		}

		return res.body;
	}
}
