// import { selectors as businessSelectors } from "reducers/businessReducers";
import { selectors as userSelectors } from "reducers/userReducers";
import VatomInc from "utils/VatomIncApi";
import entities, { Provider } from "reducers/entitiesReducers";
// import { Invite } from "reducers/businessReducers";
// import { accountSelectors } from "reducers/accountReducers";
// import { AccountActionType } from "actions/accountActions";
// import { User, PlansList } from "reducers/planReducers";
// import { findActivePlan } from "actions/planActions";

export type BusinessActions =
// 	| UpdateBusinessInviteAction
// 	| SendInvitesStartAction
// 	| SendInvitesErrorAction
// 	| SendInvitesSuccessAction
// 	| ResetInvitesAction
// 	| SetupInvitesAction
	| GetBusinessFontsStartAction
	| GetBusinessFontsErrorAction
	| GetBusinessFontsSuccessAction
// 	| ClearBusinessInvite;

// Define Action Keys
export enum BusinessActionKeys {
	// UPDATE_BUSINESS_INVITE = "BUSINESS/UPDATE_BUSINESS_INVITE",
	// CLEAR_BUSINESS_INVITE = "BUSINESS/CLEAR_BUSINESS_INVITE",
	// SEND_INVITES_START = "BUSINESS/SEND_INVITES_START",
	// SEND_INVITES_ERROR = "BUSINESS/SEND_INVITES_ERROR",
	// SEND_INVITES_SUCCESS = "BUSINESS/SEND_INVITES_SUCCESS",
	// RESET_INVITES = "BUSINESS/RESET_INVITES",
	// SETUP_INVITES = "BUSINESS/SETUP_INVITES",
	GET_BUSINESS_FONTS_START = "BUSINESS/GET_BUSINESS_FONTS_START",
	GET_BUSINESS_FONTS_ERROR = "BUSINESS/GET_BUSINESS_FONTS_ERROR",
	GET_BUSINESS_FONTS_SUCCESS = "BUSINESS/GET_BUSINESS_FONTS_SUCCESS",
}

interface Action<Type> {
	type: Type;
}

// Create Action Types
// export interface SetupInvitesAction extends Action<BusinessActionKeys.SETUP_INVITES> {
// 	data: {
// 		invites: Invite[];
// 		maxSeats: number;
// 	};
// }

// export interface UpdateBusinessInviteAction
// 	extends Action<BusinessActionKeys.UPDATE_BUSINESS_INVITE> {
// 	data: {
// 		email: string;
// 		index: number;
// 	};
// }

// export interface SendInvitesStartAction extends Action<BusinessActionKeys.SEND_INVITES_START> {
// 	data: {
// 		recipientIndices: number[];
// 	};
// }

// export interface SendInvitesErrorAction extends Action<BusinessActionKeys.SEND_INVITES_ERROR> {
// 	data: {
// 		error: any;
// 		recipientIndices: number[];
// 	};
// }

// export interface SendInvitesSuccessAction extends Action<BusinessActionKeys.SEND_INVITES_SUCCESS> {
// 	data: {
// 		recipientIndices: number[];
// 	};
// }

// export interface ClearBusinessInvite
// 	extends Action<BusinessActionKeys.CLEAR_BUSINESS_INVITE>{}

export interface GetBusinessFontsStartAction
	extends Action<BusinessActionKeys.GET_BUSINESS_FONTS_START> {}

export interface GetBusinessFontsSuccessAction
	extends Action<BusinessActionKeys.GET_BUSINESS_FONTS_SUCCESS> {
	data: {
		fonts: any[];
	};
}

export interface GetBusinessFontsErrorAction
	extends Action<BusinessActionKeys.GET_BUSINESS_FONTS_ERROR> {
	data: {
		error: any;
	};
}

// export interface ResetInvitesAction extends Action<BusinessActionKeys.RESET_INVITES> {
// 	data?: any;
// }

// Action Creators
const actions = {
	// Setup the business invites email list
	// setupInvites: (users: User[], plans: PlansList) => dispatch => {
	// 	const activePlan = findActivePlan(plans);
	// 	const invites: Invite[] = [];

	// 	if (!activePlan || !users.length) {
	// 		return;
	// 	}

	// 	const {
	// 		limits: { max_seats },
	// 	} = activePlan;
		
	// 	for (let i = 0; i < users.length; i++) {
	// 		const user = users[i];
			
	// 		invites.push({
	// 			id: i + 1,
	// 			email: user.email,
	// 			sent: true,
	// 			errored: false,
	// 		});
	// 	}

	// 	if (users.length < max_seats) {
	// 		invites.push({
	// 			id: users.length,
	// 			email: "",
	// 		});
	// 	}

	// 	dispatch({
	// 		type: BusinessActionKeys.SETUP_INVITES,
	// 		data: {
	// 			invites,
	// 			maxSeats: max_seats,
	// 		},
	// 	});
	// },

	// Provider actions
	/**
	 * Save a Provider from the specified data, either via a create or an update
	 * @param data
	 * @param typeId
	 * @param serviceId
	 * @param providerId? - if provided, an update is performed instead of a create
	 * @param callback? - if provided, this will be executed after the save has finished
	 */
	saveProvider: (
		data: Partial<Provider>,
		typeId: string,
		serviceId: string,
		providerId?: string,
		callback?: ([arg]: any) => any
	) => async dispatch => {
		let provider;

		if (providerId) {
			provider = await dispatch(
				entities.providers.actions.update!(providerId, data, typeId, serviceId)
			);
		} else {
			provider = await dispatch(entities.providers.actions.create!(data, typeId, serviceId));
		}

		dispatch(entities.providers.actions.list!());

		// if a callback was passed in, send the providerId to it
		if (callback) {
			await callback(provider.id);
		}
	},

	/**
	 * Archive a specified Provider
	 * @param providerId
	 */
	archiveProvider: (providerId: string) => async dispatch => {
		await dispatch(entities.providers.actions.archive!(providerId));

		dispatch(entities.providers.actions.list!());
	},

	getFonts: () => async (dispatch, getState) => {
		dispatch({
			type: BusinessActionKeys.GET_BUSINESS_FONTS_START,
		});

		try {
			const currentBusiness = userSelectors.currentBusiness(getState());
			const fonts: any[] = await VatomInc.businesses.getFonts(currentBusiness!.businessId);

			const newFonts: any[] = fonts.map((f: any) => {
				return {
					...f,
					...{ source: "varius-business-resources" },
				};
			});

			await dispatch({
				type: BusinessActionKeys.GET_BUSINESS_FONTS_SUCCESS,
				data: { fonts: newFonts },
			});
		} catch (error) {
			dispatch({
				type: BusinessActionKeys.GET_BUSINESS_FONTS_ERROR,
				data: {
					error: (error instanceof Error) ? error.message : error,
				},
			});
		}
	},

	// Invite Business Actions
	// sendInvites: (reset: boolean = false) => async (dispatch, getState) => {
	// 	const inviteEmails = businessSelectors.inviteEmails(getState());
	// 	const accountInfo = accountSelectors.accountInfo(getState());

	// 	const recipients: Invite[] = [];
	// 	const recipientIndices: number[] = []; // array of indices to mark as "sent" in the reducer for UI purposes

	// 	// remove any invites without an email address
	// 	for (let i = 0; i < inviteEmails.length; i++) {
	// 		const invite = inviteEmails[i];
	// 		if (invite.email && !invite.sent) {
	// 			recipients.push({ email: invite.email, id: i.toString() });
	// 			recipientIndices.push(i);
	// 		}
	// 	}

	// 	// don't try and send invites if empty
	// 	if (!recipientIndices.length) {
	// 		return;
	// 	}

	// 	dispatch({
	// 		type: BusinessActionKeys.SEND_INVITES_START,
	// 		data: { recipientIndices },
	// 	});

	// 	try {
	// 		const response: Invite[] = await new Varius().businesses.sendBusinessInvites(recipients);

	// 		// if accountInfo.invites are not empty - we're sending invites from accountOverview page
	// 		// and need to concat accountInfo.invites and recipients
	// 		if (accountInfo) {
	// 			dispatch({
	// 				type: AccountActionType.SUCCESS_UPDATE_INVITES_LIST,
	// 				data: [...accountInfo.invites.items, ...response],
	// 			});
	// 		}
	// 		dispatch({
	// 			type: BusinessActionKeys.SEND_INVITES_SUCCESS,
	// 			data: { recipientIndices },
	// 		});

	// 		if (reset) {
	// 			dispatch({
	// 				type: BusinessActionKeys.RESET_INVITES,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		dispatch({
	// 			type: BusinessActionKeys.SEND_INVITES_ERROR,
	// 			data: { error, recipientIndices },
	// 		});
	// 	}
	// },
	// updateBusinessInvite: (email, index) => dispatch => {
	// 	dispatch({
	// 		type: BusinessActionKeys.UPDATE_BUSINESS_INVITE,
	// 		data: {
	// 			email,
	// 			index,
	// 		},
	// 	});
	// },
};

export default actions;
