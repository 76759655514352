import useLocalStorage from "./useLocalStorage";
import { flags } from "utils/flags";
import { useSelector } from "react-redux";
import { selectors as userSelectors, useCurrentBusinessName } from "reducers/userReducers";

function useFlag<T>(id: string): T {
	const [ storedFlags ] = useLocalStorage("feature_flags");
	const businessName = useCurrentBusinessName();
	const userBusinessFlags = useSelector(state => userSelectors.userRolesMap(state)?.[businessName!]?.flags || {});
	
	// todo: user.userRolesMap.businessId.flags
	
	const flagDef = flags[id];
	
	// if no feature flag is found, throw an error
	if (flagDef === undefined) {
		throw new Error(`unrecognized flag id "${id}"`);
	}
	
	// customized flags in localStorage (modified via dev-info page)
	
	const { [id]: value } = storedFlags || {};
	
	if (value !== undefined) {
		return value;
	}
	
	// business user flags
	
	if (userBusinessFlags[id] !== undefined) {
		return userBusinessFlags[id];
	}
	
	// env defaults
	
	return flagDef.default;
}

export default useFlag;
