interface BusinessProfileState {
	displayName: string;
	url: string;
	location: string;
	logoSrc: string;
	logoSrcStaging: string; // stores value before "save" is pressed
	logoSrcUploadStatus: string;
	loadStatus: string;
	saveStatus: string;
	ethAccount: string;
	ownerOperated: boolean;
	defaultCampaignId: string;
}

const INITIAL_STATE: BusinessProfileState = {
	displayName: "",
	url: "",
	location: "",
	logoSrc: "",
	logoSrcStaging: "",
	logoSrcUploadStatus: "",
	loadStatus: "",
	saveStatus: "",
	ethAccount: "",
	ownerOperated: false,
	defaultCampaignId: "",
};

const reducer = (
	state: BusinessProfileState = INITIAL_STATE,
	action: any
): BusinessProfileState => {
	if (action.type === "business-profile.get.profile.pending") {
		return {
			...state,
			loadStatus: "pending",
		};
	}

	if (action.type === "business-profile.get.profile.success") {
		const { displayName, url, location, logoSrc, ethAccount, ownerOperated } = action.data;

		return {
			...state,
			loadStatus: "success",
			displayName,
			url,
			location,
			logoSrc,
			ethAccount,
			ownerOperated,
		};
	}

	if (action.type === "business-profile.update.profile") {
		const newState = { ...state };

		const { displayName, url, location, logoSrc, ethAccount, ownerOperated, defaultCampaignId } = action.data;

		if (displayName !== undefined) {
			newState.displayName = displayName;
		}

		if (url !== undefined) {
			newState.url = url;
		}

		if (location !== undefined) {
			newState.location = location;
		}

		if (logoSrc !== undefined) {
			newState.logoSrc = logoSrc;
		}

		if (ethAccount !== undefined) {
			newState.ethAccount = ethAccount;
		}

		if (ownerOperated !== undefined) {
			newState.ownerOperated = ownerOperated;
		}
		
		if (defaultCampaignId !== undefined) {
			newState.defaultCampaignId = defaultCampaignId;
		}
		
		return newState;
	}

	if (action.type === "business-profile.save.profile.pending") {
		return {
			...state,
			saveStatus: "pending",
		};
	}

	if (action.type === "business-profile.save.profile.success") {
		return {
			...state,
			saveStatus: "success",
		};
	}

	if (action.type === "business-profile.upload.logo.pending") {
		return {
			...state,
			logoSrcUploadStatus: "pending",
			logoSrcStaging: "",
		};
	}

	if (action.type === "business-profile.upload.logo.success") {
		const { url } = action.data;

		return {
			...state,
			logoSrcStaging: url,
			logoSrcUploadStatus: "success",
		};
	}

	if (action.type === "business-profile.update.logo.pending") {
		return {
			...state,
			saveStatus: "pending",
		};
	}

	if (action.type === "business-profile.update.logo.success") {
		const { url } = action.data;

		return {
			...state,
			saveStatus: "success",
			logoSrc: url,
		};
	}

	return state;
};

const selectState = (state): BusinessProfileState => state.businessProfile;

export function selectDisplayName(state): string {
	return selectState(state).displayName || "";
}

export function selectDefaultCampaignId(state): string {
	return selectState(state).defaultCampaignId || "";
}

export function selectUrl(state): string {
	return selectState(state).url || "";
}

export function selectLocation(state): string {
	return selectState(state).location || "";
}

export function selectEthAccount(state): string {
	return selectState(state).ethAccount || "";
}

export function selectOwnerOperated(state): boolean {
	return selectState(state).ownerOperated;
}

export function selectLogoSrc(state): string {
	return selectState(state).logoSrc || "";
}

export function selectLogoSrcStaging(state): string {
	return selectState(state).logoSrcStaging;
}

export function selectLogoSrcUploadStatus(state): string {
	return selectState(state).logoSrcUploadStatus;
}

export function selectLoadStatus(state): string {
	return selectState(state).loadStatus;
}

export function selectSaveStatus(state): string {
	return selectState(state).saveStatus;
}

export default reducer;
