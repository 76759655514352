import { useState } from "react";
import { Banner, Text, useTheme, Button } from "@varius.io/wombo";
import { useAppUpdateAvailable } from "hooks/useAppUpdateAvailable";

const UpdateBanner = () => {
	const theme = useTheme();
	const fontStyles = {
		fontSize: "15px",
		lineHeight: "20px",
		margin: "auto",
		letterSpacing: "-0.23999999463558197px",
	};
	const buttonStyles = {
		textDecoration: "underline",
		cursor: "pointer",
		background: "transparent",
		outline: "none",
		border: "none",
		padding: 0,
		fontSize: "15px",
		lineHeight: "20px",
		letterSpacing: "-0.23999999463558197px",
	};

	const hasAppUpdate = useAppUpdateAvailable();

	// Local States
	const [isBannerOpen, setIsBannerOpen] = useState<boolean>(hasAppUpdate);

	// Handlers
	const closeBanner = () => {
		setIsBannerOpen(false);
	};

	// ref: https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
	function updateServiceWorker() {
		const sw = window.waitingServiceWorker;

		if (sw === null || sw === undefined) {
			return;
		}

		sw.postMessage({ type: "SKIP_WAITING" });

		sw.addEventListener("statechange", e => {
			if (e.target.state === "activated") {
				window.location.reload();
			}
		});
	}

	return (
		<Banner
			style={{
				zIndex: 10,
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				maxWidth: "1100px",
				margin: "auto",
			}}
			top={theme.rhythm(3)}
			isOpen={isBannerOpen}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					padding: "0 20px",
				}}
			>
				<Text style={fontStyles}>
					There is an update available! Please{" "}
					<button onClick={updateServiceWorker} style={buttonStyles}>
						click here
					</button>{" "}
					to update to the latest version.
				</Text>
			</div>
		</Banner>
	);
};

export default UpdateBanner;
