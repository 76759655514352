import { combineReducers } from "redux";
import { LoadStatus } from "hooks/useReduxLoadable";
import { UserActionsKeys } from "actions/userActions";
import { toast } from "react-toastify";

export interface DnsRecord {
	type: "txt" | "cname";
	name: string;
	value: string;
}

enum Status {
	None = "",
	Pending = "pending",
	Active = "active",
	Failure = "failure",
	Expired = "expired",
}

export interface Domain {
	value: string;
	dns: DnsRecord | null;
	isCustomized: boolean;
	toggleStatus: string;
	status: Status;
}

interface State {
	all: { [type: string]: Domain };
	loadStatusMap: { [type: string]: LoadStatus };
	loadErrorMap: { [type: string]: any };
}

const INITIAL_STATE: State = {
	all: {},
	loadStatusMap: {},
	loadErrorMap: {},
};

const showToast = (message: string)=>{
	// Toast classes are on src/index.css
	toast.success(message, {
		icon: false,
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
		position: toast.POSITION.TOP_CENTER,
		className: "Toast_Domain_ClassName",
		bodyClassName: "Toast_Domain_BodyClassName"
	})
}

const domains = (state: State = INITIAL_STATE, action: any): State => {
	if (action.type === "business.get.domain.pending") {
		const type = action.data.type as string;

		return {
			...state,
			all: {
				...state.all,
				[type]: {
					...state.all[type],
					value: "",
					dns: null,
					isCustomized: false,
					status: Status.None,
					toggleStatus: "",
				},
			},
			loadStatusMap: {
				...state.loadStatusMap,
				[type]: "pending",
			},
		};
	}

	if (action.type === "business.get.domain.success") {
		const type = action.data.type as string;

		const { value, dns, isCustomized, status } = action.data;

		return {
			...state,
			all: {
				...state.all,
				[type]: {
					...state.all[type],
					value,
					dns,
					isCustomized,
					status,
					toggleStatus: "",
				},
			},
			loadStatusMap: {
				...state.loadStatusMap,
				[type]: "success",
			},
		};
	}

	if (action.type === "business.get.domain.failure") {
		const type = action.data.type as string;
		const { error } = action.data;
		
		return {
			...state,
			all: {
				...state.all,
				[type]: {
					value: "",
					dns: null,
					isCustomized: false,
					status: Status.None,
					toggleStatus: "",
				},
			},
			loadStatusMap: {
				...state.loadStatusMap,
				[type]: "failure",
			},
			loadErrorMap: {
				...state.loadErrorMap,
				[type]: error,
			}
		};
	}

	if (action.type === "business.enable.domain.failure") {
		const type = action.data.type as string;
			showToast("There was an error adding your domain, please try again later or contact us for support.")

		return {
			...state,
			all: {
				...state.all,
				[type]:{
					value: state.all[type].value,
					dns: null,
					isCustomized: false,
					status:Status.None,
					toggleStatus: ""
				}
			},
		};
	}
	if (action.type === "business.enable.domain.pending") {
		const type = action.data.type as string;

		return {
			...state,
			all: {
				...state.all,
				[type]: {
					...state.all[type],
					toggleStatus: "pending",
				},
			},
		};
	}

	if (action.type === "business.enable.domain.success") {
		const type = action.data.type as string;

		if(type==="root"){
			// Toast classes are on src/index.css
		showToast("Success! Your domain has been verified and link subdomain succesfully configured.")
		}
		if(type==="link"){
			showToast("Success! Your link subdomain has been succesfully configured.")
		}

		const { value, dns } = action.data;
		return {
			...state,
			all: {
				...state.all,
				[type]: {
					...state.all[type],
					value,
					dns,
					isCustomized: true,
					toggleStatus: "",
				},
			},
		};
	}

	if (action.type === "business.disable.domain.pending") {
		const type = action.data.type as string;

		return {
			...state,
			[type]: {
				...state.all[type],
				toggleStatus: "pending",
			},
		};
	}

	if (action.type === "business.disable.domain.success") {
		const deleted = action.data;
		
		const newAll = state.all;
		
		for (const [type, update] of Object.entries<any>(deleted)) {
			newAll[type] = {
				...newAll[type],
				...update,
				toggleStatus: "",
			};
		}
		if(deleted.type==="link"){
			showToast("Success! Your link subdomain has been succesfully deleted.")
		}

		return {
			...state,
			all: newAll,
		};
	}
	if (action.type === "business.update.domain.success") {
		const {type, value} = action.data;
		if(type==="link"){
			showToast("Success! Your link subdomain has been succesfully updated.")
		}
		return {
			...state,
			all: {
				...state.all,
				[type]: {
					...state.all[type],
					value,
					dns:{...state.all[type].dns, name:value},
					isCustomized: true,
					toggleStatus: "",
				},
			},
		};
	}
	
	if (action.type === UserActionsKeys.STORE_SELECTED_BUSINESS) {
		return INITIAL_STATE;
	}

	return state;
};

export const selectDomains = (state): State => state.businessDomains.domains.all;
export const selectDomainLoadStatus = (state, type: string) => state.businessDomains.domains.loadStatusMap[type] ?? "";
export const selectDomainLoadError = (state, type: string) => state.businessDomains.domains.loadErrorMap[type];

export const selectDomainValue = (state, type: string) => {
	const domain = selectDomains(state)[type];

	if (domain === undefined) {
		return "";
	}

	return domain.value;
};

export const selectDomainDnsRecord = (state, type: string) => {
	const domain = selectDomains(state)[type];

	if (domain === undefined) {
		return "";
	}

	return domain.dns;
};

export const selectDomainIsCustomized = (state, type: string) => {
	const domain = selectDomains(state)[type];

	if (domain === undefined) {
		return false;
	}

	return domain.isCustomized;
};

export const selectDomainStatus = (state, type: string) => {
	const domain = selectDomains(state)[type];

	if (domain === undefined) {
		return false;
	}

	return domain.status;
};

export const selectDomainToggleStatus = (state, type: string) => {
	const domain = selectDomains(state)[type];

	if (domain === undefined) {
		return false;
	}

	return domain.toggleStatus;
};

export default combineReducers({
	domains,
});
