import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useCurrentBusinessId } from "reducers/userReducers";
import usePrevious from "hooks/usePrevious";

const SocketContext = React.createContext(undefined);

export function SocketWrapper({ children }: any) {
	const [socket, setSocket] = useState<any>(undefined);
	const businessId = useCurrentBusinessId();
	const prevBusinessId = usePrevious<any>(businessId);
	
	useEffect(() => {
		if (businessId === undefined) {
			return;
		}
		
		if (socket !== undefined && businessId === prevBusinessId) {
			return;
		}
		
		const socketUri = `/b/${businessId}`;

		setSocket(io("wss://ws.varius.io/", {
			query: {
				eventUri: socketUri,
			},
		}));

		return () => {
			if (socket !== undefined) {
				socket.close();
			}
		}
	}, [businessId, prevBusinessId, socket]);
	
	return (
		<SocketContext.Provider value={socket}>
			{children}
		</SocketContext.Provider>
	)
}

export function useSocket(): any {
	return React.useContext(SocketContext);
}
