import React from "react";
import { Container, Row, Column, Text, withTheme, ThemeResult, Modal } from "@varius.io/wombo";
import styled from "@emotion/styled";
import Sentry from "utils/sentry";

const StyledDetails = styled<any>("details")`
	text-align: left;
	margin: 0 auto;
	max-width: 650px;
	width: 100%;
	white-space: pre-wrap;
`;

interface ErrorBoundaryProps {
	theme: ThemeResult;
	children?:any
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
	state = {
		hasError: false,
		error: undefined as Error | undefined,
	};

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		if (error instanceof Error === false) {
			throw error;
		}

		this.setState({ error, errorInfo });
		Sentry.captureException(error);
	}

	render() {
		const { theme, children } = this.props;
		const { error, hasError } = this.state;
		if (hasError) {
			return (
				<Modal
					isOpen={error !== undefined}
					onClose={() => {
						this.setState({ error: undefined });
					}}
				>
					<Container narrow>
						<Row
							style={{
								margin: theme.rhythm(3),
								textAlign: "center",
							}}
						>
							<Column>
								<Text renderAs="h2">We're sorry, but something went wrong.</Text>
								<Text
									renderAs="h5"
									style={{
										margin: `${theme.rhythm(1)} 0 ${theme.rhythm(1)}`,
									}}
								>
									We apologize for any inconvenience. Our team has been notified.
								</Text>
								<StyledDetails>
									<Text
										style={{
											margin: `${theme.rhythm(1)} 0 0`,
										}}
									>
										Error: An error ocurred
										{this.state.error?.message}
									</Text>
								</StyledDetails>

								<div
									style={{
										marginTop: theme.rhythm(4),
									}}
								>
									<button onClick={() => window.location.reload()}>Return to Studio </button>
								</div>
							</Column>
						</Row>
					</Container>
				</Modal>
			);
		}
		return children;
	}
}

export default withTheme(ErrorBoundary);
