import { useEffect, useState } from "react";
import { useCurrentBusinessName } from "reducers/userReducers";
import useSWR from "swr";
import VatomInc from "utils/VatomIncApi";
import { BusinessLicenses } from "utils/VatomIncApi/account";
import { useCampaignStats } from "./businesses";
import useAccountInfo from "./useAccountInfo";

export interface LicenseLimits {
    isLoading: boolean,
    freeVatoms: number | undefined,
    emitted: number,
    hasFreeRemaining: boolean,
    qtyFreeRemaining: number,

    hasRemaining: boolean,
    qtyRemaining: number,
    campaignsEnabled: boolean,
    customer_support: boolean
}

export function useLicenseLimits(): LicenseLimits {
    const [isLoading, setIsLoading] = useState(true);

    

    const accountInfo = useAccountInfo();
    const businessStats = useCampaignStats();
    
    const hasCreditCard = !!accountInfo?.billingInfo?.creditCard
	const invoiceBilling = accountInfo?.billingInfo?.type === "invoice"
	
    const freeVatoms = accountInfo?.license?.limits.free_vatoms;
    const emitted = businessStats?.emitted || 0;

    const hasFreeRemaining = !freeVatoms || freeVatoms > emitted
    const qtyFreeRemaining = !!freeVatoms ? Math.max(freeVatoms - emitted, 0) : 0
    
    // The user has remaining if they have free vatoms or if they have a paid plan
    const hasRemaining = hasFreeRemaining || hasCreditCard || invoiceBilling
    const qtyRemaining = Infinity // TODO: calculate this
    const campaignsEnabled = !!accountInfo?.license?.limits?.campaigns;
    const customer_support = !!accountInfo?.license?.limits.customer_support
    useEffect(() => {
        setIsLoading(accountInfo === undefined || businessStats === undefined);
    }, [accountInfo, businessStats]);

    return { isLoading, freeVatoms, emitted, hasRemaining, qtyRemaining, hasFreeRemaining, qtyFreeRemaining, campaignsEnabled, customer_support };

}

export function useAllLicenses() {

    const { data, error } = useSWR<BusinessLicenses>(`/products`, () => VatomInc.plans.fetchPlans("*", true, 'system'));

    return { data, error };
}

export function useCurrentProductLicenses() {

    const businessName = useCurrentBusinessName();
    
    const { data, error } = useSWR<BusinessLicenses>(`/b/${businessName}/products`, () => VatomInc.plans.fetchPlans(process.env.REACT_APP_AUDIENCE!));

    return { data, error };
}

