import React, { useEffect, Suspense, lazy } from "react";

import { Route, Switch } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import store from "utils/store";
import history from "utils/history";
import { ConnectedRouter } from "connected-react-router";
import DocumentTitle from "react-document-title";
import appActions from "actions/appActions";
import { GoogleTagManager } from "utils/GoogleTagManager";
import "react-step-progress-bar/styles.css";
import PageLoader from "./components/PageLoader";
import { AuthProvider } from "../../auth"
// array of routes
import routes from "./routes";
import HttpErrorBoundary from "components/common/HttpErrorBoundary";
import ErrorBoundary from "./ErrorBoundary";
import { SocketWrapper } from "./SocketContext";
import SocketEvents from "./SocketEvents";
import Login from "components/Auth/Login";
import Register from "components/Auth/Register";
import { AuthSignInCallbackRoute, AuthSignOutCallbackRoute } from "auth";
import { RecoilRoot } from "recoil";
import ThemeContext from "@varius.io/wombo/components/Theme";
import { useTheme } from "@varius.io/wombo";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import VatomIncApi from "utils/VatomIncApi";

// Components
const Index = lazy(() => import("./components/Index"));
const NotFound = lazy(() => import("./NotFound"));

// having throttling issues
history.listen((location, action) => {
	if (action === "POP") {
		return;
	}
});

function deepMergeTheme(target: any, source: any): any {
	if (!source) return target;
	for (const key in source) {
		if (source[key] instanceof Object && key in target) {
			Object.assign(source[key], deepMergeTheme(target[key], source[key]));
		}
	}

	// Join `target` and modified `source`
	Object.assign(target || {}, source);
	return target;
}

const App = () => {
	const theme = useTheme();

	const { data, isValidating } = useSWR(
		"/studio-viewers/config",
		() => VatomIncApi.businesses.getStudioConfig(),
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	);
	if (data === undefined ) {
		return <PageLoader />;
	}
	
	const mergedTheme = deepMergeTheme(theme, data);
	return (
		<ThemeContext.Provider value={mergedTheme}>
			<RecoilRoot>
				<Helmet>
					<link rel="icon" href={theme?.favicon ?? "favicon-32x32.png"} />
				</Helmet>
				<DocumentTitle title={`${theme.appTitle} Studio™`}>
					<Provider store={store}>
						<ConnectedRouter history={history}>
							<ErrorBoundary>
								<HttpErrorBoundary>
									<SocketWrapper>
										<GetSystemInfo />
										<SocketEvents />
										<GoogleTagManager gtmId={process.env.REACT_APP_GTM_APP_ID!} />
										<Suspense fallback={<PageLoader />}>
											<AuthProvider
												oidcClientId={data?.oidcClientId}
												onAccessTokenChanged={at => {
													if (at === undefined) {
														localStorage.removeItem("access_token");
													} else {
														localStorage.setItem("access_token", at);
													}
												}}
											>
												<Switch>
													<Route exact path="/" component={Index} />
													<Route exact path="/login" component={Login} />
													<Route exact path="/register" component={Register} />
													<Route exact path="/callback">
														<AuthSignInCallbackRoute>
															<PageLoader />
														</AuthSignInCallbackRoute>
													</Route>
													<Route exact path="/logout-callback">
														<AuthSignOutCallbackRoute>
															<PageLoader />
														</AuthSignOutCallbackRoute>
													</Route>
													{routes.map(({ path, exact, component }, key) => (
														<Route key={key} path={path} exact={exact} component={component} />
													))}
													<Route component={NotFound} />
												</Switch>
											</AuthProvider>
										</Suspense>
									</SocketWrapper>
								</HttpErrorBoundary>
							</ErrorBoundary>
						</ConnectedRouter>
					</Provider>
				</DocumentTitle>
			</RecoilRoot>
		</ThemeContext.Provider>
	);
};

const GetSystemInfo = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(appActions.getSystemInfo());
	}, [dispatch]);

	return null;
};

export default App;
