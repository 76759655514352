import { DigitalObjectsActionKeys, DigitalObjectsActions } from "actions/digitalObjectsActions";
import entities, { Type, Campaign, Design, DigitalObject } from "reducers/entitiesReducers";
import { AuthActions } from "actions/authActions";
import { UserActions, UserActionsKeys } from "actions/userActions";

export interface TypeDesignMap {
	[typeId: string]: string[];
}

export interface AccessoriesMap {
	[key: string]: any;
}

export interface FacetValue {
	value: string;
	count: number;
}

interface Facet {
	name: string;
	selectedValue: FacetValue | undefined;
	availableValues: FacetValue[];
}
export interface ObjectEntity {
	designName: string;
	blueprintName: string;
	objectID: string;
}

export interface DigitalObjectsFilters {
	query: string;
	page: number;
	hitsPerPage: number;
	tags: any;
	facets: Facet[];
}

// Interfaces
export interface DigitalObjectsState {
	accessoriesMap: AccessoriesMap;
	typesOrder: string[];
	designsOrder: string[];
	digitalObjectsOrder: string[];
	totalDigitalObjects: number;
	digitalObjectCampaignsOrder: string[];
	typeDesignsMap: TypeDesignMap;
	digitalObjectId: string;
	selectedTypeId?: string;
	selectedDesignId?: string;
	formData?: DigitalObject;
	createError?: any;
	loadError?: any;
	sendTestError?: any;
	publishError?: any;
	isPublishingDigitalObject: boolean;
	isBuildingFormData: boolean;
	isTestingDigitalObject: boolean;
	isUploading: boolean;
	isUpdatingImage: boolean;
	isFiltering: boolean;
	localImage?: File;
	temporaryResourceUrl?: string;
	filters: DigitalObjectsFilters;
	zeroState?: boolean;
	objectEntity: ObjectEntity[]
}

// Initial State
const initialState: DigitalObjectsState = {
	accessoriesMap: {},
	typesOrder: [],
	typeDesignsMap: {},
	totalDigitalObjects: 0,
	designsOrder: [],
	digitalObjectId: "",
	digitalObjectsOrder: [],
	digitalObjectCampaignsOrder: [],
	isBuildingFormData: false,
	isTestingDigitalObject: false,
	isFiltering: false,
	isUpdatingImage: false,
	isUploading: false,
	isPublishingDigitalObject: false,
	zeroState: undefined,
	filters: {
		query: "",
		page: 0,
		hitsPerPage: 12,
		tags: [],
		facets: [],
	},
	objectEntity: [],
};

export default (
	state: DigitalObjectsState = initialState,
	action: DigitalObjectsActions | UserActions | AuthActions
): DigitalObjectsState => {
	switch (action.type) {
		// Reset to initialState on logout
		case UserActionsKeys.STORE_SELECTED_BUSINESS: {
			return {
				...initialState,
			};
		}

		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// case AuthActionsKeys.LOGOUT: {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		case DigitalObjectsActionKeys.STORE_ACCESSORIES_MAP: {
			return {
				...state,
				accessoriesMap: action.data.accessoriesMap,
			};
		}

		case DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_START: {
			return {
				...state,
				isPublishingDigitalObject: true,
			};
		}

		case DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_SUCCESS: {
			return {
				...state,
				isPublishingDigitalObject: false,
			};
		}

		case DigitalObjectsActionKeys.PUBLISH_DIGITAL_OBJECT_ERROR: {
			return {
				...state,
				isPublishingDigitalObject: false,
				publishError: action.data.error,
			};
		}

		case DigitalObjectsActionKeys.UPDATE_IMAGE_START: {
			return {
				...state,
				isUpdatingImage: true,
			};
		}

		case DigitalObjectsActionKeys.UPDATE_IMAGE_ERROR:
		case DigitalObjectsActionKeys.UPDATE_IMAGE_SUCCESS: {
			return {
				...state,
				isUpdatingImage: false,
			};
		}

		case DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_START: {
			return {
				...state,
				isTestingDigitalObject: true,
				sendTestError: undefined,
			};
		}

		case DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_ERROR: {
			return {
				...state,
				isTestingDigitalObject: false,
				sendTestError: action.data.error,
			};
		}

		case DigitalObjectsActionKeys.TEST_DIGITAL_OBJECT_SUCCESS: {
			return {
				...state,
				isTestingDigitalObject: false,
				sendTestError: undefined,
			};
		}

		case entities.digitalObjectCampaigns.actionNames.list.success: {
			const digitalObjectCampaignsOrder = (action as any).data.items.map(
				(campaign: Campaign) => campaign.groupCampaignId
			);

			return {
				...state,
				digitalObjectCampaignsOrder,
			};
		}

		case entities.types.actionNames.list.success: {
			const typesOrder = (action as any).data.items.map((type: Type) => type.id);

			return {
				...state,
				typesOrder,
			};
		}

		case entities.digitalObjects.actionNames.listSearch.success:
		case entities.digitalObjects.actionNames.list.success: {
			const { isFiltering, filters } = state;

			// this reducer should only respond to entities actions when its isFiltering flag is raised
			if (!isFiltering) {
				return {
					...state,
				};
			}

			const { page, hitsPerPage, facets } = filters;
			const { newlyAvailableFacets, total } = (action as any).data;

			const objectEntity: ObjectEntity[] = (action as any).data.items.map(
				(digitalObject: DigitalObject) => {return{objectDefinitionId: digitalObject.id, blueprintName: digitalObject.blueprintName, designName: digitalObject.designName}}
			);

			const zeroState = state.zeroState === undefined ? !total : state.zeroState;

			// let currentFacets = state.filterData.currentFacets;
			const pageStartIndex = page * hitsPerPage;
			const newDigitalObjectsOrder = (action as any).data.items.map(
				(digitalObject: DigitalObject) => digitalObject.id
			);
			let digitalObjectsOrder = JSON.parse(JSON.stringify(state.digitalObjectsOrder));

			// splice new object ids into order array at the appropriate location based on page, and make unqiue
			digitalObjectsOrder.splice(pageStartIndex, hitsPerPage, ...newDigitalObjectsOrder);
			digitalObjectsOrder = [...Array.from(new Set(digitalObjectsOrder))];

			// if (data.params.facetFilters !== undefined) {
			// 	const selectedName = data.params.faceFilters[0];
			// 	const alreadySelected = currentFacets.includes(selectedName);
			// 	if (alreadySelected) {

			// 		currentFacets = currentFacets.filter(f => f !== selectedName)

			// 	} else {
			// 		console.log('3')

			// 		currentFacets = [...currentFacets, ...data.params.facetFilters];
			// 	}

			// 	console.log('currentFacets', currentFacets)
			// } else {
			// 	console.log('its undefined')
			// }

			const newFacets: Facet[] = [];

			for (const [facetName, facetInfo] of Object.entries<any>(newlyAvailableFacets)) {
				const availableValues: FacetValue[] = [];

				for (const [value, count] of Object.entries<any>(facetInfo)) {
					availableValues.push({
						value,
						count,
					});
				}

				const existingFacet = facets.find(f => f.name === facetName);

				const facet: Facet = {
					name: facetName,
					selectedValue: existingFacet === undefined ? undefined : existingFacet.selectedValue,
					availableValues,
				};

				newFacets.push(facet);
			}

			return {
				...state,
				totalDigitalObjects: total,
				zeroState,
				digitalObjectsOrder,
				filters: {
					...state.filters,
					facets: newFacets,
				},
				objectEntity
			};
		}

		case DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_START: {
			const page = (action as any).data.filters.page || state.filters.page;
			const totalDigitalObjects = !page ? 0 : state.totalDigitalObjects;
			const digitalObjectsOrder = !page ? [] : state.digitalObjectsOrder;

			return {
				...state,
				totalDigitalObjects,
				digitalObjectsOrder,
				isFiltering: true,
				filters: {
					...state.filters,
					...(action as any).data.filters,
				},
			};
		}

		case DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_SUCCESS:
		case DigitalObjectsActionKeys.FILTER_DIGITAL_OBJECTS_ERROR: {
			return {
				...state,
				isFiltering: false,
			};
		}

		case entities.designs.actionNames.list.success: {
			const designsOrder = (action as any).data.items.map((design: Design) => design.id);

			return {
				...state,
				designsOrder,
				typeDesignsMap: {
					...state.typeDesignsMap,
					[(action as any).data.params.typeId]: designsOrder,
				},
			};
		}

		case DigitalObjectsActionKeys.STORE_BLUEPRINT_DESIGNS: {
			return {
				...state,
				designsOrder: action.data.designIds,
			};
		}

		case DigitalObjectsActionKeys.SELECT_TYPE: {
			return {
				...state,
				selectedTypeId: action.data.typeId,
			};
		}

		case DigitalObjectsActionKeys.SELECT_DESIGN: {
			return {
				...state,
				selectedDesignId: action.data.designId,
			};
		}

		case entities.digitalObjects.actionNames.create.success: {
			return {
				...state,
				zeroState: false,
				selectedTypeId: undefined,
				selectedDesignId: undefined,
			};
		}

		case entities.digitalObjects.actionNames.create.error: {
			return {
				...state,
				selectedTypeId: undefined,
				selectedDesignId: undefined,
			};
		}

		case DigitalObjectsActionKeys.UPDATE_FORM_DATA: {
			return {
				...state,
				formData: action.data.formData,
			};
		}

		case DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_START: {
			return {
				...state,
				isBuildingFormData: true,
				digitalObjectId: action.data.digitalObjectId,
			};
		}

		case DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_SUCCESS: {
			return {
				...state,
				isBuildingFormData: false,
				loadError: null,
			};
		}

		case DigitalObjectsActionKeys.LOAD_DIGITAL_OBJECT_DATA_ERROR: {
			return {
				...state,
				isBuildingFormData: false,
				loadError: action.data.error,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_IMAGE_START: {
			return {
				...state,
				isUploading: true,
				localImage: action.data.image,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_IMAGE_SUCCESS: {
			const temporaryResourceUrl = action.data.temporaryResourceUrl;

			return {
				...state,
				isUploading: false,
				loadError: null,
				temporaryResourceUrl,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_IMAGE_ERROR: {
			return {
				...state,
				isUploading: false,
				loadError: action.data.error,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_FILE_START: {
			return {
				...state,
				isUploading: true,
				localImage: action.data.file,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_FILE_SUCCESS: {
			return {
				...state,
				isUploading: false,
				loadError: null,
				temporaryResourceUrl: action.data.temporaryResourceUrl,
			};
		}

		case DigitalObjectsActionKeys.UPLOAD_FILE_ERROR: {
			return {
				...state,
				isUploading: false,
				loadError: action.data.error,
			};
		}

		case DigitalObjectsActionKeys.CREATE_FORM_DATA: {
			return {
				...state,
				formData: action.data.formData,
			};
		}

		default: {
			return state;
		}
	}
};

const digitalObjectsState = state => state.digitalObjects as DigitalObjectsState;

// Selectors
export const selectors = {
	typesOrder: state => digitalObjectsState(state).typesOrder,
	typeDesignsMap: state => digitalObjectsState(state).typeDesignsMap,
	designsOrder: state => digitalObjectsState(state).designsOrder,
	digitalObjectsOrder: state => digitalObjectsState(state).digitalObjectsOrder,
	digitalObjectCampaignsOrder: state => digitalObjectsState(state).digitalObjectCampaignsOrder,
	createError: state => digitalObjectsState(state).createError,
	isPublishingDigitalObject: state => digitalObjectsState(state).isPublishingDigitalObject,
	publishError: state => digitalObjectsState(state).publishError,
	isBuildingFormData: state => digitalObjectsState(state).isBuildingFormData,
	formData: state => digitalObjectsState(state).formData,
	selectedTypeId: state => digitalObjectsState(state).selectedTypeId,
	selectedDesignId: state => digitalObjectsState(state).selectedDesignId,
	digitalObjectId: state => digitalObjectsState(state).digitalObjectId,
	isTestingDigitalObject: state => digitalObjectsState(state).isTestingDigitalObject,
	sendTestError: state => digitalObjectsState(state).sendTestError,
	isUploading: state => digitalObjectsState(state).isUploading,
	isUpdatingImage: state => digitalObjectsState(state).isUpdatingImage,
	localImage: state => digitalObjectsState(state).localImage,
	temporaryResourceUrl: state => digitalObjectsState(state).temporaryResourceUrl,
	zeroState: state => digitalObjectsState(state).zeroState,
	accessoriesMap: state => digitalObjectsState(state).accessoriesMap,
	filters: state => digitalObjectsState(state).filters,
	totalDigitalObjects: state => digitalObjectsState(state).totalDigitalObjects,
	isFiltering: state => digitalObjectsState(state).isFiltering,
	objectEntity: state => digitalObjectsState(state).objectEntity,
};
