import entities, { Rule, CampaignEntity } from "reducers/entitiesReducers";
import { selectors as campaignSelectors } from "../reducers/campaignsReducers";

export type RulesActions =
	| AddNewRuleStartAction
	| AddNewRuleSuccessAction
	| AddNewRuleErrorAction
	| DeleteTempRuleAction;

export enum RulesActionKey {
	ADD_NEW_RULE = "CAMPAIGNS/ADD_NEW_RULE",
	ADD_NEW_RULE_START = "CAMPAIGNS/ADD_NEW_RULE_START",
	ADD_NEW_RULE_SUCCESS = "CAMPAIGNS/ADD_NEW_RULE_SUCCESS",
	ADD_NEW_RULE_ERROR = "CAMPAIGNS/ADD_NEW_RULE_ERROR",
	DELETE_TEMP_RULE = "CAMPAIGNS/DELETE_TEMP_RULE",
}

interface Action<Type> {
	type: Type;
	data?: any;
}

export interface AddNewRuleStartAction extends Action<RulesActionKey.ADD_NEW_RULE_START> {}

export interface AddNewRuleSuccessAction extends Action<RulesActionKey.ADD_NEW_RULE_SUCCESS> {
	data: {
		rule: Rule;
	};
}

export interface AddNewRuleErrorAction extends Action<RulesActionKey.ADD_NEW_RULE_ERROR> {
	data: {
		error: any;
	};
}

export interface DeleteTempRuleAction extends Action<RulesActionKey.DELETE_TEMP_RULE> {
	data: {
		id: string;
	};
}

/**
 * Add a non-persisted rule to a campaign, as well as adding the source as a rule entity
 * @param source
 * @param updateCampaignData - optional data to use to update the campaign
 */
export function addRule(source: CampaignEntity, updateCampaignData?: any) {
	return async (dispatch, getState) => {
		const campaignId = campaignSelectors.campaignId(getState());
		const id = `TEMP-${new Date().getTime()}`;
		const rule: Rule = {
			id,
			effects: [],
			trigger: {
				config: {},
				source: {
					id: source.id,
					type: source.type,
				},
				isArchived: false,
			},
		};

		await dispatch({
			type: RulesActionKey.ADD_NEW_RULE_START,
		});

		try {
			// if updateCampaignData has been passed, update the campaign using the data
			if (updateCampaignData) {
				await dispatch(entities.campaigns.actions.update!(campaignId, updateCampaignData));
			}

			await dispatch({
				type: RulesActionKey.ADD_NEW_RULE_SUCCESS,
				data: {
					rule,
				},
			});
		} catch (error) {
			dispatch({
				type: RulesActionKey.ADD_NEW_RULE_ERROR,
				data: {
					error,
				},
			});
		}
	};
}

/**
 * Delete a specified rule from a Campaign
 */
export function deleteRule(rule: Rule) {
	return async (dispatch, getState) => {
		const isNewRule = rule.id.startsWith("TEMP");

		// new rules are not persisted through the API yet, so only delete them locally
		if (isNewRule) {
			// fire off action to handle local delete
			await dispatch({
				type: RulesActionKey.DELETE_TEMP_RULE,
				data: {
					id: rule.id,
				},
			});
		} else {
			// otherwise, delete through the API
			const campaignId = campaignSelectors.campaignId(getState());

			await dispatch(entities.rules.actions.archive!(rule.id, campaignId));
			dispatch(entities.rulesEntities.actions.get!(campaignId));
		}
	};
}
