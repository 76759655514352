import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import es from "./locales/es.json";
import en from "./locales/en.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
    fallbackLng:["en", "es"],
		resources: {
			en,
			es
		},

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
