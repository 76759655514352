import { ContactListActionTypes, ContactListAction } from "actions/contactListActions";
import entityReducers from "./entitiesReducers";

export interface ContactListsState {
	isImporting: boolean;
}

// Initial State
const initialState: ContactListsState = {
	isImporting: false,
};

export default (
	state: ContactListsState = initialState,
	action: ContactListAction
): ContactListsState => {
	switch (action.type) {
		case entityReducers.contactLists.actionNames.create.start: {
			return {
				isImporting: true,
			};
		}

		// Reset to initialState on logout
		case ContactListActionTypes.ADD_CONTACTS_SUCCESS: {
			return {
				isImporting: false,
			};
		}

		default: {
			return state;
		}
	}
};

const contactListsState = state => state.contactLists as ContactListsState;

// Selectors
export const selectors = {
	isImporting: state => contactListsState(state).isImporting,
};
