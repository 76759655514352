import Redux from "redux";
import { SearchResults } from "reducers/entitiesReducers";

export type RequestStatus = "" | "pending" | "success" | "failure";

interface StringMap<Entity> {
	[key: string]: Entity;
}

interface State<Entity> {
	all: { [key: string]: Entity };

	loadStatus: RequestStatus;
	loadError: any;

	loadOneStatus: RequestStatus;
	loadOneStatusMap: { [key: string]: RequestStatus };
	loadOneError: any;

	updateStatus: RequestStatus;
	updateError: any;
	updateId: string;

	createStatus: RequestStatus;
	createError: any;

	cloneStatus: RequestStatus;
	cloneError: any;

	archiveStatus: RequestStatus;
	archiveError: any;
	archiveId: string;

	versionStatus: RequestStatus;
	versionError: any;

	publishStatus: RequestStatus;
	publishError: any;
}

type EntityReducer<Entity> = (state: State<Entity>, action: Redux.Action) => State<Entity>;

/**
 * Creates a Redux reducer for the given entity.
 * @param actionNames Object containing structured action names from createActionNames.
 * @param primaryKey The name of the field used to index the entity.
 * @param options Options used to configure this reducer
 */
function createReducer<Entity>(
	actionNames,
	primaryKey: string = "id",
	options?: ReducerOptions
): EntityReducer<Entity> {
	const initialState: State<Entity> = {
		all: {},
		loadStatus: "" as RequestStatus,
		loadError: undefined,
		loadOneStatus: "" as RequestStatus,
		loadOneStatusMap: {},
		loadOneError: undefined,
		updateStatus: "" as RequestStatus,
		updateError: undefined,
		updateId: "",
		createStatus: "" as RequestStatus,
		createError: undefined,
		cloneStatus: "" as RequestStatus,
		cloneError: undefined,
		archiveStatus: "" as RequestStatus,
		archiveError: undefined,
		archiveId: "",
		versionStatus: "" as RequestStatus,
		versionError: undefined,
		publishStatus: "" as RequestStatus,
		publishError: undefined,
	};

	const defaultOptions: ReducerOptions = {
		clearOnBusinessSwitch: true,
	};

	const config = { ...defaultOptions, ...options };

	return (state: State<Entity> = initialState, action: Redux.AnyAction) => {
		const id = (action.data !== undefined ? action.data[primaryKey] : undefined) || (action.data !== undefined ? action.data.item !== undefined ? action.data.item[primaryKey] : undefined : undefined);
		
		// reset to initialState on logout
		// @TODO figure out how to make javascript work to allow me to import the action key names from their respective files
		// this should be AuthActions.LOGOUT
		// note: this is no longer required because oidc uses a logout redirect, which will reset the redux store
		// if (action.type === "AUTH/LOGOUT" && config.clearOnUserSwitch) {
		// 	return {
		// 		...initialState,
		// 	};
		// }

		// clear all entities on business switch
		// @TODO figure out how to make javascript work to allow me to import the action key names from their respective files
		// this should be UserActionKeys.STORE_SELECTED_BUSINESS
		if (action.type === "USER/STORE_SELECTED_BUSINESS" && config.clearOnBusinessSwitch) {
			return {
				...state,
				all: {},
			};
		}

		if (action.type === actionNames.version.start) {
			return {
				...state,
				versionStatus: "pending",
			};
		}

		if (action.type === actionNames.version.success) {
			const all: StringMap<Entity> = {
				...state.all,
				[action.data.item.id]: action.data.item,
			};

			return {
				...state,
				versionStatus: "success",
				versionError: undefined,
				all,
			};
		}

		// @TODO create own properties for listSearch?
		if (action.type === actionNames.listSearch.start) {
			return {
				...state,
				loadStatus: "pending",
			};
		}

		if (action.type === actionNames.listSearch.error) {
			return {
				...state,
				loadStatus: "failure",
				loadError: action.data.error,
			};
		}

		if (action.type === actionNames.listSearch.success) {
			const all: StringMap<Entity> = action.data.items.reduce(
				(map: StringMap<Entity>, item: Entity) => ({
					...map,
					// merge current state's entity with incoming entity
					[item[primaryKey]]: {
						// todo: try to remove "as any"
						...(state.all[item[primaryKey]] as any),
						...(item as any),
					},
				}),
				{}
			);

			return {
				...state,
				loadStatus: "success",
				all: {
					...state.all,
					...all,
				},
			};
		}

		if (action.type === actionNames.list.start) {
			return {
				...state,
				loadStatus: "pending",
			};
		}

		if (action.type === actionNames.list.error) {
			return {
				...state,
				loadStatus: "failure",
				loadError: action.data.error,
			};
		}

		if (action.type === actionNames.list.success) {
			const all: StringMap<Entity> = action.data.items.reduce(
				(map: StringMap<Entity>, item: Entity) => ({
					...map,
					// merge current state's entity with incoming entity
					[item[primaryKey]]: {
						// todo: try to remove "as any"
						...(state.all[item[primaryKey]] as any),
						...(item as any),
					},
				}),
				{}
			);

			return {
				...state,
				loadStatus: "success",
				all: {
					...state.all,
					...all,
				},
			};
		}

		if (action.type === actionNames.get.start) {
			return {
				...state,
				loadOneStatus: "pending",
				loadOneStatusMap: {
					...state.loadOneStatusMap,
					[id]: "pending",
				},
			};
		}

		if (action.type === actionNames.get.error) {
			return {
				...state,
				loadOneStatus: "failure",
				loadOneStatusMap: {
					...state.loadOneStatusMap,
					[id]: "failure",
				},
				loadOneError: action.data.error,
			};
		}

		if (action.type === actionNames.get.success) {
			const all: StringMap<Entity> = {
				...state.all,
				[id ? id : action.data.key]: action.data
					.item,
			};

			return {
				...state,
				loadOneStatus: "success",
				loadOneStatusMap: {
					...state.loadOneStatusMap,
					[id]: "success",
				},
				loadError: undefined,
				all,
			};
		}

		if (action.type === actionNames.update.start) {
			return {
				...state,
				updateStatus: "pending",
				updateId: action.data.id,
			};
		}

		if (action.type === actionNames.update.error) {
			return {
				...state,
				updateStatus: "failure",
				updateError: action.data.error,
				updateId: "",
			};
		}

		if (action.type === actionNames.update.success) {
			const primaryKeyValue = id;
			const newItem =
				action.data.merge === true
					? { ...(state.all[primaryKeyValue] as any), ...action.data.item }
					: action.data.item;

			const all: StringMap<Entity> = {
				...state.all,
				[primaryKeyValue]: newItem,
			};

			return {
				...state,
				updateStatus: "success",
				updateError: undefined,
				updateId: "",
				all,
			};
		}

		if (action.type === actionNames.setOne) {
			const primaryKeyValue = id;
			const newItem =
				action.data.merge === true
					? { ...(state.all[primaryKeyValue] as any), ...action.data.item }
					: action.data.item;

			const all: StringMap<Entity> = {
				...state.all,
				[primaryKeyValue]: newItem,
			};

			return {
				...state,
				all,
			};
		}

		if (action.type === actionNames.create.start) {
			return {
				...state,
				createStatus: "pending",
			};
		}

		if (action.type === actionNames.create.error) {
			return {
				...state,
				createStatus: "failure",
				createError: action.data.error,
			};
		}

		if (action.type === actionNames.create.success) {
			const all: StringMap<Entity> = {
				...state.all,
				[id]: action.data.item,
			};

			return {
				...state,
				createStatus: "success",
				createError: undefined,
				all,
			};
		}

		if (action.type === actionNames.clone.start) {
			return {
				...state,
				cloneStatus: "pending",
			};
		}

		if (action.type === actionNames.clone.error) {
			return {
				...state,
				cloneStatus: "failure",
				cloneError: action.data.error,
			};
		}

		if (action.type === actionNames.clone.success) {
			const all: StringMap<Entity> = {
				...state.all,
				[id]: action.data.item,
			};

			return {
				...state,
				cloneStatus: "success",
				cloneError: undefined,
				all,
			};
		}

		if (action.type === actionNames.archive.start) {
			return {
				...state,
				archiveStatus: "pending",
				archiveId: action.data.id,
			};
		}

		if (action.type === actionNames.archive.error) {
			return {
				...state,
				archiveStatus: "failure",
				archiveError: action.data.error,
				archiveId: "",
			};
		}

		if (action.type === actionNames.archive.success) {
			// todo: for campaign archival... hacky... this api call func will return a boolean of success or failure
			const newItem = (typeof action.data.item === "boolean" || typeof action.data.item.ok === "boolean") ? undefined : action.data.item;
			
			// todo: for campaign archival... hacky...
			if (action.data.item === false) {
				return {
					...state,
					archiveStatus: "success",
					archiveError: undefined,
					archiveId: "",
				};
			}
			
			const all: StringMap<Entity> = {
				...state.all,
				[id]: newItem,
			};
			
			// todo: hack for campaign group archive
			if (action.type === "campaigns.archive.success") {
				for (const [itemId, item] of Object.entries(all)) {
					// todo: not sure why i need to check for undefined... an error is thrown otherwise
					if ((item !== undefined && item as any).groupCampaignId === id) {
						all[itemId] = undefined as any;
					}
				}
			}

			return {
				...state,
				archiveStatus: "success",
				archiveError: undefined,
				archiveId: "",
				all,
			};
		}

		if (action.type === actionNames.publish.start) {
			return {
				...state,
				publishStatus: "pending",
			};
		}

		if (action.type === actionNames.publish.error) {
			return {
				...state,
				publishStatus: "failure",
				publishError: action.data.error,
			};
		}

		if (action.type === actionNames.publish.success) {
			const all: StringMap<Entity> = {
				...state.all,
				[id]: action.data.item,
			};

			return {
				...state,
				publishStatus: "success",
				publishError: undefined,
				all,
			};
		}

		if (action.type === actionNames.setByPrimaryKey) {
			const all: StringMap<Entity> = {
				...state.all,
				[id ? id : action.data.key]: action.data
					.item,
			};

			return {
				...state,
				all,
			};
		}
		
		return state;
	};
}

/**
 * Create a set of action names for the entity.
 * @param entityName The name of the entity (ie. "users").
 */
function createActionNames(entityName: string) {
	const actionName = (...args) => [entityName, ...args].join(".");
	const actionNamesForVerb = verb => ({
		start: actionName(verb, "start"),
		success: actionName(verb, "success"),
		error: actionName(verb, "error"),
	});

	return {
		list: actionNamesForVerb("list"),
		listSearch: actionNamesForVerb("listSearch"),
		get: actionNamesForVerb("get"),
		update: actionNamesForVerb("update"),
		create: actionNamesForVerb("create"),
		clone: actionNamesForVerb("clone"),
		archive: actionNamesForVerb("archive"),
		version: actionNamesForVerb("version"),
		publish: actionNamesForVerb("publish"),
		setByPrimaryKey: actionName("setByPrimaryKey"),
		setOne: actionName("setOne"),
	};
}

interface ReducerOptions {
	clearOnBusinessSwitch?: boolean;
}

interface CreateActionOptions<Entity> {
	list?: (params: any) => Promise<Entity[]>;
	listSearch?: (params: any) => Promise<SearchResults<Entity[]>>;
	get?: (id: string, addArgs?: any) => Promise<Entity>;
	update?: (id: string, data: Partial<Entity>, addArgs?: any) => Promise<Entity>;
	create?: (data: Partial<Entity>, addArgs?: any) => Promise<Entity>;
	clone?: (id: string, addArgs?: any) => Promise<Entity>;
	archive?: (id: string, addArgs?: any) => Promise<boolean | Entity>;
	version?: (id: string) => Promise<Entity>;
	publish?: (id: string, data: any ) => Promise<Entity>;
}

/**
 * Create action creators that trigger the handlers passed in the createEntity call.
 * @param actionNames Object containing structured action names from createActionNames.
 * @param options Options passed to the createEntity call.
 */
function createActions<Entity>(actionNames, options: CreateActionOptions<Entity>) {
	function list(params?: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.list.start,
			});

			try {
				// note: assumes "list" is defined
				const res = await options.list!(params);
				await dispatch({
					type: actionNames.list.success,
					data: {
						items: res,
						params,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.list.error,
					data: {
						error,
						params,
					},
				});
				
				throw error;
			}
		};
	}

	function listSearch(params?: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.listSearch.start,
			});

			try {
				// note: assumes "listSearch" is defined
				const res = await options.listSearch!(params);

				await dispatch({
					type: actionNames.listSearch.success,
					data: {
						...res,
						params,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.listSearch.error,
					data: {
						error,
						params,
					},
				});
				
				throw error;
			}
		};
	}

	function get(id: string, ...addArgs: any): (dispatch: any) => Promise<Entity | undefined> {
		return async dispatch => {
			let key: any = id;
			
			// todo: hacky
			if (actionNames.get.start.indexOf("distributions") === 0) {
				key = addArgs[0];
			}
			else {
				key = undefined;
			}
			
			// console.log("dispatching get start for id", id);
			await dispatch({
				type: actionNames.get.start,
				data: {
					id: key,
				},
			});

			try {
				const res = await options.get!(id, addArgs[0]);
				
				// console.log("dispatching get success for id", id);

				await dispatch({
					type: actionNames.get.success,
					data: {
						item: res,
						id: key,
						key: id,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.get.error,
					data: {
						error,
						id: key,
					},
				});
				
				throw error;
			}
		};
	}

	function update(id: string, data: Partial<Entity>, ...addArgs: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.update.start,
				data: {
					id,
				},
			});

			try {
				// note: assumes "update" is defined
				const res = await options.update!(id, data, addArgs);

				await dispatch({
					type: actionNames.update.success,
					data: {
						item: res,
						// note: hacking in merge=true for updatePendingDesignChanges
						merge: addArgs.length > 0 ? addArgs[0].merge : undefined,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.update.error,
					data: {
						error,
					},
				});
				
				throw error;
			}
		};
	}

	function setOne(key: string, newValue: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.setOne,
				data: {
					key,
					item: newValue,
				},
			});			
		};
	}

	function create(data: Partial<Entity>, ...addArgs: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.create.start,
				data: {
					addArgs,
				},
			});

			try {
				// note: assumes "create" is defined
				const res = await options.create!(data, addArgs);

				await dispatch({
					type: actionNames.create.success,
					data: {
						item: res,
					},
				});

				return res;
			} catch (error) {
				console.error(`error creating entity`, error);

				dispatch({
					type: actionNames.create.error,
					data: {
						error,
					},
				});
				
				throw error;
			}
		};
	}

	function clone(id: string) {
		return async dispatch => {
			await dispatch({
				type: actionNames.clone.start,
			});

			try {
				// note: assumes "clone" is defined
				const res = await options.clone!(id);

				await dispatch({
					type: actionNames.clone.success,
					data: {
						item: res,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.clone.error,
					data: {
						error,
					},
				});
				
				throw error;
			}
		};
	}

	function archive(id: string, ...addArgs: any) {
		return async dispatch => {
			await dispatch({
				type: actionNames.archive.start,
				data: {
					id,
				},
			});

			try {
				// note: assumes "archive" is defined
				const res = await options.archive!(id, addArgs);

				await dispatch({
					type: actionNames.archive.success,
					data: {
						item: res,
						id,
					},
				});

				return res;
			} catch (error) {
				console.error("error during entity archive", error);
				
				dispatch({
					type: actionNames.archive.error,
					data: {
						error,
						id,
					},
				});
				
				throw error;
			}
		};
	}

	function version(id: string) {
		return async dispatch => {
			await dispatch({
				type: actionNames.version.start,
			});

			try {
				// note: assumes "version" is defined
				const res = await options.version!(id);

				await dispatch({
					type: actionNames.version.success,
					data: {
						item: res,
						id,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.version.error,
					data: {
						error,
						id,
					},
				});
				
				throw error;
			}
		};
	}

	function publish(id: string, isTesting: boolean = false) {
		return async dispatch => {
			await dispatch({
				type: actionNames.publish.start,
			});

			try {
				// note: assumes "publish" is defined
				const res = await options.publish!(id, { isTesting });

				await dispatch({
					type: actionNames.publish.success,
					data: {
						item: res,
						id,
					},
				});

				return res;
			} catch (error) {
				dispatch({
					type: actionNames.publish.error,
					data: {
						error,
						id,
					},
				});
				
				throw error;
			}
		};
	}

	return {
		list: options.list !== undefined ? list : undefined,
		listSearch: options.listSearch !== undefined ? listSearch : undefined,
		get: options.get !== undefined ? get : undefined,
		update: options.update !== undefined ? update : undefined,
		create: options.create !== undefined ? create : undefined,
		clone: options.clone !== undefined ? clone : undefined,
		archive: options.archive !== undefined ? archive : undefined,
		version: options.version !== undefined ? version : undefined,
		publish: options.publish !== undefined ? publish : undefined,
		setOne,
	};
}

/**
 * Selector to get entity state from global Redux reducer.
 * @param state Global Redux state.
 * @param entityName The name of the entity (ie. "users").
 */
const entitiesState = <Entity>(state, entityName) => state.entities[entityName] as State<Entity>;

/**
 * Create Redux selectors for the entity.
 * @param entityName The name of the entity (ie. "users").
 */
function createSelectors<Entity>(entityName: string) {
	return {
		all: state => entitiesState<Entity>(state, entityName).all,
		isLoading: state => entitiesState<Entity>(state, entityName).loadStatus === "pending",
		loadStatus: state => entitiesState<Entity>(state, entityName).loadStatus,
		loadListError: state => entitiesState<Entity>(state, entityName).loadError,
		loadOneStatus: state => entitiesState<Entity>(state, entityName).loadOneStatus,
		loadOneStatus2: (state, key: string) => entitiesState<Entity>(state, entityName).loadOneStatusMap[key],
		isLoadingOne: state => entitiesState<Entity>(state, entityName).loadOneStatus === "pending",
		loadOneError: state => entitiesState<Entity>(state, entityName).loadOneError,
		isUpdating: state => entitiesState<Entity>(state, entityName).updateStatus === "pending",
		updateStatus: state => entitiesState<Entity>(state, entityName).updateStatus,
		updateError: state => entitiesState<Entity>(state, entityName).updateError,
		updateId: state => entitiesState<Entity>(state, entityName).updateId,
		isCreating: state => entitiesState<Entity>(state, entityName).createStatus === "pending",
		createError: state => entitiesState<Entity>(state, entityName).createError,
		isCloning: state => entitiesState<Entity>(state, entityName).cloneStatus === "pending",
		cloneError: state => entitiesState<Entity>(state, entityName).cloneError,
		isArchiving: state => entitiesState<Entity>(state, entityName).archiveStatus === "pending",
		archiveError: state => entitiesState<Entity>(state, entityName).archiveError,
		archiveId: state => entitiesState<Entity>(state, entityName).archiveId,
		isVersioning: state => entitiesState<Entity>(state, entityName).versionStatus === "pending",
		versionError: state => entitiesState<Entity>(state, entityName).versionError,
		isPublishing: state => entitiesState<Entity>(state, entityName).publishStatus === "pending",
		publishError: state => entitiesState<Entity>(state, entityName).publishError,
	};
}

/**
 * Create everything required for storing a new entity in Redux.
 * @param entityName The name of the entity (ie. "users").
 * @param primaryKey The name of the field used to index the entity.
 * @param createActionsOptions Options used to create actions.
 * @param reducerOptions Options used to configure the reducer for this entity
 */
function entity<Entity>(
	entityName: string,
	primaryKey: string,
	createActionsOptions: CreateActionOptions<Entity>,
	reducerOptions?: ReducerOptions
) {
	const actionNames = createActionNames(entityName);

	return {
		actionNames,
		reducer: createReducer<Entity>(actionNames, primaryKey, reducerOptions),
		actions: createActions<Entity>(actionNames, createActionsOptions),
		selectors: createSelectors<Entity>(entityName),
		entityName,
	};
}

export default entity;
