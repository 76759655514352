import { UserWizard } from "reducers/entitiesReducers";
import VatomInc from "utils/VatomIncApi/index";


interface PublishCampaignWizardRequestBody {
	stage?: string
}
export default class VatomIncUserWizards {
	constructor(private varius: typeof VatomInc) {}

	public async listUserWizards(userId: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/u/${userId || this.varius.userId}/wizards/`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("User wizards could not be loaded.");
		}

		return res.body.items;
	}

	public async updateUserWizard(userWizardId: string, body: any) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			path: `/u/${this.varius.userId}/wizards/${userWizardId}`,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("User wizards could not be updated.");
		}

		return res.body;
	}

	public async deleteUserWizard(userWizardId: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.delete({
			path: `/u/${this.varius.userId}/wizards/${userWizardId}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("User wizard could not be deleted.");
		}

		return res.body;
	}

	public async getCampaignWizard(businessId: string, campaignGroupId: string): Promise<UserWizard> {
		const res = await this.varius.get({
			path: `/b/${businessId}/campaign-groups/${campaignGroupId}/wizard`,
			isAuthed: true,
		});

		return res.body;
	}
	
	public async updateCampaignWizard(userWizardId: string, body: any) {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/user-wizards/${userWizardId}`,
			isAuthed: true,
			body,
		});

		return res.body;
	}

	public async createUserWizard(body: any) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			path: `/u/${this.varius.userId}/wizards`,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw Error("User wizards could not be created.");
		}

		return res.body;
	}

	public async publishCampaignWizard(campaignGroupId: string, body: PublishCampaignWizardRequestBody = { stage: "test" }) {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaign-groups/${campaignGroupId}/wizard/publish`,
			isAuthed: true,
			body: body
		});

		return res.body;
	}
}
