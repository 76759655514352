import VatomInc, { RequestService } from "utils/VatomIncApi/index";


export default class VatomIncAddOns {
	constructor(private varius: typeof VatomInc) {}

	public async list( licenseId?: string, billingPeriod?: string,) {

		try {
			let path = `/b/${this.varius.businessId}/addons`
			if (licenseId) {
				path = `${path}?licenseId=${licenseId}`
			}
	
			if (billingPeriod) {
				path = `${path}&billingPeriod=${billingPeriod}`
			}
			const res = await this.varius.get({
				service: RequestService.Billing,
				path,
				isAuthed: true,
			});
			const addonsArray: any[] = []
			res.body.map((addon: any) => {
				if (addon.displayName.toLowerCase() !== "personal") {
					addonsArray.push(addon)
				}
			})
			return addonsArray;
			
		} catch (error) {
			return []
		}
	}

	public async add(entityType: string, type: string, quantity: number) {
		const {businessId} = this.varius
		const res = await this.varius.post({
			service: RequestService.Billing,
			path: `/b/${businessId}/addons`,
			headers:  {"Content-Type": "application/json"},
			isAuthed: true,
			body: {
				entityId: businessId,
				entityType,
				type,
				quantity
			},
		});
		return res;
	}
	public async delete(entityType: string, type: string, quantity: number) {
		const {businessId} = this.varius
		const res = await this.varius.delete({
			service: RequestService.Billing,
			path: `/b/${businessId}/addons`,
			headers:  {"Content-Type": "application/json"},
			isAuthed: true,
			body: {
				entityId: businessId,
				entityType,
				type,
				quantity
			},
		});
		return res;
	}
}
