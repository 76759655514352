import * as React from "react";
import { useAuth } from "auth";
import { useEffect } from "react";
import useQuery from "../../hooks/query";
import PageLoader from "components/App/components/PageLoader";


interface Props {
	oidcClientId? : string	
}

const Login: React.FC<Props> = () => {
	const q = useQuery();
	const auth = useAuth();
	useEffect(() => {
		const p: any = {};

		// NOTE: I think `.entries` is newer, so TS doesn't recognize it.
		for (const [k, v] of Array.from<any>((q as any).entries()))
			if (k.startsWith("secret-")) p[k] = v;

		auth({
			successRedirectTo: q.get("auth-return-to") ?? undefined,
			queryParams: p,
		});
	}, [auth, q]);
	
	return <PageLoader />;
};

export default Login;
