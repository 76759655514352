import {
	AddressForm,
	selectors as billingSelectors,
	FieldError,
	InvoicesList,
} from "reducers/billingReducers";
import VatomInc from "utils/VatomIncApi";
import { selectors as userSelectors } from "reducers/userReducers";
import history from "utils/history";

export type BillingActions =
	| ChangePaymentDetailsAction
	| SubmitPaymentAction
	| ToggleLoadingAction
	| ChangeErrorsListAction
	| ListInvoicesSuccess
	| ListInvoicesError
	| ListInvoicesStart
	| GetInvoiceSuccess
	| GetInvoiceError
	| GetInvoiceStart
	| SetAutomaticInvoiceStart
	| SetAutomaticInvoiceError
	| SetAutomaticInvoiceSuccess
	| StartFetchBillingSource
	| ErrorFetchBillingSource
	| SuccessFetchBillingSource
	| StartPayingInvoice
	| SuccessPayingInvoice
	| ErrorPayingInvoice
	| ErrorPayingInvoice
	| StartDeletingSource
	| SuccessDeletingSource
	| ErrorDeletingSource
	| ToggleBillingDetailsCollapsed;

export enum CommerceActionTypes {
	CHANGE_PAYMENT_DETAILS = "BILLING/CHANGE_PAYMENT_DETAILS",
	TOGGLE_LOADING = "BILLING/TOGGLE_LOADING",
	CHANGE_ERRORS_LIST = "BILLING/CHANGE_ERRORS_LIST",

	START_FETCH_INVOICES_LIST = "BILLING/START_FETCH_INVOICES_LIST",
	SUCCESS_FETCH_INVOICES_LIST = "BILLING/SUCCESS_FETCH_INVOICES_LIST",
	ERROR_FETCH_INVOICES_LIST = "BILLING/ERROR_FETCH_INVOICES_LIST",

	START_FETCH_INVOICE = "BILLING/START_FETCH_INVOICE",
	SUCCESS_FETCH_INVOICE = "BILLING/SUCCESS_FETCH_INVOICE",
	ERROR_FETCH_INVOICE = "BILLING/ERROR_FETCH_INVOICE",

	START_SET_AUTOMATIC_INVOICE = "BILLING/START_SET_AUTOMATIC_INVOICE",
	SUCCESS_SET_AUTOMATIC_INVOICE = "BILLING/SUCCESS_SET_AUTOMATIC_INVOICE",
	ERROR_SET_AUTOMATIC_INVOICE = "ERROR/ERROR_SET_AUTOMATIC_INVOICE",

	START_FETCH_BILLING_SOURCE = "BILLING/START_FETCH_BILLING_SOURCE",
	SUCCESS_FETCH_BILLING_SOURCE = "BILLING/SUCCESS_FETCH_BILLING_SOURCE",
	ERROR_FETCH_BILLING_SOURCE = "BILLING/ERROR_FETCH_BILLING_SOURCE",

	START_PAYING_INVOICE = "BILLING/START_PAYING_INVOICE",
	SUCCESS_PAYING_INVOICE = "BILLING/SUCCESS_PAYING_INVOICE",
	ERROR_PAYING_INVOICE = "BILLING/ERROR_PAYING_INVOICE",

	START_DELETING_SOURCE = "BILLING/START_DELETING_SOURCE",
	SUCCESS_DELETING_SOURCE = "BILLING/SUCCESS_DELETING_SOURCE",
	ERROR_DELETING_SOURCE = "BILLING/ERROR_DELETING_SOURCE",

	TOGGLE_BILLING_DETAILS_COLLAPSED = "TOGGLE_BILLING_DETAILS_COLLAPSED",
}

const actions = {
	changePaymentDetails: (field: keyof AddressForm, value: string) => (dispatch, getState) => {
		const form = billingSelectors.getAddressForm(getState());
		dispatch({
			type: CommerceActionTypes.CHANGE_PAYMENT_DETAILS,
			data: {
				...form,
				...{ [field]: value },
			},
		});
	},
	toggleBillingDetails: (collapsed: boolean) => dispatch => {
		dispatch({
			type: CommerceActionTypes.TOGGLE_BILLING_DETAILS_COLLAPSED,
			data: collapsed,
		});
	},
	payInvoice: (invoiceId: string) => async (dispatch, getState) => {
		try {
			dispatch({
				type: CommerceActionTypes.START_PAYING_INVOICE,
			});
			await VatomInc.billing.checkout(invoiceId);
			dispatch({
				type: CommerceActionTypes.SUCCESS_PAYING_INVOICE,
				data: {
					paidInvoice: invoiceId,
				},
			});
		} catch (err:any) {
			dispatch({
				type: CommerceActionTypes.ERROR_PAYING_INVOICE,
				data: err.message || err,
			});
		}
	},
	/**
	 * Creates stripe token and calls API with this token to complete payment
	 * Validates only form. Card validation is implemented in react-stripe-elements
	 * For now - just ensure there're no empty inputs
	 * @todo: implement some advanced validation with (possibly) optional fields
	 */
	submitPayment: (stripe: any, cardElement: any, businessId: string) => async (dispatch, getState) => {
		try {
			// show loadingIndicator and disable button
			dispatch({
				type: CommerceActionTypes.TOGGLE_LOADING,
				data: {
					isLoading: true,
				},
			});
			const checkoutFormState = billingSelectors.getCheckoutForm(getState());
			const errors: FieldError[] = [];
			Object.keys(checkoutFormState.form).forEach((key: string) => {
				if (checkoutFormState.form[key].length === 0) {
					errors.push({
						field: key as keyof AddressForm,
						errorText: `Required Field`,
					});
				}
			});
			dispatch({
				type: CommerceActionTypes.CHANGE_ERRORS_LIST,
				data: {
					errors: errors.length > 0 ? errors : [],
				},
			});
			if (errors.length > 0) {
				return;
			}
			const { name, country, zip } = checkoutFormState.form;
			// await stripe token creating
			const { token, error } = await stripe.createToken(cardElement, {
				name,
				address_zip: zip,
				address_country: country,
			}
			);
			if (error) {
				dispatch({
					type: CommerceActionTypes.CHANGE_ERRORS_LIST,
					data: {
						errors: [
							...errors,
							...[{ field: "card", errorText: error.message || "Card validation failed" }],
						],
					},
				});
				return;
			}
			await VatomInc.billing.sendToken(token!.id);
			// dispatch(actions.getDefaultAccountInfo(true));
			// hack: currently redirects to account after updating credit card because stripe cant find cardelement if user tries to change credit card again
		
			// Added This timeput to have time to display toast messages
			setTimeout(()=> history.replace(`/${businessId}/account/billing`) , 3000)
			
		} finally {
			dispatch({
				type: CommerceActionTypes.TOGGLE_LOADING,
				data: {
					isLoading: false,
				},
			});
		}
	},
	listInvoices: (businessName: string) => async (dispatch, getState) => {
		try {
			// show loading indicator
			dispatch({
				type: CommerceActionTypes.START_FETCH_INVOICES_LIST,
			});
			// get businessId by businessName
			const businessId: string = userSelectors.businessNameMap(getState())![businessName];
			const res = await VatomInc.invoices.getInvoicesByBusiness(businessId);
			dispatch({
				type: CommerceActionTypes.SUCCESS_FETCH_INVOICES_LIST,
				data: res,
			});
		} catch (err:any) {
			dispatch({
				type: CommerceActionTypes.ERROR_FETCH_INVOICES_LIST,
			});
		}
	},
	loadMore: (businessName?: string, lastInvoice?: string) => async (dispatch, getState) => {
		const currentBusiness = userSelectors.currentBusiness(getState());
		const allInvoices = billingSelectors.allInvoices(getState());

		const currentBusinessId = currentBusiness!.businessId;
		const lastInvoiceId = allInvoices!.lastInvoiceId;

		try {
			// show loading indicator
			dispatch({
				type: CommerceActionTypes.START_FETCH_INVOICES_LIST,
			});
			// get invoices by businessName and last invoice id in paged list
			const res = await VatomInc.invoices.getInvoicesByBusiness(
				currentBusinessId,
				lastInvoiceId
			);

			const newItems = {};
			Object.keys(allInvoices.items).forEach((item, index) => {
				newItems[item] = [allInvoices.items[item], res.items[item]].reduce(
					(a, b) => [...a, ...b],
					[]
				);
			});
			const newRes = {
				...res,
				items: newItems,
			};

			dispatch({
				type: CommerceActionTypes.SUCCESS_FETCH_INVOICES_LIST,
				data: newRes,
			});
		} catch (err:any) {
			console.log(err);
			dispatch({
				type: CommerceActionTypes.ERROR_FETCH_INVOICES_LIST,
			});
		}
	},
	getInvoice: (businessName: string, invoiceId: string) => async (dispatch, getState) => {
		try {
			// show loading indicator
			dispatch({
				type: CommerceActionTypes.START_FETCH_INVOICE,
			});
			// get businessId by businessName
			const businessId: string = userSelectors.businessNameMap(getState())![businessName];
			const res = await VatomInc.invoices.getSingleInvoice(businessId, invoiceId);
			dispatch({
				type: CommerceActionTypes.SUCCESS_FETCH_INVOICE,
				data: res,
			});
		} catch (err:any) {
			dispatch({
				type: CommerceActionTypes.ERROR_FETCH_INVOICE,
			});
		}
	},
	setAutomaticInvoice: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: CommerceActionTypes.START_SET_AUTOMATIC_INVOICE,
			});
			const res = await VatomInc.billing.setAutoPayInvoices({
				chargeAutomatically: !billingSelectors.autoPayInvoices(getState()),
			});
			dispatch({
				type: CommerceActionTypes.SUCCESS_SET_AUTOMATIC_INVOICE,
				data: res,
			});
		} catch (err:any) {
			dispatch({
				type: CommerceActionTypes.ERROR_SET_AUTOMATIC_INVOICE,
				data: err.message || "Could not set automatic invoices payment",
			});
		}
	},
	// getDefaultAccountInfo: (collapseDetailsOnComplete = false) => async dispatch => {
	// 	try {
	// 		dispatch({
	// 			type: CommerceActionTypes.START_FETCH_BILLING_SOURCE,
	// 		});

	// 		const source = await VatomInc.account.fetchAccountInfo()
	// 		dispatch({
	// 			type: CommerceActionTypes.SUCCESS_FETCH_BILLING_SOURCE,
	// 			data: source,
	// 		});
	// 		if (!source.source) {
	// 			dispatch({ type: CommerceActionTypes.TOGGLE_BILLING_DETAILS_COLLAPSED, data: false });
	// 		} else {
	// 			dispatch({ type: CommerceActionTypes.TOGGLE_BILLING_DETAILS_COLLAPSED, data: true });
	// 		}
	// 	} catch (err:any) {
	// 		dispatch({
	// 			type: CommerceActionTypes.ERROR_FETCH_BILLING_SOURCE,
	// 			data: err.message || err,
	// 		});
	// 	}
	// },

	deleteDefaultBillingSource: () => async dispatch => {
		try {
			dispatch({
				type: CommerceActionTypes.START_DELETING_SOURCE,
			});

			const source = await VatomInc.billing.deleteDefaultBillingSource();

			dispatch({
				type: CommerceActionTypes.SUCCESS_DELETING_SOURCE,
				data: source,
			});
		} catch (err:any) {
			dispatch({
				type: CommerceActionTypes.ERROR_DELETING_SOURCE,
				data: err.message || err,
			});
		}
	},
};

interface Action<Type> {
	type: Type;
}

// updating payment details
export interface ChangePaymentDetailsAction
	extends Action<CommerceActionTypes.CHANGE_PAYMENT_DETAILS> {
	data: AddressForm;
}

export interface SubmitPaymentAction extends Action<CommerceActionTypes.CHANGE_PAYMENT_DETAILS> {
	data: any;
}

export interface ChangeErrorsListAction extends Action<CommerceActionTypes.CHANGE_ERRORS_LIST> {
	data: FieldError[];
}

export interface ToggleLoadingAction extends Action<CommerceActionTypes.TOGGLE_LOADING> {
	data: {
		isLoading: boolean;
	};
}

// listing invoices
export interface ListInvoicesStart extends Action<CommerceActionTypes.START_FETCH_INVOICES_LIST> {
	data: any;
}

export interface ListInvoicesSuccess
	extends Action<CommerceActionTypes.SUCCESS_FETCH_INVOICES_LIST> {
	data: InvoicesList;
}

export interface ListInvoicesError extends Action<CommerceActionTypes.ERROR_FETCH_INVOICES_LIST> {
	data: any;
}

// get single invoice
export interface GetInvoiceStart extends Action<CommerceActionTypes.START_FETCH_INVOICE> {
	data: any;
}

export interface GetInvoiceSuccess extends Action<CommerceActionTypes.SUCCESS_FETCH_INVOICE> {
	data: any;
}

export interface GetInvoiceError extends Action<CommerceActionTypes.ERROR_FETCH_INVOICE> {
	data: any;
}

// Paying Invoices
export interface StartPayingInvoice extends Action<CommerceActionTypes.START_PAYING_INVOICE> {
	data: any;
}

export interface SuccessPayingInvoice extends Action<CommerceActionTypes.SUCCESS_PAYING_INVOICE> {
	data: any;
}

export interface ErrorPayingInvoice extends Action<CommerceActionTypes.ERROR_PAYING_INVOICE> {
	data: any;
}

// Toggling billing charge type
export interface SetAutomaticInvoiceStart
	extends Action<CommerceActionTypes.START_SET_AUTOMATIC_INVOICE> {
	data: any;
}

export interface SetAutomaticInvoiceSuccess
	extends Action<CommerceActionTypes.SUCCESS_SET_AUTOMATIC_INVOICE> {
	data: any;
}

export interface SetAutomaticInvoiceError
	extends Action<CommerceActionTypes.ERROR_SET_AUTOMATIC_INVOICE> {
	data: any;
}

// Getting source
export interface StartFetchBillingSource
	extends Action<CommerceActionTypes.START_FETCH_BILLING_SOURCE> {
	data: any;
}

export interface SuccessFetchBillingSource
	extends Action<CommerceActionTypes.SUCCESS_FETCH_BILLING_SOURCE> {
	data: any;
}

export interface ErrorFetchBillingSource
	extends Action<CommerceActionTypes.ERROR_FETCH_BILLING_SOURCE> {
	data: any;
}

export interface StartPayingInvoice extends Action<CommerceActionTypes.START_PAYING_INVOICE> {
	data: any;
}

export interface SuccessPayingInvoice extends Action<CommerceActionTypes.SUCCESS_PAYING_INVOICE> {
	data: any;
}

export interface ErrorPayingInvoice extends Action<CommerceActionTypes.ERROR_PAYING_INVOICE> {
	data: any;
}

export interface ToggleBillingDetailsCollapsed
	extends Action<CommerceActionTypes.TOGGLE_BILLING_DETAILS_COLLAPSED> {
	data: any;
}

// deleting source
export interface StartDeletingSource extends Action<CommerceActionTypes.START_DELETING_SOURCE> {
	data: any;
}

export interface SuccessDeletingSource extends Action<CommerceActionTypes.SUCCESS_DELETING_SOURCE> {
	data: any;
}

export interface ErrorDeletingSource extends Action<CommerceActionTypes.ERROR_DELETING_SOURCE> {
	data: any;
}

export default actions;
