import * as React from "react";
import useSocketEvent from "hooks/useSocketEvent";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useCurrentBusinessName } from "reducers/userReducers";
import campaignActions from "actions/campaignsActions";
import { useHistory } from "react-router-dom";
import entitiesReducers, { Campaign } from "reducers/entitiesReducers";
import styled from "@emotion/styled";

export enum EventType {
	UpdateObjectDefinition = "update-object-definition",
	UpdateCampaign = "update-campaign",
	ChangeCampaignStageSuccess = "change-campaign-stage-success",
	ChangeCampaignStageError = "change-campaign-stage-error",
	UpdatePublishedObjectDefinitionSuccess = "update-published-object-definition-success",
	UpdatePublishedObjectDefinitionError = "update-published-object-definition-error",
	UpdateWizard = "update-wizard",
	ExportCampaignUsersSuccess = "export-campaign-users-success",
	ExportCampaignUsersError = "export-campaign-users-error",
	ExportPointsTransactionLogsSuccess = "export-points-transaction-logs-success",
	ExportPointsTransactionLogsError = "export-points-transaction-logs-error",
	ExportFormDataSuccess = "export-form-data-success",
	ExportFormDataError = "export-form-data-error",
}

export interface SocketEvent {
	eventType: EventType;
	eventUri: string;
	payload: any;
}

const StyledLink = styled<any>("span")`
	cursor: pointer;
	text-decoration: underline;
`;

const SocketEvents = () => {
	const dispatch = useDispatch();
	const businessName = useCurrentBusinessName();
	const history = useHistory();

	useSocketEvent(EventType.ChangeCampaignStageError, payload => {
		const campaign: Campaign = payload;
		const campaignUri = `/${businessName}/campaigns/${
			campaign.groupCampaignId ? campaign.groupCampaignId : campaign.id
		}`;
		if (payload.error !== undefined && payload.error.body !== undefined) {
			const body = JSON.parse(payload.error.body);

			if (body.code === "max_subscriptions") {
				toast.error(
					<>
						Sorry, we couldn't Go Live with your Campaign. We currently allow 25 live Campaigns per
						business. Please Archive your past campaigns and try again.
					</>
				);

				dispatch({
					type: entitiesReducers.campaigns.actionNames.update.success,
					data: {
						item: payload,
						merge: true,
					},
				});

				return;
			}
		}

		const STAGE_MESSAGE = {
			published: "be published",
			draft: "exit test mode",
			test: "enter test mode",
		};

		const message = STAGE_MESSAGE[campaign.attemptedFutureStage];

		toast.error(
			<>
				Something went wrong, and your campaign could not {message}.{" "}
				<StyledLink onClick={() => history.push(campaignUri)}>Click here</StyledLink> to review the
				campaign.
			</>
		);

		dispatch({
			type: entitiesReducers.campaigns.actionNames.update.success,
			data: {
				item: payload,
				merge: true,
			},
		});
	});

	useSocketEvent(EventType.ChangeCampaignStageSuccess, payload => {
		const campaign: Campaign = payload;
		const campaignUri = `/${businessName}/campaigns/${
			campaign.groupCampaignId ? campaign.groupCampaignId : campaign.id
		}`;

		const STAGE_MESSAGE = {
			published: "Your campaign has been published!",
			draft: "Your campaign has exited test mode!",
			test: "Your campaign has entered test mode!",
		};

		const message = STAGE_MESSAGE[campaign.stage];

		toast.success(
			<>
				{message} <StyledLink onClick={() => history.push(campaignUri)}>Click here</StyledLink> to
				review the campaign.
			</>
		);

		dispatch({
			type: entitiesReducers.campaigns.actionNames.update.success,
			data: {
				item: payload,
				merge: true,
			},
		});

		// We update our redux state which will refresh the page
		if (payload.groupCampaignId) {
			dispatch(campaignActions.loadCampaignData(payload.groupCampaignId));
		}
	});

	useSocketEvent(EventType.UpdatePublishedObjectDefinitionSuccess, () => {
		toast.success(<>Edited published object definition successfully.</>);
	});

	useSocketEvent(EventType.UpdatePublishedObjectDefinitionError, () => {
		toast.error(<>Could not edit published object definition.</>);
	});

	useSocketEvent(EventType.UpdateWizard, payload => {
		dispatch({
			type: entitiesReducers.userWizards.actionNames.update.success,
			data: {
				item: payload,
				merge: true,
			},
		});
	});

	useSocketEvent(EventType.ExportCampaignUsersSuccess, payload => {
		const { url } = payload;

		toast.success(
			<>
				Campaign users successfully exported.
				<a href={url} target="_blank" rel="noopener noreferrer">
					Click here
				</a>{" "}
				to open the file.
			</>
		);
	});

	useSocketEvent(EventType.ExportCampaignUsersError, () => {
		toast.error(<>Could not export campaign users.</>);
	});

	useSocketEvent(EventType.ExportPointsTransactionLogsSuccess, payload => {
		const { url } = payload;

		toast.success(
			<>
				Points transaction logs successfully exported.
				<a href={url} target="_blank" rel="noopener noreferrer">
					Click here
				</a>{" "}
				to open the file.
			</>
		);
	});

	useSocketEvent(EventType.ExportPointsTransactionLogsError, () => {
		toast.error(<>Could not export points transaction logs.</>);
	});

	useSocketEvent(EventType.ExportFormDataSuccess, payload => {
		const { url } = payload;

		toast.success(
			<>
				User's form data successfully exported.
				<a href={url} target="_blank" rel="noopener noreferrer">
					Click here
				</a>{" "}
				to open the file.
			</>
		);
	});

	useSocketEvent(EventType.ExportFormDataError, () => {
		toast.error(<>Could not export user's form data.</>);
	});

	return null;
};

export default SocketEvents;
