import VatomInc, { RequestService } from "./index";
import { Provider, Service } from "reducers/entitiesReducers";

export interface CouponPoolStats {
	total: number;
	assigned: number;
	available: number;
	campaigns: number;
}

export interface CouponPool {
	id: string;
	name: string;
	isArchived: boolean;
	stats: CouponPoolStats;
}

export default class VatomIncProviders {
	constructor(private varius: typeof VatomInc) {}

	public async createProvider(
		typeId: string,
		serviceId: string,
		data: Partial<Provider>
	): Promise<Provider> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/types/${typeId}/services/${serviceId.split(":")[0]}/providers`,
			isAuthed: true,
			service: RequestService.Providers,
			body: {
				...data,
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Provider could not be created");
		}

		return res.body;
	}

	public async updateProvider(
		typeId: string,
		serviceId: string,
		providerId: string,
		data: Partial<Provider>
	): Promise<Provider> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/types/${typeId}/services/${serviceId}/providers/${providerId}`,
			isAuthed: true,
			service: RequestService.Providers,
			body: {
				...data,
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Provider could not be created");
		}

		return res.body;
	}

	public async listProviders(): Promise<Provider[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/providers`,
			isAuthed: true,
			service: RequestService.Providers,
		});

		return res.body;
	}

	public async deleteProvider(providerId: string): Promise<Provider> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/providers/${providerId}`,
			isAuthed: true,
			service: RequestService.Providers,
		});

		return res.body;
	}

	public async listServices(): Promise<Service[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/services`,
			isAuthed: true,
			service: RequestService.Providers,
		});

		// Create a composite key from service id and type
		return res.body.map(service => {
			return {
				...service,
				serviceId: service.id,
				id: `${service.id}:${service.type}` // Not sure why this is needed
			}
		});
	}
	
	public async listCouponPools(): Promise<CouponPool[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/coupon-pools`,
			isAuthed: true,
			service: RequestService.Coupons,
		});

		return res.body.items;
	}
	
	public async getCouponPoolStatsCsv(couponPoolId: string): Promise<string> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/coupon-pools/${couponPoolId}/stats-csv`,
			isAuthed: true,
			service: RequestService.Coupons,
		});

		return res.body;
	}
	
	public async updateCouponPool(couponPoolId: string, body: any): Promise<any> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/coupon-pools/${couponPoolId}`,
			isAuthed: true,
			service: RequestService.Coupons,
			body,
		});

		return res.body;
	}
	
	public async resetCouponPool(couponPoolId: string): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/coupon-pools/${couponPoolId}/reset`,
			isAuthed: true,
			service: RequestService.Coupons,
		});
		
		return res.body;
	}
	
	public async createCouponPool(name: string, url: string | undefined, coupons: any[]): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/coupon-pools`,
			isAuthed: true,
			service: RequestService.Coupons,
			body: {
				name,
				url,
				coupons,
			},
		});
		
		return res.body;
	}
}
