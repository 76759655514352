
import * as React from "react";
import { useAuth, InteractionType } from "auth";
import { useEffect } from "react";
import useQuery from "../../hooks/query";
import PageLoader from "components/App/components/PageLoader";

const Register: React.FC = () => {
	const query = useQuery();
	const auth = useAuth();

	useEffect(() => {
		auth({
			successRedirectTo: query.get("auth-return-to") ?? undefined,
			queryParams: {
				"interaction-type": InteractionType.Register,
			},
		});
	}, [auth, query]);

	return <PageLoader />;
};

export default Register;
