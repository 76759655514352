import VatomInc from "./index";
import { Blueprint } from "reducers/entitiesReducers";

export default class VatomIncBlueprints {
	constructor(public varius: typeof VatomInc) {}

	public async listBlueprints(): Promise<Blueprint[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/blueprints`,
			isAuthed: true,
		});

		// todo: check `status` in res
		return res.body.items;
	}

	public async getBlueprint(blueprintId: string): Promise<Blueprint> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/blueprints/${blueprintId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Blueprint could not be loaded.");
		}

		// todo: check `status` in res
		return res.body;
	}
}
