import VatomInc, { RequestService } from "./index";
import { Campaign, PrizeSet } from "reducers/entitiesReducers";
import { CampaignEntity, Trigger } from "reducers/entitiesReducers";
import queryString from "querystring";


interface ExportUserFilters{
	termsAccepted: string[];	
}

export default class VatomIncCampaigns {
	constructor(private varius: typeof VatomInc) {}

	public async listCampaigns(): Promise<Campaign[]> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaigns`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaigns could not be retrieved.");
		}

		// todo: check `status` in res
		return res.body.items;
	}

	public async getCampaign(campaignId: string): Promise<Campaign> {
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign could not be loaded.");
		}

		// todo: check `status` in res
		return res.body;
	}

	public async updateCampaign(campaignId: string, body: any): Promise<Campaign> {
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}`,
			isAuthed: true,
			body,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign could not be updated.");
		}

		return res.body;
	}

	public async archiveCampaign(campaignId: string): Promise<Campaign> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign could not be archived.");
		}

		// todo: check `status` in res
		return res.body;
	}

	public async cloneCampaign(campaignId: string): Promise<Campaign> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/clone`,
			isAuthed: true,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign could not be cloned.");
		}

		// todo: check `status` in res
		return res.body;
	}

	public async createCampaign(data: any): Promise<Campaign> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/`,
			isAuthed: true,
			body: {
				...data,
				businessId: this.varius.businessId,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign could not be created.");
		}

		return res.body;
	}

	public async addViewers(campaignId: string, viewerIds: string[]): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/viewers`,
			body: {
				viewerIds,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign viewers could not be updated.");
		}

		return {};
	}

	public async addObjectDefinitions(
		campaignId: string,
		objectDefinitionIds: string[]
	): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/object-definitions`,
			body: {
				objectDefinitionIds,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign objects could not be added.");
		}

		return {};
	}

	public async addProvider(campaignId: string, providerId: string): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/providers`,
			body: {
				providerId,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign provider could not be added.");
		}

		return {};
	}

	public async addUser(campaignId: string, userId: string): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/users`,
			body: {
				userId,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign user could not be added.");
		}

		return {};
	}
	public async addSpace(campaignId: string, spaceId: string): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/spaces`,
			body: {
				spaceId,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign space could not be added.");
		}

		return {};
	}

	public async removeViewer(campaignId: string, viewerId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/viewers/${viewerId}`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign viewer could not be removed.");
		}

		return {};
	}

	public async removeObjectDefinition(
		campaignId: string,
		objectDefinitionId: string
	): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/object-definitions/${objectDefinitionId}`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign object definition could not be removed.");
		}

		return {};
	}

	public async removeProvider(campaignId: string, providerId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/providers/${providerId}`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign provider could not be removed.");
		}

		return {};
	}

	public async removeUser(campaignId: string, userId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/users/${userId}`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign user could not be removed.");
		}

		return {};
	}
	public async removeSpace(campaignId: string, spaceId: string): Promise<any> {
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/spaces/${spaceId}`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign user could not be removed.");
		}

		return {};
	}

	public async sendCampaignObject(
		campaignId: string,
		objectDefinitionId: string,
		data: {}
	): Promise<any> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/object-definitions/${objectDefinitionId}/send-objects`,
			body: {
				...data,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Campaign object could not be sent.");
		}

		return {};
	}

	public async createPrizeSet(data: {}): Promise<PrizeSet> {
		const res = await this.varius.post({
			service: RequestService.Prizes,
			path: `/b/${this.varius.businessId}/prize-sets`,
			body: {
				...data,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Prize set could not be created.");
		}

		return res.body;
	}

	public async listPrizeSets(campaignId: string): Promise<PrizeSet[]> {
		const res = await this.varius.get({
			service: RequestService.Prizes,
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/prize-sets`,
		});

		if (res.statusCode !== 200) {
			throw new Error("Prize sets could not be retrieved.");
		}

		return res.body.items;
	}

	public async updatePrizeSet(campaignId: string, prizeSetId: string, data: {}): Promise<PrizeSet> {
		const res = await this.varius.patch({
			service: RequestService.Prizes,
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/prize-sets/${prizeSetId}`,
			body: {
				...data,
			},
		});

		if (res.statusCode !== 200) {
			throw new Error("Prize set could not be updated.");
		}

		return res.body;
	}

	public async listTriggers(source: CampaignEntity, campaignId: string): Promise<Trigger[]> {
		const queryStringParams = queryString.stringify({
			"source-type": source.type,
			"source-id": source.id,
		});

		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/triggers?${queryStringParams}`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body.items;
	}

	public async versionCampaign(campaignId: string): Promise<Campaign> {
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/revisions`,
		});

		return res.body;
	}


	public async exportUsers(campaignId: string,  filters?: ExportUserFilters, startDate?: string, endDate?: string): Promise<number> {
		let body = {}
		if (startDate) {
			body['startDate'] = startDate
		}
		if (startDate && endDate) {
			body['endDate'] = endDate
		}
		if (filters?.termsAccepted.length) {
			body['filters'] = filters
		}
		
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/export-users`,
			isAuthed: true,
			body
		});

		if (res.statusCode !== 204) {
			throw new Error("Campaign Users could not be exported.");
		}

		return res.statusCode;
	}

	public async exportUserPointsTransactions(campaignId: string, startDate?: string, endDate?: string): Promise<number> {
		let body = {}
		if (startDate) {
			body['startDate'] = startDate
		}
		if (startDate && endDate) {
			body['endDate'] = endDate
		}
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/export-points-txns`,
			isAuthed: true,
			body: body
		});

		if (res.statusCode !== 204) {
			throw new Error("User Points Transaction Logs could not be exported.");
		}

		return res.statusCode;
	}

	public async exportUserFormData(campaignId: string, startDate?: string, endDate?: string): Promise<number> {
		let body = {}
		if (startDate) {
			body['startDate'] = startDate
		}
		if (startDate && endDate) {
			body['endDate'] = endDate
		}
		
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/campaigns/${campaignId}/export-forms`,
			isAuthed: true,
			body: body,
		});

		if (res.statusCode !== 204) {
			throw new Error("User Form Data could not be exported.");
		}

		return res.statusCode;
	}
}
