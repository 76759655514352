import VatomInc, { RequestService } from "./index";
import { DistributionStage } from "reducers/entitiesReducers";
export interface Distribution {
	id: string;
	type: string;
	displayName: string;
	objectDefinitionId: string;
	createdAt: string;
	stage: DistributionStage;

	sentCount: number;
	openedCount: number;
	clickedCount: number;
	claimedCount: number;
}

export interface DistributionsWithPagination {
	items: Distribution[],
	cursor?: string;
}

export interface DistributionToCreate {
	type: string;
	displayName: string;
	groupCampaignId: string;
	campaignId: string;
	campaignStage: string;
	contactListId: string;
	campaignIds: string[];
	objectDefinitionIds: string[];
	recipientTokenType: string;
	recipientTokenValue: string;
	quantity: number;
	locations?: any[];
	center?: any[];
	radius?: number;
	features?: any;
	images?: any[];
	cleanupDate?: string;
	isRedropEnabled?: boolean;
	maxRefillQuantity?: number;
	redropInterval?: number;
	scheduledDate?: string;
	price?: number
	listingType?: string
	minimumBid?: number
	reservePrice?: number
	bidIncrement?: number
	startDate?: string
	startDateTimezone?: string
	endDate?: string
	endDateTimezone?: string
	acceptedPayments: string
	physicalItem?:boolean
	physicalItemDescription?:string
	physicalItemContact?:string
	isPrivate?: boolean
	segmentId?: string
}
export default class VatomIncDistributions {
	constructor(private varius: typeof VatomInc) { }

	public async updateDistribution(id: string, data: any, type: string): Promise<Distribution> {
		let res;

		if (type === "image-recognition") {
			res = await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/image-distributions/${id}`,
				isAuthed: true,
				body: {
					...data,
				},
			});
		}

		if (type === "map") {
			res = await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/distributions/${id}`,
				isAuthed: true,
				body: data,
			});
		}

		if (type === "marketplace") {
			res = await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/distributions/${id}`,
				isAuthed: true,
				body: data,
			});
		}
		
		return res.body;
	}

	public async list(groupCampaignId: string): Promise<Distribution[]> {
		const res = await this.varius.get({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/distributions`,
			isAuthed: true,
		});

		return res.body.items.map(item => ({
			id: item.id,
			type: item.type,
			displayName: item.displayName,
			objectDefinitionId: item.objectDefinitionId,
			createdAt: item.createdAt,
			sentCount: item.sentCount,
			openedCount: item.openedCount,
			clickedCount: item.clickedCount,
			claimedCount: item.claimedCount,
			stage: item.stage,
			stats: item.stats,
		}));
	}

	// We need another service since we return completely different things.
	public async listWithPagination(groupCampaignId: string, startKey = ""): Promise<DistributionsWithPagination> {
		
		const res = await this.varius.get({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/campaign-groups/${groupCampaignId}/distributions?startKey=${startKey}`,
			isAuthed: true,
		});

		return {
			items: res.body.items.map(item => ({
				id: item.id,
				type: item.type,
				displayName: item.displayName,
				objectDefinitionId: item.objectDefinitionId,
				createdAt: item.createdAt,
				sentCount: item.sentCount,
				openedCount: item.openedCount,
				clickedCount: item.clickedCount,
				claimedCount: item.claimedCount,
				stage: item.stage,
				stats: item.stats,
				endDate: item?.endDate,
				endDateTimezone: item?.endDateTimezone,
				isActive: item?.isActive
				})),
			cursor: res.body?.cursor
		}
	}

	public async createDistribution(item: DistributionToCreate): Promise<Distribution> {

		const reqBody:any = {
			type: item.type,
			displayName: item.displayName,
			campaignId: item.campaignId,
			campaignStage: item.campaignStage,
			objectDefinitionId: item.objectDefinitionIds[0],
		};

		if (item.type === "map") {
			const {
				quantity,
				isRedropEnabled,
				maxRefillQuantity,
				redropInterval,
				locations,
				center,
				radius,
				features,
				cleanupDate,
				scheduledDate,
			} = item;
			
			reqBody.map = {
				quantity,
				locations,
				center,
				radius,
				features,
				cleanupDate,
				redrop: isRedropEnabled ? {
					maxQuantity: maxRefillQuantity,
					interval: {
						value: redropInterval,
						unit: "sec",
					},
				} : undefined,
			};
			
			reqBody.scheduledDate = scheduledDate;
		}
		if (item.type === "segment") {
			reqBody.segment = Object.assign({},
					{
						quantity: item.quantity || 1
					},
					{
						groupCampaignId:item.groupCampaignId
					},
					{
						objectDefinitionId: item.objectDefinitionIds[0]
					},
					{
						campaignId: item.campaignId
					},
					{
						segmentId: item.segmentId
					}
			)
			reqBody.campaignId = item.campaignId
		}

		const res = await this.varius.post({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/campaign-groups/${item.groupCampaignId}/distributions`,
			isAuthed: true,
			body: reqBody,
		});


		const body = res.body;

		if (item.type === "contact") {
			await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/contact-distributions/${res.body.id}`,
				isAuthed: true,
				body: {
					objectDefinitionId: item.objectDefinitionIds[0],
					contactListId: item.contactListId,
				},
			});

			await this.varius.post({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/contact-distributions/${res.body.id}/send`,
				isAuthed: true,
			});
		} else if (item.type === "direct") {
			await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/direct-distributions/${res.body.id}`,
				body: {
					objectDefinitionId: item.objectDefinitionIds[0],
					recipientToken: {
						type: item.recipientTokenType,
						value: item.recipientTokenValue,
					},
					quantity: item.quantity,
				},
			});

			await this.varius.post({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/direct-distributions/${res.body.id}/send`,
				isAuthed: true,
			});
		} else if (item.type === "campaign") {
			await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/campaign-distributions/${res.body.id}`,
				isAuthed: true,
				body: {
					objectDefinitionIds: item.objectDefinitionIds,
					sourceCampaignIds: item.campaignIds,
				},
			});

			await this.varius.post({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/campaign-distributions/${res.body.id}/send`,
				isAuthed: true,
			});
		}
		else if (item.type === "segment") {
			await this.varius.post({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/segment-distributions/${res.body.id}/send`,
				isAuthed: true,
				body: reqBody,
			});
		} else if (item.type === "image-recognition") {
			await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/image-distributions/${res.body.id}`,
				isAuthed: true,
				body: {
					objectDefinitionId: item.objectDefinitionIds[0],
					images: item.images,
					pvid: "",
				},
			});
		} else if (item.type === "marketplace") {
			await this.varius.patch({
				service: RequestService.Distribution,
				path: `/b/${this.varius.businessId}/distributions/${res.body.id}`,
				isAuthed: true,
				body: {
					// objectDefinitionId: item.objectDefinitionIds[0],
					marketplace: {
						listingType: item.listingType,
						quantity: item.quantity,
						price: item.price,
						minimumBid: item.minimumBid,
						reservePrice: item.reservePrice,
						bidIncrement: item.bidIncrement,
						startDate: item.startDate,
						startDateTimezone: item.startDateTimezone,
						endDate: item.endDate,
						endDateTimezone: item.endDateTimezone,
						acceptedPayments: item.acceptedPayments,
						physicalItem: item.physicalItem,
						physicalItemDescription: item.physicalItemDescription,
						physicalItemContact: item.physicalItemContact,
						isPrivate: item.isPrivate,
					}
				},
			});
		}

		return body;
	}

	public async get(campaignGroupId: string, id: string): Promise<Distribution> {
		const res = await this.varius.get({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/campaign-groups/${campaignGroupId}/distributions/${id}`,
			isAuthed: true,
		});
		
		return res.body;
	}
	
	public async geoDiscover(distributionId: string): Promise<Distribution> {
		const res = await this.varius.get({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/map-distributions/${distributionId}/discover`,
			isAuthed: true,
		});
		
		return res.body;
	}
	
	public async cleanup(distributionId: string): Promise<Distribution> {
		const res = await this.varius.post({
			service: RequestService.Distribution,
			path: `/b/${this.varius.businessId}/distributions/${distributionId}/cleanup`,
			isAuthed: true,
		});
		
		return res.body;
	}
}
