import * as React from "react";
import { createContext, useState, useEffect, useContext } from "react";
import Oidc, { UserManager, WebStorageStateStore } from "oidc-client";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

import { strict as assert } from "assert";
import { assertExhaustive } from "utils/error";
import VatomInc from "utils/VatomIncApi";
import { UserProfile } from "utils/VatomIncApi/users";

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.ERROR;

const {
	REACT_APP_OIDC_AUTHORITY,
	REACT_APP_OIDC_CLIENT_ID,
	REACT_APP_OIDC_REDIRECT_URI,
	REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
} = process.env;
class SilentRenewService {
    _userManager: any;
    _callback: any;

    constructor(userManager: UserManager) {
        this._userManager = userManager;
    }

    start() {
        if (!this._callback) {
            this._callback = this._tokenExpiring.bind(this);
            this._userManager.events.addAccessTokenExpiring(this._callback);

            // this will trigger loading of the user so the expiring events can be initialized
            this._userManager.getUser().then(() => {
                // deliberate nop
            }).catch((err: Error) => {
                // catch to suppress errors since we're in a ctor
                Oidc.Log.error("SW.SilentRenewService.start: Error from getUser:", err.message);
            });
        }
    }

    stop() {
        if (this._callback) {
            this._userManager.events.removeAccessTokenExpiring(this._callback);
            delete this._callback;
        }
    }

    get renewKey() {
        return `oidc.renew:${this._userManager.settings.authority}:${this._userManager.settings.client_id}`;
    }

    setIsSilentRenewing() {
        window.localStorage.setItem(this.renewKey, new Date().getTime().toString())
    }

    clearIsSilentRenewing() {
        window.localStorage.removeItem(this.renewKey)
    }

    checkIfSilentRenewing() {
        const renewTime = parseInt(window.localStorage.getItem(this.renewKey) || '0', 10)
        Oidc.Log.debug("Last renew time: ", renewTime, new Date(renewTime))
        if (new Date().getTime() > (renewTime + 30000)) {
            Oidc.Log.debug("Renew time was more than 30s ago, clearing")
            this.clearIsSilentRenewing()
            return false
        }
        Oidc.Log.debug("Some other process is renewing the token")
        return true
    }

    waitIfAlreadyRenewing() {
        
        var _this = this;
        return new Promise<void>((resolve, reject) => {

            function check() {
                return new Promise<void>((resolve, reject) => {
                    if (_this.checkIfSilentRenewing()) {
                        Oidc.Log.debug("Waiting 5s");
                        setTimeout(() => {
                            check().then(() => resolve());
                        }, 5000);
                    } else {
                        Oidc.Log.debug("no other process currently renewing");
                        resolve()
                    }
                })
            }

            check().then(() => {
                resolve()
            })

        });
    }

    _tokenExpiring() {

        this.waitIfAlreadyRenewing().then(() => {

            // Recheck the token to see if its valid
            this._userManager._loadUser().then((user: Oidc.User) => {
                // If the token expires more than 1 min in the future, assume its been renewed by another process
                if ((user.expires_at * 1000) > (new Date().getTime() + 60 * 1000)) {
                    Oidc.Log.debug("SW.SilentRenewService._tokenExpiring: Silent token renewal performed by a different process");
                    this._userManager._events.load(user);
                } else if ((user.expires_at * 1000) < new Date().getTime()) {
                    // If the token is already expired then do nothing - the 'Access token expired' timer can handle it
                    Oidc.Log.debug("SW.SilentRenewService._tokenExpiring: Silent token renewal skipped. 'Access token expired' timer will handle it");
                } else {
                    // Mark this session as renewing the token
                    this.setIsSilentRenewing();

                    this._userManager.signinSilent().then((user: Oidc.User) => {
                        Oidc.Log.debug("SW.SilentRenewService._tokenExpiring: Silent token renewal successful");
                        this.clearIsSilentRenewing();
                    }, (err: Error) => {
                        Oidc.Log.error("SW.SilentRenewService._tokenExpiring: Error from signinSilent:", err.message);
                        this._userManager.events._raiseSilentRenewError(err);
                        this.clearIsSilentRenewing();
                    });
                }
            });
        })
    }
}

export const AuthContext = createContext<any>(undefined);

export enum InteractionType {
	Login = "login",
	Register = "register",
}

interface AuthProviderProps {
	onAccessTokenChanged?(at: string | undefined): void;
    children?:any;
    oidcClientId?: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
	const {
		onAccessTokenChanged,
        oidcClientId,
	} = props;

    
    const mgr = React.useRef(
        new UserManager({
          userStore: new WebStorageStateStore({ store: window.localStorage }),
          automaticSilentRenew: false,
          response_type: 'code',
          response_mode: 'query',
          prompt: 'consent',
          scope: 'openid email profile offline_access',
          authority: REACT_APP_OIDC_AUTHORITY,
          client_id: oidcClientId || REACT_APP_OIDC_CLIENT_ID,
          redirect_uri: `${window.location.origin}/callback` || REACT_APP_OIDC_REDIRECT_URI,
          post_logout_redirect_uri: `${window.location.origin}/logout-callback` || REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
        }),
    )

	const [user, setUser] = useState<Oidc.User | undefined | null>(undefined);
	const auth = useAuth(mgr.current);
	const loc = useLocation();
    const renewer = React.useRef(new SilentRenewService(mgr.current))

	useEffect(() => {
		renewer.current.start();
		(async () => {
			const u = await mgr.current.getUser();
			if (u?.expired !== true) {
				onAccessTokenChanged?.(u?.access_token);
				setUser(u);
			}
		})();
	}, [onAccessTokenChanged]);

	function onUserLoaded(u: Oidc.User | null) {
		if (u?.expired !== true) {
			onAccessTokenChanged?.(u?.access_token);
			setUser(u);
		}
	}

	// note: we do not add an `onUserUnloaded` event handler because
	// it triggers before the logout via redirect finishes processing.
	// once the logout callback succeeds the user will be unloaded.

	// this is called when an access token expires...
	// `oidc-client` will try to renew the access token before this is called
	// via an "expiring" event, but this will fire if that cannot complete (ie. if the page has been closed for a while)
	const onAccessTokenExpired = useCallback(async () => {
		Oidc.Log.debug("auth: access token expired...");
		// attempt a silent token renewal using the refresh token

		try {
			Oidc.Log.debug("auth: attempting silent sign in");
			const u = await mgr.current.signinSilent();
			setUser(u);
			Oidc.Log.debug("auth: successfully performed silent sign in");
		}
		catch (e:any) {
            if (e instanceof Error) {
                if (e.message === "invalid_grant") {
                    // the refresh token is likely expired

                    Oidc.Log.debug("auth: silent sign in failed... attempting a callback redirect auth");
                    auth({ successRedirectTo: loc.pathname });
                }
                else if (e.message === "Network Error") {
                    alert("We are having trouble verifying your login. You might not be connected to the Internet. Please try reconnecting and refreshing this page.");
                }
                else {
                    throw e;
    			}
            } else {
                throw e;
            }
		}
	}, [auth, loc]);

	async function onSilentRenewError() {
		await onAccessTokenExpired();
	}

	// add event handlers
	
	useEffect(() => {
		mgr.current.events.addAccessTokenExpired(onAccessTokenExpired);
		return () => mgr.current.events.removeAccessTokenExpired(onAccessTokenExpired);
	}, [onAccessTokenExpired]);

	useEffect(() => {
		mgr.current.events.addUserLoaded(onUserLoaded);
		mgr.current.events.addSilentRenewError(onSilentRenewError);

		return () => {
			mgr.current.events.removeUserLoaded(onUserLoaded);
			mgr.current.events.removeSilentRenewError(onSilentRenewError);
		};
	});

	const value = React.useMemo(() => ({ mgr, user, setUser }), [mgr,user,setUser])

	return (
		<AuthContext.Provider value={value}>
			{props.children}
		</AuthContext.Provider>
	);
};

const useCtx = () => {
    const ctx = useContext(AuthContext)
    if (!ctx) throw new Error('Called outside of context')
    return ctx
  }
  
export const useMgr = () => useCtx().mgr.current

export const AuthSignInCallbackRoute: React.FC<any> = ({ children }) => {
    const fromCtx = useContext(AuthContext)?.mgr.current
	const history = useHistory();
    if (!fromCtx) throw new Error('No UserManager in Sign In Callback')

	const onUserLoaded = useCallback(() => {
		fromCtx.clearStaleState();
		const returnTo = localStorage.getItem("auth-return-to");
		localStorage.removeItem("auth-return-to");
		history.push(returnTo ?? "/");
	}, [history]);
	
	// add event handlers

	useEffect(() => {
		fromCtx.events.addUserLoaded(onUserLoaded);

		(async () => {
			try {
				await fromCtx.signinCallback();
			}
			catch (e:any) {
				if (e instanceof Error && e.message === "No matching state found in storage") {
					Oidc.Log.warn("Recovering from:", e);
					
					const p = new URLSearchParams();
					const returnTo = localStorage.getItem("auth-return-to");
					
					if (returnTo !== null) {
						p.set("auth-return-to", returnTo);
					}

					history.push(`/login?${p.toString()}`);
				}
				else {
					throw e;
				}
			}
		})();
		return () => fromCtx.events.removeUserLoaded(onUserLoaded);
	}, [onUserLoaded, history]);

	return <>{children}</>;
};

export const AuthSignOutCallbackRoute: React.FC<any> = ({ children }) => {
    const fromCtx = useContext(AuthContext)?.mgr.current
	const history = useHistory();
    if (!fromCtx) throw new Error('No UserManager in Sign Out Callback')

	const onUserUnloaded = useCallback(() => {
		history.push("/");
	}, [history]);

	useEffect(() => {
		(async () => {
			await fromCtx.signoutCallback();
			onUserUnloaded();
		})();
	}, [onUserUnloaded]);

	return <>{children}</>;
};



export interface AuthOptions {
    /**
     * By default, will redirect to the same url from which the `login` function was called.
     */
    successRedirectTo?: string;
    queryParams?: Record<string, string>;
}


/**
 * Returns a function which can be used to trigger an OIDC login popup.
 */
export function useAuth(mgr?: UserManager): (opts?: AuthOptions) => Promise<void> {
    const fromCtx = useContext(AuthContext)?.mgr.current
    mgr = mgr ?? fromCtx
    if (!mgr) throw new Error('No UserManager')
    return async (opts?: AuthOptions) => {
        const {
            successRedirectTo = "/",
        } = opts ?? {};
        
        let {
            queryParams,
        } = opts ?? {};

        // window.open(url.toString(), "_self");
        // await (authMgr as any)._signinStart({}, (authMgr as any)._redirectNavigator, {});

        // ref: https://github.com/IdentityModel/oidc-client-js/blob/2e5e28b2f531141616463cb91ec632115216b650/src/UserManager.js#L89

        const args = { request_type: "si:r" };
        const navigator = (mgr as any)._redirectNavigator;
        const navigatorParams: any = {};

        // ref: https://github.com/IdentityModel/oidc-client-js/blob/2e5e28b2f531141616463cb91ec632115216b650/src/UserManager.js#L379

        const handle = await navigator.prepare(navigatorParams);

        try {
            Oidc.Log.debug("UserManager._signinStart: got navigator window handle");

            const req = await mgr!.createSigninRequest({ request_type: 'si:r' })

            const url = new URL(req.url);

            queryParams = queryParams ?? {};
            queryParams["interaction-type"] = queryParams["interaction-type"] ?? InteractionType.Login;

            for (const [key, value] of Object.entries(queryParams)) {
                if (value !== undefined) {
                    url.searchParams.set(key, value);
                }
            }

            Oidc.Log.debug("UserManager._signinStart: got signin request");

            navigatorParams.url = url.toString();
            navigatorParams.id = req.state.id;
            
            localStorage.setItem("auth-return-to", successRedirectTo);

            return handle.navigate(navigatorParams);
        }
        catch (e:any) {
            if (handle.close) {
                Oidc.Log.debug("UserManager._signinStart: Error after preparing navigator, closing navigator window");
                handle.close();
            }

            throw e;
        }
    };
}

export enum LogoutType {
	Redirect,
	RemoveUser,
}

/**
 * Returns a function which can be used to trigger an OIDC logout.
 */
export function useLogout(type: LogoutType = LogoutType.Redirect): () => void {
	const { setUser, mgr } = useContext(AuthContext);

    return async () => {
		if (type === LogoutType.Redirect) {
			await mgr.current.signoutRedirect();
		}
		else if (type === LogoutType.RemoveUser) {
			await mgr.current.removeUser();
			setUser(null);
		}
		else {
			assertExhaustive(type);
		}
	}
}

/**
 * Returns user info or `undefined` if unauthed.
 */
export function useUser(): Oidc.User | undefined {
    return useContext(AuthContext)?.user;
}

export enum AuthStatus {
    Authed = "authed",
    Unauthed = "unauthed",
    Loading = "loading",
}

/**
 * Returns the user auth status.
 */
export function useAuthStatus(): AuthStatus {
    const user = useUser();

    const now = Math.floor(new Date().getTime() / 1000); // unix time

    if (user === undefined || user?.expires_at < now) {
        localStorage.removeItem("access_token");
        return AuthStatus.Loading;
    }

    if(user !== null) {
        localStorage.setItem("access_token", user?.access_token);
        return AuthStatus.Authed 
    } 
    return AuthStatus.Unauthed
}

export function useUserId(): string {
    const user = useUser();

    if (user === undefined) {
        return AuthStatus.Loading;
    }

    return user === null ? '' : user?.profile?.sub;
}

export function useIsAuthed(): boolean {
    const user = useUser();
    return !!user
}

export function useIsProfileFixed(): boolean {
    return !localStorage.getItem('temp-user-profile-picture')
}

export function useIsUpdatedUser(): boolean {
    const user = useUser();
    return !!user?.profile?.updated_at;
}

export function useUpdateUser() {
    const { user, setUser } = useContext(AuthContext);

    return async (updates: Partial<UserProfile>) => {
        const newProfile = await VatomInc.users.updateProfile(updates);

        const localStorageUser = retrieveUser();
        localStorageUser.profile = { ...localStorageUser.profile, ...newProfile };
        storeUser(localStorageUser);

        const newUser = { ...user };
        newUser.profile = { ...user.profile, ...newProfile };
        setUser(newUser);
    }
}

export const userLocalStorageKey = ["oidc.user", REACT_APP_OIDC_AUTHORITY, REACT_APP_OIDC_CLIENT_ID].join(":");

export function storeUser(user: any) {
    // guest users need to have `expires_at` stored as well, derrived from `expires_in`...
    // usually `oidc-client` will do this while processing a sign in, but custom grant types
    // side-step sign in request processing.
	if (user.expires_at === undefined && user.expires_in !== undefined) {
		user.expires_at = (new Date().getTime() / 1000) + user.expires_in;
	}

    if (!!user) {
        window.localStorage.setItem(userLocalStorageKey, JSON.stringify(user));
    } else {
        window.localStorage.removeItem(userLocalStorageKey);
    }
}

export function retrieveUser() {
    const localStorageUser: any = JSON.parse(window.localStorage.getItem(userLocalStorageKey) || 'null');

    assert.notStrictEqual(localStorageUser, null);
    return localStorageUser;
}

export function getBase64Token(): string | null {
    const token = retrieveUser();

    if (!token) {
        return null
    }

    return Buffer.from(JSON.stringify(token), "utf8").toString("base64");
}