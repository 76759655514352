import React, { Suspense } from "react";
import { useTheme } from "@varius.io/wombo";
import { toast, ToastContainer } from "react-toastify";
import { BusinessProvider } from "contexts/business";
import CustomToastIcon from "./CustomToastIcon";
import { StyledContainer } from "./styles"

// components
import UpdateBanner from "./UpdateBanner";
import Nav from "./Nav";

// styles
import "index.css";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "./components/PageLoader";

const Layout: React.FC<any> = ({ children }) => {
	const theme = useTheme();

	// const isPersonalLicense = currentBusiness?.licenseId === 4 || currentBusiness?.licenseId === 0;

	const CloseButton = () => (
		<i
			className="fal fa-times"
			style={{
				fontSize: theme.ms(-0.5),
				position: "absolute",
				top: 10,
				right: 10,
				color: "white",
			}}
		/>
	);

	return (
		<div>
			<BusinessProvider>
			<Nav />

			<div
				style={{
					paddingTop: theme.rhythm(5.5),
					position: "relative",
				}}
			>
				{/* {!isPersonalLicense && <WelcomeBanner />} */}

				{/* <VerificationBanner /> */}

				<UpdateBanner />

				<StyledContainer
					position={toast.POSITION.BOTTOM_CENTER}
					hideProgressBar={true}
					bodyClassName="toastBody"
					closeButton={CloseButton}
					theme="light"
					icon={<CustomToastIcon/>}
				/>

				<Suspense fallback={<PageLoader />}>{children}</Suspense>
			</div>
			</BusinessProvider>
		</div>
	);
};

export default Layout;
