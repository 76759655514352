export enum Types {
    Put = 'PUT_BUSINESS',
    UpdateField = 'UPDATE_BUSINESS_FIELD',
  }

export const businessReducer = (state, action) => {
    switch (action.type) {
        case Types.Put:
            return action.payload.business
        case Types.UpdateField:
            let newState
            if (state.hasOwnProperty(action.payload.field)) {
                newState = Object.assign({}, state);
                newState[action.payload.field] = action.payload.value
            }
            // When you disable coin it erases from de states, we need another conditional for this use case in specific.
            else if(action.payload.field === "coin") {
                newState = Object.assign({}, state);
                newState[action.payload.field] = action.payload.value
            }
            return newState
      default:
        return state;
    }
}
  