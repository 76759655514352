import React, { useEffect, useLayoutEffect, lazy } from "react";
import { useSelector } from "react-redux";
import BusinessRequired from "utils/VariusAuth/businessRequired";
import subscriptionRequired from "utils/VariusAuth/subscriptionRequired";
import AuthRequired from "utils/VariusAuth/authRequired";
import wrapWithStripeElements from "utils/Stripe/wrapWithStripeElements";
import { Redirect } from "react-router";
import Layout from "./Layout";
import { compose } from "redux";
import { AuthProvider, useUser } from "auth";
import VatomInc from "utils/VatomIncApi";
import { GoogleTagManagerTracker } from "utils/GoogleTagManager";
import { selectors as userSelectors, useCurrentBusinessId } from "reducers/userReducers";
// import HubspotTracker from "utils/HubspotTracker";

export interface StudioRoute {
	path: string;
	component: any;
	exact?: boolean;
}

function wrap(Wrapper: any) {
	return (Wrapped: any) => {
		return (props: any) => {
			return (
				<Wrapper>
					<Wrapped {...props} />
				</Wrapper>
			);
		};
	};
}

const ApiAuther = () => {
	const u = useUser();
	const businessId = useCurrentBusinessId();
	const algoliaKey = useSelector(userSelectors.algoliaKey);

	// user

	const userId = u?.profile?.sub;

	useEffect(() => {
		VatomInc.userId = userId;
	}, [userId]);

	// business

	useLayoutEffect(() => {
		VatomInc.businessId = businessId;
	}, [businessId]);

	// algolia

	useEffect(() => {
		VatomInc.algoliaKey = algoliaKey;
	}, [algoliaKey]);

	return null;
};

/*
 * This component wraps its children with a configured AuthProvider. It does NOT check if the user is authenticated.
 */
const Auth = ({ children }) => (
	<React.Fragment>
		<ApiAuther />
		<GoogleTagManagerTracker />
		{/* <HubspotTracker /> */}
		{children}
	</React.Fragment>
);

// Unauthenticated Routes

const base = compose(wrap(Auth), wrap(Layout));

const unauthedRoutes: StudioRoute[] = [
	{
		path: "/dev-info",
		component: base(lazy(() => import("components/Tools/DevInfo"))),
		exact: true,
	},
	{
		path: "/pricing",
		component: base(lazy(() => import("components/Plans/PublicPricing"))),
		exact: true,
	},
	{
		path: "/business/join",
		component: base(lazy(() => import("components/Business/BusinessJoin"))),
		exact: true,
	},
	// todo: `inviteCode` doesn't seem to get used
	{
		path: "/business/join/:inviteCode",
		component: base(lazy(() => import("components/Business/BusinessJoin"))),
		exact: true,
	},
];

const authed = compose(base, wrap(AuthRequired));

// Non business-specific authenticated routes
const businessRoutes: StudioRoute[] = [
	{
		path: "/tools",
		component: authed(lazy(() => import("components/Tools/DevTools"))),
		exact: true,
	},
	// TODO! is it necessary?
	{
		path: "/business/checkout-form",
		component: wrap(AuthRequired)(
			wrapWithStripeElements(wrap(Layout)(lazy(() => import("components/Account/BillingDetails"))))
		),
		exact: true,
	},
	// TODO: Finish not supported page
	{
		path: "/not-supported-on-mobile",
		component: authed(lazy(() => import("components/Mobile/notSupportedMobile"))),
		exact: true,
	},
];

const business = compose(authed, wrap(BusinessRequired));

const subscription = compose(business, subscriptionRequired);

const withStripe = compose(subscription, wrapWithStripeElements);

// Business-specific authenticated routes
const authedRoutes: StudioRoute[] = [
	{
		path: "/:businessName",
		component: subscription(lazy(() => import("components/Home"))),
		exact: true,
	},
	{
		path: "/:businessName/welcome",
		component: subscription(lazy(() => import("components/Account/Welcome"))),
		exact: true,
	},
	{
		path: "/:businessName/connections",
		component: subscription(
			lazy(() => import("components/Connections/containers/connectionsContainer"))
		),
		exact: true,
	},
	{
		path: "/:businessName/firehose",
		component: subscription(lazy(() => import("components/Webhooks/"))),
		exact: true,
	},
	{
		path: "/:businessName/connections/coupon-pools",
		component: subscription(lazy(() => import("components/CouponPoolsPage"))),
		exact: true,
	},
	// {
	// 	path: "/:businessName/invite",
	// 	component: subscription(lazy(() => import("components/Business/containers/businessInviteContainer"))),
	// 	exact: true,
	// },
	{
		path: "/:businessName/objects",
		component: subscription(
			lazy(() => import("components/DigitalObjects/containers/digitalObjectsContainer"))
		),
		exact: true,
	},
	{
		path: "/:businessName/objects/intro",
		component: subscription(lazy(() => import("components/DigitalObjects/Intro"))),
		exact: true,
	},
	{
		path: "/:businessName/objects/new",
		component: subscription(lazy(() => import("components/DigitalObjects/WizardTypeSelector"))),
		exact: true,
	},
	{
		path: "/:businessName/objects/:digitalObjectId",
		component: subscription(lazy(() => import("components/DigitalObjects/Editor"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns",
		component: subscription(lazy(() => import("components/Campaigns/Campaigns"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns/new",
		component: subscription(lazy(() => import("components/Wizards/SelectWizard"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns/nft",
		component: subscription(lazy(() => import("components/DigitalObjects/WizardNftSelector"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns/:campaignGroupId",
		component: subscription(lazy(() => import("components/Campaigns/CampaignPage"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns/:campaignGroupId/:step",
		component: subscription(lazy(() => import("components/Campaigns/CampaignPage"))),
		exact: true,
	},
	{
		path: "/:businessName/campaigns/:groupCampaignId/create",
		component: subscription(lazy(() => import("components/Campaigns/CampaignCreate"))),
	},
	{
		path: "/:businessName/campaigns/:groupCampaignId/billing",
		component: wrap(BusinessRequired)(
			subscriptionRequired(
				wrapWithStripeElements(
					wrap(Layout)(lazy(() => import("components/Account/BillingDetails")))
				)
			)
		),
		exact: true,
	},
	{
		path: "/:businessName/account/subscriptions",
		component: withStripe(lazy(() => import("components/Business/Account"))),
		exact: true,
	},
	{
		path: "/:businessName/account/profile",
		component: subscription(lazy(() => import("components/Business/Account"))),
		exact: true,
	},
	{
		path: "/:businessName/account/billing",
		component: withStripe(lazy(() => import("components/Business/Account"))),
		exact: true,
	},
	{
		path: "/:businessName/account/users",
		component: subscription(lazy(() => import("components/Business/Account"))),
		exact: true,
	},

	{
		path: "/:businessName/account/invoices",
		component: subscription(lazy(() => import("components/Account/containers/invoicesContainer"))),
		exact: true,
	},
	{
		path: "/:businessName/account/invoice/:invoiceId",
		component: subscription(lazy(() => import("components/Account/containers/invoiceContainer"))),
		exact: true,
	},
	{
		path: "/:businessName/account/plans",
		component: business(lazy(() => import("components/Plans/containers/plansContainer"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/viewers",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	// OLD WALLETS(we have new ones in settings>wallets), commented not erase just in case
	// {
	// 	path: "/:businessName/settings/viewers/:id",
	// 	component: subscription(lazy(() => import("components/Business/Settings/ViewerPage"))),
	// 	exact: true,
	// },
	// {
	// 	path: "/:businessName/settings/viewers/:id/config",
	// 	component: subscription(lazy(() => import("components/Business/Settings/ViewerPage"))),
	// 	exact: true,
	// },
	// {
	// 	path: "/:businessName/settings/viewers/:id/domain",
	// 	component: subscription(lazy(() => import("components/Business/Settings/ViewerPage"))),
	// 	exact: true,
	// },

	// Check if we still need this
	{
		path: "/:businessName/account/payment-method-legacy",
		component: wrap(BusinessRequired)(
			subscriptionRequired(
				wrapWithStripeElements(
					wrap(Layout)(lazy(() => import("components/Account/BillingDetails")))
				)
			)
		),
		exact: true,
	},
	{
		path: "/:businessName/customer-lists",
		component: subscription(
			lazy(() => import("components/CustomerLists/containers/customerListsContainer"))
		),
		exact: true,
	},
	{
		path: "/:businessName/customers/lists",
		component: subscription(
			lazy(() => import("components/CustomerLists/containers/customerListsContainer"))
		),
		exact: true,
	},
	{
		path: "/:businessName/settings",
		component: wrap(BusinessRequired)(
			subscriptionRequired((props: any) => (
				<Redirect to={`/${props.match.params.businessName}/settings/eth`} />
			))
		),
		exact: true,
	},
	// TODO: remove this
	{
		path: "/:businessName/settings/brand-kit",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/wallets",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/studio-viewers",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/folders-communities",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/domains",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/plan",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/links",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/language",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	// TODO: do it like subscription required
	{
		path: "/:businessName/settings/filemanager",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/login",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/rewards",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/templates",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/templates/detail",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/eth",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/payouts",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/logs",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/manage-api-keys",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/settings/token-gating",
		component: subscription(lazy(() => import("components/Business/Settings"))),
		exact: true,
	},
	{
		path: "/:businessName/flags",
		component: subscription(lazy(() => import("components/Tools/Flags"))),
		exact: true,
	},
	{
		path: "/:businessName/customers",

		component: subscription(lazy(() => import("components/Customers/Search/Customers"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/search",
		component: subscription(lazy(() => import("components/Customers/Search/Customers"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/explore",
		component: subscription(lazy(() => import("components/Customers/Explore/Explore"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/explore/add-filter",
		component: subscription(lazy(() => import("components/Customers/Explore/AddFilterPage"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/segments",
		component: subscription(lazy(() => import("components/Customers/Segments/Segments"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/segments/:segmentId/:segmentName",
		component: subscription(
			lazy(() => import("components/Customers/Segments/SegmentPage/SegmentProfile"))
		),
		exact: true,
	},
	{
		path: "/:businessName/customers/segments/create-segment",
		component: subscription(lazy(() => import("components/Customers/Segments/Segments"))),
		exact: true,
	},
	{
		path: "/:businessName/customers/profile/:userId/:tab?",
		component: subscription(lazy(() => import("components/Customers/Profile/CustomerProfile"))),
		exact: true,
	},
	// Spaces URLs

	{
		path: "/:businessName/spaces",
		component: wrap(BusinessRequired)(
			subscriptionRequired((props: any) => (
				<Redirect to={`/${props.match.params.businessName}/spaces/list`} />
			))
		),
		exact: true,
	},
	{
		path: "/:businessName/spaces/list",
		component: subscription(lazy(() => import("components/Spaces/"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/create",
		component: subscription(lazy(() => import("components/Spaces/Spaces/Create"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/edit/:spaceId",
		component: subscription(lazy(() => import("components/Spaces/Spaces/Edit"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/events",
		component: subscription(lazy(() => import("components/Spaces/Events/List"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/events/create",
		component: subscription(lazy(() => import("components/Spaces/Events/Create"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/events/edit/:eventId",
		component: subscription(lazy(() => import("components/Spaces/Events/Edit"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/templates",
		component: subscription(lazy(() => import("components/Spaces/Templates/List"))),
		exact: true,
	},
	{
		path: "/:businessName/spaces/templates/edit/:templateId",
		component: subscription(lazy(() => import("components/Spaces/Spaces/Edit"))),
		exact: true,
	},
	{
		path: "/:businessName/analytics",
		component: subscription(lazy(() => import("components/Home"))),
		exact: true,
	},
];

export default [...unauthedRoutes, ...businessRoutes, ...authedRoutes];
