import entities, {
	Campaign,
	Rule,
	CampaignEntity,
	CampaignStage,
} from "reducers/entitiesReducers";
import history from "utils/history";
import { selectors as userSelectors } from "reducers/userReducers";
import {
	selectors as campaignSelectors,
} from "reducers/campaignsReducers";
import { validateSchema } from "utils/validator";
import pointer from "json-pointer";
import VatomInc from "utils/VatomIncApi";
import { parseEntityUriString } from "utils/uri";
import { toast } from "react-toastify";
// import billingActions from "actions/billingActions";
import wizardActions from "actions/wizardActions";
import { allBehaviorUris } from "utils/behaviors";
import { convertSource } from "utils/campaignsource";
import entitiesReducers from "reducers/entitiesReducers";
import { getBehaviorsData } from "./utils/duplicate-behavior-actions";

export interface Operation {
	name: string;
	width?: number;
	height?: number;
	x?: number;
	y?: number;
}

const AUTOSAVE_DEBOUNCE_TIME: number = parseInt(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_TIME!, 10); // debounce time for autosave feature in ms

export type CampaignsActions =
	| LoadCampaignDataStartAction
	| LoadCampaignDataSuccessAction
	| LoadCampaignDataErrorAction
	| UpdateExplicitEntitiesSuccessAction
	| UpdateExplicitEntitiesErrorAction
	| UpdateExplicitEntitiesStartAction
	| SendCampaignObjectSuccessAction
	| SendCampaignObjectStartAction
	| SendCampaignObjectErrorAction
	| SelectSourceAction
	| UpdateRuleAction
	| ToggleCampaignBehaviorErrorAction
	| ToggleCampaignBehaviorStartAction
	| ToggleCampaignBehaviorSuccessAction
	| ModifyRevisionStartAction
	| ModifyRevisionSuccessAction
	| ModifyRevisionErrorAction
	| UpdateImageStartAction
	| UpdateImageSuccessAction
	| UpdateImageErrorAction
	| UploadImageErrorAction
	| UploadImageStartAction
	| UploadImageSuccessAction;

// Define Action Keys
export enum CampaignsActionKeys {
	LOAD_CAMPAIGN_DATA_START = "CAMPAIGNS/LOAD_CAMPAIGN_DATA_START",
	LOAD_CAMPAIGN_DATA_SUCCESS = "CAMPAIGNS/LOAD_CAMPAIGN_DATA_SUCCESS",
	LOAD_CAMPAIGN_DATA_ERROR = "CAMPAIGNS/LOAD_CAMPAIGN_DATA_ERROR",
	UPDATE_EXPLICIT_ENTITIES_START = "CAMPAIGNS/UPDATE_EXPLICIT_ENTITIES_START",
	UPDATE_EXPLICIT_ENTITIES_SUCCESS = "CAMPAIGNS/UPDATE_EXPLICIT_ENTITIES_SUCCESS",
	UPDATE_EXPLICIT_ENTITIES_ERROR = "CAMPAIGNS/UPDATE_EXPLICIT_ENTITIES_ERROR",
	SEND_CAMPAIGN_OBJECT_START = "CAMPAIGNS/SEND_CAMPAIGN_OBJECT_START",
	SEND_CAMPAIGN_OBJECT_SUCCESS = "CAMPAIGNS/SEND_CAMPAIGN_OBJECT_SUCCESS",
	SEND_CAMPAIGN_OBJECT_ERROR = "CAMPAIGNS/SEND_CAMPAIGN_OBJECT_ERROR",
	SELECT_SOURCE = "CAMPAIGNS/SELECT_SOURCE",
	UPDATE_RULE = "CAMPAIGNS/UPDATE_RULE",
	TOGGLE_CAMPAIGN_BEHAVIOR_START = "CAMPAIGNS/TOGGLE_CAMPAIGN_BEHAVIOR_START",
	TOGGLE_CAMPAIGN_BEHAVIOR_SUCCESS = "CAMPAIGNS/TOGGLE_CAMPAIGN_BEHAVIOR_SUCCESS",
	TOGGLE_CAMPAIGN_BEHAVIOR_ERROR = "CAMPAIGNS/TOGGLE_CAMPAIGN_BEHAVIOR_ERROR",
	MODIFY_REVISION_START = "CAMPAIGNS/MODIFY_REVISION_START",
	MODIFY_REVISION_SUCCESS = "CAMPAIGNS/MODIFY_REVISION_SUCCESS",
	MODIFY_REVISION_ERROR = "CAMPAIGNS/MODIFY_REVISION_ERROR",
	UPDATE_DISTRIBUTION = "CAMPAIGNS/DISTRIBUTION/UPDATE_DISTRIBUTION",
	UPLOAD_IMAGE_START = "CAMPAIGNS/UPLOAD_IMAGE_START",
	UPLOAD_IMAGE_SUCCESS = "CAMPAIGNS/UPLOAD_IMAGE_SUCCESS",
	UPLOAD_IMAGE_ERROR = "CAMPAIGNS/UPLOAD_IMAGE_ERROR",
	UPDATE_IMAGE_START = "CAMPAIGNS/UPDATE_IMAGE_START",
	UPDATE_IMAGE_SUCCESS = "CAMPAIGNS/UPDATE_IMAGE_SUCCESS",
	UPDATE_IMAGE_ERROR = "CAMPAIGNS/UPDATE_IMAGE_ERROR",
}

interface Action<Type> {
	type: Type;
	data?: any;
}

export interface ModifyRevisionStartAction
	extends Action<CampaignsActionKeys.MODIFY_REVISION_START> {}

export interface ModifyRevisionSuccessAction
	extends Action<CampaignsActionKeys.MODIFY_REVISION_SUCCESS> {}

export interface ModifyRevisionErrorAction
	extends Action<CampaignsActionKeys.MODIFY_REVISION_ERROR> {
	data: {
		error: any;
	};
}

export interface ToggleCampaignBehaviorStartAction
	extends Action<CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_START> {}

export interface ToggleCampaignBehaviorSuccessAction
	extends Action<CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_SUCCESS> {}

export interface ToggleCampaignBehaviorErrorAction
	extends Action<CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_ERROR> {
	data: {
		error: any;
	};
}

export interface SendCampaignObjectStartAction
	extends Action<CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_START> {}

export interface SendCampaignObjectSuccessAction
	extends Action<CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_SUCCESS> {}

export interface SendCampaignObjectErrorAction
	extends Action<CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_ERROR> {
	data: {
		error: any;
	};
}

export interface UpdateExplicitEntitiesStartAction
	extends Action<CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_START> {}

export interface UpdateExplicitEntitiesSuccessAction
	extends Action<CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_SUCCESS> {}

export interface UpdateExplicitEntitiesErrorAction
	extends Action<CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_ERROR> {
	data: {
		error: any;
	};
}

export interface UpdateRuleAction extends Action<CampaignsActionKeys.UPDATE_RULE> {
	data: {
		rule: Rule;
	};
}

export interface SelectSourceAction extends Action<CampaignsActionKeys.SELECT_SOURCE> {
	data: {
		source: CampaignEntity;
	};
}

export interface LoadCampaignDataStartAction
	extends Action<CampaignsActionKeys.LOAD_CAMPAIGN_DATA_START> {
		data: {};
	}

export interface LoadCampaignDataSuccessAction
	extends Action<CampaignsActionKeys.LOAD_CAMPAIGN_DATA_SUCCESS> {
	data: {
		groupId: string;
		campaignId: string;
	};
}

export interface LoadCampaignDataErrorAction
	extends Action<CampaignsActionKeys.LOAD_CAMPAIGN_DATA_ERROR> {
	data: {
		error: any;
	};
}

export interface UploadImageStartAction extends Action<CampaignsActionKeys.UPLOAD_IMAGE_START> {}

export interface UploadImageSuccessAction
	extends Action<CampaignsActionKeys.UPLOAD_IMAGE_SUCCESS> {}

export interface UploadImageErrorAction extends Action<CampaignsActionKeys.UPLOAD_IMAGE_ERROR> {
	data: {
		error: any;
	};
}

export interface UpdateImageStartAction extends Action<CampaignsActionKeys.UPDATE_IMAGE_START> {}

export interface UpdateImageSuccessAction
	extends Action<CampaignsActionKeys.UPDATE_IMAGE_SUCCESS> {}

export interface UpdateImageErrorAction extends Action<CampaignsActionKeys.UPDATE_IMAGE_ERROR> {
	data: {
		error: any;
	};
}

let autosaveDebounce;
let updateDebounce;

// Action Creators
const actions = {
	/**
	 * Select a campaign from the list
	 */
	selectCampaign: (campaign: Campaign) => async (dispatch, getState) => {
		const currentBusiness = userSelectors.currentBusiness(getState());
		let destination;

		if (campaign.stage !== CampaignStage.Draft) {
			// when the campaign is live, send them to the review page
			destination = "review";
		} else {
			// otherwise, send them to experiences
			destination = "experience";
		}

		dispatch(actions.loadCampaignData(campaign.groupCampaignId));

		history.push(
			`/${currentBusiness!.businessName}/campaigns/${campaign.groupCampaignId}/${destination}`
		);
	},

	/**
	 * Load campaign data and any associated data needed to edit a campaign
	 * @param groupId
	 * @param revision
	 */
	loadCampaignData: (groupId: string, revision?: number) => async (dispatch, getState) => {
		await dispatch({
			type: CampaignsActionKeys.LOAD_CAMPAIGN_DATA_START,
			data: {
				groupId,
			},
		});

		try {
			const effects = entities.effects.selectors.all(getState());
			let campaignId = campaignSelectors.campaignId(getState());

			// if we haven't loaded the data for this campaign yet, do so now
			if (true) {
				const campaign = await dispatch(entities.campaigns.actions.get!(groupId, revision));
				campaignId = campaign.id;

				// load necessary data in parallel
				await Promise.all([
					(async () => {
						// load rules for the selected campaign
						await dispatch(entities.rules.actions.list!(campaignId));
					})(),
					(async () => {
						// load the ruleEntities for the selected campaign
						await dispatch(entities.rulesEntities.actions.get!(campaignId));
					})(),
					(async () => {
						// load the behaviors available for this business
						await dispatch(entities.behaviors.actions.list!());
					})(),
					(async () => {
						// load the available system behaviors
						await dispatch(entities.behaviors.actions.list!("system"));
					})(),
					(async () => {
						// load the effects if they have not yet been loaded
						if (!Object.keys(effects).length) {
							await dispatch(entities.effects.actions.list!(campaign?.businessId));
						}
					})(),
					(async () => {
						// note: load distributions on the referenced test campaign if this campaign is in test mode
						const distsGroupId =
							campaign.stage === CampaignStage.Test ? campaign.testCampaignId : groupId;

						// load the campaign's distributions
						await dispatch(entities.distributions.actions.list!(distsGroupId));
					})(),
					// (async () => {
					// 	// load default billing source
					// 	await dispatch(billingActions.getDefaultAccountInfo());
					// })(),
					(async () => {
						// load providers for this business
						await dispatch(entities.providers.actions.list!());
					})(),
					(async () => {
						// load services
						await dispatch(entities.services.actions.list!());
					})(),
					(async () => {
						// load the campaignWizard (if applicable) for the selected campaign
						if (campaign.type === "simple") {
							const userWizard = await dispatch(entities.userWizards.actions.get!(campaign.businessId, groupId));
							const { wizardId } = userWizard;
							await dispatch(entities.wizards.actions.get!(wizardId, campaign?.businessId));
						}
					})(),
				]);

				const rules = campaignSelectors.editableRulesCopy(getState());
				const sourceOrder = campaignSelectors.sourceOrder(getState());

				const triggersNeeded: any = [];

				// load all triggers for the campaign's sources in parallel
				// @TODO temporary
				for (const source of sourceOrder) {
					triggersNeeded.push(
						(async () =>
							await dispatch(
								entities.triggers.actions.list!({
									source: convertSource(source),
									campaignId,
								})
							))()
					);
				}

				if (triggersNeeded.length) {
					await Promise.all(triggersNeeded);
				}

				// select the first source in the object as the selectedSource and its rules as selectedRules
				if (sourceOrder.length) {
					const type = sourceOrder[0].type;
					const id = sourceOrder[0].id;
					const source = { id, type };

					await dispatch(actions.selectSource(source));
				}

				const validations: any = [];

				// validate each of the returned rules
				Object.keys(rules).map(ruleId =>
					validations.push((async () => await dispatch(actions.updateRule(rules[ruleId], false)))())
				);

				// validate the rules in parallel
				if (validations.length) {
					await Promise.all(validations);
				}
			}

			dispatch({
				type: CampaignsActionKeys.LOAD_CAMPAIGN_DATA_SUCCESS,
				data: {
					campaignId,
					groupId,
				},
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.LOAD_CAMPAIGN_DATA_ERROR,
				data: {
					error,
					groupId,
				},
			});
			
			throw error;
		}
	},

	/**
	 * Create a campaign for the selected business
	 * @param displayName
	 * @param digitalObjectId
	 */
	 createCampaign: ({ displayName, type, wizardId, digitalObjectId, redirectToCampaign = true }: any) => async (dispatch, getState) => {
		const businessName = userSelectors.selectedBusiness(getState());
		const body: { displayName: string, type: string | undefined  } = { displayName, type };
		const campaign: Campaign = await dispatch(entities.campaigns.actions.create!(body));

		// for quick campaigns, add the selected object to the campaign as an explicit object
		if (digitalObjectId) {
			await dispatch(
				actions.addCampaignEntity(
					{ id: digitalObjectId, type: "object-definition" },
					true,
					campaign.id
				)
			);
		}
		
		// create wizard if type is "simple"
		if (type === "simple" && wizardId !== undefined) {
			await dispatch(wizardActions.createUserWizard(wizardId, campaign.groupCampaignId));
		}

		// note: i removed this action, but we might need to do something like
		// it, or call `loadCampaignData` in order to reset an old campaign if
		// there is one, and load the new campaign. - andrew

		// await dispatch({
		// 	type: CampaignsActionKeys.CLEAR_CAMPAIGN_DATA,
		// });

		if (redirectToCampaign && campaign) {
			history.push(`/${businessName}/campaigns/${campaign.groupCampaignId}`);
		}
		
		return campaign

	},

	/**
	 * Create a revision of the current campaign
	 */
	versionCampaign: () => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());

		await dispatch(entities.campaigns.actions.version!(groupCampaignId));
		dispatch(actions.loadCampaignData(groupCampaignId));
	},

	/**
	 * Select a revision of the current campaign
	 */
	selectCampaignRevision: (revision: number) => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		dispatch(actions.loadCampaignData(groupCampaignId, revision));
	},

	/**
	 * Toggle the draft state of a revision of the current campaign
	 */
	toggleCampaignRevision: () => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());

		dispatch({
			type: CampaignsActionKeys.MODIFY_REVISION_START,
		});

		try {
			const newCampaign = await VatomInc.campaignGroups.toggleCampaignGroupRevision(
				groupCampaignId
			);

			await dispatch({
				type: "campaigns.update.success",
				data: {
					item: newCampaign,
				},
			});

			dispatch({
				type: CampaignsActionKeys.MODIFY_REVISION_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.MODIFY_REVISION_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Delete a revision of the current campaign
	 */
	deleteCampaignRevision: () => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());

		dispatch({
			type: CampaignsActionKeys.MODIFY_REVISION_START,
		});

		try {
			await VatomInc.campaignGroups.deleteCampaignGroupRevision(groupCampaignId);

			dispatch(actions.loadCampaignData(groupCampaignId));

			dispatch({
				type: CampaignsActionKeys.MODIFY_REVISION_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.MODIFY_REVISION_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Archive a specified Campaign
	 * @param campaignId
	 */
	archiveCampaign: (campaignId: string, force: boolean) => async dispatch => {
		return await dispatch(entities.campaigns.actions.archive!(campaignId, force));
	},

	/**
	 * Select a source to operate on its list of rules in the campaign builder
	 * @param source
	 */
	selectSource: (source: CampaignEntity) => async (dispatch, getState) => {
		const sourceTriggersMap = campaignSelectors.sourceTriggersMap(getState());
		const campaignId = campaignSelectors.campaignId(getState());
		const { type, id } = convertSource(source);

		// make sure we have loaded the triggers for this source already
		if (!sourceTriggersMap[type][id]) {
			await dispatch(entities.triggers.actions.list!({ source: { id, type }, campaignId }));
		}

		await dispatch({
			type: CampaignsActionKeys.SELECT_SOURCE,
			data: {
				source,
			},
		});
	},

	/**
	 * Add the selected trigger to the current rule
	 * @param rule
	 * @param triggerId
	 */
	setTrigger: (rule: Rule, triggerId?: string) => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const selectedSource = campaignSelectors.selectedSource(getState());
		const config: any = { campaignId: groupCampaignId };

		if (triggerId) {
			const triggersMap = entities.triggers.selectors.all(getState());
			const selectedTrigger = triggersMap[triggerId];

			if (selectedTrigger.sourceIdConfigPointer) {
				pointer.set(config, selectedTrigger.sourceIdConfigPointer, selectedSource!.id);
			}
		}

		// when the source is a viewer, add the viewerId to the new trigger config
		if (selectedSource!.type === "viewer") {
			config.viewerId = selectedSource!.id;
		}

		// hack in the default value
		if (triggerId === "action-id-submit-quiz-v2") {
			const behaviorsMap = campaignSelectors.editableBehaviorsCopy(getState());
			const quizConfig = behaviorsMap[rule.trigger.source.id][allBehaviorUris.quiz].config!;

			config.answer = {
				comp: "eq",
				rhs: quizConfig.answer,
			};
		}

		rule.trigger.id = triggerId;
		rule.trigger.config = config;
		rule.effects = [];

		dispatch(actions.updateRule(rule));
	},

	/**
	 * Set a trigger config to a certain value
	 * @param rule
	 * @param propertyName
	 * @param value
	 */
	setTriggerConfig: (rule: Rule, propertyName: string, value?: any) => async dispatch => {
		const ruleTrigger = rule.trigger!;
		const config = ruleTrigger ? JSON.parse(JSON.stringify(ruleTrigger.config)) : {};
		const key = pointer.compile([propertyName]);

		if (value) {
			pointer.set(config, key, value);
		} else {
			pointer.remove(config, key);
		}

		ruleTrigger.config = config;

		dispatch(actions.updateRule(rule));
	},

	/**
	 * Clear out the trigger config for a specified rule
	 * @param rule
	 */
	clearTriggerConfig: (rule: Rule) => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const selectedSource = campaignSelectors.selectedSource(getState());
		const config: any = { campaignId: groupCampaignId };

		// when the source is a viewer, add the viewerId to the new trigger config
		if (selectedSource!.type === "viewer") {
			config.viewerId = selectedSource!.id;
		}

		rule.trigger.config = config;
		rule.effects = [];

		dispatch(actions.updateRule(rule));
	},

	/**
	 * Add the selected effect to the current rule
	 * @param rule
	 * @param effectId
	 */
	setOutcome: (rule: Rule, effectId?: string) => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const effects: any = [];

		if (effectId) {
			effects.push({ id: effectId, config: { campaignId: groupCampaignId } });
		}

		rule.effects = effects;

		dispatch(actions.updateRule(rule));
	},

	/**
	 * Set an outcome config to a certain value
	 * @param rule
	 * @param propertyName
	 * @param value
	 */
	setOutcomeConfig: (
		rule: Rule,
		propertyName: string | string[],
		value?: any
	) => async dispatch => {
		const newRule = { ...rule };
		const [ruleEffect] = newRule.effects!;
		const config = ruleEffect ? ruleEffect.config : {};
		const key = Array.isArray(propertyName)
			? pointer.compile(propertyName)
			: pointer.compile([propertyName]);

		if (value) {
			pointer.set(config, key, value);
		} else {
			pointer.remove(config, key);
		}

		ruleEffect.config = config;

		dispatch(actions.updateRule(newRule));
	},

	onToggleNonce: (rule: Rule, value: boolean) => async dispatch => {
		const newRule = { ...rule };
		const [ruleEffect] = newRule.effects!;
		ruleEffect.isNonceEnabled = value;
		dispatch(actions.updateRule(newRule));
	},

	/**
	 * Clear out the effects config for a specified rule
	 * @param rule
	 */
	clearOutcomeConfig: (rule: Rule) => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const effects: any = [];
		const [effect] = rule.effects!;

		effects.push({ id: effect.id, config: { campaignId: groupCampaignId } });

		rule.effects = effects;

		dispatch(actions.updateRule(rule));
	},

	/**
	 * Dispatch the UPDATE_RULE action and save if necessary
	 * @param rule
	 * @param performSave
	 */
	updateRule: (rule: Rule, performSave: boolean = true) => async (dispatch, getState) => {
		await dispatch(actions.validateRule(rule));

		dispatch({
			type: CampaignsActionKeys.UPDATE_RULE,
			data: {
				rule,
			},
		});

		// cancel the previous autosave timeout
		if (autosaveDebounce) {
			clearTimeout(autosaveDebounce);
		}

		if (performSave && rule.isValid) {
			// set a debounce timeout for autosaving
			autosaveDebounce = setTimeout(() => {
				dispatch(actions.saveRule(rule));
			}, AUTOSAVE_DEBOUNCE_TIME);
		}
	},

	/**
	 * Validate a specified rule's configs against their schemas
	 * @param rule
	 */
	validateRule: (rule: Rule) => async (dispatch, getState) => {
		const triggersMap = entities.triggers.selectors.all(getState());
		const effectsMap = entities.effects.selectors.all(getState());
		const [ruleEffect] = rule.effects!;
		const { trigger } = rule;

		const selectedEffect = ruleEffect ? effectsMap[ruleEffect.id] : undefined;
		const effectConfig = ruleEffect ? ruleEffect.config : {};
		const effectConfigSchema = selectedEffect ? selectedEffect.configSchema : {};

		const selectedTrigger = trigger ? triggersMap[trigger.id!] : undefined;
		const triggerConfig = trigger ? trigger.config : {};
		const triggerConfigSchema = selectedTrigger ? selectedTrigger.configSchema : {};

		let isEffectValid = false;
		let isTriggerValid = false;

		// valid the trigger and effect schemas for this rule in parallel
		await Promise.all([
			(async () => {
				// validate the effect
				const { isValid } = await validateSchema(effectConfigSchema, effectConfig);
				isEffectValid = isValid;
			})(),
			(async () => {
				// validate the trigger
				const { isValid } = await validateSchema(triggerConfigSchema, triggerConfig);
				isTriggerValid = isValid;
			})(),
		]);

		const isRuleValid = !!selectedTrigger && !!selectedEffect && isTriggerValid && isEffectValid;

		rule.isEffectValid = isEffectValid;
		rule.isTriggerValid = isTriggerValid;
		rule.isValid = isRuleValid;
	},

	/**
	 * Persist a rule to the database
	 * @param rule
	 */
	saveRule: (rule: Rule) => async (dispatch, getState) => {
		const campaignId = campaignSelectors.campaignId(getState());
		const isNewRule = rule.id.startsWith("TEMP");

		const {
			trigger,
			effects: [effect],
		} = rule;
		const data = {
			trigger: {
				config: trigger.config,
				id: trigger.id,
			},
			effects: [
				{
					id: effect.id,
					config: effect.config,
					isNonceEnabled: effect.isNonceEnabled,
				},
			],
		};

		if (isNewRule) {
			await dispatch(entities.rules.actions.create!(data as any, campaignId, rule.id));
		} else {
			await dispatch(entities.rules.actions.update!(rule.id, data as any, campaignId));
		}

		dispatch(entities.rulesEntities.actions.get!(campaignId));
	},

	/**
	 * Set the campaign type to either 'simple' or 'experiential'
	 * @param type
	 */
	setCampaignType: (type: "simple" | "experiential") => async (dispatch, getState) => {
		const campaignId = campaignSelectors.campaignId(getState());

		dispatch(entities.campaigns.actions.update!(campaignId, { type }));
	},

	/**
	 * Add a list of objects explicitly to a campaign
	 * @param objectDefinitionIds
	 * @param updateCampaignData
	 * @param quickCampaignId
	 */
	addCampaignObjects: (
		objectDefinitionIds: string[],
		updateCampaignData?: any,
		quickCampaignId?: string
	) => async (dispatch, getState) => {
		const campaignId = quickCampaignId || campaignSelectors.campaignId(getState());

		dispatch({
			type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_START,
		});

		try {
			// if updateCampaignData has been passed, update the campaign using the data
			if (updateCampaignData) {
				await dispatch(entities.campaigns.actions.update!(campaignId, updateCampaignData));
			}

			await VatomInc.campaigns.addObjectDefinitions(campaignId, objectDefinitionIds);
			await dispatch(entities.rulesEntities.actions.get!(campaignId));

			dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Update data for the current campaign
	 */
	updateCampaignGroup: (data: Partial<Campaign>, debounceTime = 0) => async (
		dispatch,
		getState
	) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());

		// cancel the previous update timeout
		if (updateDebounce) {
			clearTimeout(updateDebounce);
		}

		// set a debounce timeout for autosaving
		updateDebounce = setTimeout(() => {
			dispatch(entities.campaigns.actions.update!(groupCampaignId, data, { merge: true }));
		}, debounceTime);
	},

	/**
	 * Add an entity explicitly to a campaign
	 */
	addCampaignEntity: (
		source: CampaignEntity,
		shouldSelectSource: boolean,
		newCampaignId?: string
	) => async (dispatch, getState) => {
		dispatch({
			type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_START,
		});

		try {
			const state = getState();
			const campaignId = newCampaignId || campaignSelectors.campaignId(state);
			const sourceOrder = campaignSelectors.sourceOrder(state);
			if (!findSource(sourceOrder, source)) {
				if (source.type === "viewer") {
					await VatomInc.campaigns.addViewers(campaignId, [source.id]);
				} else if (source.type === "object-definition") {
					await VatomInc.campaigns.addObjectDefinitions(campaignId, [source.id]);
				} else if (source.type === "provider") {
					await VatomInc.campaigns.addProvider(campaignId, source.id);
				} else if (source.type === "user") {
					await VatomInc.campaigns.addUser(campaignId, source.id);
				} else if (source.type === "space") {
					await VatomInc.campaigns.addSpace(campaignId, source.id);
				}
			}

			if (shouldSelectSource) {
				await dispatch(actions.selectSource(source));
			}

			await dispatch(entities.rulesEntities.actions.get!(campaignId));

			await dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Remove an entity that was explicitly added to a campaign
	 */
	removeCampaignEntity: (source: CampaignEntity) => async (dispatch, getState) => {
		dispatch({
			type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_START,
		});

		try {
			const state = getState();
			const campaignId = campaignSelectors.campaignId(state);
			const selectedSource = campaignSelectors.selectedSource(state) || { type: "", id: "" };
			const isSelected = selectedSource.id === source.id && selectedSource.type === source.type;

			if (source.type === "viewer") {
				await VatomInc.campaigns.removeViewer(campaignId, source.id);
			} else if (source.type === "provider") {
				await VatomInc.campaigns.removeProvider(campaignId, source.id);
			} else if (source.type === "user") {
				await VatomInc.campaigns.removeUser(campaignId, source.id);
			} else if (source.type === "space") {
				await VatomInc.campaigns.removeSpace(campaignId, source.id);
			} else {
				await VatomInc.campaigns.removeObjectDefinition(campaignId, source.id);
			}

			await dispatch(entities.rules.actions.list!(campaignId));
			await dispatch(entities.rulesEntities.actions.get!(campaignId));

			// select a new source
			if (isSelected) {
				const sourceOrder = campaignSelectors.sourceOrder(getState());
				const type = sourceOrder[0].type;
				const id = sourceOrder[0].id;
				const newSource = { id, type };

				await dispatch(actions.selectSource(newSource));
			}

			dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.UPDATE_EXPLICIT_ENTITIES_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Publish the current campaign
	 */
	publishCampaign: () => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const campaignId = campaignSelectors.campaignId(getState());

		try {
			await dispatch(
				entities.campaigns.actions.update!(groupCampaignId, {
					stage: CampaignStage.Published,
				})
			);

			dispatch(entities.rulesEntities.actions.get!(campaignId));

			toast("Campaign is publishing. We will notify you when it is complete.");
		} catch (err:any) {
			toast.error(
				"Something went wrong and the campaign could not be published. Please try again."
			);
		}
	},

	/**
	 * Toggle the current campaign into / out of test mode
	 */
	toggleTestMode: () => async (dispatch, getState) => {
		const groupCampaignId = campaignSelectors.groupCampaignId(getState());
		const campaignId = campaignSelectors.campaignId(getState());
		const allCamapaigns = entitiesReducers.campaigns.selectors.all(getState());
		const campaign = allCamapaigns[campaignId];
		const stage = campaign.stage;
		const newStage = stage === CampaignStage.Test ? CampaignStage.Draft : CampaignStage.Test;

		try {
			await dispatch(
				entities.campaigns.actions.update!(groupCampaignId, {
					stage: newStage,
				})
			);

			dispatch(entities.rulesEntities.actions.get!(campaignId));

			toast(
				`Campaign is ${
					newStage === CampaignStage.Test ? "entering" : "exiting"
				} test mode. We will notify you when it is complete.`
			);
		} catch (err:any) {
			toast.error(
				"Something went wrong and the campaign could not be put into test mode. Please try again."
			);
		}
	},

	/**
	 * Send a test digital object to a phone number, email address, or QR code
	 * @param digitalObjectId
	 * @param data
	 */
	sendCampaignObject: (digitalObjectId: string, data: any) => async (dispatch, getState) => {
		const campaignId = campaignSelectors.campaignId(getState());

		dispatch({
			type: CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_START,
		});

		try {
			await VatomInc.campaigns.sendCampaignObject(campaignId, digitalObjectId, data);

			dispatch({
				type: CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.SEND_CAMPAIGN_OBJECT_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Toggle a behavior on or off of a campaign rule entity
	 * @param payload
	 * @param digitalObjectId
	 * @param behaviorUri
	 * @param debounce
	 */
	updateCampaignBehavior: (
		payload: {} | null,
		digitalObjectId: string,
		behaviorUri: string,
		debounce: boolean = false
	) => async (dispatch, getState) => {
		// cancel the previous autosave timeout
		if (autosaveDebounce) {
			clearTimeout(autosaveDebounce);
		}
		// set a debounce timeout for autosaving
		autosaveDebounce = setTimeout(
			async () => {
				const campaignId = campaignSelectors.campaignId(getState());
				const behaviorId = parseEntityUriString(behaviorUri).identifier;
				const source = campaignSelectors.selectedSource(getState());

				const data: {} = getBehaviorsData(behaviorId, digitalObjectId, payload);
			
				dispatch({
					type: CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_START,
				});

				try {
					await VatomInc.rulesEntities.updateRulesEntities(campaignId, data);
					await dispatch(entities.triggers.actions.list!({ source, campaignId }));
					await dispatch(entities.rulesEntities.actions.get!(campaignId));

					dispatch({
						type: CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_SUCCESS,
					});
				} catch (error) {
					dispatch({
						type: CampaignsActionKeys.TOGGLE_CAMPAIGN_BEHAVIOR_ERROR,
						data: {
							error,
						},
					});
				}
			},
			debounce ? AUTOSAVE_DEBOUNCE_TIME : 0
		);
	},
	/**
	 * Persist a distribution to the DB
	 * @param id
	 * @param payload
	 * @param type
	 */
	updateDistribution: (id: string, payload: any = {}, type: string) => async (
		dispatch,
		getState
	) => {
		await dispatch(entities.distributions.actions.update!(id, payload, type));
	},

	/**
	 * Upload a user-selected image to Varius
	 * @param image
	 * @param callback
	 */
	uploadImage: (image: File, callback?: any) => async dispatch => {
		await dispatch({
			type: CampaignsActionKeys.UPLOAD_IMAGE_START,
			data: {
				image,
			},
		});

		try {
			const { url } = await VatomInc.resources.uploadImage(image);

			dispatch({
				type: CampaignsActionKeys.UPLOAD_IMAGE_SUCCESS,
				data: {
					temporaryResourceUrl: url,
				},
			});

			if (callback) {
				callback(url);
			}
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.UPLOAD_IMAGE_ERROR,
				data: {
					error,
				},
			});
		}
	},

	/**
	 * Apply a list of operations to an image, and then persist the returned image to a distribution
	 * @param resourceUrl
	 * @param operations
	 * @param callback
	 */
	updateImage: (resourceUrl: string, operations?: Operation[], callback?: any) => async (
		dispatch,
		getState
	) => {
		await dispatch({
			type: CampaignsActionKeys.UPDATE_IMAGE_START,
		});

		try {
			const timestamp = new Date().getTime();
			let ref: string;

			// if this function is called with a list of operations, send them to the API
			if (operations && operations.length) {
				const res = await VatomInc.resources.updateImage(resourceUrl, operations);
				ref = `${res.url}?${timestamp}`; // append timestamp for cache-busting purposes
			} else {
				// otherwise, we already have the url for this image, use that
				ref = resourceUrl;
			}

			if (callback) {
				callback(ref);
			}

			dispatch({
				type: CampaignsActionKeys.UPDATE_IMAGE_SUCCESS,
			});
		} catch (error) {
			dispatch({
				type: CampaignsActionKeys.UPDATE_IMAGE_ERROR,
				data: {
					error,
				},
			});
		}
	},
};



const findSource = (sourceOrder: CampaignEntity[], source: CampaignEntity) => {
	for (const ruleSource of sourceOrder) {
		const { type, id } = ruleSource;

		if (type === source.type && id === source.id) {
			return true;
		}
	}

	return false;
};

export default actions;
