// List of hard coded behaviors that are displayed in the UI
// @todo rename this to hardcodedBehaviorUris..
export const behaviorUris = {
	scarcity: "varius.behavior:varius.io:scarcity-v1",
	share: "varius.behavior:varius.io:cloneable-v1",
	send: "varius.behavior:varius.io:transferable-v1",
	scan: "varius.behavior:varius.io:auto-create-v1",
	map: "varius.behavior:varius.io:map-ready-v1",
};

export function determineBehaviors(behaviors) {
	const behaviorInfo = {
		scarcity: !!behaviors[behaviorUris.scarcity],
		share: !!behaviors[behaviorUris.share],
		scan: !!behaviors[behaviorUris.scan],
		send: !!behaviors[behaviorUris.send],
		map: !!behaviors[behaviorUris.map],
	};

	return behaviorInfo;
}

// @todo not complete
export const allBehaviorUris = {
	scarcity: "varius.behavior:varius.io:scarcity-v1",
	share: "varius.behavior:varius.io:cloneable-v1",
	send: "varius.behavior:varius.io:transferable-v1",
	scan: "varius.behavior:varius.io:auto-create-v1",
	map: "varius.behavior:varius.io:map-ready-v1",
	poll: "varius.behavior:varius.io:poll-v1",
	quiz: "varius.behavior:varius.io:quiz-v2",
};

export const hardcodedBehaviors = {};
