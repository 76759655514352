import AddOns from "./addons";
import Blueprints from "./blueprints";
import Designs from "./designs";
import Views from "./views";
import DigitalObjects from "./digitalObjects";
import Behaviors from "./behaviors";
import Businesses from "./businesses";
import Loyalty from "./loyalties";
import Events from "./events";
import Templates from "./templates";
import FileSystem from "./filesystem";
import Resources from "./resources";
import Users from "./users";
import Types from "./types";
import Viewers from "./viewers";
import StudioViewers from "./studio-viewers";
import Campaigns from "./campaigns";
import CampaignGroups from "./campaignGroups";
import Rules from "./rules";
import WalletConfig from "./walletConfig";
import BrandConfig from "./brandConfig";
import RulesEntities from "./rulesEntities";
import Effects from "./effects";
import Invoices from "./invoices";
import Admin from "./admin";
import Links from "./links";
import Webhooks from "./webhooks";
import queryString from "query-string";
import Account from "utils/VatomIncApi/account";
import Billing from "utils/VatomIncApi/billing";
import Plans from "utils/VatomIncApi/plans";
import Budget from "utils/VatomIncApi/budget";
import ContactLists from "./contactLists";
import Distributions from "./distributions";
import Providers from "./providers";
import Wizards from "./wizards";
import UserWizards from "./userWizards";
import Analytics from "./analytics";
import Points from "./points";
import Me from "./me";
import Client from "./clients";
import Communities from "./communities";
import TokenGating from "./tokenGating";

export enum RequestService {
	Api = "api",
	Providers = "providers",
	Distribution = "distribution",
	Businesses = "businesses",
	Loyalty = "loyalty",
	Events = "events",
	Styles = "styles",
	Prizes = "prizes",
	Coupons = "coupons",
	Billing = "billing",
	Identity = "identity",
	FileSystem = "filesystem",
	Analytics = "analytics",
	Points = "points",
	Resources = "resource",
	Communities = "communities",
	TokenGating = "tokengating"
}

export const ALL_REQUEST_SERVICES = [
	RequestService.Api,
	RequestService.Providers,
	RequestService.Distribution,
	RequestService.Businesses,
	RequestService.Loyalty,
	RequestService.Events,
	RequestService.Styles,
	RequestService.Prizes,
	RequestService.Coupons,
	RequestService.Billing,
	RequestService.Identity,
	RequestService.Analytics,
	RequestService.Points,
	RequestService.Communities,
	RequestService.TokenGating
];

interface RequestOptions {
	path: string;
	method?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
	body?: any;
	isAuthed?: boolean;
	isAbsoluteUrl?: boolean;
	service?: RequestService;
	headers?: any;
	needErrorMessage?: boolean;
}

export interface HttpResponse {
	method: string;
	statusCode: number;
	headers: any;
	body: any;
}

export class HttpError extends Error implements HttpResponse {
	constructor(
		public url: string,
		public method: string,
		public statusCode: number,
		public headers: any,
		public body: any
	) {
		super(`HTTP Error: ${method} ${url} (${statusCode})`);
	}
}

class VatomInc {
	public addOns: AddOns;
	public blueprints: Blueprints;
	public types: Types;
	public designs: Designs;
	public digitalObjects: DigitalObjects;
	public behaviors: Behaviors;
	public businesses: Businesses;
	public loyalty: Loyalty;
	public events: Events;
	public templates: Templates;
	public resources: Resources;
	public me: Me;
	public users: Users;
	public campaigns: Campaigns;
	public campaignGroups: CampaignGroups;
	public viewers: Viewers;
	public studioViewers: StudioViewers;
	public rules: Rules;
	public effects: Effects;
	public views: Views;
	public rulesEntities: RulesEntities;
	public account: Account;
	public invoices: Invoices;
	public billing: Billing;
	public plans: Plans;
	public budget: Budget;
	public contactLists: ContactLists;
	public admin: Admin;
	public links: Links;
	public distributions: Distributions;
	public providers: Providers;
	public wizards: Wizards;
	public userWizards: UserWizards;
	public filesystem: FileSystem;
	public analytics: Analytics;
	public points: Points;
	public webhooks: Webhooks;
	public walletConfig: WalletConfig;
	public brandConfig: BrandConfig;
	public clients: Client;
	public communities: Communities;
	public tokengating: TokenGating;
	static businessId?: string;
	static userId?: string;
	static algoliaKey?: string;

	get userId(): string | undefined {
		return VatomInc.userId;
	}

	set userId(userId: string | undefined) {
		VatomInc.userId = userId;
	}

	get businessId(): string | undefined {
		return VatomInc.businessId;
	}

	set businessId(businessId: string | undefined) {
		VatomInc.businessId = businessId;
	}

	get algoliaKey(): string | undefined {
		return VatomInc.algoliaKey;
	}

	set algoliaKey(algoliaKey: string | undefined) {
		VatomInc.algoliaKey = algoliaKey;
	}

	// setup all subcomponents of varius api
	constructor() {
		this.addOns = new AddOns(this);
		this.blueprints = new Blueprints(this);
		this.types = new Types(this);
		this.designs = new Designs(this);
		this.views = new Views(this);

		this.campaigns = new Campaigns(this);
		this.campaignGroups = new CampaignGroups(this);
		this.budget = new Budget(this);
		this.digitalObjects = new DigitalObjects(this);
		this.behaviors = new Behaviors(this);
		this.businesses = new Businesses(this);
		this.loyalty = new Loyalty(this);
		this.events = new Events(this);
		this.templates = new Templates(this);
		this.links = new Links(this);
		this.resources = new Resources(this);
		this.users = new Users(this);
		this.me = new Me(this);
		this.viewers = new Viewers(this);
		this.studioViewers = new StudioViewers(this);
		this.rules = new Rules(this);
		this.effects = new Effects(this);
		this.rulesEntities = new RulesEntities(this);
		this.account = new Account(this);
		this.invoices = new Invoices(this);
		this.plans = new Plans(this);
		this.billing = new Billing(this);
		this.contactLists = new ContactLists(this);
		this.admin = new Admin(this);
		this.distributions = new Distributions(this);
		this.providers = new Providers(this);
		this.wizards = new Wizards(this);
		this.userWizards = new UserWizards(this);
		this.filesystem = new FileSystem(this);
		this.analytics = new Analytics(this);
		this.points = new Points(this);
		this.webhooks = new Webhooks(this);
		this.walletConfig = new WalletConfig(this);
		this.brandConfig = new BrandConfig(this);
		this.clients = new Client(this);
		this.communities = new Communities(this);
		this.tokengating = new TokenGating(this);
	}

	getApiBase(service: RequestService): string {
		const key = `varius-${service}-api-base`;

		// look for `api-host` query parameter

		const parsed = queryString.parse(window.location.search);
		const queryStringApiHost: any = parsed[`provider-${service}-host`];

		if (queryStringApiHost !== undefined) {
			localStorage.setItem(key, queryStringApiHost);
			return queryStringApiHost;
		}

		// if no parameter was passed, return what is in localStorage

		const sessionStorageApiHost: string | undefined = localStorage.getItem(key) || undefined;

		if (sessionStorageApiHost !== undefined) {
			return sessionStorageApiHost;
		}

		// if the api base was not customized at runtime, then return config at build time

		const envKey = `REACT_APP_${service.toUpperCase()}_SERVER`;
		const envValue = process.env[envKey];
		if (envValue !== undefined) {
			return envValue;
		}

		throw new Error(`no env value defined for key ${envKey}`);
	}

	setApiBase(service: RequestService, value: string) {
		const key = `varius-${service}-api-base`;

		if (value === "") {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, value);
		}
	}

	private getResContentMimeType(res: Response): string {
		const headerValue = res.headers.get("content-type") || "";
		const contentType = headerValue.split(";")[0];
		return contentType;
	}

	private async getResBody(res: Response): Promise<any> {
		const mime = this.getResContentMimeType(res);

		if (mime === "application/json") {
			return res.json();
		} else if (mime === "text/csv") {
			return res.text();
		} else if (!mime) {
			return undefined;
		}

		throw new Error(`unrecognized res content mime type ${mime}`);
	}

	public async request({
		method,
		body,
		service = RequestService.Api,
		path,
		isAuthed,
		isAbsoluteUrl = false,
		headers = {},
		needErrorMessage = false,
	}: RequestOptions): Promise<HttpResponse> {
		if (localStorage.getItem("i18nextLng"))
			headers["Accept-Language"] = localStorage.getItem("i18nextLng");

		if (headers["Content-Type"] === undefined && method !== "GET") {
			headers["Content-Type"] = "application/json";
		}

		if (body instanceof FormData) {
			delete headers["Content-Type"];
		}

		if (body instanceof Buffer) {
			headers["Content-Encoding"] = "zlib";
		}

		if (isAuthed === undefined || isAuthed) {
			headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
		}

		const url = isAbsoluteUrl ? path : this.getApiBase(service) + path;

		const res = await fetch(url, {
			headers,
			method,
			mode: "cors",
			credentials: "same-origin",
			body:
				headers["Content-Type"] === "application/json" && !(body instanceof Buffer)
					? JSON.stringify(body)
					: body,
		});

		const resBody = await this.getResBody(res);
		const { status } = res;

		if (status >= 400 && status < 600 && !needErrorMessage) {
			throw new HttpError(url, method as string, res.status, res.headers, resBody);
		}

		return {
			method: method as string,
			body: resBody,
			statusCode: status,
			headers: res.headers,
		};
	}

	// http related stuff here

	public get(options: RequestOptions) {
		return this.request({ ...options, method: "GET" });
	}

	public post(options: RequestOptions) {
		return this.request({ ...options, method: "POST" });
	}

	public put(options: RequestOptions) {
		return this.request({ ...options, method: "PUT" });
	}

	public patch(options: RequestOptions) {
		return this.request({ ...options, method: "PATCH" });
	}

	public delete(options: RequestOptions) {
		return this.request({ ...options, method: "DELETE" });
	}
}

export default new VatomInc();
