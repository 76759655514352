import VatomInc, { RequestService } from "utils/VatomIncApi/index";
import { BusinessLicenses } from "./account";
import authActions from "actions/authActions";

export default class VatomIncPlans {
	constructor(private varius: typeof VatomInc) {}

	/**
	 * @fetchPlans
	 * get all plan options, descriptions, meta plus user current active plan and subscription
	 *
	 * @returns {Promise<any>}
	 */
	public async fetchPlans(
		audience: string,
		showInternal = false,
		businessId?: string | undefined,
		dispatch?: any
	): Promise<BusinessLicenses> {
		const EXPECTED_STATUS_CODE = 200;

		let _businessId = businessId;
		if (!_businessId) {
			_businessId = this.varius.businessId;
		}
		if (!_businessId) {
			const businessName : string = window.location.pathname.split("/")[1];
			const business = await VatomInc.businesses.getBusiness(businessName);
			_businessId = business.id;
			if (dispatch) {
				dispatch(authActions.updateUserRoles(_businessId));
			}
		}

		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${_businessId}/products?audience=${audience}&showInternal=${showInternal}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Products could not be fetched");
		}
		return res.body;
	}

	/**
	 * @fetchCurrentPlan
	 * note:  this function is not used yet, current plan is sent through in fetchplans
	 *
	 * @returns {Promise<any>}
	 */
	public async fetchCurrentPlan() {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Billing,
			path: `/b/${this.varius.businessId}/subscriptions`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Subscriptions could not be fetched");
		}
		return res.body;
	}

	/**
	 * @updateSubscription
	 *
	 * @param {string} planId
	 * @param {string} subscriptionId
	 * @returns {Promise<any>}
	 */
	public async updateSubscription(planId: string, subscriptionId?: string) {
		const EXPECTED_STATUS_CODE = 200;

		if (subscriptionId) {
			const res = await this.varius.patch({
				service: RequestService.Billing,
				path: `/b/${this.varius.businessId}/subscriptions/${subscriptionId}`,
				body: {
					planId,
				},
				isAuthed: true,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Subscription could not be updated");
			}
			return res.body;
		} else {
			const res = await this.varius.post({
				service: RequestService.Billing,
				path: `/b/${this.varius.businessId}/subscriptions`,
				body: {
					planId,
				},
				isAuthed: true,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE && res.statusCode !== 204) {
				throw new Error("Subscription could not be updated");
			}
			return res.body;
		}
	}
}
