import { BudgetActions, BudgetActionTypes } from "actions/budgetActions";

export interface MetaData {
	max_rules_per_campaign: number;
	max_seats: number;
	included_seats: number;
	max_object_types_per_campaign: number;
}

export interface CurrentLicense {
	name: string;
	relatedPlanId: string;
	limits: {
		max_rules_per_campaign: number;
		max_seats: number;
		included_seats: number;
		max_object_types_per_campaign: number;
	};
}

export interface UpgradeLicense {
	name: string;
	limits: {
		max_seats: string;
		included_seats: number;
		max_rules_per_campaign: string;
		max_object_types_per_campaign: string;
	};
	relatedPlanId: string;
}

export interface CalculatedBudget {
	numUnits: number;
	numUnitsUpgrade: number;
	currentLicense: CurrentLicense;
	upgradeLicense: UpgradeLicense;
}

interface BudgetCalculatorState {
	currentBudget: CalculatedBudget | null;
	loading: boolean;
	budgetAmount: string;
	error: string | null;
}

const initialState: BudgetCalculatorState = {
	currentBudget: null,
	budgetAmount: "",
	loading: false,
	error: null,
};

export default function budgetReducers(state = initialState, action: BudgetActions) {
	const { type, data } = action;
	switch (type) {
		case BudgetActionTypes.START_CALCULATE_BUDGET:
			return {
				...state,
				loading: true,
			};
		case BudgetActionTypes.SUCCESS_CALCULATE_BUDGET:
			return {
				...state,
				loading: false,
				currentBudget: data,
			};
		case BudgetActionTypes.ERROR_CALCULATE_BUDGET:
			return {
				...state,
				error: data,
				loading: false,
			};
		case BudgetActionTypes.CHANGE_BUDGET_AMOUNT:
			return {
				...state,
				budgetAmount: data,
			};
		default:
			return state;
	}
}

const budgetState = state => state.budget as BudgetCalculatorState;

export const budgetSelectors = {
	getBudget: state => budgetState(state).currentBudget,
	isLoading: state => budgetState(state).loading,
	error: state => budgetState(state).error,
	budgetAmount: state => budgetState(state).budgetAmount,
};
