import VatomInc from "./index";

// Interfaces
export interface TreasuryBalanceI {
	"points": string
}

const LOYALTY_API = process.env.REACT_APP_LOYALTY_SERVER || "https://loyalty.api.vatominc.com";

export default class VatomIncLoyalties {

	constructor(private varius: typeof VatomInc) {}

  public async getTreasuryBalance(): Promise<any> {
    try {
      const res: any = await this.varius.get({
        path: `${LOYALTY_API}/b/${this.varius.businessId}/coins/`,
        isAuthed: true,
        isAbsoluteUrl: true
      });

      return res.body;
    } catch (e:any) {
      return e;
    }
  }

  public async fundTreasury(points: number): Promise<any> {
    try {
      const res: any = await this.varius.post({
        path: `${LOYALTY_API}/b/${this.varius.businessId}/coins/`,
        isAuthed: true,
        isAbsoluteUrl: true,
        body: { points }
      });

      return res.body;
    } catch (e:any) {
      return e;
    }
  }
}