import { Space } from "./entitiesReducers";
import {SpacesActionsKeys} from "actions/spacesActions"

interface SpacesState {
  all: Record<string, Space>;
  spaceOrder: string[];
  loadStatus: string;
  loadListError: string | null;
}

const initialState: SpacesState = {
  all: {},
  spaceOrder: [],
  loadStatus: 'initial',
  loadListError: null,
};

const spaces = (
  state: SpacesState = initialState,
  action: { type: string; data?: any; error?: string }
): SpacesState => {
  switch (action.type) {
    case SpacesActionsKeys.SPACES_LIST_SUCCESS: {
      const spacesData = action.data;
      const updatedAll = spacesData.items.reduce((acc: Record<string, Space>, space: Space) => {
        acc[space.id] = space;
        return acc;
    }, {});

    return {
        ...state,
        all: updatedAll,
        spaceOrder: spacesData.items.map((space) => space.id),
        loadStatus: 'success',
        loadListError: null,
    };
}

    case SpacesActionsKeys.SPACES_LIST_FAILURE: {
      return {
        ...state,
        loadStatus: 'failure',
        loadListError: action.error || 'Unknown error',
      };
    }

    default: {
      return state;
    }
  }
};

const selectSpacesState = (state: any): SpacesState => state.entities.spaces;

export const selectors = {
  all: (state: any) => selectSpacesState(state).all,
  spaceOrder: (state: any) => selectSpacesState(state).spaceOrder,
  loadStatus: (state: any) => selectSpacesState(state).loadStatus,
  loadListError: (state: any) => selectSpacesState(state).loadListError,
};

export default spaces;
